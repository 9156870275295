import React from "react";
import style from "./Information.module.scss";
import GlobalHeader from "../../components/organisms/GlobalHeader/GlobalHeader";
import GlobalFooter from "../../components/organisms/GlobalFooter/GlobalFooter";
import { Div, Main } from "../../components/atoms/Html/Html";
import LoadingWrapper from "../../components/organisms/LoadingWrapper/LoadingWrapper";
import ContentBoxGroup from "../../components/organisms/ContentBoxGroup/ContentBoxGroup";
import ScrollView from "../../components/atoms/ScrollView/ScrollView";

class Information extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    if (this.props.information.initInformationFlag) {
      this.props.fetchInformationList();
      this.props.setInitInformationFlag(false);
    }
  }

  handleClickFaq() {
    this.props.history.push("/faq");
  }

  handleClickSettings() {
    this.props.history.push("/settings");
  }

  handleClickRetry() {
    this.props.setIsErrorFetchingInformationList(false);
    this.props.setInformationMsg("");
    this.props.fetchInformationList();
  }

  render() {
    const leftContent = [{ label: "bell", isActive: true }];
    const rightContent = [
      { label: "faq", action: this.handleClickFaq.bind(this) },
      { label: "settings", action: this.handleClickSettings.bind(this) }
    ];
    const informationList = this.props.information.informationList.map(
      function(element) {
        return {
          type: "base",
          contentBoxStyle:
            style.informationBlock__main__scrollViewBlock__contentBox,
          body: {
            title: element.title,
            date: element.date,
            text: element.text
          },
          exLinkUrl: element.linkUrl,
          exLinkText: element.linkText
        };
      }
    );
    return (
      <>
        <GlobalHeader
          title={this.props.intl.formatMessage({ id: "information" })}
          leftContent={leftContent}
          rightContent={rightContent}
        />
        <Main className={style.informationBlock__main}>
          <ScrollView
            contentContainerStyle={
              style.informationBlock__main__scrollViewBlock
            }
          >
            <LoadingWrapper
              isConnecting={this.props.information.isFetchingInformationList}
              loadingStyle={
                style.informationBlock__main__scrollViewBlock__loadingBlock
              }
              errorText={this.props.information.errors.msg}
              onRetry={this.handleClickRetry.bind(this)}
              retryText={this.props.intl.formatMessage({ id: "get.again" })}
              errorStyle={
                style.informationBlock__main__scrollViewBlock__errorBlock
              }
            >
              {!this.props.information.isFetchingInformationList &&
                this.props.information.errors.msg === "" && (
                  <ContentBoxGroup contents={informationList} />
                )}
            </LoadingWrapper>
          </ScrollView>
        </Main>
        <GlobalFooter
          intl={this.props.intl}
          pathname={this.props.history.location.pathname}
        />
      </>
    );
  }
}

export default Information;
