import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  fetchInformationList,
  setIsErrorFetchingInformationList,
  setInformationMsg,
  setInitInformationFlag
} from "../actions/information";

import Information from "../../web/pages/Information/Information";

const mapStateToProps = state => {
  return { information: state.information };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchInformationList: () => {
      dispatch(fetchInformationList());
    },
    setIsErrorFetchingInformationList: bool => {
      dispatch(setIsErrorFetchingInformationList(bool));
    },
    setInformationMsg: msg => {
      dispatch(setInformationMsg(msg));
    },
    setInitInformationFlag: bool => {
      dispatch(setInitInformationFlag(bool));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Information)
);
