import React from "react";
import style from "./AiHelpsYou.module.scss";
import GlobalHeader from "../../components/organisms/GlobalHeader/GlobalHeader";
import { Div, Main, P, A, Img } from "../../components/atoms/Html/Html";
import ChatBot, { Loading } from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import botIcon from "../../../assets/images/icon_robot_face.svg";
import arrowIcon from "../../../assets/images/icon_arrow_blue_right.svg";
import { getLanguage, escapeString, getFormattedMessage } from "../../constants/functions";
import JapaneseHowToDisposeImage from "../../../assets/images/how_to_dispose_ja.png";
import englishHowToDisposeImage from "../../../assets/images/how_to_dispose_en.png";

class BotDesc extends React.Component {
  constructor(props) {
    super(props);
    this.additionalProps = {};
  }

  componentWillMount() {
    if (this.props.dispatchProps) {
      this.additionalProps = this.props.dispatchProps();
    }
  }

  hundleClickButton(e) {
    if (this.props.type === "exlink") {
      window.open(this.props.link);
    } else if (this.props.type === "howToDispose") {
      const id = e.currentTarget.dataset.id;
      // 粗大ゴミ("oversized_trash")の場合は/oversizedに、それ以外は/how_to_dispose/detailsに飛びます。
      if(id === "oversized_trash"){
        this.props.history.push("/oversized");
      }else{
        this.props.history.push({
          pathname: "/how_to_dispose/details",
          state: { trashType: id }
        });
      }
    } else if (this.props.type === "select") {
      this.props.setUserMessage(
        this.props.children[e.currentTarget.dataset.id].text
      );
      this.props.setResults(this.props.children[e.currentTarget.dataset.id]);
      this.props.triggerNextStep({
        trigger: "userDesc"
      });
    }
  }

  render() {
    const { disposalList } = this.props,
      hundleClickButton = this.hundleClickButton.bind(this);
    return (
      <Div className={style.aiHelpsYouBlock__main__chatBot__botDesc}>
        <Img
          src={botIcon}
          className={style.aiHelpsYouBlock__main__chatBot__botDesc__icon}
        />
        <Div
          className={style.aiHelpsYouBlock__main__chatBot__botDesc__descBlock}
        >
          {this.additionalProps.loading ? (
            <Loading />
          ) : (
            <>
              {this.props.message && (
                <Div
                  escapeText={escapeString(this.props.message)}
                  className={
                    style.aiHelpsYouBlock__main__chatBot__botDesc__descBlock__content
                  }
                />
              )}
              {this.props.type === "exlink" && (
                <ButtonBlock
                  hundleClick={hundleClickButton}
                  buttonText={this.props.link}
                />
              )}
              {this.props.type === "howToDispose" && (
                <>
                  {this.props.details.map(function(element) {
                    // 粗大ゴミ("oversized_trash")の場合のみ例外的にtrueを入れます。
                    const found = element.id === "oversized_trash" ? true : disposalList.details.find(function(values) {
                      return values.type === element.id;
                    });
                    if (found !== undefined) {
                      const linkText = getFormattedMessage('click.here.for.how.to.put.out', element.name);
                      return (
                        <ButtonBlock
                          hundleClick={hundleClickButton}
                          buttonText={linkText}
                          id={element.id}
                        />
                      );
                    }
                  })}
                </>
              )}
              {this.props.src && (
                <Img
                  src={this.props.src}
                  className={style.botDesc__image}
                  /*src={
                  getLanguage() === "en"
                    ? englishHowToDisposeImage
                    : JapaneseHowToDisposeImage
                }*/
                />
              )}
              {this.props.children && this.props.children.length && (
                <>
                  {this.props.children.map(function(element, index) {
                    return (
                      <ButtonBlock
                        hundleClick={hundleClickButton}
                        buttonText={element.text}
                        id={index}
                      />
                    );
                  })}
                </>
              )}
            </>
          )}
        </Div>
      </Div>
    );
  }
}

const ButtonBlock = props => {
  return (
    <Div>
      <A
        className={
          style.aiHelpsYouBlock__main__chatBot__botDesc__descBlock__link
        }
        onClick={props.hundleClick}
        dataId={props.id}
      >
        <Img
          src={arrowIcon}
          className={
            style.aiHelpsYouBlock__main__chatBot__botDesc__descBlock__link__arrow
          }
        />
        {props.buttonText}
      </A>
    </Div>
  );
};

class BotAnswer extends React.Component {
  constructor(props) {
    super(props);
    this.additionalProps = {};
  }

  componentWillMount() {
    this.additionalProps = this.props.dispatchProps();
  }

  componentDidMount() {
    if (!this.additionalProps.loading) {
      const results = this.additionalProps.results;
      // toDo: resultの中身をtriggerNextStepの引数にいれる。Optionで選択したものなど
      this.props.triggerNextStep({
        value: results.length ? results[results.length - 1].userAction : false
      });
    }
  }

  render() {
    const {
      history,
      triggerNextStep,
      setResults,
      setUserMessage,
      disposalList,
      dispatchProps
    } = this.props;
    return (
      <>
        {this.additionalProps.results.map(function(element) {
          return (
            <BotDesc
              {...element}
              history={history}
              triggerNextStep={triggerNextStep}
              setResults={setResults}
              setUserMessage={setUserMessage}
              disposalList={disposalList}
              dispatchProps={dispatchProps}
            />
          );
        })}
      </>
    );
  }
}

class UserAnswer extends React.Component {
  componentWillMount() {
    this.additionalProps = this.props.dispatchProps();
  }
  render() {
    const { userMessage } = this.additionalProps;
    return (
      <Div className={style.userAnswer}>
        <Div className={style.userAnswer__text}>{userMessage}</Div>
      </Div>
    );
  }
}

class Fetch extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.setState(() => {
      return {isFetching: true};
    });

    (async () => {
      await this.props.handleSendText(this.props.previousStep.value);
      this.props.triggerNextStep({
        trigger: "3"
      });
      this.setState(() => {
        return {isFetching: false};
      });
    })();
  }

  render() {
    return (() => {
      return this.state.isFetching ? (<Div className={style.loading}>...</Div>) : (<></>);
    })();
  }
}

class AiHelpsYou extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    if (!Number.isInteger(this.props.common.status.authority.id)) {
      this.props.history.push("/settings/account/address");
    }
  }

  handleClickArrow() {
    this.props.history.goBack();
  }

  dispatchProps() {
    return this.props.aiHelpsYou;
  }

  async handleSendText(value) {
    await this.props.fetchAiAnswer(
      value,
      this.props.common.status.authority.id
    );
  }

  handleNextStep(object) {
    if (object.value === "3") {
      return "3";
    } else if (object.value === "userDesc") {
      return "userDesc";
    } else {
      return "search";
    }
  }

  render() {
    const leftContent = [
      { label: "arrow", action: this.handleClickArrow.bind(this) }
    ];
    const chatBotStyles = {
      container: {
        display: "flex",
        flexDirection: "column"
      },
      content: {
        flexGrow: 1
      },
      bubble: {
        fontWeight: "var(--font_weight_text)",
        fontSize: "var(--font_size_question)",
        padding: "7px 10px",
        lineHeight: "var(--line_height_l)",
        borderRadius: 13,
        boxShadow: "inherit",
        maxWidth: "calc(100% - 100px)",
        marginTop: 6,
        wordBreak: "keep-all",
        lineBreak: "strict",
        wordWrap: "break-word",
        overflowWrap: "break-word"
      },
      custom: {
        margin: 0,
        padding: 0,
        display: "inherit",
        background: "inherit",
        borderRadius: "inherit",
        boxShadow: "inherit",
        WebkitBoxPack: "inherit",
        WebkitJustifyContent: "inherit",
        justifyContent: "inherit"
      }
    };
    const theme = {
      background: "#f9f9f9",
      userBubbleColor: "#5ec8db",
      userFontColor: "#fff"
    };
    return (
      <>
        <GlobalHeader
          title="AI Helps You"
          leftContent={leftContent}
          subtitle={
            this.props.common.status.authority.name +
            this.props.common.status.block.name
          }
        />
        <Main className={style.aiHelpsYouBlock__main}>
          <ThemeProvider theme={theme}>
            <ChatBot
                enableSmoothScroll
              customStyle={chatBotStyles.custom}
              className={style.aiHelpsYouBlock__main__chatBot}
              style={chatBotStyles.container}
              contentStyle={chatBotStyles.content}
              hideHeader
              placeholder={this.props.intl.formatMessage({
                id: "type.your.text"
              })}
              width="100%"
              height="100%"
              bubbleStyle={chatBotStyles.bubble}
              hideUserAvatar
              customDelay={0}
              steps={[
                {
                  id: "1",
                  component: (
                    <BotDesc
                      message={this.props.intl.formatMessage({
                        id: "enter.the.name.of.the.trash.you.want.to.check"
                      })}
                      history={this.props.history}
                    />
                  ),
                  trigger: "search",
                  delay: 0
                },
                {
                  id: "search",
                  validator: value => {
                    if (!value) {
                      return this.props.intl.formatMessage({
                        id: "enter.the.name.of.the.trash.you.want.to.check"
                      });
                    }
                    return true;
                  },
                  user: true,
                  trigger: "fetch"
                },
                {
                  id: "3",
                  component: (
                    <BotAnswer
                      dispatchProps={this.dispatchProps.bind(this)}
                      history={this.props.history}
                      setResults={this.props.setResults}
                      setUserMessage={this.props.setUserMessage}
                      disposalList={this.props.common.disposalList}
                    />
                  ),
                  waitAction: true,
                  trigger: this.handleNextStep.bind(this)
                },
                {
                  id: "userDesc",
                  component: (
                    <UserAnswer dispatchProps={this.dispatchProps.bind(this)} />
                  ),
                  trigger: "3",
                  delay: 0
                },
                {
                  id: "fetch",
                  component: (
                    <Fetch handleSendText={this.handleSendText.bind(this)} />
                  ),
                  waitAction: true,
                }
              ]}
            />
          </ThemeProvider>
        </Main>
      </>
    );
  }
}

export default AiHelpsYou;
