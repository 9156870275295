import {localApiUrl, developmentApiUrl, productionApiUrl } from "../../web/constants/constants";
import {
  getRequestHeader,
  isStatusOk,
  getFormattedMessage
} from "../../web/constants/functions";
import { logOut } from "./auth";
import { store } from "../../index";

export const REQUEST_FETCH_AI_ANSWER = "REQUEST_FETCH_AI_ANSWER";

function requestFetchAiAnswer() {
  return {
    type: REQUEST_FETCH_AI_ANSWER
  };
}

export const SUCCESS_FETCH_AI_ANSWER = "SUCCESS_FETCH_AI_ANSWER";

function successFetchAiAnswer(data) {
  return {
    type: SUCCESS_FETCH_AI_ANSWER,
    data
  };
}

export const ERROR_FETCH_AI_ANSWER = "ERROR_FETCH_AI_ANSWER";

function errorFetchAiAnswer(msg) {
  return {
    type: ERROR_FETCH_AI_ANSWER,
    msg
  };
}

export const ERROR_AUTH_FETCH_AI_ANSWER = "ERROR_AUTH_FETCH_AI_ANSWER";

function errorAuthFetchAiAnswer() {
  return {
    type: ERROR_AUTH_FETCH_AI_ANSWER
  };
}

export async function fetchAiAnswer(value, authority_id) {
  store.dispatch(requestFetchAiAnswer());
  const params = new URLSearchParams();
  params.set("text", value);
  params.set("authority_id", authority_id);
  try {
    const response = await fetch(
      /*developmentApiUrl*/ productionApiUrl + "/chats?" + params.toString(),
      {
        headers: getRequestHeader()
      }
    );
    const json = await response.json();
    const status = await response.status;
    await (function() {
      if (isStatusOk(status)) {
        store.dispatch(successFetchAiAnswer(json.data));
      } else if (status === 401) {
        store.dispatch(logOut());
        store.dispatch(errorAuthFetchAiAnswer());
      } else {
        store.dispatch(errorFetchAiAnswer(getFormattedMessage('failed.to.get.information.')));
      }
    })();
  } catch (error) {
    store.dispatch(errorFetchAiAnswer(getFormattedMessage('communication.failed.')));
  }
}

export const SET_RESULTS = "SET_RESULTS";

export function setResults(values) {
  return {
    type: SET_RESULTS,
    values
  };
}

export const SET_USER_MESSAGE = "SET_USER_MESSAGE";

export function setUserMessage(value) {
  return {
    type: SET_USER_MESSAGE,
    value
  };
}
