import React from "react";
import { Formik, Form } from "formik";
import FormParts from "../../molecules/FormParts/FormParts";
import styles from "./FirstRegisterFrom.module.scss";

const FirstRegisterFrom = props => (
  <Formik>
    <Form className={styles.firstRegisterFromBlock__form}>
      <FormParts
        label=""
        name="region"
        component="select"
        options={props.options}
        size="large"
        intl={props.intl}
        handleChange={props.handleChangeSelectRegion}
      />
    </Form>
  </Formik>
);

export default FirstRegisterFrom;
