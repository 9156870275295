import React from "react";
import styles from "./ResetPassword.module.scss";
import ResetPasswordForm from "../../components/register/ResetPasswordForm/ResetPasswordForm";
import Logo from "../../components/molecules/Logo/Logo";
import TextLinkBlock from "../../components/molecules/TextLinkBlock/TextLinkBlock";
import { Div } from "../../components/atoms/Html/Html";
import Modal from "../../components/organisms/Modal/Modal";
import { Redirect } from "react-router-dom";
import { getUrlQueries, getFormattedMessage } from "../../constants/functions";

const ResetPassword = props => {
  // ログインしている場合、/に飛ばします。
  if (props.auth.isLoggedIn) {
    return <Redirect to={"/"} />
  }

  // tokenなどがない場合、/loginに飛ばします。
  const queries = getUrlQueries();
  if (!queries["access-token"] || !queries.client || !queries.uid) {
    return <Redirect to={"/login"} />
  }

  let modal = null;
  if (props.auth.flags.resettingPasswordFlag) {
    modal = (<Modal type={"loading"} text={getFormattedMessage("connecting")} />);
  } else if (props.auth.flags.successResetPasswordFlag) {
    modal = (
      <Modal
        type={"choice"}
        text={props.auth.msg}
        choiceList={[
          {
            label: getFormattedMessage("ok"),
            action: () => {
              props.setValues({ msg: "" });
              props.setFlags({ successResetPasswordFlag: false });
              props.history.push("/login");
            }
          }
        ]}
      />
    );
  } else if (props.auth.errors.tokenErrorFlagOfResetPassword) {
    modal = (
      <Modal
        type={"choice"}
        text={props.auth.errors.msg}
        choiceList={[
          {
            label: getFormattedMessage("ok"),
            action: () => {
              props.setErrors({ msg: "", tokenErrorFlagOfResetPassword: false });
              props.history.push("/forget_password");
            }
          }
        ]}
      />
    );
  } else if (props.auth.errors.communicationFailedFlagOfResetPassword) {
    modal = (
      <Modal
        type={"choice"}
        text={props.auth.errors.msg}
        choiceList={[
          {
            label: getFormattedMessage("ok"),
            action: () => {
              props.setErrors({ msg: "", communicationFailedFlagOfResetPassword: false });
            }
          }
        ]}
      />
    );
  }

  return (
    <Div className={styles.resetPasswordBlock}>
      <Div />
      <Div>
        <Div className={styles.resetPasswordBlock__logo}>
          <Logo type="blue" />
        </Div>
        <ResetPasswordForm
          submitData={(values) => { props.resetPassword(values) }}
          validations={props.auth.validations.resetPassword}
        />
        <TextLinkBlock
          linkText={getFormattedMessage("back.to.login")}
          handleClick={() => { props.history.push("/login") }}
        />
      </Div>
      <TextLinkBlock
        text={getFormattedMessage("dont.you.have.an.account?")}
        linkText={getFormattedMessage("sign.up")}
        handleClick={() => { props.history.push("/register/select_region") }}
      />
      {modal && modal}
    </Div>
  );
}

export default ResetPassword;
