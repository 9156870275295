import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import LanguageSetting from "./LanguageSetting";
import SelectRegion from "./SelectRegion";
import MoreInfomations from "./MoreInfomations";
import CheckMail from "./CheckMail";
import ReadyToUse from "./ReadyToUse";

const Register = props => {
  return (
    <>
      {(() => {
        if (props.history.location.pathname === "/register/ready_to_use") {
          return (
            <Route
              path="/register/ready_to_use"
              render={() => <ReadyToUse {...props} />}
            />
          );
        } else if (props.auth.isLoggedIn) {
          return <Redirect to={"/"} />;
        } else if (
          props.history.location.pathname === "/register/language_setting"
        ) {
          return (
            <Route
              path="/register/language_setting"
              render={() => <LanguageSetting {...props} />}
            />
          );
        } else if (
          props.history.location.pathname === "/register/select_region" &&
          props.user.availableRegisterPage.select_region === true
        ) {
          return (
            <Route
              path="/register/select_region"
              render={() => <SelectRegion {...props} />}
            />
          );
        } else if (
          props.history.location.pathname === "/register/more_information" &&
          props.user.availableRegisterPage.more_information === true
        ) {
          return (
            <Route
              path="/register/more_information"
              render={() => <MoreInfomations {...props} />}
            />
          );
        } else if (
          props.history.location.pathname === "/register/check_mail" &&
          props.user.availableRegisterPage.check_mail === true
        ) {
          return (
            <Route
              path="/register/check_mail"
              render={() => <CheckMail {...props} />}
            />
          );
        } else {
          props.resetUserState();
          return <Redirect to={"/register/language_setting"} />;
        }
      })()}
    </>
  );
};

export default Register;
