import React from "react";
import SwipeableViews from "react-swipeable-views";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import style from "./GeneralTab.module.scss";
import {capitalize} from "@material-ui/core/utils";

function GeneralTab(props) {
  function handleChange(event, value) {
    props.changeTab(value);
  }

  function handleChangeIndex(index) {
    props.changeTab(index);
  }

  const useStyles = makeStyles({
    root: {
      background: "#fff",
      height: 50
    },
    indicator: {
      backgroundColor: "#5ec8db"
    },
    textColorInherit: {
      width: "50%",
      minWidth: "50%",
      padding: 0,
      color: "#666",
      opacity: 1
    },
    wrapper: {
      fontFamily: "var(--font_family_display)",
      fontWeight: "var(--font_weight_heading)",
      fontSize: "var(--font_size_heading_s)",
      lineHeight: "var(--line_height_m)",
      textTransform: "capitalize"
    },
    selected: {
      color: "#5ec8db"
    }
  });

  const classes = useStyles();
  const slideStyles = {
    slideContainer: {
      height: "100%",
      WebkitOverflowScrolling: "touch" // iOS momentum scrolling
    }
  };

  return (
    <>
      <Tabs
        value={props.index}
        onChange={handleChange}
        classes={{
          root: classes.root,
          indicator: classes.indicator
        }}
      >
        {props.tabList.map(value => (
          <Tab
            label={value.label}
            classes={{
              textColorInherit: classes.textColorInherit,
              wrapper: classes.wrapper,
              selected: classes.selected
            }}
          />
        ))}
      </Tabs>
      <SwipeableViews
        enableMouseEvents
        index={props.index}
        onChangeIndex={handleChangeIndex}
        containerStyle={slideStyles.slideContainer}
        className={style.swipeableBlock}
      >
        {props.tabList.map(value => (
          <>{value.component}</>
        ))}
      </SwipeableViews>
    </>
  );
}

export default GeneralTab;
