import React from "react";
import { Field } from "formik";
import styles from "./FormParts.module.scss";
import { Label, Div, Option, P, Span } from "../../atoms/Html/Html";
import { isObject } from "../../../constants/functions";

const FormParts = props => {
  return (
    <>
      <Label className={styles.formPartsBlock__label}>
        {props.label}
        {props.required && (
          <Span className={styles.formPartsBlock__label__required}>
            {"(" + props.intl.formatMessage({ id: "required" }) + ")"}
          </Span>
        )}
      </Label>
      {(() => {
        switch (props.component) {
          case "input":
            return (
              <Field
                name={props.name}
                component={props.component}
                type={props.type}
                className={styles.formPartsBlock__input}
                onChange={e => {
                  props.handleChange && props.handleChange(e);
                  if (
                    e.target.name !== "password" &&
                    e.target.name !== "password_confirmation"
                  ) {
                    props.handleChangeSecondRegisterForm &&
                      props.handleChangeSecondRegisterForm(
                        e.target.name,
                        e.target.value
                      );
                  }
                }}
              />
            );
          case "select":
            return (
              <Div className={styles.formPartsBlock__selectBlock}>
                <Field
                  component={props.component}
                  name={props.name}
                  className={
                    styles.formPartsBlock__selectBlock__select +
                    " " +
                    (props.size === "large"
                      ? styles.formPartsBlock__selectBlock__largeSelect
                      : "") +
                    " " +
                    (props.isFetching || props.disabled
                      ? styles.formPartsBlock__selectBlock__disabled
                      : "")
                  }
                  disabled={(props.isFetching || props.disabled) && "disabled"}
                  onChange={e => {
                    if (e.target.name === "prefecture_id") {
                      const tmpAuthorityIdEvent = {
                        target: {
                          name: "authority_id",
                          value: ""
                        }
                      };
                      const tmpBlockIdEvent = {
                        target: {
                          name: "block_id",
                          value: ""
                        }
                      };
                      props.handleChange &&
                        props.handleChange(tmpAuthorityIdEvent);
                      props.handleChange && props.handleChange(tmpBlockIdEvent);
                    } else if (e.target.name === "authority_id") {
                      const tmpBlockIdEvent = {
                        target: {
                          name: "block_id",
                          value: ""
                        }
                      };
                      props.handleChange && props.handleChange(tmpBlockIdEvent);
                    }
                    props.handleChange && props.handleChange(e);
                    props.handleChangeSecondRegisterForm &&
                      props.handleChangeSecondRegisterForm(
                        e.target.name,
                        e.target.value
                      );
                  }}
                >
                  {props.isFetching && (
                    <Option value="">
                      {props.intl.formatMessage({ id: "connecting" })}
                    </Option>
                  )}
                  {props.options.map((element, index) => {
                    return (
                      <>
                        {index === 0 && (
                          <Option value="">
                            {props.intl.formatMessage({ id: "please.select" })}
                          </Option>
                        )}
                        <Option value={element.value}>{element.label}</Option>
                      </>
                    );
                  })}
                </Field>
              </Div>
            );
        }
      })()}
      {props.errors &&
        !isObject(props.errors) &&
        props.errors.map(element => {
          return <P className={styles.formPartsBlock__error}>{element}</P>;
        })}
    </>
  );
};

export default FormParts;
