import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { createStore, compose, applyMiddleware } from "redux";
import loggerMiddleware from "redux-logger";
import thunkMiddleware from "redux-thunk";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import rootReducer from "./redux/reducers";
import "./index.scss";

import App from "./redux/containers/AppContainer";
import { setIsRegistered, setToken, setIsLoggedIn } from "./redux/actions/auth";
import { setLanguage } from "./redux/actions/user";
import { Provider } from "react-redux";
import { Storage } from "./web/constants/functions";
import {logger} from "redux-logger/src";

const history = createBrowserHistory();

export const store = createStore(
  rootReducer(history),
  compose(
    applyMiddleware(
      routerMiddleware(history),
      thunkMiddleware
    )
  )
);

if (Storage.get("isRegistered") !== null) {
  store.dispatch(setIsRegistered(true));
} else {
  store.dispatch(setIsRegistered(false));
}

if (
  Storage.get("Access-Token") !== null &&
  Storage.get("Client") !== null &&
  Storage.get("Uid") !== null
) {
  store.dispatch(
    setToken(
      Storage.get("Access-Token"),
      Storage.get("Client"),
      Storage.get("Uid")
    )
  );
  store.dispatch(setIsLoggedIn(true));
} else {
  store.dispatch(setIsLoggedIn(false));
}

if (Storage.get("language") !== null) {
  store.dispatch(setLanguage(Storage.get("language")));
} else {
  store.dispatch(setLanguage("ja"));
}

navigator.geolocation.getCurrentPosition(() => {});

ReactDOM.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();