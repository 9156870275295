import React from "react";
import style from "./Oversized.module.scss";
import GlobalHeader from "../../components/organisms/GlobalHeader/GlobalHeader";
import GlobalFooter from "../../components/organisms/GlobalFooter/GlobalFooter";
import { Div, Main, Img, H2 } from "../../components/atoms/Html/Html";
import ContentBoxGroup from "../../components/organisms/ContentBoxGroup/ContentBoxGroup";
import LoadingWrapper from "../../components/organisms/LoadingWrapper/LoadingWrapper";
import MultipurposeButton from "../../components/molecules/MultipurposeButton/MultipurposeButton";
import iconWarning from "../../../assets/images/icon_warning.svg";
import Heading from "../../components/atoms/Heading/Heading";
import oversizedImage from "../../../assets/images/oversized_image.png";
import ScrollView from "../../components/atoms/ScrollView/ScrollView";

class Oversized extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    if (this.props.oversized.initOversizedFlag) {
      this.props.fetchOversizedList();
      this.props.setInitOversizedFlag(false);
    }
  }

  handleClickBell() {
    this.props.history.push("/information");
  }

  handleClickFaq() {
    this.props.history.push("/faq");
  }

  handleClickSettings() {
    this.props.history.push("/settings");
  }

  handleClickRetry() {
    this.props.setIsErrorFetchingOversizedList(false);
    this.props.setOversizedMsg("");
    this.props.fetchOversizedList();
  }

  hundleClickButton() {
    if (
      this.props.oversized.oversizedList.button &&
      this.props.oversized.oversizedList.button.buttonUrl
    ) {
      window.open(
        this.props.oversized.oversizedList.button.buttonUrl,
        "_blank"
      );
    }
  }

  render() {
    const leftContent = [
      { label: "bell", action: this.handleClickBell.bind(this) }
    ];
    const rightContent = [
      { label: "faq", action: this.handleClickFaq.bind(this) },
      { label: "settings", action: this.handleClickSettings.bind(this) }
    ];

    const contents = [
      {
        heading: this.props.intl.formatMessage({
          id: "definition.of.oversized.garbage"
        }),
        body: this.props.oversized.oversizedList.definition,
        exLinkUrl: "",
        exLinkText: ""
      },
      {
        heading: this.props.intl.formatMessage({
          id: "how.to.dispose.of.oversized.garbage"
        }),
        body: this.props.intl.formatMessage({
          id: "dispose.step.of.oversized.garbage"
        }),
        exLinkUrl: this.props.oversized.oversizedList.url,
        exLinkText: this.props.intl.formatMessage({
          id: "see.on.the.local.government.website"
        })
      },
      {
        heading: this.props.intl.formatMessage({ id: "ticket.prices" }),
        body:
          this.props.oversized.oversizedList.ticket_type +
          "\n" +
          this.props.oversized.oversizedList.example,
        exLinkUrl: "",
        exLinkText: ""
      }
    ];

    //const oversizedList = this.props.oversized.oversizedList.content.map(
    const oversizedList = contents.map(function(content) {
      return {
        type: "base",
        contentBoxStyle:
          style.oversizedBlock__main__scrollViewBlock__contentBox,
        heading: content.heading,
        body: {
          text: content.body,
          blockStyle: style.oversizedBlock__main__oversizedList
        },
        exLinkUrl: content.exLinkUrl,
        exLinkText: content.exLinkText
      };
    });
    const images = this.props.oversized.oversizedList.image_url
      ? this.props.oversized.oversizedList.image_url.map(url => (
          <Img src={url} className={style.oversizedBlock__imageBlock__image} />
        ))
      : "";

    return (
      <>
        <GlobalHeader
          title={this.props.intl.formatMessage({
            id: "oversized.consultation"
          })}
          subtitle={
            this.props.common.status.authority.name +
            this.props.common.status.block.name
          }
          leftContent={leftContent}
          rightContent={rightContent}
        />
        <Main className={style.oversizedBlock__main}>
          <ScrollView
            contentContainerStyle={style.oversizedBlock__main__scrollViewBlock}
          >
            <LoadingWrapper
              isConnecting={this.props.oversized.isFetchingOversizedList}
              loadingStyle={
                style.oversizedBlock__main__scrollViewBlock__loadingBlock
              }
              errorText={this.props.oversized.errors.msg}
              onRetry={this.handleClickRetry.bind(this)}
              retryText={this.props.intl.formatMessage({ id: "get.again" })}
              errorStyle={
                style.oversizedBlock__main__scrollViewBlock__errorBlock
              }
            >
              {!this.props.oversized.isFetchingOversizedList &&
                this.props.oversized.errors.msg === "" && (
                  <>
                    <Div className={style.oversizedBlock__warningBlock}>
                      <Div className={style.oversizedBlock__warningBlock__wrap}>
                        <Img
                          className={
                            style.oversizedBlock__warningBlock__wrap__icon
                          }
                          src={iconWarning}
                        />
                        <Heading
                          text={this.props.intl.formatMessage({
                            id:
                              "you.cannot.dispose.oversized.trashes.on.general.ways"
                          })}
                        />
                      </Div>
                    </Div>
                    <Div className={style.oversizedBlock__imageBlock}>
                      {images}
                    </Div>
                    <ContentBoxGroup contents={oversizedList} />
                    {this.props.oversized.oversizedList.button &&
                      this.props.oversized.oversizedList.button.buttonText && (
                        <Div className={style.oversizedBlock__buttonBlock}>
                          <MultipurposeButton
                            onClick={this.hundleClickButton.bind(this)}
                            text={
                              this.props.oversized.oversizedList.button
                                .buttonText
                            }
                          />
                        </Div>
                      )}
                  </>
                )}
            </LoadingWrapper>
          </ScrollView>
        </Main>
        <GlobalFooter
          intl={this.props.intl}
          pathname={this.props.history.location.pathname}
        />
      </>
    );
  }
}

export default Oversized;
