import React from "react";
import styles from "./ForgetPassword.module.scss";
import ForgetPaawordForm from "../../components/register/ForgetPaawordForm/ForgetPaawordForm";
import Logo from "../../components/molecules/Logo/Logo";
import TextLinkBlock from "../../components/molecules/TextLinkBlock/TextLinkBlock";
import { Div } from "../../components/atoms/Html/Html";
import Modal from "../../components/organisms/Modal/Modal";
import { Redirect } from "react-router-dom";

class ForgetPassword extends React.Component {
  constructor(props) {
    super(props);
  }

  submitData(values) {
    this.props.sendMailOfResetPassword(values);
  }

  handleClickOk() {
    this.props.setIsForgetPasswordError(false);
    this.props.setErrorMsgOfAuthState("");
  }

  handleClickToLogin() {
    this.props.history.push("/login");
  }

  handleClickToSelectRegion() {
    this.props.history.push("/register/select_region");
  }

  render() {
    const choiceListOfForgetPasswordError = [
      {
        label: this.props.intl.formatMessage({ id: "ok" }),
        action: this.handleClickOk.bind(this)
      }
    ];
    return this.props.auth.isLoggedIn ? (
      <Redirect to={"/"} />
    ) : (
      <Div className={styles.forgetPasswordBlock}>
        <Div />
        <Div>
          <Div className={styles.forgetPasswordBlock__logo}>
            <Logo type="blue" />
          </Div>
          <ForgetPaawordForm
            submitData={this.submitData.bind(this)}
            intl={this.props.intl}
          />
          <TextLinkBlock
            linkText={this.props.intl.formatMessage({ id: "back.to.login" })}
            handleClick={this.handleClickToLogin.bind(this)}
          />
        </Div>
        <TextLinkBlock
          text={this.props.intl.formatMessage({
            id: "dont.you.have.an.account?"
          })}
          linkText={this.props.intl.formatMessage({ id: "sign.up" })}
          handleClick={this.handleClickToSelectRegion.bind(this)}
        />
        {(() => {
          if (this.props.auth.isForgetPasswordFetching === true) {
            return (
              <Modal
                type={"loading"}
                text={this.props.intl.formatMessage({
                  id: "connecting"
                })}
              />
            );
          } else if (this.props.auth.errors.isForgetPasswordError === true) {
            return (
              <Modal
                type={"choice"}
                text={this.props.auth.errors.msg}
                choiceList={choiceListOfForgetPasswordError}
              />
            );
          }
        })()}
      </Div>
    );
  }
}

export default ForgetPassword;
