import React from "react";
import { withFormik, Form } from "formik";
import FormParts from "../../molecules/FormParts/FormParts";
import MultipurposeButton from "../../molecules/MultipurposeButton/MultipurposeButton";
import styles from "./ForgetPaawordForm.module.scss";
import { Div } from "../../atoms/Html/Html";
import * as Yup from "yup";

const ForgetPaawordForm = props => {
  const { touched, errors, handleChange } = props;
  return (
    <Form className={styles.forgetPaddwordFormBlock}>
      <Div className={styles.forgetPaddwordFormBlock__formItem}>
        <FormParts
          label={props.intl.formatMessage({ id: "email" })}
          name="email"
          component="input"
          type="email"
          errors={errors.email && touched.email ? [errors.email] : null}
          handleChange={handleChange}
        />
      </Div>
      <MultipurposeButton
        type="submit"
        text={props.intl.formatMessage({ id: "reset.password" })}
      />
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: () => {
    return {
      email: ""
    }
  },
  validationSchema(props) {
    return Yup.object().shape({
      email: Yup.string()
        .email(props.intl.formatMessage({ id: "validation.invalid.email" }))
        .required(props.intl.formatMessage({ id: "validation.required" }))
    });
  },
  handleSubmit: (values, { props }) => {
    const submitValue = {
      email: values.email
    };
    props.submitData(submitValue);
  }
})(ForgetPaawordForm);
