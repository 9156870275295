import robotIcon from "../assets/images/icon_robot_body_english.svg";

const vi_VN = {
  'log.in':'Đăng nhập',
  'already.use.gommit?':'Đã sử dụng GOMMIT?',
  '1.select.your.region': '1. Chọn khu vực của bạn',
  'use.the.other.region':'Sử dụng khu vực khác',
  'dont.you.have.an.account?':'Bạn có một tài khoản?',
  'sign.up':'Đăng ký',
  'email':'Email',
  'password':'Mật khẩu',
  'login':'Đăng nhập',
  'forget.password':'Quên mật khẩu',
  'getting.your.location.data':'Lấy dữ liệu vị trí của bạn',
  '2.input.more.information': '2. Nhập thêm thông tin',
  'retry': 'Thử lại',
  'input.manually': 'Nhập thủ công',
  'prefecture': 'Tỉnh',
  'city': 'Thành phố',
  'region': 'Khu vực',
  'address': 'Địa chỉ',
  'type.of.residence': 'Loại hình cư trú',
  'residence.name': 'Tên cư trú',
  'room.number': 'Số phòng',
  'your.mail': 'Email của bạn',
  'login.password': 'Mật khẩu đăng nhập',
  'required': 'cần thiết',
  'register': 'Đăng ký',
  'upcoming.schedule':'Lịch trình sắp tới',
  'cancel':'Hủy bỏ',
  'trash.name':'Tên thùng rác',
  'type':'Kiểu',
  'next.collection.date':'Ngày thu tiếp theo',
  'validation.invalid.email':'Email không hợp lệ',
  'validation.required':'Cần thiết',
  'please.select':'Vui lòng chọn',
  '3.check.the.confirmation.email.and.complete.the.registration':'3. Kiểm tra email xác nhận và hoàn tất đăng ký',
  'check.mailbox':'Kiểm tra hộp thư',
  'start':'Khởi đầu',
  'ready.to.use.gommit!':'Sẵn sàng sử dụng GOMMIT',
  'registering':'Đăng ký…',
  'connecting':'Đang kết nối…',
  'back.to.login':'Quay lại đăng nhập',
  'reset.password':'Đặt lại mật khẩu',
  'ok':'đồng ý',
  'home': 'Trang Chủ',
  'gommit': 'GOMMIT',
  'ai.helps.you': 'AI giúp bạn',
  'reward': 'Phần thưởng',
  'how.to.dispose': 'Cách vứt bỏ',
  'dictionary': 'Mục lục',
  'oversized': 'Quá khổ',
  'burnable.trash': 'Thùng rác',
  'unburnable.trash': 'Thùng rác không cháy',
  'harmful.trash': 'Thùng rác có hại',
  'oversized.trash': 'Rác quá khổ',
  'plastic': 'Nhựa',
  'books.magazines': 'Sách, tạp chí',
  'cardboard': 'Các tông',
  'milk.carton': 'Thùng sữa',
  'paper.newspaper': 'Giấy, báo',
  'cans.bottles': 'Lon, chai',
  'metals': 'Metals',
  'clothes': 'Quần áo',
  'recyclable.trash': 'Thùng rác có thể tái chế',
  'get.again': 'Nhận lại',
  'how.to.dispose.of.tab': 'Cách vứt bỏ',
  'items': 'Mặt hàng',
  'notes': 'Ghi chú',
  'see.detail': 'Xem chi tiết',
  'faq': 'Câu hỏi thường gặp',
  'information': 'Thông tin',
  'oversized.consultation': 'Rác quá khổ',
  'you.cannot.dispose.oversized.trashes.on.general.ways' : 'Xử lý rác quá khổ theo một quy trình khác',
  'type.your.text': 'Gõ văn bản của bạn',
  'enter.the.name.of.the.trash.you.want.to.check': 'Nhập tên của thùng rác bạn muốn kiểm tra.',
  'settings':'Cài đặt',
  'account':'Tài khoản',
  'notifications':'Thông báo',
  'language':'Ngôn ngữ',
  'sns.integration':'Tích hợp SNS',
  'save': 'Tiết kiệm',
  'select.language':'Chọn ngôn ngữ',
  'english': 'Tiếng Anh',
  'japanese': 'tiếng Nhật',
  'reward.programs': 'Chương trình khen thưởng',
  'total.points': 'Tổng số điểm',
  'start.reward':'Khởi đầu',
  'special.reward':'Đặc biệt\nPhần thưởng',
  'password.confirmation':'Xác nhận mật khẩu',
  'please.enter.a.password.with.at.least.6.characters':'Vui lòng nhập mật khẩu có ít nhất 6 ký tự.',
  'logout': 'Đăng xuất',
  'based.on.information.of.local.government.website': 'Dựa trên trang web của chính quyền địa phương',
  'notice':'Để ý',
  'search':'Tìm kiếm',
  'definition.of.oversized.garbage': 'Định nghĩa "Rác quá khổ"',
  'how.to.dispose.of.oversized.garbage':'Cách vứt bỏ',
  'ticket.prices': 'Phí phát hành vé cho việc xử lý rác quá khổ\n*Phí có thể thay đổi trong một số trường hợp',
  'dispose.step.of.oversized.garbage': '1. Áp dụng tại trung tâm tiếp nhận\n' +
      '2. Mua vé xử lý rác cồng kềnh tại một đại lý được chỉ định\n' +
      '3.Trash vào những ngày được chỉ định',
  'see.on.the.local.government.website': 'Kiểm tra trang web HP của chính quyền địa phương của bạn',
  'not.found.': 'Không tìm thấy.',
  'plain.address': 'Địa chỉ',
  'invisible': 'Vô hình',
  'return': 'Trở về',
  'upcoming': 'Sắp tới.',
  'apartment': 'Apartment',
  'house': 'House',
  'failed.to.get.information.': 'Failed to get information.',
  'communication.failed.': 'Communication failed.{v1}',
  'failed.to.get.your.location.': 'Failed to get your location.',
  'not.compatible.with.your.area.': 'Not compatible with your area.',
  'the.password.was.updated.successfully.': 'The password was updated successfully.',
  'the.address.was.successfully.updated.': 'The address was successfully updated.',
  'failed.to.get.event.': 'Failed to get event.',
  'failed.to.get.list.': 'Failed to get list.',
  'incorrect.email.or.password.': 'Incorrect email or password.',
  'failed.to.get.faq.': 'Failed to get FAQ.',
  'mail.token.error': 'This e-mail address is temporarily registered.\nYou can’t register with this email address until {v1}',
  'january': 'january',
  'february': 'february',
  'march': 'march',
  'april': 'april',
  'may': 'may',
  'june': 'june',
  'july': 'july',
  'august': 'august',
  'september': 'september',
  'october': 'october',
  'november': 'november',
  'december': 'december',
  'date1': '{v3} {v1}',
  'sunday': 'sunday',
  'monday': 'monday',
  'tuesday': 'tuesday',
  'wednesday': 'wednesday',
  'thursday': 'thursday',
  'friday': 'friday',
  'saturday': 'saturday',
  'week1': '{v2}',
  'date2': '{v2} {v3}',
  'week2': '{v2}',
  'today': 'Today',
  'robot.icon': robotIcon,
  'click.here.for.how.to.put.out': 'Click here for how to put out {v1}',
  'next': 'next',
  'rough.address': '{v3}, {v2}, {v1}',
  'the.password.reset.email.has.been.sent.successfully.': 'An email has been sent to \'{v1}\' containing instructions for resetting your password.',
  'unable.to.find.user.with.email': 'Unable to find user with email \'{v1}\'',
  'please.log.in.again.': 'Please log in again.',
  'an.error.has.occurred.please.send.an.email.again.': 'An error has occurred.\nPlease send an email again.',
  'news': 'News',
};
export default vi_VN;