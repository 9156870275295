import React from "react";
import styles from "./ProgressTracker.module.scss";
import { Div } from "../../atoms/Html/Html";
import StepNumber from "../../molecules/StepNumber/StepNumber";
import HeadingPrimary from "../../atoms/Heading/HeadingPrimary";

const ProgressTracker = props => {
  return (
     <>
       <StepNumber currentNumber={props.currentNumber} totalNumber={props.totalNumber} />
       <HeadingPrimary
           text={props.text}
       />
     </>
  );
};

export default ProgressTracker;
