import {
  REQUEST_FETCH_INFORMATION_LIST,
  SUCCESS_FETCH_INFORMATION_LIST,
  ERROR_FETCH_INFORMATION_LIST,
  ERROR_AUTH_FETCH_INFORMATION_LIST,
  SET_IS_ERROR_FETCHING_INFORMATION_LIST,
  SET_INFORMATION_MSG,
  SET_INIT_INFORMATION_FLAG
} from "../actions/information";

const Information = (
  state = {
    informationList: [],
    isFetchingInformationList: false,
    errors: {
      msg: "",
      isErrorFetchingInformationList: false
    },
    initInformationFlag: true
  },
  action
) => {
  switch (action.type) {
    case REQUEST_FETCH_INFORMATION_LIST:
      return Object.assign({}, state, {
        isFetchingInformationList: true
      });
    case SUCCESS_FETCH_INFORMATION_LIST:
      return Object.assign({}, state, {
        informationList: action.list,
        isFetchingInformationList: false
      });
    case ERROR_FETCH_INFORMATION_LIST:
      return Object.assign({}, state, {
        isFetchingInformationList: false,
        errors: {
          msg: action.msg,
          isErrorFetchingInformationList: true
        }
      });
    case ERROR_AUTH_FETCH_INFORMATION_LIST:
      return Object.assign({}, state, {
        isFetchingInformationList: false
      });
    case SET_IS_ERROR_FETCHING_INFORMATION_LIST:
      return Object.assign({}, state, {
        errors: {
          msg: state.errors.msg,
          isErrorFetchingInformationList: action.bool
        }
      });
    case SET_INFORMATION_MSG:
      return Object.assign({}, state, {
        errors: {
          msg: action.msg,
          isErrorFetchingInformationList:
            state.errors.isErrorFetchingInformationList
        }
      });
    case SET_INIT_INFORMATION_FLAG:
      return Object.assign({}, state, {
        initInformationFlag: action.bool
      });
    default:
      return state;
  }
};

export default Information;
