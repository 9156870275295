import { developmentApiUrl, productionApiUrl } from "../../web/constants/constants";
import { getRequestHeader, getLanguage, isStatusOk, getFormattedMessage } from "../../web/constants/functions";
import { logOut } from "./auth";

export const REQUEST_FETCH_OVERSIZED_LIST = "REQUEST_FETCH_OVERSIZED_LIST";

function requestFetchOversizedList() {
  return {
    type: REQUEST_FETCH_OVERSIZED_LIST
  };
}

export const SUCCESS_FETCH_OVERSIZED_LIST = "SUCCESS_FETCH_OVERSIZED_LIST";

function successFetchOversizedList(json) {
  return {
    type: SUCCESS_FETCH_OVERSIZED_LIST,
    list: json.data
  };
}

export const ERROR_FETCH_OVERSIZED_LIST = "ERROR_FETCH_OVERSIZED_LIST";

function errorFetchOversizedList(msg) {
  return {
    type: ERROR_FETCH_OVERSIZED_LIST,
    msg
  };
}

export const ERROR_AUTH_FETCH_OVERSIZED_LIST =
  "ERROR_AUTH_FETCH_OVERSIZED_LIST";

function errorAuthFetchOversizedList() {
  return {
    type: ERROR_AUTH_FETCH_OVERSIZED_LIST
  };
}

export function fetchOversizedList() {
  return function(dispatch) {
    dispatch(requestFetchOversizedList());
    const url = productionApiUrl + "/oversized_trash_prices/index";
    let status;
    return fetch(url, {
      headers: getRequestHeader()
    })
      .then(function(response) {
        status = response.status;
        return response.json();
      })
      .then(function(json) {
        if (isStatusOk(status)) {
          dispatch(successFetchOversizedList(json));
        } else if (status === 401) {
          dispatch(logOut());
          dispatch(errorAuthFetchOversizedList());
        } else {
          dispatch(errorFetchOversizedList(getFormattedMessage('failed.to.get.information.')));
        }
      })
      .catch(function() {
        dispatch(errorFetchOversizedList(getFormattedMessage('communication.failed.')));
      });
  };
}

export const SET_IS_ERROR_FETCHING_OVERSIZED_LIST =
  "SET_IS_ERROR_FETCHING_OVERSIZED_LIST";

export function setIsErrorFetchingOversizedList(bool) {
  return {
    type: SET_IS_ERROR_FETCHING_OVERSIZED_LIST,
    bool
  };
}

export const SET_OVERSIZED_MSG = "SET_OVERSIZED_MSG";

export function setOversizedMsg(msg) {
  return {
    type: SET_OVERSIZED_MSG,
    msg
  };
}

export const SET_INIT_OVERSIZED_FLAG = "SET_INIT_OVERSIZED_FLAG";

export function setInitOversizedFlag(bool) {
  return {
    type: SET_INIT_OVERSIZED_FLAG,
    bool
  };
}
