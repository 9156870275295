import React from "react";
import styles from "./Register.module.scss";
import { Div } from "../../components/atoms/Html/Html";
import BackLink from "../../components/molecules/BackLink/BackLink";
import SecondRegisterFrom from "../../components/register/SecondRegisterFrom/SecondRegisterFrom";
import Modal from "../../components/organisms/Modal/Modal";
import ProgressTracker from "../../components/organisms/ProgressTracker/ProgressTracker";
import TextLinkBlock from "../../components/molecules/TextLinkBlock/TextLinkBlock";
import { getFormattedMessage } from "../../constants/functions";

class MoreInfomations extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    if (!this.props.user.addressOptions.initFetchPrefectures) {
      this.props.fetchPrefectures();
    }
  }

  handleClickRetryOfRegisterError() {
    this.props.setIsRegisterError(false);
    this.props.setErrorMsgOfUserState("");
    this.props.resetValidationMessageOfRegisterUserInfo();
    document
      .getElementById("registerUserForm")
      .dispatchEvent(new Event("submit"));
  }

  handleClickBackLink() {
    this.props.changeAvailableRegisterPage({ select_region: true });
    this.props.history.push("/register/select_region");
    this.props.changeAvailableRegisterPage({ more_information: false });
    this.props.resetValidationMessageOfRegisterUserInfo();
    let resetValue = {
      prefecture_id: "",
      authority_id: "",
      block_id: "",
      street_name: "",
      house_type_id: "",
      residence_name: "",
      room_number: "",
      email: "",
      password: "",
      password_confirmation: "",
      prefecture_value: "",
      authority_value: "",
      block_value: ""
    };
    this.props.modifyRegisterUserForm(resetValue);
    this.props.resetInitFetchPrefectures();
    this.props.setPrefectures([]);
    this.props.setAuthorities([]);
    this.props.setBlocks([]);
  }

  handleClickRetryOfFetchPrefecturesError() {
    this.props.setIsFetchPrefecturesError(false);
    this.props.setErrorMsgOfUserState("");
    this.props.fetchPrefectures();
  }

  handleClickRetryOfFetchAuthoritiesError() {
    this.props.setIsFetchAuthoritiesError(false);
    this.props.setErrorMsgOfUserState("");
    this.props.fetchAuthorities(this.props.user.form.prefecture_value);
  }

  handleChangeSecondRegisterForm(name, value) {
    if (name === "block_id") {
      const tmpObject = {};
      if (value !== "") {
        const filtered = this.props.user.addressOptions.blocks[value];
        tmpObject.block_value = String(filtered.id);
      } else {
        tmpObject.block_value = String(value);
      }
      tmpObject.block_id = String(value);
      this.props.modifyRegisterUserForm(tmpObject);
    } else if (name === "authority_id") {
      const tmpObject = {};
      if (value !== "") {
        const filtered = this.props.user.addressOptions.authorities[value];
        this.props.fetchBlocks(filtered.id, filtered.district_id);
        tmpObject.authority_value = String(filtered.id);
      } else {
        tmpObject.authority_value = String(value);
      }
      tmpObject.authority_id = String(value);
      this.props.modifyRegisterUserForm(tmpObject);
      this.props.setTheValueOfFormBlockId("");
      this.props.setBlocks([]);
    } else if (name === "prefecture_id") {
      const tmpObject = {};
      if (value !== "") {
        const filtered = this.props.user.addressOptions.prefectures[value];
        this.props.fetchAuthorities(filtered.id);
        tmpObject.prefecture_value = String(filtered.id);
      } else {
        tmpObject.prefecture_value = String(value);
      }
      tmpObject.prefecture_id = String(value);
      this.props.modifyRegisterUserForm(tmpObject);
      this.props.setTheValueOfFormAuthorityId("");
      this.props.setTheValueOfFormBlockId("");
      this.props.setAuthorities([]);
      this.props.setBlocks([]);
    } else {
      this.props.modifyRegisterUserForm({ [name]: value });
    }
  }

  handleClickRetryOfEmptyBlocksError() {
    this.props.setIsEmptyBlocksError(false);
    this.props.setErrorMsgOfUserState("");
  }

  handleClickRetryOfFetchBlocksError() {
    this.props.setIsFetchBlocksError(false);
    this.props.setErrorMsgOfUserState("");
    const authorityId = this.props.user.form.authority_value;
    const filtered = this.props.user.addressOptions.authorities.filter(function (
      element
    ) {
      return element.id == authorityId;
    });
    this.props.fetchBlocks(
      this.props.user.form.authority_value,
      filtered[0].district_id
    );
  }

  handleClickOkOfMailTokenTimeError() {
    this.props.setIsMailTokenTimeError(false);
    this.props.setErrorMsgOfUserState("");
  }

  handleClickToLogin() {
    this.props.history.push("/login");
  }

  render() {
    const block = this.props.user.regions.blocks.filter(
      value => value.id == this.props.user.form.block_value
    );

    const regionText = block.length ? getFormattedMessage("rough.address", this.props.user.regions.prefecture.name, this.props.user.regions.authority.name, block[0].name) : null;

    if (this.props.user.isSuccessUserRegistration) {
      this.props.changeAvailableRegisterPage({ check_mail: true });
      this.props.history.push("/register/check_mail");
      this.props.changeAvailableRegisterPage({ more_information: false });
      this.props.setIsSuccessUserRegistration(false);
    }

    let modalType, modalText, modalChoiceList;
    if (this.props.user.isRegisteringUser) {
      modalType = "loading";
      modalText = this.props.intl.formatMessage({ id: "registering" });
      modalChoiceList = null;
    } else if (this.props.user.errors.isRegisterError) {
      modalType = "choice";
      modalText = this.props.user.errors.msg;
      modalChoiceList = [
        {
          label: this.props.intl.formatMessage({ id: "retry" }),
          action: this.handleClickRetryOfRegisterError.bind(this)
        }
      ];
    } else if (this.props.user.errors.isFetchPrefecturesError) {
      modalType = "choice";
      modalText = this.props.user.errors.msg;
      modalChoiceList = [
        {
          label: this.props.intl.formatMessage({ id: "retry" }),
          action: this.handleClickRetryOfFetchPrefecturesError.bind(this)
        }
      ];
    } else if (this.props.user.errors.isFetchAuthoritiesError) {
      modalType = "choice";
      modalText = this.props.user.errors.msg;
      modalChoiceList = [
        {
          label: this.props.intl.formatMessage({ id: "retry" }),
          action: this.handleClickRetryOfFetchAuthoritiesError.bind(this)
        }
      ];
    } else if (this.props.user.errors.isEmptyBlocksError) {
      modalType = "choice";
      modalText = this.props.user.errors.msg;
      modalChoiceList = [
        {
          label: this.props.intl.formatMessage({ id: "ok" }),
          action: this.handleClickRetryOfEmptyBlocksError.bind(this)
        }
      ];
    } else if (this.props.user.errors.isFetchBlocksError) {
      modalType = "choice";
      modalText = this.props.user.errors.msg;
      modalChoiceList = [
        {
          label: this.props.intl.formatMessage({ id: "retry" }),
          action: this.handleClickRetryOfFetchBlocksError.bind(this)
        }
      ];
    } else if (this.props.user.errors.isMailTokenTimeError) {
      modalType = "choice";
      modalText = this.props.user.errors.msg;
      modalChoiceList = [
        {
          label: this.props.intl.formatMessage({ id: "ok" }),
          action: this.handleClickOkOfMailTokenTimeError.bind(this)
        }
      ];
    }

    const prefectureOptions = this.props.user.addressOptions.prefectures.map(
      function (element, index) {
        return {
          value: index,
          label: element.name
        };
      }
    );

    const authorityOptions = this.props.user.addressOptions.authorities.map(
      function (element, index) {
        return {
          value: index,
          label: element.name
        };
      }
    );

    const blockOptions = this.props.user.addressOptions.blocks.map(function (
      element,
      index
    ) {
      return {
        value: index,
        label: element.name
      };
    });

    return (
      <Div className={styles.moreInfomationsPage}>
        <BackLink handleClick={this.handleClickBackLink.bind(this)} />
        <Div className={styles.moreInfomationsPage__mainBlock}>
          <ProgressTracker
            currentNumber={2}
            totalNumber={3}
            text={this.props.intl.formatMessage({
              id: "2.input.more.information"
            })}
          />
          <SecondRegisterFrom
            isOtherRegionMode={this.props.user.isOtherRegionMode}
            prefectureOptions={prefectureOptions}
            authorityOptions={authorityOptions}
            blockOptions={blockOptions}
            form={this.props.user.form}
            regionText={regionText}
            intl={this.props.intl}
            registerUserInfo={this.props.registerUserInfo}
            validationOfRegisterUserInfo={
              this.props.user.errors.validationOfRegisterUserInfo
            }
            isFetchingPrefectures={
              this.props.user.addressOptions.isFetchingPrefectures
            }
            isFetchingAuthorities={
              this.props.user.addressOptions.isFetchingAuthorities
            }
            isFetchingBlocks={this.props.user.addressOptions.isFetchingBlocks}
            handleChangeSecondRegisterForm={this.handleChangeSecondRegisterForm.bind(
              this
            )}
            resetValidationMessageOfRegisterUserInfo={
              this.props.resetValidationMessageOfRegisterUserInfo
            }
          />
        </Div>
        <TextLinkBlock
          text={this.props.intl.formatMessage({ id: "already.use.gommit?" })}
          linkText={this.props.intl.formatMessage({ id: "log.in" })}
          handleClick={this.handleClickToLogin.bind(this)}
        />
        {modalType && (
          <Modal
            type={modalType}
            text={modalText}
            choiceList={modalChoiceList}
          />
        )}
      </Div>
    );
  }
}

export default MoreInfomations;
