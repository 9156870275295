import React from "react";
import style from "./Schedule.module.scss";
import {
  Div,
  Img,
  H3,
  Table,
  Tbody,
  Tr,
  Td,
  Button
} from "../../atoms/Html/Html";
import {
  getDay,
  dateToString,
  today,
  calcMonth,
  getTrashTypeIcon,
  getFormattedMessage
} from "../../../constants/functions";
import {
  howManyMonthsToGet,
  monthEnglishList,
  weekEnglishList
} from "../../../constants/constants";
import check from "../../../../assets/images/icon_check_white.svg";
import checkGray from "../../../../assets/images/icon_check_gray.svg";
import Observer from "react-intersection-observer";
import loadingBlue from "../../../../assets/images/loading-blue.svg";
import LoadingWrapper from "../LoadingWrapper/LoadingWrapper";
import { Link } from "react-router-dom";
import ScrollView from "../../atoms/ScrollView/ScrollView";

function Schedule(props) {
  function handleClickCheck(e) {
    const bool = e.currentTarget.getAttribute("data-value");
    props.changeWasChecked(!(bool === "true"));
  }

  function handleClickRetry() {
    props.setIsErrorFetchScheduleList(false);
    props.setScheduleMsg("");
    props.fetchEventList(
      props.date.year,
      props.date.month,
      "schedule",
      howManyMonthsToGet,
      props.blockGroupId,
      false
    );
  }

  const handleInview = inView => {
    if (inView) {
      const date = calcMonth(
        props.date.year,
        props.date.month,
        howManyMonthsToGet
      );
      props.changeScheduleDate(date.year, date.month);
      props.fetchEventList(
        date.year,
        date.month,
        "schedule",
        howManyMonthsToGet,
        props.blockGroupId,
        false
      );
    }
  };

  const createSchedule = list => {
    const scheduleBlockList = list.map(function(element1) {
      const scheduleBlock = (
        <>
          {element1.days.map(function(element2) {
            const dateObjectOfScheduleDay = new Date(
              element1.year + "/" + element1.month + "/" + element2.date
            );
            const dateObjectOfCurrentDay = new Date(today());
            const scheduleDay = dateToString(dateObjectOfScheduleDay);
            const currentDay = dateToString(dateObjectOfCurrentDay);
            let isToday = false;
            if (scheduleDay === currentDay) {
              isToday = true;
            }
            // 何もない日と前の日は表示しません。
            if (
              element2.types.length !== 0 &&
              (dateObjectOfScheduleDay >= dateObjectOfCurrentDay || isToday)
            ) {
              const dayNumber = getDay(
                element1.year,
                element1.month,
                element2.date
              );
              const youbi = getFormattedMessage(weekEnglishList[dayNumber]);
              const monthStr = getFormattedMessage(monthEnglishList[element1.month - 1]);
              let dayOfTheWeek = getFormattedMessage("week2", youbi.slice(0, 1), youbi.charAt(0).toUpperCase() + youbi.slice(1, 3));
              const date = getFormattedMessage("date2", element1.month, monthStr.charAt(0).toUpperCase() + monthStr.slice(1, 3), element2.date);
              const imageList = element2.types.map(function(
                value,
                index,
                array
              ) {
                if (array.length <= 1) {
                  return (
                    <Img
                      className={style.imageBlock__wrapper__image}
                      src={getTrashTypeIcon(value)}
                    />
                  );
                } else {
                  return (
                    <Div className={style.imageBlock__wrapper}>
                      <Img
                        className={style.imageBlock__wrapper__image}
                        src={getTrashTypeIcon(value)}
                      />
                    </Div>
                  );
                }
              });
              let trashTypeText = "";
              element2.types.forEach(function(value, index, array) {
                trashTypeText +=
                  props.conversionTable[value] +
                  (array.length - 1 === index ? "" : "/");
              });

              // 年末年始はグレーアウトさせる
              let dayExtra = "";
              if (element1.month === 12) {
                const yearEnd = [25, 26, 27, 28, 29, 30, 31];
                if (yearEnd.indexOf(element2.date) >= 0) {
                  dayExtra = style.bg_gray;
                }
              }

              if (element1.month === 1) {
                const yearStart = [1, 2, 3, 4, 5, 6, 7];
                if (yearStart.indexOf(element2.date) >= 0) {
                  dayExtra = style.bg_gray;
                }
              }

              let classNameOfToday = "";
              if (isToday) {
                classNameOfToday = style.today;
                dayOfTheWeek = getFormattedMessage("today");
              }
              return (
                <Tr
                  className={
                    style.schedule__content__table__row +
                    " " +
                    classNameOfToday +
                    " " +
                    dayExtra
                  }
                >
                  <Td
                    className={
                      style.schedule__content__table__row__weeks +
                      " " +
                      classNameOfToday
                    }
                  >
                    {dayOfTheWeek}
                  </Td>
                  <Td
                    className={
                      style.schedule__content__table__row__date +
                      " " +
                      classNameOfToday
                    }
                  >
                    {date}
                  </Td>
                  <Td
                    className={style.schedule__content__table__row__imageBlock}
                  >
                    <Div
                      className={
                        imageList.length <= 1
                          ? style.imageBlock__oneImege
                          : style.imageBlock__twoToFourImege
                      }
                    >
                      {imageList.map(imageComponent => imageComponent)}
                    </Div>
                  </Td>
                  <Td className={style.schedule__content__table__row__type}>
                    {trashTypeText}
                  </Td>
                  <Td
                    className={style.schedule__content__table__row__checkBlock}
                  >
                    <Button
                      dataValue={isToday ? props.wasChecked : ""}
                      onClick={handleClickCheck}
                      disabled={isToday ? false : true}
                      className={
                        style.schedule__content__table__row__checkBlock__check +
                        " " +
                        classNameOfToday
                      }
                    >
                      <Img src={isToday ? checkGray : check} />
                    </Button>
                  </Td>
                </Tr>
              );
            }
          })}
        </>
      );
      return scheduleBlock;
    });
    const body = (
      <Table className={style.schedule__content__table}>
        <Tbody>{scheduleBlockList}</Tbody>
      </Table>
    );
    return body;
  };
  const schedule = createSchedule(props.schedule.list);

  return (
    <Div className={style.schedule}>
      <H3 className={style.schedule__heading}>
        {props.intl.formatMessage({ id: "upcoming.schedule" })}
      </H3>
      <ScrollView contentContainerStyle={style.schedule__content}>
        <LoadingWrapper
          isConnecting={props.isFetchingScheduleList}
          errorText={props.scheduleMsg}
          onRetry={handleClickRetry}
          retryText={props.intl.formatMessage({ id: "get.again" })}
          willLoadNext={!props.isFullScheduleList}
          onLoadNext={handleInview}
          dummyStyle={style.schedule__content__lastBlock}
          loadingStyle={style.schedule__content__lastBlock}
        >
          {schedule}
        </LoadingWrapper>
      </ScrollView>
      <Link className={style.schedule__robotBlock} to="/ai_helps_you">
        <Img
          src={getFormattedMessage("robot.icon")}
          className={style.schedule__robotBlock__robot}
        />
      </Link>
    </Div>
  );
}

export default Schedule;
