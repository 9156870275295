import React from "react";
import style from "./Answer.module.scss";
import { P, Div, Img } from "../../atoms/Html/Html";
import answerIcon from "../../../../assets/images/icon_answer_red.svg";

const Answer = props => (
  <Div className={style.answerBlock}>
    <Img className={style.answerBlock__icon} src={answerIcon} />
    <P className={style.answerBlock__text}>{props.text}</P>
  </Div>
);

export default Answer;
