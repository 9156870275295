import {
  developmentApiUrl,
  productionApiUrl,
  productionBuildUrl
} from "../../web/constants/constants";
import {
  getRequestHeader,
  isStatusOk,
  getLanguage,
  getState,
  Storage,
  getFormattedMessage
} from "../../web/constants/functions";
import {
  houseTypeOptions
} from "../../web/constants/constants";
import { logOut } from "./auth";

export const CHANGE_SETTINGS_LANG = "CHANGE_SETTINGS_LANG";

export function changeSettingsLang(lang) {
  return {
    type: CHANGE_SETTINGS_LANG,
    lang
  };
}

export const REQUEST_FETCH_PREFECTURES = "REQUEST_FETCH_PREFECTURES";

function requestFetchPrefectures() {
  return {
    type: REQUEST_FETCH_PREFECTURES
  };
}

export const SUCCESS_FETCH_PREFECTURES = "SUCCESS_FETCH_PREFECTURES";

function successFetchPrefectures(json) {
  return {
    type: SUCCESS_FETCH_PREFECTURES,
    prefectures: json.data.prefectures
  };
}

export const ERROR_FETCH_PREFECTURES = "ERROR_FETCH_PREFECTURES";

function errorFetchPrefectures(message) {
  return {
    type: ERROR_FETCH_PREFECTURES,
    errorMsg: message
  };
}

export function fetchPrefectures() {
  return function (dispatch) {
    dispatch(requestFetchPrefectures());
    let status;
    return fetch(/*developmentApiUrl*/ productionApiUrl + "/prefectures/index", {
      headers: getRequestHeader()
    })
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then(function (json) {
        if (isStatusOk(status)) {
          dispatch(successFetchPrefectures(json));
        } else {
          dispatch(errorFetchPrefectures(getFormattedMessage('failed.to.get.information.')));
        }
      })
      .catch(function () {
        dispatch(errorFetchPrefectures(getFormattedMessage('communication.failed.')));
      });
  };
}

export const REQUEST_FETCH_AUTHORITIES = "REQUEST_FETCH_AUTHORITIES";

function requestFetchAuthorities() {
  return {
    type: REQUEST_FETCH_AUTHORITIES
  };
}

export const SUCCESS_FETCH_AUTHORITIES = "SUCCESS_FETCH_AUTHORITIES";

function successFetchAuthorities(json) {
  const authorities = [];
  json.data.authorities.forEach((e1) => {
    if(Object.prototype.toString.call(e1) === "[object Array]"){
      e1.forEach((e2) => {
        authorities.push(e2);
      });
    }else{
      authorities.push(e1);
    }
  });
  return {
    type: SUCCESS_FETCH_AUTHORITIES,
    authorities
  };
}

export const ERROR_FETCH_AUTHORITIES = "ERROR_FETCH_AUTHORITIES";

function errorFetchAuthorities(message) {
  return {
    type: ERROR_FETCH_AUTHORITIES,
    errorMsg: message
  };
}

export function fetchAuthorities(id) {
  return function (dispatch) {
    dispatch(requestFetchAuthorities());
    const params = new URLSearchParams();
    params.set("prefecture_id", id);
    let status;
    return fetch(
      /*developmentApiUrl*/ productionApiUrl +
      "/authorities/index?" +
      params.toString(),
      {
        headers: getRequestHeader()
      }
    )
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then(function (json) {
        if (isStatusOk(status)) {
          dispatch(successFetchAuthorities(json));
        } else {
          dispatch(errorFetchAuthorities(getFormattedMessage('failed.to.get.information.')));
        }
      })
      .catch(function () {
        dispatch(errorFetchAuthorities(getFormattedMessage('communication.failed.')));
      });
  };
}

export const REQUEST_FETCH_BLOCKS = "REQUEST_FETCH_BLOCKS";

function requestFetchBlocks() {
  return {
    type: REQUEST_FETCH_BLOCKS
  };
}

export const SUCCESS_FETCH_BLOCKS = "SUCCESS_FETCH_BLOCKS";

function successFetchBlocks(json) {
  return {
    type: SUCCESS_FETCH_BLOCKS,
    blocks: json.data.blocks
  };
}

export const ERROR_EMPTY_BLOCKS = "ERROR_EMPTY_BLOCKS";

function errorEmptyBlocks() {
  return {
    type: ERROR_EMPTY_BLOCKS,
    errorMsg: getFormattedMessage('not.compatible.with.your.area.')
  };
}

export const ERROR_FETCH_BLOCKS = "ERROR_FETCH_BLOCKS";

function errorFetchBlocks(message) {
  return {
    type: ERROR_FETCH_BLOCKS,
    errorMsg: message
  };
}

export function fetchBlocks(authorityId, districtId) {
  return function (dispatch) {
    dispatch(requestFetchBlocks());
    const params = new URLSearchParams();
    params.set("authority_id", authorityId);
    districtId && params.set("district_id", districtId);
    let status;
    return fetch(
      /*developmentApiUrl*/ productionApiUrl + "/blocks/index?" + params.toString(),
      {
        headers: getRequestHeader()
      }
    )
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then(function (json) {
        if (isStatusOk(status)) {
          json.data.blocks.length
            ? dispatch(successFetchBlocks(json))
            : dispatch(errorEmptyBlocks());
        } else {
          dispatch(errorFetchBlocks(getFormattedMessage('failed.to.get.information.')));
        }
      })
      .catch(function () {
        dispatch(errorFetchBlocks(getFormattedMessage('communication.failed.')));
      });
  };
}

export const MODIFY_FORM_VALUES = "MODIFY_FORM_VALUES";

export function modifyFormValues(values) {
  return {
    type: MODIFY_FORM_VALUES,
    values
  };
}

export const SET_ADDRESS_OPTIONS = "SET_ADDRESS_OPTIONS";

export function setAddressOptions(values) {
  return {
    type: SET_ADDRESS_OPTIONS,
    values
  };
}

export const REQUEST_UPDATE_ADDRESS = "REQUEST_UPDATE_ADDRESS";

function requestUpdateAddress() {
  return {
    type: REQUEST_UPDATE_ADDRESS
  };
}

export const SUCCESS_UPDATE_ADDRESS = "SUCCESS_UPDATE_ADDRESS";

function successUpdateAddress(msg, values, intl) {
  return function (dispatch) {
    const addressOptions = getState().settings.account.address.addressOptions;
    const tmpPrefecture = addressOptions.prefectures.find(
      item => item.id == values.prefecture_id
    );
    const prefecture = tmpPrefecture === undefined ? null : tmpPrefecture;
    const tmpAuthority = addressOptions.authorities.find(
      item => item.id == values.authority_id
    );
    const authority =
      tmpAuthority === undefined
        ? null
        : {
          id: tmpAuthority.id,
          name: tmpAuthority.name,
          district_id: tmpAuthority.district_id
            ? tmpAuthority.district_id
            : null
        };
    const tmpBlock = addressOptions.blocks.find(
      item => item.id == values.block_id
    );
    const block =
      tmpBlock === undefined ? null : { id: tmpBlock.id, name: tmpBlock.name };
    const block_group_id =
      tmpBlock === undefined ? null : tmpBlock.block_group_id;
    const tmpHouseType = houseTypeOptions.find(item => item.value == values.house_type_id);
    const house_type =
      tmpHouseType === undefined
        ? null
        : { value: tmpHouseType.value, name: intl.formatMessage({ id: tmpHouseType.id }) };
    const residence_name = values.residence_name;
    const room_number = values.room_number;
    const street_name = values.street_name;
    dispatch(
      updateStatus({
        prefecture,
        authority,
        block,
        block_group_id,
        house_type,
        residence_name,
        room_number,
        street_name
      })
    );
    dispatch({ type: SUCCESS_UPDATE_ADDRESS, msg });
  };
}

export const ERROR_UPDATE_ADDRESS = "ERROR_UPDATE_ADDRESS";

function errorUpdateAddress(values) {
  let errors = {};
  values.prefecture && (errors.prefecture_id = values.prefecture);
  values.authority && (errors.authority_id = values.authority);
  values.block && (errors.block_id = values.block);
  delete values.prefecture;
  delete values.authority;
  delete values.block;
  return {
    type: ERROR_UPDATE_ADDRESS,
    errors: Object.assign({}, errors, values)
  };
}

export const ERROR_AUTH_UPDATE_ADDRESS = "ERROR_AUTH_UPDATE_ADDRESS";

function errorAuthUpdateAddress() {
  return {
    type: ERROR_AUTH_UPDATE_ADDRESS
  };
}

export const ERROR_FETCH_UPDATE_ADDRESS = "ERROR_FETCH_UPDATE_ADDRESS";

function errorFetchUpdateAddress(value) {
  return {
    type: ERROR_FETCH_UPDATE_ADDRESS,
    errorMsg: value
  };
}

export function updateAddress(form_values, intl) {
  return function (dispatch) {
    /*
    form_valuesとgetState().settings.account.address.formValuesのprefecture_id, authority_id, block_idはそれぞれのaddressOptionsのindexの値になっています。
    getState().settings.account.address.formValuesのprefecture_value, authority_value, block_valueがそれぞれのidにっています。
    なのでgetState().settings.account.address.formValuesのprefecture_value, authority_value, block_valueでform_valuesのprefecture_id, authority_id, block_idを上書きしています。
    */
    let values = Object.assign({}, form_values, {
      prefecture_id: getState().settings.account.address.formValues
        .prefecture_value,
      authority_id: getState().settings.account.address.formValues
        .authority_value,
      block_id: getState().settings.account.address.formValues.block_value
    });
    delete values.prefecture_value;
    delete values.authority_value;
    delete values.block_value;
    dispatch(requestUpdateAddress());
    let status;
    return fetch(/*developmentApiUrl*/ productionApiUrl + "/address/update", {
      method: "POST",
      headers: getRequestHeader(),
      body: JSON.stringify(values)
    })
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then(function (json) {
        if (isStatusOk(status)) {
          dispatch(successUpdateAddress(getFormattedMessage('the.address.was.successfully.updated.'), values, intl));
        } else if (status === 401) {
          dispatch(logOut());
          dispatch(errorAuthUpdateAddress());
        } else {
          dispatch(errorUpdateAddress(json.data.errors));
        }
      })
      .catch(function () {
        dispatch(errorFetchUpdateAddress(getFormattedMessage('communication.failed.')));
      });
  };
}

export const RESET_VALIDATION_MESSAGES = "RESET_VALIDATION_MESSAGES";

export function resetValidationMessages() {
  return {
    type: RESET_VALIDATION_MESSAGES
  };
}

export const UPDATE_STATUS = "UPDATE_STATUS";

function updateStatus(values) {
  return {
    type: UPDATE_STATUS,
    values
  };
}

export const SET_ADDRESS_MSG = "SET_ADDRESS_MSG";

export function setAddressMsg(msg) {
  return {
    type: SET_ADDRESS_MSG,
    msg
  };
}

export const SET_IS_EMPTY_BLOCKS_ERROR = "SET_IS_EMPTY_BLOCKS_ERROR";

export function setIsEmptyBlocksError(bool) {
  return {
    type: SET_IS_EMPTY_BLOCKS_ERROR,
    bool
  };
}

export const SET_IS_SUCCESS_UPDATE_ADDRESS = "SET_IS_SUCCESS_UPDATE_ADDRESS";

export function setIsSeccessUpdateAddress(bool) {
  return {
    type: SET_IS_SUCCESS_UPDATE_ADDRESS,
    bool
  };
}

export const SET_IS_FETCH_PREFECTURES_ERROR = "SET_IS_FETCH_PREFECTURES_ERROR";

export function setIsFetchPrefecturesError(bool) {
  return {
    type: SET_IS_FETCH_PREFECTURES_ERROR,
    bool
  };
}

export const SET_IS_FETCH_AUTHORITIES_ERROR = "SET_IS_FETCH_AUTHORITIES_ERROR";

export function setIsFetchAuthoritiesError(bool) {
  return {
    type: SET_IS_FETCH_AUTHORITIES_ERROR,
    bool
  };
}

export const SET_IS_FETCH_BLOCKS_ERROR = "SET_IS_FETCH_BLOCKS_ERROR";

export function setIsFetchBlocksError(bool) {
  return {
    type: SET_IS_FETCH_BLOCKS_ERROR,
    bool
  };
}

export const SET_IS_ERROR_FETCH_UPDATE_ADDRESS =
  "SET_IS_ERROR_FETCH_UPDATE_ADDRESS";

export function setIsErrorFetchUpdateAddress(bool) {
  return {
    type: SET_IS_ERROR_FETCH_UPDATE_ADDRESS,
    bool
  };
}

export const REQUEST_UPDATE_PASSWORD = "REQUEST_UPDATE_PASSWORD";

function requestUpdatePassword() {
  return {
    type: REQUEST_UPDATE_PASSWORD
  };
}

export const SUCCESS_UPDATE_PASSWORD = "SUCCESS_UPDATE_PASSWORD";

function successUpdatePassword(msg) {
  return {
    type: SUCCESS_UPDATE_PASSWORD,
    msg
  };
}

export const ERROR_UPDATE_PASSWORD = "ERROR_UPDATE_PASSWORD";

function errorUpdatePassword(errors) {
  return {
    type: ERROR_UPDATE_PASSWORD,
    errors
  };
}

export const ERROR_AUTH_UPDATE_PASSWORD = "ERROR_AUTH_UPDATE_PASSWORD";

function errorAuthUpdatePassword() {
  return {
    type: ERROR_AUTH_UPDATE_PASSWORD
  };
}

export const ERROR_FETCH_UPDATE_PASSWORD = "ERROR_FETCH_UPDATE_PASSWORD";

function errorFetchUpdatePassword(msg) {
  return {
    type: ERROR_FETCH_UPDATE_PASSWORD,
    msg
  };
}

export function updatePassword(values) {
  return function (dispatch) {
    dispatch(requestUpdatePassword());
    let status;
    return fetch(/*developmentApiUrl*/ productionApiUrl + "/auth/password", {
      method: "PUT",
      headers: getRequestHeader(),
      body: JSON.stringify(values)
    })
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then(function (json) {
        if (isStatusOk(status)) {
          dispatch(successUpdatePassword(getFormattedMessage('the.password.was.updated.successfully.')));
        } else if (status === 401) {
          dispatch(logOut());
          dispatch(errorAuthUpdatePassword());
        } else {
          dispatch(errorUpdatePassword(json.data.errors));
        }
      })
      .catch(function () {
        dispatch(errorFetchUpdatePassword(getFormattedMessage('communication.failed.')));
      });
  };
}

export const SET_PASSWORD_MSG = "SET_PASSWORD_MSG";

export function setPasswordMsg(msg) {
  return {
    type: SET_PASSWORD_MSG,
    msg
  };
}

export const SET_IS_SUCCESS_UPDATE_PASSWORD = "SET_IS_SUCCESS_UPDATE_PASSWORD";

export function setIsSuccessUpdatePassword(bool) {
  return {
    type: SET_IS_SUCCESS_UPDATE_PASSWORD,
    bool
  };
}

export const SET_IS_ERROR_FETCH_UPDATE_PASSWORD =
  "SET_IS_ERROR_FETCH_UPDATE_PASSWORD";

export function setIsErrorFetchUpdatePassword(bool) {
  return {
    type: SET_IS_ERROR_FETCH_UPDATE_PASSWORD,
    bool
  };
}

export const RESET_PASSWORD_VALIDATION_MESSAGES =
  "RESET_PASSWORD_VALIDATION_MESSAGES";

export function resetPasswordValidationMessages() {
  return {
    type: RESET_PASSWORD_VALIDATION_MESSAGES
  };
}
