import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  checkAuth,
  setErrorMsgOfAuthState,
  setIsErrorFetchCommonInfo,
  fetchUserStatus
} from "../actions/auth";

import Auth from "../../web/components/App/Auth";

const mapStateToProps = state => {
  return { auth: state.auth };
};

const mapDispatchToProps = dispatch => {
  return {
    checkAuth: history => {
      dispatch(checkAuth(history));
    },
    setErrorMsgOfAuthState: msg => {
      dispatch(setErrorMsgOfAuthState(msg));
    },
    setIsErrorFetchCommonInfo: bool => {
      dispatch(setIsErrorFetchCommonInfo(bool));
    },
    fetchUserStatus: history => {
      dispatch(fetchUserStatus(history));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Auth)
);
