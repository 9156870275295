import React from "react";
import logoBlue from "../../../../assets/images/logo-blue.svg";
import styles from "./Logo.module.scss";

const Logo = props => {
  return (
    <div className={styles.logoBlock}>
      <img src={logoBlue} className={styles.logoBlock__logo} />
    </div>
  );
};

export default Logo;
