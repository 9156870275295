import React from "react";
import styles from "./Register.module.scss";
import { Div } from "../../components/atoms/Html/Html";
import HeadingPrimary from "../../components/atoms/Heading/HeadingPrimary";
import MultipurposeButton from "../../components/molecules/MultipurposeButton/MultipurposeButton";
import Character from "../../components/molecules/Character/Character";
import characterIcon from "../../../assets/images/robot.png";
import { getUrlQueries, Storage } from "../../constants/functions";

class ReadyToUse extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    if (this.props.history.location.state !== "fromLogin") {
      const query = getUrlQueries();
      if (
        query.prefecture_id &&
        query.authority_id &&
        query.block_id &&
        query.email &&
        query.lang
      ) {
        let tmpAddress = {};
        query.prefecture_id && (tmpAddress.prefecture_id = query.prefecture_id);
        query.authority_id && (tmpAddress.authority_id = query.authority_id);
        query.block_id && (tmpAddress.block_id = query.block_id);
        query.street_name && (tmpAddress.street_name = query.street_name);
        query.house_type_id && (tmpAddress.house_type_id = query.house_type_id);
        query.residence_name &&
          (tmpAddress.residence_name = query.residence_name);
        query.room_number && (tmpAddress.room_number = query.room_number);
        let tmpData = JSON.parse(Storage.get("tmpData"));
        if (!tmpData) {
          tmpData = {};
        }
        if (!tmpData[query.email]) {
          tmpData[query.email] = {
            tmpAddress,
            lang: query.lang
          };
        }
        this.props.setLanguage(query.lang);
        Storage.set("tmpData", JSON.stringify(tmpData));
        this.props.history.push("/login");
      } else if (Storage.get("isRegistered")) {
        this.props.history.push("/");
      } else {
        this.props.resetUserState();
        this.props.history.push("/register/language_setting");
      }
    }
  }

  hundleClickStart() {
    this.props.history.push("/");
  }

  render() {
    return (
      <Div className={styles.readyToUsePage}>
        <Div className={styles.readyToUsePage__mainBlock}>
          <HeadingPrimary
            text={this.props.intl.formatMessage({ id: "ready.to.use.gommit!" })}
            className={styles.readyToUsePage__mainBlock__heading}
          />
          <Div className={styles.readyToUsePage__mainBlock__character}>
            <Character src={characterIcon} />
          </Div>
          <MultipurposeButton
            onClick={this.hundleClickStart.bind(this)}
            text={this.props.intl.formatMessage({ id: "start" })}
          />
        </Div>
      </Div>
    );
  }
}

export default ReadyToUse;
