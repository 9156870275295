import React from "react";
import styles from "./Item.module.scss";
import { P, Div } from "../Html/Html";

const Item = props => (
  <Div className={styles.itemWrapper}>
    <P className={styles.item + " " + (props.className ? props.className : "")}>
      {props.text}
    </P>
  </Div>
);

export default Item;
