import React from "react";
import { Formik, Form } from "formik";
import FormParts from "../../molecules/FormParts/FormParts";
import styles from "./SecondRegisterFrom.module.scss";
import { Div } from "../../atoms/Html/Html";
import MultipurposeButton from "../../molecules/MultipurposeButton/MultipurposeButton";
import * as Yup from "yup";
import {
  houseTypeOptions
} from "../../../constants/constants";

const SecondRegisterFrom = props => {
  let registerSchema;
  if (props.isOtherRegionMode === true) {
    registerSchema = Yup.object().shape({
      prefecture_id: Yup.string().required(
        props.intl.formatMessage({ id: "validation.required" })
      ),
      authority_id: Yup.string().required(
        props.intl.formatMessage({ id: "validation.required" })
      ),
      block_id: Yup.string().required(
        props.intl.formatMessage({ id: "validation.required" })
      ),
      email: Yup.string()
        .email(props.intl.formatMessage({ id: "validation.invalid.email" }))
        .required(props.intl.formatMessage({ id: "validation.required" })),
      password: Yup.string()
        .min(
          6,
          props.intl.formatMessage({
            id: "please.enter.a.password.with.at.least.6.characters"
          })
        )
        .required(props.intl.formatMessage({ id: "validation.required" })),
      password_confirmation: Yup.string().required(
        props.intl.formatMessage({ id: "validation.required" })
      )
    });
  } else {
    registerSchema = Yup.object().shape({
      email: Yup.string()
        .email(props.intl.formatMessage({ id: "validation.invalid.email" }))
        .required(props.intl.formatMessage({ id: "validation.required" })),
      password: Yup.string()
        .min(
          6,
          props.intl.formatMessage({
            id: "please.enter.a.password.with.at.least.6.characters"
          })
        )
        .required(props.intl.formatMessage({ id: "validation.required" })),
      password_confirmation: Yup.string().required(
        props.intl.formatMessage({ id: "validation.required" })
      )
    });
  }

  return (
    <Formik
      initialValues={props.form}
      validationSchema={registerSchema}
      onSubmit={values => {
        props.registerUserInfo(values);
        props.resetValidationMessageOfRegisterUserInfo();
      }}
    >
      {({ errors, touched, handleChange }) => (
        <Form id="registerUserForm">
          <Div className={styles.secondRegisterFromBlock}>
            {(() => {
              if (props.isOtherRegionMode === true) {
                return (
                  <>
                    <Div
                      className={
                        styles.secondRegisterFromBlock__multiple +
                        " " +
                        styles.secondRegisterFromBlock__row
                      }
                    >
                      <Div
                        className={
                          styles.secondRegisterFromBlock__multiple__item50
                        }
                      >
                        <FormParts
                          label={props.intl.formatMessage({ id: "prefecture" })}
                          name="prefecture_id"
                          component="select"
                          intl={props.intl}
                          required={true}
                          options={props.prefectureOptions}
                          errors={
                            errors.prefecture_id && touched.prefecture_id
                              ? [errors.prefecture_id]
                              : props.validationOfRegisterUserInfo.prefecture_id
                          }
                          isFetching={props.isFetchingPrefectures}
                          handleChange={handleChange}
                          handleChangeSecondRegisterForm={
                            props.handleChangeSecondRegisterForm
                          }
                        />
                      </Div>
                      <Div
                        className={
                          styles.secondRegisterFromBlock__multiple__item50
                        }
                      >
                        <FormParts
                          label={props.intl.formatMessage({ id: "city" })}
                          name="authority_id"
                          component="select"
                          intl={props.intl}
                          required={true}
                          options={props.authorityOptions}
                          errors={
                            errors.authority_id && touched.authority_id
                              ? [errors.authority_id]
                              : props.validationOfRegisterUserInfo.authority_id
                          }
                          isFetching={props.isFetchingAuthorities}
                          handleChange={handleChange}
                          handleChangeSecondRegisterForm={
                            props.handleChangeSecondRegisterForm
                          }
                          disabled={!props.authorityOptions.length && true}
                        />
                      </Div>
                    </Div>
                    <Div className={styles.secondRegisterFromBlock__row}>
                      <FormParts
                        label={props.intl.formatMessage({ id: "region" })}
                        name="block_id"
                        component="select"
                        intl={props.intl}
                        required={true}
                        options={props.blockOptions}
                        errors={
                          errors.block_id && touched.block_id
                            ? [errors.block_id]
                            : props.validationOfRegisterUserInfo.block_id
                        }
                        isFetching={props.isFetchingBlocks}
                        disabled={!props.blockOptions.length && true}
                        handleChange={handleChange}
                        handleChangeSecondRegisterForm={
                          props.handleChangeSecondRegisterForm
                        }
                      />
                    </Div>
                    <Div className={styles.secondRegisterFromBlock__row}>
                      <FormParts
                        label={props.intl.formatMessage({ id: "address" })}
                        name="street_name"
                        component="input"
                        type="text"
                        intl={props.intl}
                        errors={
                          errors.street_name && touched.street_name
                            ? [errors.street_name]
                            : props.validationOfRegisterUserInfo.street_name
                        }
                        handleChange={handleChange}
                        handleChangeSecondRegisterForm={
                          props.handleChangeSecondRegisterForm
                        }
                      />
                    </Div>
                  </>
                );
              } else {
                return (
                  <>
                    <Div
                      className={
                        styles.secondRegisterFromBlock__multiple +
                        " " +
                        styles.secondRegisterFromBlock__row
                      }
                    >
                      <Div
                        className={
                          styles.secondRegisterFromBlock__multiple__item35
                        }
                      >
                        <FormParts
                          label={props.intl.formatMessage({ id: "address" })}
                          name="street_name"
                          component="input"
                          type="text"
                          intl={props.intl}
                          errors={
                            errors.street_name && touched.street_name
                              ? [errors.street_name]
                              : props.validationOfRegisterUserInfo.street_name
                          }
                          handleChange={handleChange}
                          handleChangeSecondRegisterForm={
                            props.handleChangeSecondRegisterForm
                          }
                        />
                      </Div>
                      <Div
                        className={
                          styles.secondRegisterFromBlock__multiple__item65 +
                          " " +
                          styles.secondRegisterFromBlock__multiple__itemText
                        }
                      >
                        {props.regionText}
                      </Div>
                    </Div>
                  </>
                );
              }
            })()}

            <Div className={styles.secondRegisterFromBlock__row}>
              <FormParts
                label={props.intl.formatMessage({ id: "type.of.residence" })}
                name="house_type_id"
                component="select"
                options={houseTypeOptions.map(element => ({ value: element.value, label: props.intl.formatMessage({ id: element.id }) }))}
                intl={props.intl}
                errors={
                  errors.house_type_id && touched.house_type_id
                    ? [errors.house_type_id]
                    : props.validationOfRegisterUserInfo.house_type_id
                }
                handleChange={handleChange}
                handleChangeSecondRegisterForm={
                  props.handleChangeSecondRegisterForm
                }
              />
            </Div>

            <Div
              className={
                styles.secondRegisterFromBlock__multiple +
                " " +
                styles.secondRegisterFromBlock__row
              }
            >
              <Div className={styles.secondRegisterFromBlock__multiple__item67}>
                <FormParts
                  label={props.intl.formatMessage({ id: "residence.name" })}
                  name="residence_name"
                  component="input"
                  type="text"
                  intl={props.intl}
                  errors={
                    errors.residence_name && touched.residence_name
                      ? [errors.residence_name]
                      : props.validationOfRegisterUserInfo.residence_name
                  }
                  handleChange={handleChange}
                  handleChangeSecondRegisterForm={
                    props.handleChangeSecondRegisterForm
                  }
                />
              </Div>
              <Div className={styles.secondRegisterFromBlock__multiple__item33}>
                <FormParts
                  label={props.intl.formatMessage({ id: "room.number" })}
                  name="room_number"
                  component="input"
                  type="text"
                  intl={props.intl}
                  errors={
                    errors.room_number && touched.room_number
                      ? [errors.room_number]
                      : props.validationOfRegisterUserInfo.room_number
                  }
                  handleChange={handleChange}
                  handleChangeSecondRegisterForm={
                    props.handleChangeSecondRegisterForm
                  }
                />
              </Div>
            </Div>
            <Div className={styles.secondRegisterFromBlock__row}>
              <FormParts
                label={props.intl.formatMessage({ id: "your.mail" })}
                name="email"
                component="input"
                type="email"
                intl={props.intl}
                required={true}
                errors={
                  errors.email && touched.email
                    ? [errors.email]
                    : props.validationOfRegisterUserInfo.email
                }
                handleChange={handleChange}
                handleChangeSecondRegisterForm={
                  props.handleChangeSecondRegisterForm
                }
              />
            </Div>
            <Div className={styles.secondRegisterFromBlock__row}>
              <FormParts
                label={props.intl.formatMessage({ id: "login.password" })}
                name="password"
                component="input"
                type="password"
                intl={props.intl}
                required={true}
                errors={
                  errors.password && touched.password
                    ? [errors.password]
                    : props.validationOfRegisterUserInfo.password
                }
                handleChange={handleChange}
                handleChangeSecondRegisterForm={
                  props.handleChangeSecondRegisterForm
                }
              />
            </Div>
            <Div className={styles.secondRegisterFromBlock__row}>
              <FormParts
                label={props.intl.formatMessage({
                  id: "password.confirmation"
                })}
                name="password_confirmation"
                component="input"
                type="password"
                intl={props.intl}
                required={true}
                errors={
                  errors.password_confirmation && touched.password_confirmation
                    ? [errors.password_confirmation]
                    : props.validationOfRegisterUserInfo.password_confirmation
                }
                handleChange={handleChange}
                handleChangeSecondRegisterForm={
                  props.handleChangeSecondRegisterForm
                }
              />
            </Div>
          </Div>
          <MultipurposeButton
            type="submit"
            text={props.intl.formatMessage({ id: "register" })}
            buttonStyle={styles.secondRegisterFromBlock__button}
          />
        </Form>
      )}
    </Formik>
  );
};

export default SecondRegisterFrom;
