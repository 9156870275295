import React from 'react';
import {Div, Img} from '../../atoms/Html/Html';
import styles from './Splash.module.scss';
import Logo from '../../../../assets/images/logo.svg';
import Robot from '../../../../assets/images/robot.png';
export const Splash = props => {
    return (
        <Div className={styles.splash}>
          <Img src={Robot}/>
          <Img src={Logo} className={styles.splash__logo}/>
        </Div>
    );
};

export default Splash;