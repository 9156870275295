// APIローカル環境
export const localApiUrl = "http://localhost:3010";

// APIステージング環境
export const developmentApiUrl = "https://aips-api-staging.herokuapp.com/api/v1/gommit";

// API本番環境
export const productionApiUrl = "https://aips-api-app.herokuapp.com/api/v1/gommit";

// BUILDローカル環境
export const localBuildUrl = "http://localhost:3000";

// BUILDステージング環境
export const developmentBuildUrl = "https://aips.pikondev.com";

// BUILD本番環境
export const productionBuildUrl = "https://app.aiconcierge.jp";

export const monthEnglishList = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const weekEnglishList = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday"
];

//カレンダーやスケジュールの一回で何ヶ月分取得するのかの定数です。
export const howManyMonthsToGet = 3;

// 物件種別のoptionです。
export const houseTypeOptions = [
  { value: 1, id: "apartment" },
  { value: 2, id: "house" }
];
