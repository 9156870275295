import React from "react";
import { withFormik, Form } from "formik";
import FormParts from "../../molecules/FormParts/FormParts";
import MultipurposeButton from "../../molecules/MultipurposeButton/MultipurposeButton";
import styles from "./LoginForm.module.scss";
import { Div } from "../../atoms/Html/Html";
import * as Yup from "yup";

const LoginForm = props => {
  const { touched, errors, handleChange } = props;
  return (
    <Form className={styles.LoginFormBlock}>
      <Div className={styles.LoginFormBlock__formItem}>
        <FormParts
          label={props.intl.formatMessage({ id: "email" })}
          name="email"
          component="input"
          type="email"
          errors={errors.email && touched.email ? [errors.email] : null}
          handleChange={handleChange}
        />
      </Div>
      <Div
        className={
          styles.LoginFormBlock__formItem +
          " " +
          styles.LoginFormBlock__formItem_last
        }
      >
        <FormParts
          label={props.intl.formatMessage({ id: "password" })}
          name="password"
          component="input"
          type="password"
          errors={errors.password && touched.password ? [errors.password] : null}
          handleChange={handleChange}
        />
      </Div>
      <MultipurposeButton
        type="submit"
        text={props.intl.formatMessage({ id: "login" })}
      />
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: () => {
    return {
      email: "",
      password: ""
    }
  },
  validationSchema(props) {
    return Yup.object().shape({
      email: Yup.string()
      .email(props.intl.formatMessage({ id: "validation.invalid.email" }))
      .required(props.intl.formatMessage({ id: "validation.required" })),
      password: Yup.string().min(6, props.intl.formatMessage({ id: "please.enter.a.password.with.at.least.6.characters" })).required(
        props.intl.formatMessage({ id: "validation.required" })
      )
    });
  },
  handleSubmit: (values, { props }) => {
    const submitValue = {
      email: values.email,
      password: values.password
    };
    props.submitData(submitValue);
  }
})(LoginForm);
