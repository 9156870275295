import {
  REQUEST_FETCH_FAQ_LIST,
  SUCCESS_FETCH_FAQ_LIST,
  ERROR_FETCH_FAQ_LIST,
  ERROR_AUTH_FETCH_FAQ_LIST,
  SET_IS_ERROR_FETCHING_FAQ_LIST,
  SET_FAQ_MSG,
  SET_INIT_FAQ_FLAG
} from "../actions/faq";

const Faq = (
  state = {
    faqList: [],
    isFetchingFaqList: false,
    errors: {
      msg: "",
      isErrorFetchingFaqList: false
    },
    initFaqFlag: true
  },
  action
) => {
  switch (action.type) {
    case REQUEST_FETCH_FAQ_LIST:
      return Object.assign({}, state, {
        isFetchingFaqList: true
      });
    case SUCCESS_FETCH_FAQ_LIST:
      return Object.assign({}, state, {
        faqList: action.list,
        isFetchingFaqList: false
      });
    case ERROR_FETCH_FAQ_LIST:
      return Object.assign({}, state, {
        isFetchingFaqList: false,
        errors: {
          msg: action.msg,
          isErrorFetchingFaqList: true
        }
      });
    case ERROR_AUTH_FETCH_FAQ_LIST:
      return Object.assign({}, state, {
        isFetchingFaqList: false
      });
    case SET_IS_ERROR_FETCHING_FAQ_LIST:
      return Object.assign({}, state, {
        errors: {
          msg: state.errors.msg,
          isErrorFetchingFaqList: action.bool
        }
      });
    case SET_FAQ_MSG:
      return Object.assign({}, state, {
        errors: {
          msg: action.msg,
          isErrorFetchingFaqList: state.errors.isErrorFetchingFaqList
        }
      });
    case SET_INIT_FAQ_FLAG:
      return Object.assign({}, state, {
        initFaqFlag: action.bool
      });
    default:
      return state;
  }
};

export default Faq;
