import React from "react";
import style from "./ContentBox.module.scss";
import { Div, P, Header, Footer, A, Img } from "../../atoms/Html/Html";
import Heading from "../../atoms/Heading/Heading";
//import Divider from '../../atoms/Divider/Divider'
import link from "../../../../assets/images/icon-link.svg";

/*
 ** 引数
 *
 * heading : str
 * exLinkUrl : str
 * exLinkText : str
 * headingIcon : img_path
 * className : style
 *
 */
const ContentBox = props => {
  const headingIcon = props.headingIcon ? (
    <Div className={style.contentBox__headingBlock__iconBlock}>
      <Img src={props.headingIcon} className={style.icon} />
    </Div>
  ) : (
    ""
  );
  const header = props.heading ? (
    <>
      <Div className={style.contentBox__headingBlock}>
        <Div className={style.contentBox__headingBlock__wrap}>
          {headingIcon}
          <Heading text={props.heading} className={style.heading} />
        </Div>
      </Div>
    </>
  ) : (
    ""
  );
  const exLinkText = props.exLinkText ? props.exLinkText : props.exLinkUrl;
  const footer = props.exLinkUrl ? (
    <>
      <Div className={style.footer}>
        <A
          href={props.exLinkUrl}
          target="_blank"
          className={style.footer__link}
        >
          {exLinkText}
          <Img src={link} className={style.footer__link__icon} />
        </A>
      </Div>
    </>
  ) : (
    ""
  );

  return (
    <Div className={style.wrapper + " " + props.className}>
      {header}
      <Div className={style.body}>{props.children}</Div>
      {footer}
    </Div>
  );
};

export default ContentBox;
