import React from "react";
import style from "./Faq.module.scss";
import GlobalHeader from "../../components/organisms/GlobalHeader/GlobalHeader";
import GlobalFooter from "../../components/organisms/GlobalFooter/GlobalFooter";
import { Div, Main } from "../../components/atoms/Html/Html";
import ContentBoxGroup from "../../components/organisms/ContentBoxGroup/ContentBoxGroup";
import LoadingWrapper from "../../components/organisms/LoadingWrapper/LoadingWrapper";
import ScrollView from "../../components/atoms/ScrollView/ScrollView";

class Faq extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    if (this.props.faq.initFaqFlag) {
      this.props.fetchFaqList();
      this.props.setInitFaqFlag(false);
    }
  }

  handleClickBell() {
    this.props.history.push("/information");
  }

  handleClickSettings() {
    this.props.history.push("/settings");
  }

  handleClickRetry() {
    this.props.setIsErrorFetchingFaqList(false);
    this.props.setFaqMsg("");
    this.props.fetchFaqList();
  }

  render() {
    const leftContent = [
      { label: "bell", action: this.handleClickBell.bind(this) }
    ];
    const rightContent = [
      { label: "faq", isActive: true },
      { label: "settings", action: this.handleClickSettings.bind(this) }
    ];
    const faqList = this.props.faq.faqList.map(function(element) {
      return {
        type: "faq",
        contentBoxStyle: style.faqBlock__main__scrollViewBlock__contentBox,
        body: {
          question: element.question,
          answer: element.answer
        }
      };
    });
    return (
      <>
        <GlobalHeader
          title={this.props.intl.formatMessage({ id: "faq" })}
          leftContent={leftContent}
          rightContent={rightContent}
        />
        <Main className={style.faqBlock__main}>
          <ScrollView
            contentContainerStyle={style.faqBlock__main__scrollViewBlock}
          >
            <LoadingWrapper
              isConnecting={this.props.faq.isFetchingFaqList}
              loadingStyle={style.faqBlock__main__scrollViewBlock__loadingBlock}
              errorText={this.props.faq.errors.msg}
              onRetry={this.handleClickRetry.bind(this)}
              retryText={this.props.intl.formatMessage({ id: "get.again" })}
              errorStyle={style.faqBlock__main__scrollViewBlock__errorBlock}
            >
              {!this.props.faq.isFetchingFaqList &&
                this.props.faq.errors.msg === "" && (
                  <ContentBoxGroup contents={faqList} />
                )}
            </LoadingWrapper>
          </ScrollView>
        </Main>
        <GlobalFooter
          intl={this.props.intl}
          pathname={this.props.history.location.pathname}
        />
      </>
    );
  }
}

export default Faq;
