import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  login,
  setIsLoginError,
  setErrorMsgOfAuthState,
  tryLoginAndRegisterAddress,
  setIsLoggedIn
} from "../actions/auth";

import Login from "../../web/pages/Login/Login";

const mapStateToProps = state => {
  return { auth: state.auth };
};

const mapDispatchToProps = dispatch => {
  return {
    tryLogin: values => {
      dispatch(login(values));
    },
    setIsLoginError: bool => {
      dispatch(setIsLoginError(bool));
    },
    setErrorMsgOfAuthState: msg => {
      dispatch(setErrorMsgOfAuthState(msg));
    },
    tryLoginAndRegisterAddress: (values, history) => {
      dispatch(tryLoginAndRegisterAddress(values, history));
    },
    setIsLoggedIn: bool => {
      dispatch(setIsLoggedIn(bool));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Login)
);
