import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  sendMailOfResetPassword,
  setIsForgetPasswordError,
  setErrorMsgOfAuthState
} from "../actions/auth";

import ForgetPassword from "../../web/pages/ForgetPassword/ForgetPassword";

const mapStateToProps = state => {
  return { auth: state.auth };
};

const mapDispatchToProps = dispatch => {
  return {
    sendMailOfResetPassword: values => {
      dispatch(sendMailOfResetPassword(values));
    },
    setIsForgetPasswordError: bool => {
      dispatch(setIsForgetPasswordError(bool));
    },
    setErrorMsgOfAuthState: msg => {
      dispatch(setErrorMsgOfAuthState(msg));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ForgetPassword)
);
