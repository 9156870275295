import React from "react";
import styles from "./Register.module.scss";
import BackLink from "../../components/molecules/BackLink/BackLink";
import CurrentRegisterNumber from "../../components/molecules/StepNumber/StepNumber";
import CurrentRegisterTitle from "../../components/atoms/Heading/HeadingPrimary";
import { Div } from "../../components/atoms/Html/Html";
import Modal from "../../components/organisms/Modal/Modal";
import TextLinkBlock from "../../components/molecules/TextLinkBlock/TextLinkBlock";
import FirstRegisterFrom from "../../components/register/FirstRegisterFrom/FirstRegisterFrom";
import ProgressTracker from "../../components/organisms/ProgressTracker/ProgressTracker";
import { getFormattedMessage } from "../../constants/functions";

class SelectRegion extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.getGeoPosition();
  }

  getGeoPosition() {
    this.props.setIsGettingRegion(true);
    if (navigator.geolocation) {
      //Geolocation APIを利用できる環境向けの処理
      console.log("Start getting location");
      navigator.geolocation.getCurrentPosition(
        this.successGetGeoPosition.bind(this),
        this.failGetGeoPosition.bind(this),
        {
          enableHighAccuracy: true,
          timeout: 6000,
          maximumAge: 600000
        }
      );
    } else {
      // GPSが無効の場合
      this.failGetGeoPosition.bind(this);
    }
  }

  successGetGeoPosition(position) {
    // console.log("Success to get location");
    const location = {};
    location.longitude = position.coords.longitude;
    location.latitude = position.coords.latitude;
    this.props.fetchRegion(location);
  }

  failGetGeoPosition() {
    // console.log("fail to get location");
    this.props.setIsGettingRegion(false);
    this.props.errorGetRegion(getFormattedMessage('failed.to.get.your.location.'));
  }

  handleChangeSelectRegion(e) {
    if (e.target.value !== "") {
      this.props.modifyStateOfLocation(
        this.props.user.regions.blocks[e.target.value]
      );
      this.props.changeIsOtherRegionMode(false);
      this.props.changeAvailableRegisterPage({ more_information: true });
      this.props.history.push("/register/more_information");
      this.props.changeAvailableRegisterPage({ select_region: false });
    }
  }

  handleClickAuthLink() {
    this.props.changeIsOtherRegionMode(true);
    this.props.changeAvailableRegisterPage({ more_information: true });
    this.props.history.push("/register/more_information");
    this.props.changeAvailableRegisterPage({ select_region: false });
  }

  handleClickRetryOfFetchRegionError() {
    this.props.setIsGettingRegion(true);
    this.props.setIsFetchRegionError(false);
    this.props.setErrorMsgOfUserState("");
    setTimeout(this.getGeoPosition.bind(this), 2000);
  }

  handleClickInputManuallyOfFetchRegionError() {
    this.props.changeAvailableRegisterPage({ more_information: true });
    this.props.history.push("/register/more_information");
    this.props.changeAvailableRegisterPage({ select_region: false });
    this.props.changeIsOtherRegionMode(true);
    this.props.setIsFetchRegionError(false);
    this.props.setErrorMsgOfUserState("");
  }

  hundleClickBackLink() {
    this.props.history.push("/register/language_setting");
  }

  handleClickToLogin() {
    this.props.history.push("/login");
  }

  render() {
    let options = [];
    const regions = this.props.user.regions;
    regions.blocks.forEach(function(value, index) {
      options.push({
        value: index,
        label: getFormattedMessage("rough.address", regions.prefecture.name, regions.authority.name, value.name)
      });
    });

    let choiceListOfFetchRegionError = [
      {
        label: this.props.intl.formatMessage({ id: "retry" }),
        action: this.handleClickRetryOfFetchRegionError.bind(this)
      },
      {
        label: this.props.intl.formatMessage({ id: "input.manually" }),
        action: this.handleClickInputManuallyOfFetchRegionError.bind(this)
      }
    ];

    return (
      <Div className={styles.selectRegionPage}>
        <BackLink handleClick={this.hundleClickBackLink.bind(this)} />
        <Div className={styles.selectRegionPage__mainBlock}>
          <ProgressTracker
            currentNumber={1}
            totalNumber={3}
            text={this.props.intl.formatMessage({
              id: "1.select.your.region"
            })}
          />
          {(() => {
            if (this.props.user.isGettingRegion === true) {
              return (
                <Modal
                  type={"loading"}
                  text={this.props.intl.formatMessage({
                    id: "getting.your.location.data"
                  })}
                />
              );
            } else if (this.props.user.errors.isFetchRegionError === true) {
              return (
                <Modal
                  type={"choice"}
                  text={this.props.user.errors.msg}
                  choiceList={choiceListOfFetchRegionError}
                />
              );
            } else {
              return (
                <>
                  <FirstRegisterFrom
                    options={options}
                    handleChangeSelectRegion={this.handleChangeSelectRegion.bind(
                      this
                    )}
                    intl={this.props.intl}
                  />
                  <TextLinkBlock
                    linkText={this.props.intl.formatMessage({
                      id: "use.the.other.region"
                    })}
                    handleClick={this.handleClickAuthLink.bind(this)}
                  />
                </>
              );
            }
          })()}
        </Div>
        <TextLinkBlock
          text={this.props.intl.formatMessage({ id: "already.use.gommit?" })}
          linkText={this.props.intl.formatMessage({ id: "log.in" })}
          handleClick={this.handleClickToLogin.bind(this)}
        />
      </Div>
    );
  }
}

export default SelectRegion;
