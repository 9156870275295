import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  setLanguage,
  errorGetRegion,
  fetchRegion,
  modifyStateOfLocation,
  changeIsOtherRegionMode,
  setIsFetchRegionError,
  setErrorMsgOfUserState,
  registerUserInfo,
  setIsSuccessUserRegistration,
  setIsRegisterError,
  resetValidationMessageOfRegisterUserInfo,
  modifyRegisterUserForm,
  changeAvailableRegisterPage,
  resetUserState,
  fetchPrefectures,
  setIsFetchPrefecturesError,
  fetchAuthorities,
  setIsFetchAuthoritiesError,
  fetchBlocks,
  setIsEmptyBlocksError,
  setIsFetchBlocksError,
  setAuthorities,
  setBlocks,
  setTheValueOfFormAuthorityId,
  setTheValueOfFormBlockId,
  resetInitFetchPrefectures,
  setPrefectures,
  setIsMailTokenTimeError,
  setIsGettingRegion
} from "../actions/user";
import { setIsLoggedIn } from "../actions/auth";

import Register from "../../web/pages/Register/Register";

const mapStateToProps = state => {
  return { auth: state.auth, user: state.user };
};

const mapDispatchToProps = dispatch => {
  return {
    setLanguage: language => {
      dispatch(setLanguage(language));
    },
    fetchRegion: location => {
      dispatch(fetchRegion(location));
    },
    modifyStateOfLocation: values => {
      dispatch(modifyStateOfLocation(values));
    },
    changeIsOtherRegionMode: bool => {
      dispatch(changeIsOtherRegionMode(bool));
    },
    setIsFetchRegionError: bool => {
      dispatch(setIsFetchRegionError(bool));
    },
    setErrorMsgOfUserState: string => {
      dispatch(setErrorMsgOfUserState(string));
    },
    registerUserInfo: values => {
      dispatch(registerUserInfo(values));
    },
    setIsSuccessUserRegistration: bool => {
      dispatch(setIsSuccessUserRegistration(bool));
    },
    setIsRegisterError: bool => {
      dispatch(setIsRegisterError(bool));
    },
    resetValidationMessageOfRegisterUserInfo: () => {
      dispatch(resetValidationMessageOfRegisterUserInfo());
    },
    modifyRegisterUserForm: values => {
      dispatch(modifyRegisterUserForm(values));
    },
    setIsLoggedIn: bool => {
      dispatch(setIsLoggedIn(bool));
    },
    changeAvailableRegisterPage: values => {
      dispatch(changeAvailableRegisterPage(values));
    },
    resetUserState: () => {
      dispatch(resetUserState());
    },
    fetchPrefectures: () => {
      dispatch(fetchPrefectures());
    },
    setIsFetchPrefecturesError: bool => {
      dispatch(setIsFetchPrefecturesError(bool));
    },
    fetchAuthorities: id => {
      dispatch(fetchAuthorities(id));
    },
    setIsFetchAuthoritiesError: bool => {
      dispatch(setIsFetchAuthoritiesError(bool));
    },
    fetchBlocks: (authorityId, districtId) => {
      dispatch(fetchBlocks(authorityId, districtId));
    },
    setIsEmptyBlocksError: bool => {
      dispatch(setIsEmptyBlocksError(bool));
    },
    setIsFetchBlocksError: bool => {
      dispatch(setIsFetchBlocksError(bool));
    },
    setAuthorities: values => {
      dispatch(setAuthorities(values));
    },
    setBlocks: values => {
      dispatch(setBlocks(values));
    },
    setTheValueOfFormAuthorityId: value => {
      dispatch(setTheValueOfFormAuthorityId(value));
    },
    setTheValueOfFormBlockId: value => {
      dispatch(setTheValueOfFormBlockId(value));
    },
    resetInitFetchPrefectures: () => {
      dispatch(resetInitFetchPrefectures());
    },
    setPrefectures: values => {
      dispatch(setPrefectures(values));
    },
    setIsMailTokenTimeError: bool => {
      dispatch(setIsMailTokenTimeError(bool));
    },
    errorGetRegion: message => {
      dispatch(errorGetRegion(message));
    },
    setIsGettingRegion: bool => {
      dispatch(setIsGettingRegion(bool));
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Register)
);
