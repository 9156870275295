import {
  REQUEST_FETCH_DICTIONARY_LIST,
  SUCCESS_FETCH_DICTIONARY_LIST,
  ERROR_FETCH_DICTIONARY_LIST,
  AUTH_ERROR_FETCH_DICTIONARY_LIST,
  CHANGE_SEARCH_TEXT,
  CHANGE_SCROLL_TOP,
  SET_DICTIONARY_MSG,
  SET_IS_FETCH_LIST_ERROR,
  REQUEST_FETCH_DICTIONARY_DETAIL,
  SUCCESS_FETCH_DICTIONARY_DETAIL,
  ERROR_FETCH_DICTIONARY_DETAIL,
  AUTH_ERROR_FETCH_DICTIONARY_DETAIL,
  SET_IS_FETCH_DETAIL_ERROR,
  SET_INIT_DICTIONARY_FLAG
} from "../actions/dictionary";
import { arrangeString } from "../../web/constants/functions";

const howToDispose = (
  state = {
    list: {
      isFetchingList: false,
      isFetchListError: false,
      list: [],
      filteredList: [],
      searchText: "",
      scrollTop: 0
    },
    detail: {
      isFetchingDetail: false,
      isFetchDetailError: false,
      detail: {
        description: "",
        name: "",
        next: "",
        trashes: []
      }
    },
    msg: "",
    initDictionaryFlag: true
  },
  action
) => {
  switch (action.type) {
    case REQUEST_FETCH_DICTIONARY_LIST:
      return Object.assign({}, state, {
        list: Object.assign({}, state.list, { isFetchingList: true })
      });
    case SUCCESS_FETCH_DICTIONARY_LIST:
      return Object.assign({}, state, {
        list: Object.assign({}, state.list, {
          isFetchingList: false,
          list: action.data,
          filteredList: action.data
        })
      });
    case ERROR_FETCH_DICTIONARY_LIST:
      return Object.assign({}, state, {
        list: Object.assign({}, state.list, {
          isFetchingList: false,
          isFetchListError: true
        }),
        msg: action.msg
      });
    case AUTH_ERROR_FETCH_DICTIONARY_LIST:
      return Object.assign({}, state, {
        list: Object.assign({}, state.list, { isFetchingList: false })
      });
    case CHANGE_SEARCH_TEXT:
      let filteredList;
      if (!action.value) {
        filteredList = state.list.list;
      } else {
        filteredList = [];
        const arrangedSearchText = arrangeString(action.value);
        state.list.list.forEach(function(element1) {
          const filtered = element1.items.filter(function(element2) {
            const arrangedName = arrangeString(element2.name);
            const arrangedKane = element2.kana
              ? arrangeString(element2.kana)
              : null;
            const re = new RegExp(arrangedSearchText, "i");
            return (
              arrangedName.match(re) !== null ||
              (arrangedKane !== null && arrangedKane.match(re) !== null)
            );
          });
          if (filtered.length) {
            filteredList.push({
              index: element1.index,
              items: filtered
            });
          }
        });
      }
      return Object.assign({}, state, {
        list: Object.assign({}, state.list, {
          searchText: action.value,
          filteredList
        })
      });
    case CHANGE_SCROLL_TOP:
      return Object.assign({}, state, {
        list: Object.assign({}, state.list, { scrollTop: action.value })
      });
    case SET_DICTIONARY_MSG:
      return Object.assign({}, state, {
        msg: action.msg
      });
    case SET_IS_FETCH_LIST_ERROR:
      return Object.assign({}, state, {
        list: Object.assign({}, state.list, { isFetchListError: action.bool })
      });
    case REQUEST_FETCH_DICTIONARY_DETAIL:
      return Object.assign({}, state, {
        detail: Object.assign({}, state.detail, { isFetchingDetail: true })
      });
    case SUCCESS_FETCH_DICTIONARY_DETAIL:
      return Object.assign({}, state, {
        detail: Object.assign({}, state.detail, {
          isFetchingDetail: false,
          detail: action.data
        })
      });
    case ERROR_FETCH_DICTIONARY_DETAIL:
      return Object.assign({}, state, {
        detail: Object.assign({}, state.detail, {
          isFetchingDetail: false,
          isFetchDetailError: true
        }),
        msg: action.msg
      });
    case AUTH_ERROR_FETCH_DICTIONARY_DETAIL:
      return Object.assign({}, state, {
        detail: Object.assign({}, state.detail, { isFetchingDetail: false })
      });
    case SET_IS_FETCH_DETAIL_ERROR:
      return Object.assign({}, state, {
        detail: Object.assign({}, state.detail, {
          isFetchDetailError: action.bool
        })
      });
    case SET_INIT_DICTIONARY_FLAG:
      return Object.assign({}, state, {
        initDictionaryFlag: action.bool
      });
    default:
      return state;
  }
};

export default howToDispose;
