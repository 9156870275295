import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  fetchDictionaryList,
  changeSearchText,
  changeScrollTop,
  setDictionaryMsg,
  setIsFetchListError,
  fetchDictionaryDetail,
  setIsFetchDetailError,
  setInitDictionaryFlag
} from "../actions/dictionary";

import Dictionary from "../../web/pages/Dictionary/Dictionary";

const mapStateToProps = state => {
  return { dictionary: state.dictionary, common: state.common };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchDictionaryList: () => {
      dispatch(fetchDictionaryList());
    },
    changeSearchText: value => {
      dispatch(changeSearchText(value));
    },
    changeScrollTop: value => {
      dispatch(changeScrollTop(value));
    },
    setDictionaryMsg: msg => {
      dispatch(setDictionaryMsg(msg));
    },
    setIsFetchListError: bool => {
      dispatch(setIsFetchListError(bool));
    },
    fetchDictionaryDetail: (dictionary_id, block_group_id) => {
      dispatch(fetchDictionaryDetail(dictionary_id, block_group_id));
    },
    setIsFetchDetailError: bool => {
      dispatch(setIsFetchDetailError(bool));
    },
    setInitDictionaryFlag: bool => {
      dispatch(setInitDictionaryFlag(bool));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Dictionary)
);
