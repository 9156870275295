import React from "react";
import styles from "./SelectLanguage.module.scss";
import { A } from "../../atoms/Html/Html";

const SelectLanguage = props => {
  const list = [
    {
      label: "日本語",
      value: "ja",
      style: styles.ja
    },
    {
      label: "English",
      value: "en",
      style: styles.en
    },
    {
      label: "简体中文",
      value: "zh",
      style: styles.ja
    },
    {
      label: "한국",
      value: "ko",
      style: styles.ja
    },
    {
      label: "Tiếng việt",
      value: "vi",
      style: styles.en
    },
    {
      label: "Português",
      value: "pt",
      style: styles.en
    },
    {
      label: "Tagalog",
      value: "tl",
      style: styles.en
    },
    {
      label: "ภาษาไทย",
      value: "th",
      style: styles.en
    },
    {
      label: "Le français",
      value: "fr",
      style: styles.en
    },
    {
      label: "Espanol",
      value: "es",
      style: styles.en
    }
  ]

  return (
    <>
      {list.map((item) =>
        <A
          className={styles.selectLanguageBlockText + " " + item.style}
          onClick={props.handleClick}
          dataValue={item.value}
        >
          {item.label}
        </A>
      )}
    </>
  )
};

export default SelectLanguage;
