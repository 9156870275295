import React from "react";
import { Div, Img, P } from "../../atoms/Html/Html";
import style from "./ItemButton.module.scss";
import arrow from "../../../../assets/images/icon_arrow_gray_right.svg";

const ItemButton = props => {
  let rightContent;
  switch (props.rightContentType) {
    case "arrow":
      rightContent = <Img src={arrow} />;
      break;
    case "button":
      rightContent = <Div>button</Div>;
      break;
    default:
      rightContent = null;
  }
  return (
    <Div
      className={style.itemButton}
      onClick={props.function}
      dataType={props.type}
    >
      <Div className={style.itemButton__row}>
        <Div className={style.itemButton__row__iconBlock}>
          <Img src={props.src} />
        </Div>
        <Div className={style.itemButton__row__textBlock}>
          <P className={style.itemButton__row__textBlock__text}>{props.text}</P>
        </Div>
        <Div className={style.itemButton__row__rightBlock}>{rightContent}</Div>
      </Div>
    </Div>
  );
};

export default ItemButton;
