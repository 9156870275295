import React, {useState} from "react";
import style from "./News.module.scss";
import GlobalHeader from "../../components/organisms/GlobalHeader/GlobalHeader";
import GlobalFooter from "../../components/organisms/GlobalFooter/GlobalFooter";
import { Div, Main, H3, Img, P, A } from "../../components/atoms/Html/Html";
import ScrollView from "../../components/atoms/ScrollView/ScrollView";
import iconRobotBox from "../../../assets/images/icon_robot_Box.png";
import { getIntl, getFormattedMessage, arrangeDate, isStatusOk } from "../../constants/functions";
import LoadingWrapper from "../../components/organisms/LoadingWrapper/LoadingWrapper";

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gettingNews: false,
      newsList: [],
      msg: "",
      errorFlagOfGetNews: false
    };
    this.setOgpImageToState = this.setOgpImageToState.bind(this);
    this.api = "https://script.google.com/macros/s/AKfycbyGOr695paUBWizZMprMpaCIqzSs3nAfAl591Vfq_xfhqpT254/exec";
  }

  componentWillMount() {
    this.getNews();
  }

  getNews() {
    this.setState(Object.assign({}, this.state, {
      gettingNews: true
    }));

    let status;
    return fetch(this.api)
      .then((response) => {
        status = response.status;
        return response.json()
      })
      .then((json) => {
        if (isStatusOk(status)) {
          this.setState(Object.assign({}, this.state, {
            newsList: json
          }))
        } else {
          this.setState(Object.assign({}, this.state, {
            msg: getFormattedMessage('failed.to.get.information.'),
            errorFlagOfGetNews: true
          }))
        }
      })
      .catch(() => {
        this.setState(Object.assign({}, this.state, {
          msg: getFormattedMessage('communication.failed.'),
          errorFlagOfGetNews: true
        }))
      })
      .then(() => {
        this.setState(Object.assign({}, this.state, {
          gettingNews: false
        }))
      })
  }

    setOgpImageToState(url, functionToUpdateState) {
        let status;
        fetch(this.api + "?url=" + url)
            .then((response) => {
                status = response.status;
                return response.json()
            })
            .then((json) => {
                if (isStatusOk(status) && json.length > 0) {
                    functionToUpdateState(json[1]);
                } else {
                    functionToUpdateState(iconRobotBox);
                }
            });
    }

  render() {
    return (
      <>
        <GlobalHeader
          title={getFormattedMessage("news")}
          subtitle={
            this.props.common.status.authority.name +
            this.props.common.status.block.name
          }
          leftContent={[{ label: "bell", action: () => { this.props.history.push("/information"); } }]}
          rightContent={[
            { label: "faq", action: () => { this.props.history.push("/faq"); } },
            { label: "settings", action: () => { this.props.history.push("/settings"); } }
          ]}
        />
        <Main className={style.news__main}>
          <ScrollView contentContainerStyle={style.news__main__scrollView}>
            <LoadingWrapper
              isConnecting={this.state.gettingNews}
              loadingStyle={style.news__main__scrollViewBlock__loadingBlock}
              errorText={this.state.msg}
              onRetry={() => {
                this.setState(Object.assign({}, this.state, {
                  msg: "",
                  errorFlagOfGetNews: false
                }));
                this.getNews();
              }}
              retryText={getFormattedMessage("get.again")}
              errorStyle={style.news__main__scrollViewBlock__errorBlock}
            >
              {this.state.newsList.length > 0 && (
                <Div className={style.news__main__scrollView__details__CardGroup}>
                  {this.state.newsList.map((item, index) =>
                    <A href={item.url} key={index} target="_blank">
                      <Div className={style.news__main__scrollView__details__CardGroup__CardItem}>
                        <Div className={style.news__main__scrollView__details__CardGroup__CardItem__TextGroup}>
                          <Div className={style.news__main__scrollView__details__CardGroup__CardItem__TextGroup__TitleText}>
                            {item.title}
                          </Div>
                          <Div className={style.news__main__scrollView__details__CardGroup__CardItem__TextGroup__SubTextGroup}>
                            <Div className={style.news__main__scrollView__details__CardGroup__CardItem__TextGroup__SubTextGroup__OriginText}>
                              {item.siteName}
                            </Div>
                            <Div className={style.news__main__scrollView__details__CardGroup__CardItem__TextGroup__SubTextGroup__DayText}>
                              {arrangeDate(new Date(item.date), "yyyy年M月d日")}
                            </Div>
                          </Div>
                        </Div>
                        <Div className={style.news__main__scrollView__details__CardGroup__CardItem__ImgGroup}>
                          <Thumbnail setOgpImageToState={this.setOgpImageToState} url={item.url} className={style.news__main__scrollView__details__CardGroup__CardItem__ImgGroup__ImgBox}/>
                        </Div>
                      </Div>
                    </A>
                  )}
                </Div>
              )}
            </LoadingWrapper>

          </ScrollView>
        </Main>
        <GlobalFooter
          intl={getIntl()}
          pathname={this.props.history.location.pathname}
        />
      </>
    );
  }
}

function Thumbnail(props){
  const [src, setSrc] = useState();
  props.setOgpImageToState(props.url, setSrc);
  return <Img
      src={src}
      className={style.news__main__scrollView__details__CardGroup__CardItem__ImgGroup__ImgBox}
  />
}

export default News;
