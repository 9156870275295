import React from "react";
import style from "./Settings.module.scss";
import GlobalHeader from "../../components/organisms/GlobalHeader/GlobalHeader";
import GlobalFooter from "../../components/organisms/GlobalFooter/GlobalFooter";
import { Div, Main } from "../../components/atoms/Html/Html";
import ScrollView from "../../components/atoms/ScrollView/ScrollView";
import ActionBoxGroup from "../../components/organisms/ActionBoxGroup/ActionBoxGroup";
import { reload } from "../../constants/functions";

class Language extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.changeSettingsLang(this.props.intl.locale);
  }

  handleClickCancel() {
    this.props.history.push("/settings");
  }

  handleClickSave() {
    this.props.setLanguage(this.props.lang);
    reload();
  }

  handleChangeLang(_, value) {
    this.props.changeSettingsLang(value);
  }

  render() {
    const leftContent = [
      { label: "cancel", action: this.handleClickCancel.bind(this) }
    ];
    const rightContent = [
      { label: "save", action: this.handleClickSave.bind(this) }
    ];
    const languagesList = [
      {
        type: "select",
        body: {
          label: this.props.intl.formatMessage({ id: "select.language" }),
          name: "lang",
          options: [
            {
              value: "ja",
              label: "日本語"
            },
            {
              value: "en",
              label: "English"
            },
            {
              value: "zh",
              label: "简体中文"
            },
            {
              value: "ko",
              label: "한국"
            },
            {
              value: "vi",
              label: "Tiếng việt"
            },
            {
              label: "Português",
              value: "pt"
            },
            {
              label: "Tagalog",
              value: "tl"
            },
            {
              label: "ภาษาไทย",
              value: "th"
            },
            {
              label: "Le français",
              value: "fr"
            },
            {
              label: "Espanol",
              value: "es"
            }
          ]
        }
      }
    ];
    return (
      <>
        <GlobalHeader
          title={this.props.intl.formatMessage({ id: "language" })}
          leftContent={leftContent}
          rightContent={rightContent}
          intl={this.props.intl}
        />
        <Main className={style.main}>
          <ScrollView contentContainerStyle={style.main__contentContainer}>
            <ActionBoxGroup
              actions={languagesList}
              handleChangeForm={this.handleChangeLang.bind(this)}
              form={{ lang: this.props.lang }}
            />
          </ScrollView>
        </Main>
        <GlobalFooter
          intl={this.props.intl}
          pathname={this.props.history.location.pathname}
        />
      </>
    );
  }
}

export default Language;
