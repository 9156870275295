import React from "react";
import style from "./Calendar.module.scss";
import { Div, H2, Img, A, P, Button } from "../../atoms/Html/Html";
import {
  monthEnglishList,
  howManyMonthsToGet,
  weekEnglishList
} from "../../../constants/constants";
import SwipeableViews from "react-swipeable-views";
import {
  dateToString,
  today,
  calcMonth,
  getTrashTypeIcon,
  getFormattedMessage
} from "../../../constants/functions";
import loadingBlue from "../../../../assets/images/loading-blue.svg";
import LoadingWrapper from "../LoadingWrapper/LoadingWrapper";
import Arrow from "../../../../assets/images/icon_arrow_blue_right.svg";

const Calendar = props => {
  function handleClickRetry() {
    props.setIsErrorFetchCalendarList(false);
    props.setCalendarMsg("");
    props.fetchEventList(
      props.date.year,
      props.date.month,
      "calendar",
      howManyMonthsToGet,
      props.blockGroupId,
      false
    );
  }

  function changeCalendarIndex(index, indexLatest) {
    // indexは現在のindexです。indexLatestは前にいたindexです。
    props.changeCalendarIndex(index);
    let date;
    if (index > indexLatest) {
      date = calcMonth(props.date.year, props.date.month, 1);
    } else {
      date = calcMonth(props.date.year, props.date.month, -1);
    }
    props.changeCalendarDate(date.year, date.month);
    if (props.calendar.list.length === index) {
      props.fetchEventList(
        date.year,
        date.month,
        "calendar",
        howManyMonthsToGet,
        props.blockGroupId,
        false
      );
    }
  }

  function handleChangeCalendar(index, indexLatest) {
    changeCalendarIndex(index, indexLatest);
  }

  const createCalendar = (weeks, list) => {
    const calendarBlockList = list.map(function(element) {
      const first = new Date(element.year, element.month - 1, 1).getDay();
      const last = new Date(element.year, element.month, 0).getDate();
      const calendarList = [];
      for (let i = 0; i < 6; i++) {
        calendarList[i] = [];
        for (let j = 0; j < 7; j++) {
          const day = j + 1 + i * 7;
          if (i > 0 && j === 0 && (day - 1 < first || last < day - first)) {
            break;
          } else if (day - 1 < first || last < day - first) {
            calendarList[i][j] = null;
          } else {
            calendarList[i][j] = day - first;
          }
        }
        if (calendarList[i].length === 0) {
          calendarList.pop();
        }
      }
      const calendarRowStyle = {
        height: `calc(100% / ${calendarList.length} - 1px)`
      };

      const calendarTitle = getFormattedMessage("date1", element.year, element.month, getFormattedMessage(monthEnglishList[element.month - 1]).slice(0, 3).toUpperCase());

      const calendarBlock = (
        <Div className={style.calendarBlock__item}>
          <H2 className={style.calendarBlock__item__heading}>
            {calendarTitle}
          </H2>
          <Div className={style.calendarBlock__item__heading__calendar}>
            <Div
              className={style.calendarBlock__item__heading__calendar__weeks}
            >
              {weeks.map(youbi => (
                <Div
                  className={
                    style.calendarBlock__item__heading__calendar__weeks__item
                  }
                >
                  {youbi}
                </Div>
              ))}
            </Div>
            <Div className={style.calendarBlock__item__heading__calendar__list}>
              {calendarList.map(week => (
                <Div
                  className={
                    style.calendarBlock__item__heading__calendar__list__rowWrap
                  }
                  style={calendarRowStyle}
                >
                  <Div
                    className={
                      style.calendarBlock__item__heading__calendar__list__rowWrap__row
                    }
                  >
                    {week.map(function(day) {
                      let todayClassName = "";
                      if (day !== null) {
                        const dateObjectOfCalendarDay = new Date(
                          element.year + "/" + element.month + "/" + day
                        );
                        const dateObjectOfCurrentDay = new Date(today());
                        const calendarDay = dateToString(
                          dateObjectOfCalendarDay
                        );
                        const currentDay = dateToString(dateObjectOfCurrentDay);
                        if (calendarDay === currentDay) {
                          todayClassName = style.today;
                        }
                      }

                      let cellStyle =
                        style.calendarBlock__item__heading__calendar__list__rowWrap__row__item;
                      // 年末年始はグレーアウトさせる
                      if (props.date.month === 12) {
                        const yearEnd = [25, 26, 27, 28, 29, 30, 31];
                        if (yearEnd.indexOf(day) >= 0) {
                          cellStyle =
                            style.calendarBlock__item__heading__calendar__list__rowWrap__row__item__extra;
                        }
                      }

                      if (props.date.month === 1) {
                        const yearStart = [1, 2, 3, 4, 5, 6, 7];
                        if (yearStart.indexOf(day) >= 0) {
                          cellStyle =
                            style.calendarBlock__item__heading__calendar__list__rowWrap__row__item__extra;
                        }
                      }

                      return (
                        <Div className={cellStyle}>
                          <Div
                            className={
                              style.calendarBlock__item__heading__calendar__list__rowWrap__row__item__day
                            }
                          >
                            <Div
                              className={
                                style.day__number + " " + todayClassName
                              }
                            >
                              {day}
                            </Div>
                          </Div>
                          <Div
                            className={
                              style.calendarBlock__item__heading__calendar__list__rowWrap__row__item__imageBlock
                            }
                          >
                            {day !== null && (
                              <Div
                                className={
                                  element.days[day - 1].types.length <= 1
                                    ? style.imageBlock__oneImege
                                    : style.imageBlock__twoToFourImege
                                }
                              >
                                {element.days[day - 1].types.map(function(
                                  value,
                                  index,
                                  array
                                ) {
                                  if (array.length <= 1) {
                                    return (
                                      <Img
                                        className={
                                          style.imageBlock__wrapper__image
                                        }
                                        src={getTrashTypeIcon(value)}
                                      />
                                    );
                                  } else {
                                    return (
                                      <Div
                                        className={style.imageBlock__wrapper}
                                      >
                                        <Img
                                          className={
                                            style.imageBlock__wrapper__image
                                          }
                                          src={getTrashTypeIcon(value)}
                                        />
                                      </Div>
                                    );
                                  }
                                })}
                              </Div>
                            )}
                          </Div>
                        </Div>
                      );
                    })}
                  </Div>
                </Div>
              ))}
            </Div>
          </Div>
        </Div>
      );
      return calendarBlock;
    });
    return calendarBlockList;
  };

  const weeks = weekEnglishList.map((e) => {
    const str = getFormattedMessage(e);
    return getFormattedMessage("week1", str.slice(0, 1), str.charAt(0).toUpperCase() + str.slice(1, 3));
  });

  const calendar = createCalendar(weeks, props.calendar.list);

  const styles = {
    slideContainer: {
      height: "100%"
    }
  };

  /*
   ** コンポーネントを関数として実行する
   ** SwipeableViewsのchildrenを並列にするため
   */
  // toDo: propsに引数を設定
  const loadingWrapper = LoadingWrapper({
    children: calendar,
    isConnecting: props.isFetchingCalendarList,
    errorText: props.calendarMsg,
    onRetry: handleClickRetry,
    retryText: props.intl.formatMessage({ id: "get.again" }),
    willLoadNext: !props.isFullCalendarList,
    loadingStyle: style.calendarBlock__loadingBlock,
    errorStyle: style.calendarBlock__errorBlock
  });

  const changeIndexButtonBlock = (
    <Div>
      {props.calendarIndex === 0 ? (
        <Div />
      ) : (
        <Button
          onClick={() => {
            changeCalendarIndex(props.calendarIndex - 1, props.calendarIndex);
          }}
          className={style.indexButtonBlock__button__reverse}
        >
          <Img src={Arrow} className={style.indexButtonBlock__img__reverse}/>
        </Button>
      )}
      {props.calendar.list[props.calendarIndex] ? (
        <Button
          onClick={() => {
            changeCalendarIndex(props.calendarIndex + 1, props.calendarIndex);
          }}
          className={style.indexButtonBlock__button}
        >
          <Img src={Arrow} className={style.indexButtonBlock__img}/>
        </Button>
      ) : (
        <Div />
      )}
    </Div>
  );

  return (
    <Div className={style.calendarWrapper}>
      {changeIndexButtonBlock}
      <SwipeableViews
        className={style.calendarBlock}
        enableMouseEvents
        containerStyle={styles.slideContainer}
        onChangeIndex={handleChangeCalendar}
        index={props.calendarIndex}
      >
        {loadingWrapper}
      </SwipeableViews>
    </Div>
  );
};

export default Calendar;
