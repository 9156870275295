import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Modal from "../organisms/Modal/Modal";
import Splash from "../molecules/Splash/Splash";

class Auth extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    if (
      this.props.auth.accessToken !== "" &&
      !this.props.auth.isAfterFetchCommonInfo
    ) {
      this.props.checkAuth(this.props.history);
    }
  }

  handleClickRetry() {
    this.props.setIsErrorFetchCommonInfo(false);
    this.props.setErrorMsgOfAuthState("");
    this.props.checkAuth(this.props.history);
  }

  render() {
    let modalType, modalText, modalChoiceList;
    if (this.props.auth.errors.isErrorFetchCommonInfo) {
      modalType = "choice";
      modalText = this.props.auth.errors.msg;
      modalChoiceList = [
        {
          label: this.props.intl.formatMessage({ id: "retry" }),
          action: this.handleClickRetry.bind(this)
        }
      ];
    }

    return (
      <>
        {(() => {
          if (!this.props.auth.isLoggedIn) {
            return <Redirect to={"/login"} />;
          } else if (modalType) {
            return (
              <Modal
                type={modalType}
                text={modalText}
                choiceList={modalChoiceList}
              />
            );
          } else if (!this.props.auth.isAfterFetchCommonInfo) {
            return <Splash />;
          } else {
            return this.props.children;
          }
        })()}
      </>
    );

    //return this.props.children;
  }
}

export default Auth;
