import React from "react";
import styles from "./MultipurposeButton.module.scss";
import { Div, Button } from "../../atoms/Html/Html";

const MultipurposeButton = props => {
  return (
    <Div className={styles.buttonBlock + " " + props.buttonStyle}>
      <Button
        type={props.type}
        className={styles.blueButton}
        onClick={props.onClick}
      >
        {props.text}
      </Button>
    </Div>
  );
};

export default MultipurposeButton;
