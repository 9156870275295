import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SET_IS_REGISTERED,
  SET_TOKEN,
  SET_IS_LOGGEDIN,
  SUCCESS_REGISTER_ADDRESS,
  REQUEST_SEND_MAIL_OF_RESET_PASSWORD,
  SUCCESS_SEND_MAIL_OF_RESET_PASSWORD,
  ERROR_SEND_MAIL_OF_RESET_PASSWORD,
  SET_IS_LOGIN_ERROR,
  SET_ERROR_MSG_OF_AUTH_STATE,
  SET_IS_FORGET_PASSWORD_ERROR,
  LOG_OUT,
  SET_IS_FETCHING_COMMON_INFO,
  SET_IS_AFTER_FETCH_COMMON_INFO,
  ERROR_FETCH_COMMON_INFO,
  SET_IS_ERROR_FETCH_COMMON_INFO,
  SET_FLAGS,
  SET_VALUES,
  SET_ERRORS,
  SET_VALIDATIONS
} from "../actions/auth";

const auth = (
  state = {
    accessToken: "",
    errors: {
      msg: "",
      isLoginError: false,
      isForgetPasswordError: false,
      isErrorFetchCommonInfo: false,
      tokenErrorFlagOfResetPassword: false,
      communicationFailedFlagOfResetPassword: false
    },
    isLoggedIn: false,
    isLoginFetching: false,
    isForgetPasswordFetching: false,
    lastLogin: undefined,
    isRegistered: false,
    client: "",
    isFetchingCommonInfo: false,
    isAfterFetchCommonInfo: false,
    flags: {
      resettingPasswordFlag: false,
      successResetPasswordFlag: false
    },
    msg: "",
    validations: {
      resetPassword: {
        password: [],
        password_confirmation: []
      }
    }
  },
  action
) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        isLoggedIn: false,
        isLoginFetching: true
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isLoggedIn: true,
        isLoginFetching: false,
        isRegistered: true,
        accessToken: action.token,
        client: action.client
      });
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        isLoggedIn: false,
        isLoginFetching: false,
        errors: {
          msg: action.message,
          isLoginError: true,
          isForgetPasswordError: state.errors.isForgetPasswordError,
          isErrorFetchCommonInfo: state.errors.isErrorFetchCommonInfo
        }
      });
    case SET_IS_REGISTERED:
      return Object.assign({}, state, {
        isRegistered: action.isRegistered
      });
    case SET_TOKEN:
      return Object.assign({}, state, {
        accessToken: action.token,
        client: action.client
      });
    case SET_IS_LOGGEDIN:
      return Object.assign({}, state, {
        isLoggedIn: action.isLoggedIn
      });
    case SUCCESS_REGISTER_ADDRESS:
      return Object.assign({}, state, {
        isLoginFetching: false,
        accessToken: action.accessToken,
        client: action.client,
        isRegistered: true
      });
    case REQUEST_SEND_MAIL_OF_RESET_PASSWORD:
      return Object.assign({}, state, {
        isForgetPasswordFetching: true
      });
    case SUCCESS_SEND_MAIL_OF_RESET_PASSWORD:
      return Object.assign({}, state, {
        isForgetPasswordFetching: false,
        errors: {
          msg: action.message,
          isLoginError: state.errors.isLoginError,
          isForgetPasswordError: true,
          isErrorFetchCommonInfo: state.errors.isErrorFetchCommonInfo
        }
      });
    case ERROR_SEND_MAIL_OF_RESET_PASSWORD:
      return Object.assign({}, state, {
        isForgetPasswordFetching: false,
        errors: {
          msg: action.message,
          isLoginError: state.errors.isLoginError,
          isForgetPasswordError: true,
          isErrorFetchCommonInfo: state.errors.isErrorFetchCommonInfo
        }
      });
    case SET_IS_LOGIN_ERROR:
      return Object.assign({}, state, {
        errors: {
          msg: state.errors.msg,
          isLoginError: action.bool,
          isForgetPasswordError: state.errors.isForgetPasswordError,
          isErrorFetchCommonInfo: state.errors.isErrorFetchCommonInfo,
          isCatchRegisterAddressError: state.errors.isCatchRegisterAddressError
        }
      });
    case SET_ERROR_MSG_OF_AUTH_STATE:
      return Object.assign({}, state, {
        errors: {
          msg: action.msg,
          isLoginError: state.errors.isLoginError,
          isForgetPasswordError: state.errors.isForgetPasswordError,
          isErrorFetchCommonInfo: state.errors.isErrorFetchCommonInfo
        }
      });
    case SET_IS_FORGET_PASSWORD_ERROR:
      return Object.assign({}, state, {
        errors: {
          msg: state.errors.msg,
          isLoginError: state.errors.isLoginError,
          isForgetPasswordError: action.bool,
          isErrorFetchCommonInfo: state.errors.isErrorFetchCommonInfo
        }
      });
    case LOG_OUT:
      return Object.assign({}, state, {
        isLoggedIn: false
      });
    case SET_IS_FETCHING_COMMON_INFO:
      return Object.assign({}, state, {
        isFetchingCommonInfo: action.bool
      });
    case SET_IS_AFTER_FETCH_COMMON_INFO:
      return Object.assign({}, state, {
        isAfterFetchCommonInfo: action.bool
      });
    case ERROR_FETCH_COMMON_INFO:
      return Object.assign({}, state, {
        errors: {
          msg: action.message,
          isLoginError: state.errors.isLoginError,
          isForgetPasswordError: state.errors.isForgetPasswordError,
          isErrorFetchCommonInfo: true
        }
      });
    case SET_IS_ERROR_FETCH_COMMON_INFO:
      return Object.assign({}, state, {
        errors: {
          msg: state.errors.msg,
          isLoginError: state.errors.isLoginError,
          isForgetPasswordError: state.errors.isForgetPasswordError,
          isErrorFetchCommonInfo: action.bool
        }
      });
    case SET_FLAGS:
      return Object.assign({}, state, {
        flags: Object.assign({}, state.flags, action.object)
      });
    case SET_VALUES:
      return Object.assign({}, state, action.object);
    case SET_ERRORS:
      return Object.assign({}, state, {
        errors: Object.assign({}, state.errors, action.object)
      });
    case SET_VALIDATIONS:
      const tmpObject = {};
      switch (action.target) {
        case 'resetPassword':
          tmpObject.resetPassword = Object.assign({}, state.validations.resetPassword, action.object);
          break;
      }
      return Object.assign({}, state, {
        validations: Object.assign({}, state.validations, tmpObject)
      });
    default:
      return state;
  }
};

export default auth;
