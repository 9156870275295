import {
  developmentApiUrl,
  productionApiUrl,
  productionBuildUrl
} from "../../web/constants/constants";
import {
  getRequestHeader,
  isStatusOk,
  getLanguage,
  getState,
  Storage,
  getFormattedMessage
} from "../../web/constants/functions";

export const SET_LANGUAGE = "SET_LANGUAGE";

export function setLanguage(language) {
  return function(dispatch) {
    Storage.set("language", language);
    dispatch({
      type: SET_LANGUAGE,
      language
    });
  };
}

export const SET_IS_GETTING_REGION = "SET_IS_GETTING_REGION";

export function setIsGettingRegion(bool) {
  return {
    type: SET_IS_GETTING_REGION,
    bool
  };
}

export const SUCCESS_GET_REGION = "SUCCESS_GET_REGION";

function successGetRegion(json) {
  return {
    type: SUCCESS_GET_REGION,
    prefecture: json.data.prefecture.prefecture,
    authority: json.data.authority,
    blocks: json.data.blocks
  };
}

export const ERROR_GET_REGION = "ERROR_GET_REGION";

export function errorGetRegion(message) {
  return {
    type: ERROR_GET_REGION,
    errorMsg: message
  };
}

export function fetchRegion(location) {
  return function(dispatch) {
    const params = new URLSearchParams();
    params.set("latitude", location.latitude);
    params.set("longtitude", location.longitude);

    let status;
    return fetch(
      /*developmentApiUrl*/ productionApiUrl +
        "/prefectures/search_address?" +
        params.toString(),
      {
        headers: getRequestHeader()
      }
    )
      .then(function(response) {
        status = response.status;
        return response.json();
      })
      .then(function(json) {
        if (isStatusOk(status)) {
          dispatch(successGetRegion(json));
          dispatch(setIsGettingRegion(false));
        } else {
          dispatch(errorGetRegion(getFormattedMessage('failed.to.get.your.location.')));
          dispatch(setIsGettingRegion(false));
        }
      })
      .catch(function() {
        dispatch(errorGetRegion(getFormattedMessage('communication.failed.')));
        dispatch(setIsGettingRegion(false));
      });
  };
}

export const MODIFY_STATE_OF_LOCATION = "MODIFY_STATE_OF_LOCATION";

export function modifyStateOfLocation(values) {
  return {
    type: MODIFY_STATE_OF_LOCATION,
    values
  };
}

export const CHNAGE_IS_OTHER_REGION_MODE = "CHNAGE_IS_OTHER_REGION_MODE";

export function changeIsOtherRegionMode(bool) {
  return {
    type: CHNAGE_IS_OTHER_REGION_MODE,
    bool
  };
}

export const SET_IS_FETCH_REGION_ERROR = "SET_IS_FETCH_REGION_ERROR";

export function setIsFetchRegionError(bool) {
  return {
    type: SET_IS_FETCH_REGION_ERROR,
    bool
  };
}

export const SET_ERROR_MSG_OF_USER_STATE = "SET_ERROR_MSG_OF_USER_STATE";

export function setErrorMsgOfUserState(string) {
  return {
    type: SET_ERROR_MSG_OF_USER_STATE,
    string
  };
}

export const ERROR_MAIL_TOKEN_TIME = "ERROR_MAIL_TOKEN_TIME";

function errorMailTokenTime(value) {
  return {
    type: ERROR_MAIL_TOKEN_TIME,
    errorMsg: value
  };
}

export const REQUEST_REGISTER_USER_INFO = "REQUEST_REGISTER_USER_INFO";

function requestRegisterUserInfo() {
  return {
    type: REQUEST_REGISTER_USER_INFO
  };
}

export const SUCCESS_REGISTER_USER_INFO = "SUCCESS_REGISTER_USER_INFO";

function successRegisterUserInfo() {
  return {
    type: SUCCESS_REGISTER_USER_INFO
  };
}

export const ERROR_REGISTER_USER_INFO = "ERROR_REGISTER_USER_INFO";

function errorRegisterUserInfo(values) {
  let errors = {};
  values.prefecture && (errors.prefecture_id = values.prefecture);
  values.authority && (errors.authority_id = values.authority);
  values.block && (errors.block_id = values.block);
  delete values.prefecture;
  delete values.authority;
  delete values.block;
  return {
    type: ERROR_REGISTER_USER_INFO,
    errors: Object.assign({}, errors, values)
  };
}

export const CATCH_ERROR_REGISTER_USER_INFO = "CATCH_ERROR_REGISTER_USER_INFO";

function catchErrorRegisterUserInfo(value) {
  return {
    type: CATCH_ERROR_REGISTER_USER_INFO,
    errorMsg: value
  };
}

export function registerUserInfo(form_values) {
  return function(dispatch) {
    /*
    form_valuesとgetState().user.formのprefecture_id, authority_id, block_idはそれぞれのaddressOptionsのindexの値になっています。
    getState().user.formのprefecture_value, authority_value, block_valueがそれぞれのidにっています。
    なのでgetState().user.formのprefecture_value, authority_value, block_valueでform_valuesのprefecture_id, authority_id, block_idを上書きしています。
    */
    let values = Object.assign({}, form_values, {
      prefecture_id: getState().user.form.prefecture_value,
      authority_id: getState().user.form.authority_value,
      block_id: getState().user.form.block_value
    });
    const tmpData = JSON.parse(Storage.get("tmpData"));
    // すでに登録したことがあるemailかつ現在時刻がそのemailのトークンが切れる時刻より前だった場合、住所チェックを行いません。
    if (
      tmpData &&
      tmpData[values.email] &&
      new Date() <
        new Date(
          tmpData[values.email].tokenExpiryTime.replace(
            /([\+|\-])([0-9]{2})([0-9]{2})$/,
            "$1$2:$3"
          )
        )
    ) {
      const tokenExpiryDate = new Date(
        tmpData[values.email].tokenExpiryTime.replace(
          /([\+|\-])([0-9]{2})([0-9]{2})$/,
          "$1$2:$3"
        )
      );
      
      const dateString =
        tokenExpiryDate.getFullYear() +
        "/" +
        ("00" + (tokenExpiryDate.getMonth() + 1)).slice(-2) +
        "/" +
        ("00" + tokenExpiryDate.getDate()).slice(-2) +
        " " +
        tokenExpiryDate.getHours() +
        ":" +
        (tokenExpiryDate.getMinutes() + 1);

      return dispatch(errorMailTokenTime(getFormattedMessage("mail.token.error", dateString)));
    }
    dispatch(requestRegisterUserInfo());
    let addressCheckParams = {};
    values.prefecture_id &&
      (addressCheckParams.prefecture_id = values.prefecture_id);
    values.authority_id &&
      (addressCheckParams.authority_id = values.authority_id);
    values.block_id && (addressCheckParams.block_id = values.block_id);
    values.street_name && (addressCheckParams.street_name = values.street_name);
    values.house_type_id &&
      (addressCheckParams.house_type_id = values.house_type_id);
    values.residence_name &&
      (addressCheckParams.residence_name = values.residence_name);
    values.room_number && (addressCheckParams.room_number = values.room_number);
    let status;
    return fetch(/*developmentApiUrl*/ productionApiUrl + "/address/validate", {
      method: "POST",
      headers: getRequestHeader(),
      body: JSON.stringify(addressCheckParams)
    })
      .then(function(response) {
        status = response.status;
        return response.json();
      })
      .then(function(json) {
        if (isStatusOk(status)) {
          dispatch(registerUser(values));
        } else {
          dispatch(errorRegisterUserInfo(json.data.errors));
        }
      })
      .catch(function() {
        dispatch(catchErrorRegisterUserInfo(getFormattedMessage('communication.failed.')));
      });
  };
}

export function registerUser(values) {
  return function(dispatch) {
    let status;
    const params = new URLSearchParams();
    values.prefecture_id && params.set("prefecture_id", values.prefecture_id);
    values.authority_id && params.set("authority_id", values.authority_id);
    values.block_id && params.set("block_id", values.block_id);
    values.street_name && params.set("street_name", values.street_name);
    values.house_type_id && params.set("house_type_id", values.house_type_id);
    values.residence_name &&
      params.set("residence_name", values.residence_name);
    values.room_number && params.set("room_number", values.room_number);
    values.email && params.set("email", values.email);
    params.set("lang", getState().user.language);
    return fetch(/*developmentApiUrl*/ productionApiUrl + "/auth", {
      method: "POST",
      headers: getRequestHeader(),
      body: JSON.stringify({
        email: values.email,
        password: values.password,
        password_confirmation: values.password_confirmation,
        language: getLanguage(),
        // confirm_success_url:
        //   "http://localhost:3000/register/ready_to_use?" + params.toString()
        confirm_success_url:
          productionBuildUrl + "/register/ready_to_use?" + params.toString()
      })
    })
      .then(function(response) {
        status = response.status;
        return response.json();
      })
      .then(function(json) {
        if (isStatusOk(status)) {
          // 仮登録に成功した場合、emailをkeyにしてconfirm_byと住所情報とlanguageをstorageにセットします。
          let tmpAddress = {};
          values.prefecture_id &&
            (tmpAddress.prefecture_id = values.prefecture_id);
          values.authority_id &&
            (tmpAddress.authority_id = values.authority_id);
          values.block_id && (tmpAddress.block_id = values.block_id);
          values.street_name && (tmpAddress.street_name = values.street_name);
          values.house_type_id &&
            (tmpAddress.house_type_id = values.house_type_id);
          values.residence_name &&
            (tmpAddress.residence_name = values.residence_name);
          values.room_number && (tmpAddress.room_number = values.room_number);
          let tmpData = JSON.parse(Storage.get("tmpData"));
          if (tmpData) {
            tmpData[json.data.email] = {
              tmpAddress,
              tokenExpiryTime: json.data.confirm_by,
              lang: getState().user.language
            };
          } else {
            tmpData = {
              [json.data.email]: {
                tmpAddress,
                tokenExpiryTime: json.data.confirm_by,
                lang: getState().user.language
              }
            };
          }
          Storage.set("tmpData", JSON.stringify(tmpData));
          dispatch(successRegisterUserInfo());
        } else {
          dispatch(errorRegisterUserInfo(json.errors));
        }
      })
      .catch(function() {
        dispatch(catchErrorRegisterUserInfo(getFormattedMessage('communication.failed.')));
      });
  };
}

export const SET_IS_SUCCESS_USER_REGISTRATION =
  "SET_IS_SUCCESS_USER_REGISTRATION";

export function setIsSuccessUserRegistration(bool) {
  return {
    type: SET_IS_SUCCESS_USER_REGISTRATION,
    bool
  };
}

export const SET_IS_REGISTER_ERROR = "SET_IS_REGISTER_ERROR";

export function setIsRegisterError(bool) {
  return {
    type: SET_IS_REGISTER_ERROR,
    bool
  };
}

export const RESET_VALIDATION_MESSAGE_OF_REGISTER_USER_INFO =
  "RESET_VALIDATION_MESSAGE_OF_REGISTER_USER_INFO";

export function resetValidationMessageOfRegisterUserInfo() {
  return {
    type: RESET_VALIDATION_MESSAGE_OF_REGISTER_USER_INFO
  };
}

export const MODIFY_REGISTER_USER_FORM = "MODIFY_REGISTER_USER_FORM";

export function modifyRegisterUserForm(values) {
  return {
    type: MODIFY_REGISTER_USER_FORM,
    values
  };
}

export const CHANGE_AVAILABLE_REGISTER_PAGE = "CHANGE_AVAILABLE_REGISTER_PAGE";

export function changeAvailableRegisterPage(values) {
  return {
    type: CHANGE_AVAILABLE_REGISTER_PAGE,
    values
  };
}

export const RESET_USER_STATE = "RESET_USER_STATE";

export function resetUserState() {
  return {
    type: RESET_USER_STATE
  };
}

export const REQUEST_FETCH_PREFECTURES = "REQUEST_FETCH_PREFECTURES";

function requestFetchPrefectures() {
  return {
    type: REQUEST_FETCH_PREFECTURES
  };
}

export const SUCCESS_FETCH_PREFECTURES = "SUCCESS_FETCH_PREFECTURES";

function successFetchPrefectures(json) {
  return {
    type: SUCCESS_FETCH_PREFECTURES,
    prefectures: json.data.prefectures
  };
}

export const ERROR_FETCH_PREFECTURES = "ERROR_FETCH_PREFECTURES";

function errorFetchPrefectures(message) {
  return {
    type: ERROR_FETCH_PREFECTURES,
    errorMsg: message
  };
}

export function fetchPrefectures() {
  return function(dispatch) {
    dispatch(requestFetchPrefectures());

    let status;
    return fetch(/*developmentApiUrl*/ productionApiUrl + "/prefectures/index", {
      headers: getRequestHeader()
    })
      .then(function(response) {
        status = response.status;
        return response.json();
      })
      .then(function(json) {
        if (isStatusOk(status)) {
          dispatch(successFetchPrefectures(json));
        } else {
          dispatch(errorFetchPrefectures(getFormattedMessage('failed.to.get.information.')));
        }
      })
      .catch(function() {
        dispatch(errorFetchPrefectures(getFormattedMessage('communication.failed.')));
      });
  };
}

export const SET_IS_FETCH_PREFECTURES_ERROR = "SET_IS_FETCH_PREFECTURES_ERROR";

export function setIsFetchPrefecturesError(bool) {
  return {
    type: SET_IS_FETCH_PREFECTURES_ERROR,
    bool
  };
}

export const REQUEST_FETCH_AUTHORITIES = "REQUEST_FETCH_AUTHORITIES";

function requestFetchAuthorities() {
  return {
    type: REQUEST_FETCH_AUTHORITIES
  };
}

export const SUCCESS_FETCH_AUTHORITIES = "SUCCESS_FETCH_AUTHORITIES";

function successFetchAuthorities(json) {
  const authorities = [];
  json.data.authorities.forEach((e1) => {
    if(Object.prototype.toString.call(e1) === "[object Array]"){
      e1.forEach((e2) => {
        authorities.push(e2);
      });
    }else{
      authorities.push(e1);
    }
  });
  return {
    type: SUCCESS_FETCH_AUTHORITIES,
    authorities
  };
}

export const ERROR_FETCH_AUTHORITIES = "ERROR_FETCH_AUTHORITIES";

function errorFetchAuthorities(message) {
  return {
    type: ERROR_FETCH_AUTHORITIES,
    errorMsg: message
  };
}

export function fetchAuthorities(id) {
  return function(dispatch) {
    dispatch(requestFetchAuthorities());
    const params = new URLSearchParams();
    params.set("prefecture_id", id);
    let status;
    return fetch(
      /*developmentApiUrl*/ productionApiUrl +
        "/authorities/index?" +
        params.toString(),
      {
        headers: getRequestHeader()
      }
    )
      .then(function(response) {
        status = response.status;
        return response.json();
      })
      .then(function(json) {
        if (isStatusOk(status)) {
          dispatch(successFetchAuthorities(json));
        } else {
          dispatch(errorFetchAuthorities(getFormattedMessage('failed.to.get.information.')));
        }
      })
      .catch(function() {
        dispatch(errorFetchAuthorities(getFormattedMessage('communication.failed.')));
      });
  };
}

export const SET_IS_FETCH_AUTHORITIES_ERROR = "SET_IS_FETCH_AUTHORITIES_ERROR";

export function setIsFetchAuthoritiesError(bool) {
  return {
    type: SET_IS_FETCH_AUTHORITIES_ERROR,
    bool
  };
}

export const REQUEST_FETCH_BLOCKS = "REQUEST_FETCH_BLOCKS";

function requestFetchBlocks() {
  return {
    type: REQUEST_FETCH_BLOCKS
  };
}

export const SUCCESS_FETCH_BLOCKS = "SUCCESS_FETCH_BLOCKS";

function successFetchBlocks(json) {
  return {
    type: SUCCESS_FETCH_BLOCKS,
    blocks: json.data.blocks
  };
}

export const ERROR_EMPTY_BLOCKS = "ERROR_EMPTY_BLOCKS";

function errorEmptyBlocks() {
  return {
    type: ERROR_EMPTY_BLOCKS,
    errorMsg: getFormattedMessage('not.compatible.with.your.area.')
  };
}

export const ERROR_FETCH_BLOCKS = "ERROR_FETCH_BLOCKS";

function errorFetchBlocks(message) {
  return {
    type: ERROR_FETCH_BLOCKS,
    errorMsg: message
  };
}

export function fetchBlocks(authorityId, districtId) {
  return function(dispatch) {
    dispatch(requestFetchBlocks());
    const params = new URLSearchParams();
    params.set("authority_id", authorityId);
    districtId && params.set("district_id", districtId);
    let status;
    return fetch(
      /*developmentApiUrl*/ productionApiUrl + "/blocks/index?" + params.toString(),
      {
        headers: getRequestHeader()
      }
    )
      .then(function(response) {
        status = response.status;
        return response.json();
      })
      .then(function(json) {
        if (isStatusOk(status)) {
          json.data.blocks.length
            ? dispatch(successFetchBlocks(json))
            : dispatch(errorEmptyBlocks());
        } else {
          dispatch(errorFetchBlocks(getFormattedMessage('failed.to.get.information.')));
        }
      })
      .catch(function() {
        dispatch(errorFetchBlocks(getFormattedMessage('communication.failed.')));
      });
  };
}

export const SET_IS_EMPTY_BLOCKS_ERROR = "SET_IS_EMPTY_BLOCKS_ERROR";

export function setIsEmptyBlocksError(bool) {
  return {
    type: SET_IS_EMPTY_BLOCKS_ERROR,
    bool
  };
}

export const SET_IS_FETCH_BLOCKS_ERROR = "SET_IS_FETCH_BLOCKS_ERROR";

export function setIsFetchBlocksError(bool) {
  return {
    type: SET_IS_FETCH_BLOCKS_ERROR,
    bool
  };
}

export const SET_PREFECTURES = "SET_PREFECTURES";

export function setPrefectures(values) {
  return {
    type: SET_PREFECTURES,
    values
  };
}

export const SET_AUTHORITIES = "SET_AUTHORITIES";

export function setAuthorities(values) {
  return {
    type: SET_AUTHORITIES,
    values
  };
}

export const SET_BLOCKS = "SET_BLOCKS";

export function setBlocks(values) {
  return {
    type: SET_BLOCKS,
    values
  };
}

export const SET_THE_VALUE_OF_FORM_AUTHORITY_ID =
  "SET_THE_VALUE_OF_FORM_AUTHORITY_ID";

export function setTheValueOfFormAuthorityId(value) {
  return {
    type: SET_THE_VALUE_OF_FORM_AUTHORITY_ID,
    value
  };
}

export const SET_THE_VALUE_OF_FORM_BLOCK_ID = "SET_THE_VALUE_OF_FORM_BLOCK_ID";

export function setTheValueOfFormBlockId(value) {
  return {
    type: SET_THE_VALUE_OF_FORM_BLOCK_ID,
    value
  };
}

export const RESET_INIT_FETCH_PREFECTURES = "RESET_INIT_FETCH_PREFECTURES";

export function resetInitFetchPrefectures() {
  return {
    type: RESET_INIT_FETCH_PREFECTURES
  };
}

export const SET_IS_MAIL_TOKEN_TIME_ERROR = "SET_IS_MAIL_TOKEN_TIME_ERROR";

export function setIsMailTokenTimeError(bool) {
  return {
    type: SET_IS_MAIL_TOKEN_TIME_ERROR,
    bool
  };
}
