import React from "react";
import style from "./GlobalFooter.module.scss";
import reward from "../../../../assets/images/icon_reward.svg";
import news from "../../../../assets/images/icon_news.svg";
import howToDispose from "../../../../assets/images/icon_howToDispose.svg";
import home from "../../../../assets/images/icon_home.svg";
import dictionary from "../../../../assets/images/icon_dictionary.svg";
import oversized from "../../../../assets/images/icon_oversized.svg";
import rewardBlue from "../../../../assets/images/icon_reward_blue.svg";
import newsBlue from "../../../../assets/images/icon_news_blue.svg";
import howToDisposeBlue from "../../../../assets/images/icon_howToDispose_blue.svg";
import homeBlue from "../../../../assets/images/icon_home_blue.svg";
import dictionaryBlue from "../../../../assets/images/icon_dictionary_blue.svg";
import oversizedBlue from "../../../../assets/images/icon_oversized_blue.svg";
import { Footer, Img, Div } from "../../atoms/Html/Html";
import { Link } from "react-router-dom";

const GlobalFooter = props => {
  return (
    <Footer className={style.footer}>
      <Link
        to="/news"
        className={
          props.pathname === "/news"
            ? style.footer__link + " " + style.footer__selected
            : style.footer__link
        }
      >
        <Img
          src={props.pathname === "/news" ? newsBlue : news}
          className={style.footer__link__image}
        />
        {props.intl.formatMessage({ id: "news" })}
      </Link>
      <Link
        to="/how_to_dispose"
        className={
          props.pathname === "/how_to_dispose" ||
          props.pathname === "/how_to_dispose/details"
            ? style.footer__link + " " + style.footer__selected
            : style.footer__link
        }
      >
        <Img
          src={
            props.pathname === "/how_to_dispose" ||
            props.pathname === "/how_to_dispose/details"
              ? howToDisposeBlue
              : howToDispose
          }
          className={style.footer__link__image}
        />
        {props.intl.formatMessage({ id: "how.to.dispose" })}
      </Link>
      <Link
        to="/"
        className={
          props.pathname === "/"
            ? style.footer__link + " " + style.footer__selected
            : style.footer__link
        }
      >
        <Img
          src={props.pathname === "/" ? homeBlue : home}
          className={style.footer__link__image}
        />
        {props.intl.formatMessage({ id: "home" })}
      </Link>
      <Link
        to="/dictionary"
        className={
          props.pathname === "/dictionary" ||
          props.pathname === "/dictionary/detail"
            ? style.footer__link + " " + style.footer__selected
            : style.footer__link
        }
      >
        <Img
          src={
            props.pathname === "/dictionary" ||
            props.pathname === "/dictionary/detail"
              ? dictionaryBlue
              : dictionary
          }
          className={style.footer__link__image}
        />
        {props.intl.formatMessage({ id: "dictionary" })}
      </Link>
      <Link
        to="/oversized"
        className={
          props.pathname === "/oversized"
            ? style.footer__link + " " + style.footer__selected
            : style.footer__link
        }
      >
        <Img
          src={props.pathname === "/oversized" ? oversizedBlue : oversized}
          className={style.footer__link__image}
        />
        {props.intl.formatMessage({ id: "oversized" })}
      </Link>
    </Footer>
  );
};

export default GlobalFooter;
