import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  fetchEventList,
  changeWasChecked,
  changeCalendarDate,
  setCalendarMsg,
  setIsErrorFetchCalendarList,
  changeScheduleDate,
  setScheduleMsg,
  setIsErrorFetchScheduleList,
  setNoticeFlag,
  changeCalendarIndex,
  setCalendarEventList,
  setScheduleEventList
} from "../actions/home";

import Home from "../../web/pages/Home/Home";

const mapStateToProps = state => {
  return {
    home: state.home,
    user: state.user,
    common: state.common
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchEventList: (
      year,
      month,
      type = "both",
      number = 3,
      blockGroupId,
      isReset = false
    ) => {
      dispatch(
        fetchEventList(year, month, type, number, blockGroupId, isReset)
      );
    },
    changeWasChecked: bool => {
      dispatch(changeWasChecked(bool));
    },
    changeCalendarDate: (year, month) => {
      dispatch(changeCalendarDate(year, month));
    },
    setCalendarMsg: msg => {
      dispatch(setCalendarMsg(msg));
    },
    setIsErrorFetchCalendarList: bool => {
      dispatch(setIsErrorFetchCalendarList(bool));
    },
    changeScheduleDate: (year, month) => {
      dispatch(changeScheduleDate(year, month));
    },
    setScheduleMsg: msg => {
      dispatch(setScheduleMsg(msg));
    },
    setIsErrorFetchScheduleList: bool => {
      dispatch(setIsErrorFetchScheduleList(bool));
    },
    setNoticeFlag: bool => {
      dispatch(setNoticeFlag(bool));
    },
    changeCalendarIndex: index => {
      dispatch(changeCalendarIndex(index));
    },
    setCalendarEventList: values => {
      dispatch(setCalendarEventList(values));
    },
    setScheduleEventList: values => {
      dispatch(setScheduleEventList(values));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Home)
);
