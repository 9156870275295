import React from "react";
import style from "./ActionBoxGroup.module.scss";
import { Ul, Li, P, Option, Div, Label, A, Img } from "../../atoms/Html/Html";
import { Link } from "react-router-dom";
import { Field, Formik } from "formik";
import resetIcon from "../../../../assets/images/icon_reset.svg";
import { getLanguage } from "../../../constants/functions";

/*
 ** 引数
 * actions : array (object)
 *
 ** actionsの構造
 * type : str
 * body : object
 *
 ** bodyの構造
 * label : str
 * content : str
 * to : str
 * options : array (object)
 * invisible : bool
 * intl : intl
 * name : str
 * isConnecting : bool
 * disabled : bool
 * type : str
 *
 ** optionsの構造
 * value : str
 * label : str
 *
 */

const Error = props => {
  const list =
    props.formikError && props.touched
      ? [props.formikError]
      : props.stateErrors;
  return (
    <>
      {list.map(function(element, index) {
        return (
          <Div className={style.error} key={index}>
            {element}
          </Div>
        );
      })}
    </>
  );
};

const ActionBoxGroup = props => {
  const { bindSubmitForm, actions, handleChangeForm, stateErrors } = props;
  return (
    <Formik
      initialValues={props.form}
      enableReinitialize={true}
      validationSchema={props.schema}
      onSubmit={values => {
        props.submitData(values);
      }}
    >
      {({ handleChange, submitForm, values, errors, touched }) => {
        if (bindSubmitForm) {
          bindSubmitForm(submitForm);
        }
        function resetForm(e) {
          const tmpObject = {
            target: {
              name: e.currentTarget.previousElementSibling.name,
              value: ""
            }
          };
          handleChange(tmpObject);
          handleChangeForm &&
            handleChangeForm(e.currentTarget.previousElementSibling.name, "");
        }
        return (
          <Ul className={style.actionBoxGroup}>
            {actions.map((element, index) => {
              let body;
              switch (element.type) {
                case "link":
                  body = element.body.label ? (
                    <Link
                      to={element.body.to}
                      className={style.actionBoxGroup__contentWrap__linkBlock}
                    >
                      {element.body.label && (
                        <P
                          className={
                            style.actionBoxGroup__contentWrap__linkBlock__label
                          }
                        >
                          {element.body.label}
                        </P>
                      )}
                      {element.body.content || element.body.invisible ? (
                        <P
                          className={
                            style.actionBoxGroup__contentWrap__linkBlock__content +
                            " " +
                            (element.body.invisible
                              ? style.actionBoxGroup__contentWrap__linkBlock__contentInvisible
                              : "")
                          }
                        >
                          {element.body.invisible
                            ? element.body.intl.formatMessage({
                                id: "invisible"
                              })
                            : element.body.content}
                        </P>
                      ) : null}
                    </Link>
                  ) : null;
                  break;
                case "select":
                  body = element.body.label ? (
                    <Div
                      className={
                        style.actionBoxGroup__contentWrap__fieldBlock +
                        " " +
                        style.actionBoxGroup__contentWrap__fieldBlockSelect +
                        " " +
                        (element.body.isConnecting || element.body.disabled
                          ? style.disabled
                          : "")
                      }
                    >
                      <Label
                        for={element.body.name}
                        className={
                          style.actionBoxGroup__contentWrap__fieldBlock__label
                        }
                      >
                        {element.body.label}
                      </Label>
                      <Field
                        name={element.body.name}
                        id={element.body.name}
                        className={
                          style.actionBoxGroup__contentWrap__fieldBlock__select +
                          " " +
                          (element.body.isConnecting || element.body.disabled
                            ? style.disabled
                            : "")
                        }
                        component="select"
                        onChange={e => {
                          handleChange(e);
                          handleChangeForm &&
                            handleChangeForm(e.target.name, e.target.value);
                        }}
                        disabled={
                          (element.body.isConnecting ||
                            element.body.disabled) &&
                          "disabled"
                        }
                      >
                        {element.body.options.map((item, indexOfOptions) => (
                          <Option
                            value={item.value}
                            key={indexOfOptions}
                            className={
                              style.actionBoxGroup__contentWrap__fieldBlock__select__option
                            }
                          >
                            {item.label}
                          </Option>
                        ))}
                      </Field>
                    </Div>
                  ) : null;
                  break;
                case "input":
                  body = element.body.label ? (
                    <Div
                      className={style.actionBoxGroup__contentWrap__fieldBlock}
                    >
                      <Label
                        for={element.body.name}
                        className={
                          style.actionBoxGroup__contentWrap__fieldBlock__label
                        }
                      >
                        {element.body.label}
                      </Label>
                      <Field
                        name={element.body.name}
                        id={element.body.name}
                        className={
                          style.actionBoxGroup__contentWrap__fieldBlock__input
                        }
                        component="input"
                        onChange={e => {
                          if (e.target.name === "prefecture_id") {
                            const tmpAuthorityIdEvent = {
                              target: {
                                name: "authority_id",
                                value: ""
                              }
                            };
                            const tmpBlockIdEvent = {
                              target: {
                                name: "block_id",
                                value: ""
                              }
                            };
                            handleChange(tmpAuthorityIdEvent);
                            handleChange(tmpBlockIdEvent);
                          } else if (e.target.name === "authority_id") {
                            const tmpBlockIdEvent = {
                              target: {
                                name: "block_id",
                                value: ""
                              }
                            };
                            handleChange(tmpBlockIdEvent);
                          }
                          handleChange(e);
                          handleChangeForm &&
                            handleChangeForm(e.target.name, e.target.value);
                        }}
                        type={element.body.type}
                      />
                      {values[element.body.name] && (
                        <A onClick={resetForm} className={style.resetButton}>
                          <Img
                            src={resetIcon}
                            className={style.resetButton__icon}
                          />
                        </A>
                      )}
                    </Div>
                  ) : null;
                  break;
                default:
                  body = null;
              }
              return (
                body && (
                  <Li key={index} className={style.actionBoxGroup__contentWrap}>
                    {body}
                    <Error
                      formikError={errors[element.body.name]}
                      stateErrors={
                        element.body.name && stateErrors
                          ? stateErrors[element.body.name]
                          : []
                      }
                      touched={touched[element.body.name]}
                    />
                  </Li>
                )
              );
            })}
          </Ul>
        );
      }}
    </Formik>
  );
};

export default ActionBoxGroup;
