import robotIcon from "../assets/images/icon_robot_body_english.svg";

const en_US = {
  'log.in':'Log in',
  'already.use.gommit?':'Already use GOMMIT?',
  '1.select.your.region': '1. Select your area',
  'use.the.other.region':'Use the other area',
  'dont.you.have.an.account?':'Don’t you have an account?',
  'sign.up':'Sign up',
  'email':'Email',
  'password':'Password',
  'login':'Login',
  'forget.password':'Forget password',
  'getting.your.location.data':'Getting your location data…',
  '2.input.more.information': '2. Input more information',
  'retry': 'Retry',
  'input.manually': 'Input manually',
  'prefecture': 'Prefecture',
  'city': 'City',
  'region': 'Area',
  'address': 'Address',
  'type.of.residence': 'Type of Residence',
  'residence.name': 'Residence Name',
  'room.number': 'Room Number',
  'your.mail': 'Your Email',
  'login.password': 'Login Password',
  'required': 'required',
  'register': 'Register',
  'upcoming.schedule':'Upcoming Schedule',
  'cancel':'Cancel',
  'trash.name':'Trash Name',
  'type':'Type',
  'next.collection.date':'Next Collection Date',
  'validation.invalid.email':'Invalid email',
  'validation.required':'Required',
  'please.select':'Please select',
  '3.check.the.confirmation.email.and.complete.the.registration':'3. Check the confirmation email and complete the registration',
  'check.mailbox':'Check mailbox',
  'start':'Start',
  'ready.to.use.gommit!':'Ready to use GOMMIT!',
  'registering':'Registering…',
  'connecting':'Connecting…',
  'back.to.login':'Back to Login',
  'reset.password':'Reset Password',
  'ok':'OK',
  'home': 'Home',
  'gommit': 'GOMMIT',
  'ai.helps.you': 'AI Helps You',
  'reward': 'Reward',
  'how.to.dispose': 'How to dispose',
  'dictionary': 'Index',
  'oversized': 'Oversized',
  'burnable.trash': 'Burnable Trash',
  'unburnable.trash': 'Unburnable Trash',
  'harmful.trash': 'Harmful Trash',
  'oversized.trash': 'Oversized Garbage',
  'plastic': 'Plastic',
  'books.magazines': 'Books, Magazines',
  'cardboard': 'Cardboard',
  'milk.carton': 'Milk Carton',
  'paper.newspaper': 'Paper, Newspaper',
  'cans.bottles': 'Cans, Bottles',
  'metals': 'Metals',
  'clothes': 'Clothes',
  'recyclable.trash': 'Recyclable Trash',
  'get.again': 'Get again',
  'how.to.dispose.of.tab': 'How to dispose',
  'items': 'Items',
  'notes': 'Notes',
  'see.detail': 'See detail',
  'faq': 'FAQ',
  'information': 'Information',
  'oversized.consultation': 'Oversized Garbage',
  'you.cannot.dispose.oversized.trashes.on.general.ways' : 'Disposal of oversized garbage follows a different process',
  'type.your.text': 'Type your text',
  'enter.the.name.of.the.trash.you.want.to.check': 'Enter the name of the trash you want to check.',
  'settings':'Settings',
  'account':'Account',
  'notifications':'Notifications',
  'language':'Language',
  'sns.integration':'SNS Integration',
  'save': 'Save',
  'select.language':'Select language',
  'english': 'English',
  'japanese': 'Japanese',
  'reward.programs': 'Reward Programs',
  'total.points': 'Total Points',
  'start.reward':'Start',
  'special.reward':'Special\nReward',
  'password.confirmation':'Password Confirmation',
  'please.enter.a.password.with.at.least.6.characters':'Please enter a password with at least 6 characters.',
  'logout': 'Logout',
  'based.on.information.of.local.government.website': 'Based on the local government website',
  'notice':'Notice',
  'search':'Search',
  'definition.of.oversized.garbage': 'Definition of "Oversized Garbage"',
  'how.to.dispose.of.oversized.garbage':'How to dispose',
  'ticket.prices': 'Ticket issuance fee for the disposal of oversized garbage\n*Fee may vary in certain cases',
  'dispose.step.of.oversized.garbage': '1. Apply at the reception center\n' +
      '2.Purchase a bulky garbage disposal ticket at a designated dealer\n' +
      '3.Trash on designated days',
  'see.on.the.local.government.website': 'Check on your local government\'s HP site',
  'not.found.': 'Not Found.',
  'plain.address': 'Address',
  'invisible': 'Invisible',
  'return': 'Return',
  'upcoming': 'Upcoming.',
  'apartment': 'Apartment',
  'house': 'House',
  'failed.to.get.information.': 'Failed to get information.',
  'communication.failed.': 'Communication failed.{v1}',
  'failed.to.get.your.location.': 'Failed to get your location.',
  'not.compatible.with.your.area.': 'Not compatible with your area.',
  'the.password.was.updated.successfully.': 'The password was updated successfully.',
  'the.address.was.successfully.updated.': 'The address was successfully updated.',
  'failed.to.get.event.': 'Failed to get event.',
  'failed.to.get.list.': 'Failed to get list.',
  'incorrect.email.or.password.': 'Incorrect email or password.',
  'failed.to.get.faq.': 'Failed to get FAQ.',
  'mail.token.error': 'This e-mail address is temporarily registered.\nYou can’t register with this email address until {v1}',
  'january': 'january',
  'february': 'february',
  'march': 'march',
  'april': 'april',
  'may': 'may',
  'june': 'june',
  'july': 'july',
  'august': 'august',
  'september': 'september',
  'october': 'october',
  'november': 'november',
  'december': 'december',
  'date1': '{v3} {v1}',
  'sunday': 'sunday',
  'monday': 'monday',
  'tuesday': 'tuesday',
  'wednesday': 'wednesday',
  'thursday': 'thursday',
  'friday': 'friday',
  'saturday': 'saturday',
  'week1': '{v2}',
  'date2': '{v2} {v3}',
  'week2': '{v2}',
  'today': 'Today',
  'robot.icon': robotIcon,
  'click.here.for.how.to.put.out': 'Click here for how to put out {v1}',
  'next': 'next',
  'rough.address': '{v3}, {v2}, {v1}',
  'the.password.reset.email.has.been.sent.successfully.': 'An email has been sent to \'{v1}\' containing instructions for resetting your password.',
  'unable.to.find.user.with.email': 'Unable to find user with email \'{v1}\'',
  'please.log.in.again.': 'Please log in again.',
  'an.error.has.occurred.please.send.an.email.again.': 'An error has occurred.\nPlease send an email again.',
  'news': 'News',
};
export default en_US;