import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  changeSettingsLang,
  fetchPrefectures,
  fetchAuthorities,
  fetchBlocks,
  modifyFormValues,
  setAddressOptions,
  updateAddress,
  resetValidationMessages,
  setAddressMsg,
  setIsEmptyBlocksError,
  setIsSeccessUpdateAddress,
  setIsFetchPrefecturesError,
  setIsFetchAuthoritiesError,
  setIsFetchBlocksError,
  setIsErrorFetchUpdateAddress,
  updatePassword,
  setPasswordMsg,
  setIsSuccessUpdatePassword,
  setIsErrorFetchUpdatePassword,
  resetPasswordValidationMessages
} from "../actions/settings";

import { setLanguage } from "../actions/user";

import Settings from "../../web/pages/Settings/Settings";

const mapStateToProps = state => {
  return { settings: state.settings, common: state.common };
};

const mapDispatchToProps = dispatch => {
  return {
    changeSettingsLang: lang => {
      dispatch(changeSettingsLang(lang));
    },
    setLanguage: lang => {
      dispatch(setLanguage(lang));
    },
    fetchPrefectures: () => {
      dispatch(fetchPrefectures());
    },
    fetchAuthorities: id => {
      dispatch(fetchAuthorities(id));
    },
    fetchBlocks: (authorityId, districtId) => {
      dispatch(fetchBlocks(authorityId, districtId));
    },
    modifyFormValues: values => {
      dispatch(modifyFormValues(values));
    },
    setAddressOptions: values => {
      dispatch(setAddressOptions(values));
    },
    updateAddress: (values, intl) => {
      dispatch(updateAddress(values, intl));
    },
    resetValidationMessages: () => {
      dispatch(resetValidationMessages());
    },
    setAddressMsg: value => {
      dispatch(setAddressMsg(value));
    },
    setIsEmptyBlocksError: bool => {
      dispatch(setIsEmptyBlocksError(bool));
    },
    setIsSeccessUpdateAddress: bool => {
      dispatch(setIsSeccessUpdateAddress(bool));
    },
    setIsFetchPrefecturesError: bool => {
      dispatch(setIsFetchPrefecturesError(bool));
    },
    setIsFetchAuthoritiesError: bool => {
      dispatch(setIsFetchAuthoritiesError(bool));
    },
    setIsFetchBlocksError: bool => {
      dispatch(setIsFetchBlocksError(bool));
    },
    setIsErrorFetchUpdateAddress: bool => {
      dispatch(setIsErrorFetchUpdateAddress(bool));
    },
    updatePassword: values => {
      dispatch(updatePassword(values));
    },
    setPasswordMsg: value => {
      dispatch(setPasswordMsg(value));
    },
    setIsSuccessUpdatePassword: bool => {
      dispatch(setIsSuccessUpdatePassword(bool));
    },
    setIsErrorFetchUpdatePassword: bool => {
      dispatch(setIsErrorFetchUpdatePassword(bool));
    },
    resetPasswordValidationMessages: () => {
      dispatch(resetPasswordValidationMessages());
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Settings)
);
