import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  resetPassword,
  setFlags,
  setValues,
  setErrors,
} from "../actions/auth";

import ResetPassword from "../../web/pages/ResetPassword/ResetPassword";

const mapStateToProps = state => {
  return { auth: state.auth };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: (values) => {
      dispatch(resetPassword(values));
    },
    setFlags: (object) => {
      dispatch(setFlags(object));
    },
    setValues: (object) => {
      dispatch(setValues(object));
    },
    setErrors: (object) => {
      dispatch(setErrors(object));
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ResetPassword)
);