import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {} from "../actions/reward";

import Reward from "../../web/pages/Reward/Reward";

const mapStateToProps = state => {
  return {
    reward: state.reward,
    common: state.common
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Reward)
);
