import {
  SUCCESS_FETCH_USER_STATUS,
  SUCCESS_FETCH_DISPOSAL_LIST
} from "../actions/auth";

import { UPDATE_STATUS } from "../actions/settings";

const common = (
  state = {
    status: {
      email: null,
      block_group_id: null,
      prefecture: {
        id: null,
        name: null
      },
      authority: {
        id: null,
        name: null,
        district_id: null
      },
      block: {
        id: null,
        name: null
      },
      zip_code: null,
      house_type: null,
      residence_name: null,
      street_name: null,
      room_number: null
    },
    disposalList: {}
  },
  action
) => {
  switch (action.type) {
    case SUCCESS_FETCH_USER_STATUS:
      return Object.assign({}, state, {
        status: action.data
      });
    case UPDATE_STATUS:
      return Object.assign({}, state, {
        status: Object.assign({}, state.status, action.values)
      });
    case SUCCESS_FETCH_DISPOSAL_LIST:
      return Object.assign({}, state, {
        disposalList: action.data
      });
    default:
      return state;
  }
};

export default common;
