import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  fetchAiAnswer,
  setResults,
  setUserMessage
} from "../actions/aiHelpsYou";

import AiHelpsYou from "../../web/pages/AiHelpsYou/AiHelpsYou";

const mapStateToProps = state => {
  return { aiHelpsYou: state.aiHelpsYou, common: state.common };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAiAnswer: (value, authority_id) => {
      return fetchAiAnswer(value, authority_id);
    },
    setResults: values => {
      dispatch(setResults(values));
    },
    setUserMessage: value => {
      dispatch(setUserMessage(value));
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AiHelpsYou)
);
