import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {} from "../actions/news";

import News from "../../web/pages/News/News";

const mapStateToProps = state => {
  return {
    news: state.news,
    common: state.common
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(News)
);
