import { CHANGE_HOW_TO_DISPOSE_TAB } from "../actions/howToDispose";

const howToDispose = (
  state = {
    tabIndex: 0
  },
  action
) => {
  switch (action.type) {
    case CHANGE_HOW_TO_DISPOSE_TAB:
      return Object.assign({}, state, {
        tabIndex: action.index
      });
    default:
      return state;
  }
};

export default howToDispose;
