import React from "react";
import style from "./SearchForm.module.scss";
import { Div, A } from "../../atoms/Html/Html";
import { withFormik, Form, Field } from "formik";

const SearchForm = props => {
  const { handleChange } = props;
  function handleResetSearchForm() {
    props.handleChangeSearchForm("");
  }
  return (
    <Form className={style.form}>
      <Field
        type="text"
        name="search"
        onChange={e => {
          handleChange(e);
          props.handleChangeSearchForm(e.target.value);
        }}
        className={style.form__input}
        placeholder={props.intl.formatMessage({ id: "search" })}
      />
      <A className={style.form__cancel} onClick={handleResetSearchForm}>
        {props.intl.formatMessage({ id: "cancel" })}
      </A>
    </Form>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues({ searchText }) {
    return {
      search: searchText || ""
    };
  }
})(SearchForm);
