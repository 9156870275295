import React from "react";
import style from "./Question.module.scss";
import { Div, Img } from "../../atoms/Html/Html";
import questionIcon from "../../../../assets/images/icon_question_blue.svg";
import Heading from "../../atoms/Heading/Heading";

const Question = props => (
  <Div className={style.questionBlock}>
    <Img className={style.questionBlock__icon} src={questionIcon} />
    <Heading text={props.text} />
  </Div>
);

export default Question;
