import React from "react";
import styles from "./Login.module.scss";
import LoginForm from "../../components/register/LoginForm/LoginForm";
import Logo from "../../components/molecules/Logo/Logo";
import TextLinkBlock from "../../components/molecules/TextLinkBlock/TextLinkBlock";
import { Div } from "../../components/atoms/Html/Html";
import Modal from "../../components/organisms/Modal/Modal";
import { Redirect } from "react-router-dom";
import { Storage } from "../../constants/functions";

class Login extends React.Component {
  constructor(props) {
    super(props);
  }

  submitData(values) {
    if (
      JSON.parse(Storage.get("tmpData")) &&
      JSON.parse(Storage.get("tmpData"))[values.email]
    ) {
      this.props.tryLoginAndRegisterAddress(values, this.props.history);
    } else {
      this.props.tryLogin(values);
    }
  }

  handleClickOkOfLoginError() {
    this.props.setIsLoginError(false);
    this.props.setErrorMsgOfAuthState("");
  }

  handleClickToForgetPassword() {
    this.props.history.push("/forget_password");
  }

  handleClickToSelectRegion() {
    this.props.history.push("/register/select_region");
  }

  render() {
    let modalType, modalText, modalChoiceList;
    if (this.props.auth.isLoginFetching) {
      modalType = "loading";
      modalText = this.props.intl.formatMessage({
        id: "connecting"
      });
      modalChoiceList = null;
    } else if (this.props.auth.errors.isLoginError) {
      modalType = "choice";
      modalText = this.props.auth.errors.msg;
      modalChoiceList = [
        {
          label: this.props.intl.formatMessage({ id: "ok" }),
          action: this.handleClickOkOfLoginError.bind(this)
        }
      ];
    }

    return this.props.auth.isLoggedIn ? (
      <Redirect to={"/"} />
    ) : (
      <Div className={styles.loginBlock}>
        <Div />
        <Div>
          <Div className={styles.loginBlockLogo}>
            <Logo type="blue" />
          </Div>
          <LoginForm
            submitData={this.submitData.bind(this)}
            intl={this.props.intl}
          />
          <TextLinkBlock
            linkText={this.props.intl.formatMessage({ id: "forget.password" })}
            handleClick={this.handleClickToForgetPassword.bind(this)}
          />
        </Div>
        <TextLinkBlock
          text={this.props.intl.formatMessage({
            id: "dont.you.have.an.account?"
          })}
          linkText={this.props.intl.formatMessage({ id: "sign.up" })}
          handleClick={this.handleClickToSelectRegion.bind(this)}
        />
        {modalType && (
          <Modal
            type={modalType}
            text={modalText}
            choiceList={modalChoiceList}
          />
        )}
      </Div>
    );
  }
}

export default Login;
