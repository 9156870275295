import React from "react";
import style from "./Score.module.scss";
import { Div, Img, P, Span } from "../../atoms/Html/Html";
import iconStar from "../../../../assets/images/icon_star_yellow.svg";

const Score = props => {
  return (
    <Div className={style.score + " " + props.scoreStyle}>
      <Img src={iconStar} className={style.score__icon} />
      <P className={style.score__number}>
        {props.current}
        <Span className={style.score__number__mini}>/{props.total}</Span>
      </P>
    </Div>
  );
};

export default Score;
