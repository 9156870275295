import React from "react";
import style from "./Dictionary.module.scss";
import GlobalHeader from "../../components/organisms/GlobalHeader/GlobalHeader";
import GlobalFooter from "../../components/organisms/GlobalFooter/GlobalFooter";
import { Div, Main, Li, Ul, A, Img } from "../../components/atoms/Html/Html";
import { Switch, Route } from "react-router-dom";
import SearchForm from "../../components/molecules/SearchForm/SearchForm";
import ScrollView from "../../components/atoms/ScrollView/ScrollView";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from "react-scroll";
import LoadingWrapper from "../../components/organisms/LoadingWrapper/LoadingWrapper";
import ContentBoxGroup from "../../components/organisms/ContentBoxGroup/ContentBoxGroup";
import { getTrashTypeIcon } from "../../constants/functions";
import robot from "../../../assets/images/icon_robot_face.svg";

const Dictionary = props => {
  return (
    <Switch>
      <Route
        exact
        path="/dictionary"
        render={() => (
          <DictionaryList
            {...props.dictionary.list}
            {...props.common.status}
            history={props.history}
            fetchDictionaryList={props.fetchDictionaryList}
            changeSearchText={props.changeSearchText}
            changeScrollTop={props.changeScrollTop}
            intl={props.intl}
            msg={props.dictionary.msg}
            setDictionaryMsg={props.setDictionaryMsg}
            setIsFetchListError={props.setIsFetchListError}
            initDictionaryFlag={props.dictionary.initDictionaryFlag}
            setInitDictionaryFlag={props.setInitDictionaryFlag}
          />
        )}
      />
      <Route
        exact
        path="/dictionary/detail"
        render={() => (
          <DictionaryDetail
            {...props.dictionary.detail}
            history={props.history}
            intl={props.intl}
            msg={props.dictionary.msg}
            fetchDictionaryDetail={props.fetchDictionaryDetail}
            setDictionaryMsg={props.setDictionaryMsg}
            setIsFetchDetailError={props.setIsFetchDetailError}
            {...props.common.status}
            {...props.common.disposalList}
          />
        )}
      />
    </Switch>
  );
};

class DictionaryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { timer: null };
  }

  componentWillMount() {
    if (this.props.initDictionaryFlag) {
      this.props.fetchDictionaryList();
      this.props.changeSearchText("");
      this.props.changeScrollTop(0);
      this.props.setInitDictionaryFlag(false);
    }
  }

  handleClickBell() {
    this.props.history.push("/information");
  }

  handleClickFaq() {
    this.props.history.push("/faq");
  }

  handleClickSettings() {
    this.props.history.push("/settings");
  }

  handleChangeSearchForm(value) {
    this.props.changeSearchText(value);
    scroll.scrollToTop({
      containerId: "overFlowScrollArea",
      duration: 0
    });
  }

  handleScrollList(e) {
    const changeScrollTop = this.props.changeScrollTop;
    const target = e.currentTarget;
    clearTimeout(this.state.timer);
    this.state.timer = setTimeout(function() {
      changeScrollTop(target.scrollTop);
    }, 300);
  }

  handleRetry() {
    this.props.setDictionaryMsg("");
    this.props.setIsFetchListError(false);
    this.props.fetchDictionaryList();
  }

  handleClickTrash(e) {
    this.props.history.push({
      pathname: "/dictionary/detail",
      state: { id: e.currentTarget.dataset.id }
    });
  }

  render() {
    const leftContent = [
      { label: "bell", action: this.handleClickBell.bind(this) }
    ];
    const rightContent = [
      { label: "faq", action: this.handleClickFaq.bind(this) },
      { label: "settings", action: this.handleClickSettings.bind(this) }
    ];
    return (
      <>
        <GlobalHeader
          title={this.props.intl.formatMessage({ id: "dictionary" })}
          subtitle={this.props.authority.name + this.props.block.name}
          leftContent={leftContent}
          rightContent={rightContent}
        />
        <Main className={style.main}>
          <LoadingWrapper
            isConnecting={this.props.isFetchingList}
            loadingStyle={style.loading}
            errorText={this.props.msg}
            onRetry={this.handleRetry.bind(this)}
            retryText={this.props.intl.formatMessage({ id: "get.again" })}
            errorStyle={style.error}
          >
            {!this.props.isFetchingList && this.props.msg === "" && (
              <>
                <SearchForm
                  intl={this.props.intl}
                  handleChangeSearchForm={this.handleChangeSearchForm.bind(
                    this
                  )}
                  searchText={this.props.searchText}
                />
                <Div className={style.main__lists}>
                  <ItemListComponent
                    list={this.props.filteredList}
                    handleScroll={this.handleScrollList.bind(this)}
                    handleClickTrash={this.handleClickTrash.bind(this)}
                    scrollTop={this.props.scrollTop}
                    intl={this.props.intl}
                  />
                  <SearchListComponent
                    list={this.props.filteredList}
                    intl={this.props.intl}
                  />
                </Div>
              </>
            )}
          </LoadingWrapper>
        </Main>
        <GlobalFooter
          intl={this.props.intl}
          pathname={this.props.history.location.pathname}
        />
      </>
    );
  }
}

class ItemListComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    scroll.scrollTo(this.props.scrollTop, {
      containerId: "overFlowScrollArea",
      duration: 0
    });
  }

  render() {
    const handleClick = this.props.handleClickTrash;
    return (
      <ScrollView
        contentContainerStyle={style.itemList}
        id="overFlowScrollArea"
        onScroll={this.props.handleScroll}
      >
        {!this.props.list || !this.props.list.length ? (
          <Div className={style.itemList__warningBlock}>
            {this.props.intl.formatMessage({ id: "not.found." })}
          </Div>
        ) : (
          <Ul className={style.itemList__list}>
            {this.props.list.map(function(element1) {
              return (
                <Li name={element1.index}>
                  <Ul className={style.itemList__list__indexBlock}>
                    {element1.items.map(function(element2) {
                      return (
                        <Li
                          className={
                            style.itemList__list__indexBlock__itemBlock
                          }
                        >
                          <A
                            dataId={element2.dictionary_id}
                            className={
                              style.itemList__list__indexBlock__itemBlock__link
                            }
                            onClick={handleClick}
                          >
                            {element2.name}
                          </A>
                        </Li>
                      );
                    })}
                  </Ul>
                </Li>
              );
            })}
          </Ul>
        )}
      </ScrollView>
    );
  }
}

const SearchListComponent = props => {
  return (
    <Ul className={style.searchList}>
      {props.list && props.list.map(function(value) {
        return (
          <Li className={style.searchList__item}>
            <Link
              activeClass={
                style.itemList__list__indexBlock__itemBlock__link__active
              }
              to={value.index}
              className={style.searchList__item__link}
              spy={true}
              smooth={true}
              duration={500}
              containerId="overFlowScrollArea"
            >
              {value.index
                ? value.index.toUpperCase()
                : null}
            </Link>
          </Li>
        );
      })}
    </Ul>
  );
};

class DictionaryDetail extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    if (this.props.history.location.state) {
      if (
        this.props.history.location.state.from !== "/how_to_dispose/details"
      ) {
        if (!this.props.history.location.state.id) {
          this.props.history.push("/dictionary");
        } else {
          this.props.fetchDictionaryDetail(
            this.props.history.location.state.id,
            this.props.block_group_id
          );
        }
      }
    } else {
      this.props.history.push("/dictionary");
    }
  }

  handleClickBack() {
    this.props.history.push("/dictionary");
  }

  handleRetry() {
    this.props.setDictionaryMsg("");
    this.props.setIsFetchDetailError(false);
    this.props.fetchDictionaryDetail(
      this.props.history.location.state.id,
      this.props.block_group_id
    );
  }

  render() {
    const leftContent = [
      { label: "arrow", action: this.handleClickBack.bind(this) }
    ];
    let list = [];
    if (!this.props.isFetchingDetail && this.props.msg === "") {
      const { intl, detail, details, history } = this.props;
      list.push({
        heading: intl.formatMessage({
          id: "trash.name"
        }),
        type: "item",
        body: {
          text: detail.name
        }
      });
      this.props.detail.trashes.forEach(function(element) {
        const found = details.find(function(values) {
          return values.type === element.trashType;
        });
        list.push({
          heading: intl.formatMessage({ id: "type" }),
          type: "trashCategory",
          body: {
            text: element.trashTypeName,
            src: getTrashTypeIcon(element.trashType),
            onClick: found
              ? () => {
                  history.push({
                    pathname: "/how_to_dispose/details",
                    state: {
                      trashType: element.trashType,
                      from: "/dictionary/detail"
                    }
                  });
                }
              : null
          }
        });
        if(element.next && element.next !== "なし"){
          list.push({
            heading: intl.formatMessage({
              id: "next.collection.date"
            }),
            type: "date",
            intl: intl,
            body: {
              date: new Date(element.next.replace(/-/g, "/"))
            }
          });
        }
      });
    }
    return (
      <>
        <GlobalHeader
          title={this.props.intl.formatMessage({ id: "dictionary" })}
          subtitle={this.props.authority.name + this.props.block.name}
          leftContent={leftContent}
        />
        <Main className={style.main}>
          <LoadingWrapper
            isConnecting={this.props.isFetchingDetail}
            loadingStyle={style.loading}
            errorText={this.props.msg}
            onRetry={this.handleRetry.bind(this)}
            retryText={this.props.intl.formatMessage({ id: "get.again" })}
            errorStyle={style.error}
          >
            {!this.props.isFetchingDetail && this.props.msg === "" && (
              <ScrollView contentContainerStyle={style.scrollView}>
                <ContentBoxGroup contents={list} />
                {this.props.detail.description ? (
                  <Div className={style.main__descBlock}>
                    <Img className={style.main__descBlock__icon} src={robot} />
                    <Div className={style.main__descBlock__desc}>
                      {this.props.detail.description}
                    </Div>
                  </Div>
                ) : (
                  ""
                )}
              </ScrollView>
            )}
          </LoadingWrapper>
        </Main>
        <GlobalFooter
          intl={this.props.intl}
          pathname={this.props.history.location.pathname}
        />
      </>
    );
  }
}

export default Dictionary;
