import robotIcon from "../assets/images/icon_robot_body_english.svg";

const tl_PH = {
  'log.in': 'Mag log in',
  'already.use.gommit?': 'Nakagamit na GOMMIT?',
  '1.select.your.region': '1. Piliin ang iyong lugar',
  'use.the.other.region': 'Gamitin ang mga iba pang mga lugar',
  'dont.you.have.an.account?': 'Wala ba kayong isang account?',
  'sign.up': 'Mag-sign up',
  'email': 'email',
  'password': 'password',
  'login': 'Mag log in',
  'forget.password': 'kalimutan password',
  'getting.your.location.data': 'Pagkuha ng iyong data ng lokasyon ...',
  '2.input.more.information': '2. Input karagdagang impormasyon',
  'retry': 'Retry',
  'input.manually': 'input nang manu-mano',
  'prefecture': 'prefektyur',
  'city': 'lungsod',
  'region': 'lugar',
  'address': 'address',
  'type.of.residence': 'I-type ng Paninirahan',
  'residence.name': 'Residence Pangalan',
  'room.number': 'room Number',
  'your.mail': 'Ang email mo',
  'login.password': 'Login Password',
  'required': 'kailangan',
  'register': 'Magrehistro',
  'upcoming.schedule': 'Mga nalalapit na Iskedyul',
  'cancel': 'kanselahin',
  'trash.name': 'Basura Pangalan',
  'type': 'uri',
  'next.collection.date': 'Petsa Next Collection',
  'validation.invalid.email': 'Hindi wastong email',
  'validation.required': 'Kailangan',
  'please.select': 'Pakipili',
  '3.check.the.confirmation.email.and.complete.the.registration': '3. Check confirmation emails and complete registration',
  'check.mailbox': 'Suriin mailbox',
  'start': 'simula',
  'ready.to.use.gommit!': 'Handa nang gamitin GOMMIT!',
  'registering': 'Nirerehistro ...',
  'connecting': 'Kumukonekta ...',
  'back.to.login': 'Bumalik sa pag-login',
  'reset.password': 'I-reset ang Password',
  'ok': 'OK',
  'home': 'bahay',
  'gommit': 'GOMMIT',
  'ai.helps.you': 'Ai Tumutulong ka',
  'reward': 'gantimpala',
  'how.to.dispose': 'Paano upang magtapon',
  'dictionary': 'talatuntunan',
  'oversized': 'oversized',
  'burnable.trash': 'nasusunog na Basura',
  'unburnable.trash': 'Unburnable Trash',
  'harmful.trash': 'mapanganib na basura',
  'oversized.trash': 'oversized Basura',
  'plastic': 'plastik',
  'books.magazines': 'Books, Magazines',
  'cardboard': 'karton',
  'milk.carton': 'Milk carton',
  'paper.newspaper': 'Papel, Pahayagan',
  'cans.bottles': 'Lata, Bote',
  'metals': 'riles',
  'clothes': 'Mga damit',
  'recyclable.trash': 'recyclable Trash',
  'get.again': 'Kunin muli',
  'how.to.dispose.of.tab': 'Paano upang magtapon',
  'items': 'Item',
  'notes': 'Mga tala',
  'see.detail': 'See detalye',
  'faq': 'Mga Madalas Itanong',
  'information': 'impormasyon',
  'oversized.consultation': 'oversized Basura',
  'you.cannot.dispose.oversized.trashes.on.general.ways': 'Pagtatapon ng mga oversized basura sumusunod sa isang iba\'t ibang mga proseso',
  'type.your.text': 'I-type ang iyong teksto',
  'enter.the.name.of.the.trash.you.want.to.check': 'Ipasok ang pangalan ng basurahan na nais mong suriin.',
  'settings': 'Mga Setting',
  'account': 'account',
  'notifications': 'Mga Notification',
  'language': 'wika',
  'sns.integration': 'SNS Pagsasama',
  'save': 'I-save ang',
  'select.language': 'Piliin ang wika',
  'english': 'Ingles',
  'japanese': 'Hapon',
  'reward.programs': 'gantimpala Programa',
  'total.points': 'Ang kabuuang puntos',
  'start.reward': 'simula',
  'special.reward': 'Espesyal\nReward',
  'password.confirmation': 'password Confirmation',
  'please.enter.a.password.with.at.least.6.characters': 'Mangyaring magpasok ng isang password na may hindi bababa sa 6 na mga character.',
  'logout': 'Mag-logout',
  'based.on.information.of.local.government.website': 'Batay sa mga lokal na website ng pamahalaan',
  'notice': 'abiso',
  'search': 'Hanapan',
  'definition.of.oversized.garbage': 'Kahulugan ng "Oversized Basura"',
  'how.to.dispose.of.oversized.garbage': 'Paano upang magtapon',
  'ticket.prices': 'Iisyu ng tiket fee para sa pagtatapon ng malalaking basura\n* Fee maaaring mag-iba sa ilang mga kaso',
  'dispose.step.of.oversized.garbage': '1. Mag-apply sa reception center\n' +
    '2.Purchase isang mahirap hawakan ticket pagtatapon ng basura sa isang itinalagang dealer\n' +
    '3.Trash sa itinalagang araw',
  'see.on.the.local.government.website': 'Suriin ang site ng HP ng iyong lokal na pamahalaan',
  'not.found.': 'Hindi mahanap.',
  'plain.address': 'address',
  'invisible': 'hindi makita',
  'return': 'pagbabalik',
  'upcoming': 'Mga nalalapit na.',
  'apartment': 'Apartment',
  'house': 'House',
  'failed.to.get.information.': 'Failed to get information.',
  'communication.failed.': 'Communication failed.{v1}',
  'failed.to.get.your.location.': 'Failed to get your location.',
  'not.compatible.with.your.area.': 'Not compatible with your area.',
  'the.password.was.updated.successfully.': 'The password was updated successfully.',
  'the.address.was.successfully.updated.': 'The address was successfully updated.',
  'failed.to.get.event.': 'Failed to get event.',
  'failed.to.get.list.': 'Failed to get list.',
  'incorrect.email.or.password.': 'Incorrect email or password.',
  'failed.to.get.faq.': 'Failed to get FAQ.',
  'mail.token.error': 'This e-mail address is temporarily registered.\nYou can’t register with this email address until {v1}',
  'january': 'january',
  'february': 'february',
  'march': 'march',
  'april': 'april',
  'may': 'may',
  'june': 'june',
  'july': 'july',
  'august': 'august',
  'september': 'september',
  'october': 'october',
  'november': 'november',
  'december': 'december',
  'date1': '{v3} {v1}',
  'sunday': 'sunday',
  'monday': 'monday',
  'tuesday': 'tuesday',
  'wednesday': 'wednesday',
  'thursday': 'thursday',
  'friday': 'friday',
  'saturday': 'saturday',
  'week1': '{v2}',
  'date2': '{v2} {v3}',
  'week2': '{v2}',
  'today': 'Today',
  'robot.icon': robotIcon,
  'click.here.for.how.to.put.out': 'Click here for how to put out {v1}',
  'next': 'next',
  'rough.address': '{v3}, {v2}, {v1}',
  'the.password.reset.email.has.been.sent.successfully.': 'An email has been sent to \'{v1}\' containing instructions for resetting your password.',
  'unable.to.find.user.with.email': 'Unable to find user with email \'{v1}\'',
  'please.log.in.again.': 'Please log in again.',
  'an.error.has.occurred.please.send.an.email.again.': 'An error has occurred.\nPlease send an email again.',
  'news': 'News',
};
export default tl_PH;