import robotIcon from "../assets/images/icon_robot_body_english.svg";

const fr_FR = {
  'log.in': 'S\'identifier',
  'already.use.gommit?': 'Vous utilisez déjà GOMMIT?',
  '1.select.your.region': '1. Sélectionnez votre région',
  'use.the.other.region': 'Utilisez l\'autre zone',
  'dont.you.have.an.account?': 'Ne vous avez un compte?',
  'sign.up': 'S\'inscrire',
  'email': 'Email',
  'password': 'Mot de passe',
  'login': 'S\'identifier',
  'forget.password': 'Mot de passe oublié',
  'getting.your.location.data': 'Obtenir vos données de localisation ...',
  '2.input.more.information': '2. Informations plus d\'entrée',
  'retry': 'Retenter',
  'input.manually': 'manuellement entrée',
  'prefecture': 'Préfecture',
  'city': 'Ville',
  'region': 'Zone',
  'address': 'Adresse',
  'type.of.residence': 'Type de résidence',
  'residence.name': 'Nom de la résidence',
  'room.number': 'Numéro de chambre',
  'your.mail': 'votre e-mail',
  'login.password': 'Mot de passe',
  'required': 'obligatoire',
  'register': 'S\'inscrire',
  'upcoming.schedule': 'Calendrier à venir',
  'cancel': 'Annuler',
  'trash.name': 'Nom Corbeille',
  'type': 'Type',
  'next.collection.date': 'Date de la prochaine collection',
  'validation.invalid.email': 'Email invalide',
  'validation.required': 'Obligatoire',
  'please.select': 'Veuillez sélectionner',
  '3.check.the.confirmation.email.and.complete.the.registration': '3. Vérifiez l\'e-mail de confirmation et compléter l\'enregistrement',
  'check.mailbox': 'Vérifier boîte aux lettres',
  'start': 'Début',
  'ready.to.use.gommit!': 'Prêt à l\'emploi GOMMIT!',
  'registering': 'Enregistrement…',
  'connecting': 'De liaison…',
  'back.to.login': 'Retour connexion',
  'reset.password': 'réinitialiser le mot de passe',
  'ok': 'D\'accord',
  'home': 'Accueil',
  'gommit': 'GOMMIT',
  'ai.helps.you': 'AI vous aide',
  'reward': 'Récompense',
  'how.to.dispose': 'Comment éliminer',
  'dictionary': 'Indice',
  'oversized': 'Énorme',
  'burnable.trash': 'Trash burnable',
  'unburnable.trash': 'Trash Unburnable',
  'harmful.trash': 'Nocif Corbeille',
  'oversized.trash': 'Garbage surdimensionnée',
  'plastic': 'Plastique',
  'books.magazines': 'Livres, magazines',
  'cardboard': 'Papier carton',
  'milk.carton': 'Carton de lait',
  'paper.newspaper': 'Papier, journal',
  'cans.bottles': 'Les boîtes, bouteilles',
  'metals': 'Les métaux',
  'clothes': 'vêtements',
  'recyclable.trash': 'Corbeille recyclable',
  'get.again': 'Obtenez à nouveau',
  'how.to.dispose.of.tab': 'Comment éliminer',
  'items': 'Articles',
  'notes': 'Remarques',
  'see.detail': 'Voir le détail',
  'faq': 'FAQ',
  'information': 'Information',
  'oversized.consultation': 'Garbage surdimensionnée',
  'you.cannot.dispose.oversized.trashes.on.general.ways': 'Élimination des déchets surdimensionnée fait suite à un processus différent',
  'type.your.text': 'Tapez votre texte',
  'enter.the.name.of.the.trash.you.want.to.check': 'Entrez le nom de la poubelle que vous voulez vérifier.',
  'settings': 'Réglages',
  'account': 'Compte',
  'notifications': 'notifications',
  'language': 'Langue',
  'sns.integration': 'intégration SNS',
  'save': 'sauvegarder',
  'select.language': 'Choisir la langue',
  'english': 'Anglais',
  'japanese': 'Japonais',
  'reward.programs': 'Les programmes de récompense',
  'total.points': 'Points totaux',
  'start.reward': 'Début',
  'special.reward': 'Récompense\nSpéciale',
  'password.confirmation': 'Confirmation mot de passe',
  'please.enter.a.password.with.at.least.6.characters': 'S\'il vous plaît entrer un mot de passe avec au moins 6 caractères.',
  'logout': 'Se déconnecter',
  'based.on.information.of.local.government.website': 'Basé sur le site Web du gouvernement local',
  'notice': 'Remarquer',
  'search': 'Chercher',
  'definition.of.oversized.garbage': 'Définition de « Garbage surdimensionnée »',
  'how.to.dispose.of.oversized.garbage': 'Comment éliminer',
  'ticket.prices': 'frais d\'émission de billets pour l\'élimination des ordures surdimensionné\n* Les frais peuvent varier dans certains cas',
  'dispose.step.of.oversized.garbage': '1. Appliquer au centre de réception\n' +
    '2.Achetez un ticket d\'élimination des déchets volumineux chez un revendeur agréé\n' +
    '3.Trash les jours désignés',
  'see.on.the.local.government.website': 'Vérifiez sur le site HP de votre gouvernement local',
  'not.found.': 'Pas trouvé',
  'plain.address': 'Adresse',
  'invisible': 'Invisible',
  'return': 'Revenir',
  'upcoming': 'A venir.',
  'apartment': 'Apartment',
  'house': 'House',
  'failed.to.get.information.': 'Failed to get information.',
  'communication.failed.': 'Communication failed.{v1}',
  'failed.to.get.your.location.': 'Failed to get your location.',
  'not.compatible.with.your.area.': 'Not compatible with your area.',
  'the.password.was.updated.successfully.': 'The password was updated successfully.',
  'the.address.was.successfully.updated.': 'The address was successfully updated.',
  'failed.to.get.event.': 'Failed to get event.',
  'failed.to.get.list.': 'Failed to get list.',
  'incorrect.email.or.password.': 'Incorrect email or password.',
  'failed.to.get.faq.': 'Failed to get FAQ.',
  'mail.token.error': 'This e-mail address is temporarily registered.\nYou can’t register with this email address until {v1}',
  'january': 'january',
  'february': 'february',
  'march': 'march',
  'april': 'april',
  'may': 'may',
  'june': 'june',
  'july': 'july',
  'august': 'august',
  'september': 'september',
  'october': 'october',
  'november': 'november',
  'december': 'december',
  'date1': '{v3} {v1}',
  'sunday': 'sunday',
  'monday': 'monday',
  'tuesday': 'tuesday',
  'wednesday': 'wednesday',
  'thursday': 'thursday',
  'friday': 'friday',
  'saturday': 'saturday',
  'week1': '{v2}',
  'date2': '{v2} {v3}',
  'week2': '{v2}',
  'today': 'Today',
  'robot.icon': robotIcon,
  'click.here.for.how.to.put.out': 'Click here for how to put out {v1}',
  'next': 'next',
  'rough.address': '{v3}, {v2}, {v1}',
  'the.password.reset.email.has.been.sent.successfully.': 'An email has been sent to \'{v1}\' containing instructions for resetting your password.',
  'unable.to.find.user.with.email': 'Unable to find user with email \'{v1}\'',
  'please.log.in.again.': 'Please log in again.',
  'an.error.has.occurred.please.send.an.email.again.': 'An error has occurred.\nPlease send an email again.',
  'news': 'News',
};
export default fr_FR;