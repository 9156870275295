import { connect } from "react-redux";

import { App } from "../../web/components/App/App";
import {logOut} from "../actions/auth";

const mapStateToProps = state => {
  return {
    lang: state.user.language,
    auth: state.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => {
      dispatch(logOut());
    }
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
