import React from "react";
import styles from "./TextLinkBlock.module.scss";
import { Div, P, A } from "../../atoms/Html/Html";

const TextLinkBlock = props => {
  return (
    <Div className={styles.textLinkBlock}>
      {props.text && <P className={styles.AskUserBlock__text}>{props.text}</P>}
      <A className={styles.textLink} onClick={props.handleClick}>
        {props.linkText}
      </A>
    </Div>
  );
};

export default TextLinkBlock;
