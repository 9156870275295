import { developmentApiUrl, productionApiUrl } from "../../web/constants/constants";
import {
  getRequestHeader,
  isStatusOk,
  getFormattedMessage
} from "../../web/constants/functions";
import { logOut } from "./auth";

export const REQUEST_FETCH_DICTIONARY_LIST = "REQUEST_FETCH_DICTIONARY_LIST";

function requestFetchDictionaryList() {
  return {
    type: REQUEST_FETCH_DICTIONARY_LIST
  };
}

export const SUCCESS_FETCH_DICTIONARY_LIST = "SUCCESS_FETCH_DICTIONARY_LIST";

function successFetchDictionaryList(data) {
  return {
    type: SUCCESS_FETCH_DICTIONARY_LIST,
    data
  };
}

export const ERROR_FETCH_DICTIONARY_LIST = "ERROR_FETCH_DICTIONARY_LIST";

function errorFetchDictionaryList(message) {
  return {
    type: ERROR_FETCH_DICTIONARY_LIST,
    msg: message
  };
}

export const AUTH_ERROR_FETCH_DICTIONARY_LIST =
  "AUTH_ERROR_FETCH_DICTIONARY_LIST";

function authErrorFetchDictionaryList() {
  return {
    type: AUTH_ERROR_FETCH_DICTIONARY_LIST
  };
}

export function fetchDictionaryList() {
  return function(dispatch) {
    dispatch(requestFetchDictionaryList());
    let status;
    // dictionary_list
    return fetch(/*developmentApiUrl*/ productionApiUrl + "/trashes/index", {
      headers: getRequestHeader()
    })
      .then(function(response) {
        status = response.status;
        return response.json();
      })
      .then(function(json) {
        if (isStatusOk(status)) {
          dispatch(successFetchDictionaryList(json.data));
        } else if (status === 401) {
          dispatch(logOut());
          dispatch(authErrorFetchDictionaryList());
        } else {
          dispatch(errorFetchDictionaryList(getFormattedMessage('failed.to.get.list.')));
        }
      })
      .catch(function() {
        dispatch(errorFetchDictionaryList(getFormattedMessage('communication.failed.')));
      });
  };
}

export const CHANGE_SEARCH_TEXT = "CHANGE_SEARCH_TEXT";

export function changeSearchText(value) {
  return {
    type: CHANGE_SEARCH_TEXT,
    value
  };
}

export const CHANGE_SCROLL_TOP = "CHANGE_SCROLL_TOP";

export function changeScrollTop(value) {
  return {
    type: CHANGE_SCROLL_TOP,
    value
  };
}

export const SET_DICTIONARY_MSG = "SET_DICTIONARY_MSG";

export function setDictionaryMsg(msg) {
  return {
    type: SET_DICTIONARY_MSG,
    msg
  };
}

export const SET_IS_FETCH_LIST_ERROR = "SET_IS_FETCH_LIST_ERROR";

export function setIsFetchListError(bool) {
  return {
    type: SET_IS_FETCH_LIST_ERROR,
    bool
  };
}

export const REQUEST_FETCH_DICTIONARY_DETAIL =
  "REQUEST_FETCH_DICTIONARY_DETAIL";

function requestFetchDictionaryDetail() {
  return {
    type: REQUEST_FETCH_DICTIONARY_DETAIL
  };
}

export const SUCCESS_FETCH_DICTIONARY_DETAIL =
  "SUCCESS_FETCH_DICTIONARY_DETAIL";

function successFetchDictionaryDetail(data) {
  return {
    type: SUCCESS_FETCH_DICTIONARY_DETAIL,
    data
  };
}

export const ERROR_FETCH_DICTIONARY_DETAIL = "ERROR_FETCH_DICTIONARY_DETAIL";

function errorFetchDictionaryDetail(message) {
  return {
    type: ERROR_FETCH_DICTIONARY_DETAIL,
    msg: message
  };
}

export const AUTH_ERROR_FETCH_DICTIONARY_DETAIL =
  "AUTH_ERROR_FETCH_DICTIONARY_DETAIL";

function authErrorFetchDictionaryDetail() {
  return {
    type: AUTH_ERROR_FETCH_DICTIONARY_DETAIL
  };
}

export function fetchDictionaryDetail(dictionary_id, block_group_id) {
  return function(dispatch) {
    dispatch(requestFetchDictionaryDetail());
    const params = new URLSearchParams();

    // params.set("id", dictionary_id);
    params.set("dictionary_id", dictionary_id);
    let status;
    // dictionary_detail
    return fetch(
      /*developmentApiUrl*/ productionApiUrl + "/trashes/show?" + params.toString(),
      {
        headers: getRequestHeader()
      }
    )
      .then(function(response) {
        status = response.status;
        return response.json();
      })
      .then(function(json) {
        if (isStatusOk(status)) {
          dispatch(successFetchDictionaryDetail(json.data));
        } else if (status === 401) {
          dispatch(logOut());
          dispatch(authErrorFetchDictionaryDetail());
        } else {
          dispatch(errorFetchDictionaryDetail(getFormattedMessage('failed.to.get.information.')));
        }
      })
      .catch(function() {
        dispatch(errorFetchDictionaryDetail(getFormattedMessage('communication.failed.')));
      });
  };
}

export const SET_IS_FETCH_DETAIL_ERROR = "SET_IS_FETCH_DETAIL_ERROR";

export function setIsFetchDetailError(bool) {
  return {
    type: SET_IS_FETCH_DETAIL_ERROR,
    bool
  };
}

export const SET_INIT_DICTIONARY_FLAG = "SET_INIT_DICTIONARY_FLAG";

export function setInitDictionaryFlag(bool) {
  return {
    type: SET_INIT_DICTIONARY_FLAG,
    bool
  };
}
