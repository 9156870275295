import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  fetchFaqList,
  setIsErrorFetchingFaqList,
  setFaqMsg,
  setInitFaqFlag
} from "../actions/faq";

import Faq from "../../web/pages/Faq/Faq";

const mapStateToProps = state => {
  return { faq: state.faq };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFaqList: () => {
      dispatch(fetchFaqList());
    },
    setIsErrorFetchingFaqList: bool => {
      dispatch(setIsErrorFetchingFaqList(bool));
    },
    setFaqMsg: msg => {
      dispatch(setFaqMsg(msg));
    },
    setInitFaqFlag: bool => {
      dispatch(setInitFaqFlag(bool));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Faq)
);
