import robotIcon from "../assets/images/icon_robot_body_english.svg";

const zh_CN = {
  'log.in':'登录',
  'already.use.gommit?':'已经使用GOMMIT？',
  '1.select.your.region': '1.选择您的地区',
  'use.the.other.region':'使用其他区域',
  'dont.you.have.an.account?':'您没有帐号吗？',
  'sign.up':'注册',
  'email':'电子邮件',
  'password':'密码',
  'login':'登录',
  'forget.password':'忘记密码',
  'getting.your.location.data':'正在搜寻您的所在地…',
  '2.input.more.information': '2. 请输入其它资讯',
  'retry': '重试',
  'input.manually': '手动输入',
  'prefecture': '都道府県',
  'city': '市区町村',
  'region': '区域',
  'address': '地址',
  'type.of.residence': '不动产类别',
  'residence.name': '不动产名称',
  'room.number': '房号',
  'your.mail': 'Email',
  'login.password': '登录密码',
  'required': '必须',
  'register': '注册',
  'upcoming.schedule':'即将到来的时间表',
  'cancel':'取消',
  'trash.name':'垃圾桶名称',
  'type':'类型',
  'next.collection.date':'下次收集日期',
  'validation.invalid.email':'不合规电邮',
  'validation.required':'必须',
  'please.select':'请选择',
  '3.check.the.confirmation.email.and.complete.the.registration':'3. 查看确认电子邮件并完成注册',
  'check.mailbox':'检查邮箱',
  'start':'开始',
  'ready.to.use.gommit!':'准备使用GOMMIT!',
  'registering':'注册…',
  'connecting':'正在连接…',
  'back.to.login':'回到登入',
  'reset.password':'重设密码',
  'ok':'好',
  'home': '家',
  'gommit': 'GOMMIT',
  'ai.helps.you': '人工智能帮助您',
  'reward': '奖励',
  'how.to.dispose': '如何处置',
  'dictionary': '指数',
  'oversized': '超大',
  'burnable.trash': '可燃垃圾',
  'unburnable.trash': '不可燃垃圾',
  'harmful.trash': '有害垃圾',
  'oversized.trash': '超大垃圾',
  'plastic': '塑料',
  'books.magazines': '书籍，杂志',
  'cardboard': '纸板',
  'milk.carton': '牛奶盒',
  'paper.newspaper': '纸，报纸',
  'cans.bottles': '罐，瓶',
  'metals': '金属',
  'clothes': '衣服',
  'recyclable.trash': '可回收垃圾',
  'get.again': '再次得到',
  'how.to.dispose.of.tab': '如何处置',
  'items': '物品',
  'notes': '笔记',
  'see.detail': '查看详情',
  'faq': '常问问题',
  'information': '信息',
  'oversized.consultation': '超大垃圾',
  'you.cannot.dispose.oversized.trashes.on.general.ways' : '处理超大垃圾的过程不同',
  'type.your.text': '输入文字',
  'enter.the.name.of.the.trash.you.want.to.check': '输入您要检查的垃圾箱的名称。',
  'settings':'设定值',
  'account':'帐户',
  'notifications':'通知事项',
  'language':'语言',
  'sns.integration':'SNS整合',
  'save': '保存',
  'select.language':'选择语言',
  'english': '英语',
  'japanese': '日本',
  'reward.programs': '奖励计划',
  'total.points': '总积分',
  'start.reward':'开始',
  'special.reward':'特别\n奖励',
  'password.confirmation':'再次输入密码',
  'please.enter.a.password.with.at.least.6.characters':'请输入至少6个字符的密码。',
  'logout': '登出',
  'based.on.information.of.local.government.website': '根据当地政府网站',
  'notice':'注意',
  'search':'搜索',
  'definition.of.oversized.garbage': '“超大垃圾”的定义',
  'how.to.dispose.of.oversized.garbage':'如何处置',
  'ticket.prices': '处置超大垃圾的门票发行费\n*在某些情况下，费用可能会有所不同',
  'dispose.step.of.oversized.garbage': '1.在接待中心申请\n' +
      '2.在指定的经销商处购买大件垃圾处理票\n' +
      '3.指定日垃圾',
  'see.on.the.local.government.website': '检查您当地政府的HP网站',
  'not.found.': '未找到。',
  'plain.address': '地址',
  'invisible': '无形',
  'return': '返回',
  'upcoming': '即将来临。',
  'apartment': 'Apartment',
  'house': 'House',
  'failed.to.get.information.': 'Failed to get information.',
  'communication.failed.': 'Communication failed.{v1}',
  'failed.to.get.your.location.': 'Failed to get your location.',
  'not.compatible.with.your.area.': 'Not compatible with your area.',
  'the.password.was.updated.successfully.': 'The password was updated successfully.',
  'the.address.was.successfully.updated.': 'The address was successfully updated.',
  'failed.to.get.event.': 'Failed to get event.',
  'failed.to.get.list.': 'Failed to get list.',
  'incorrect.email.or.password.': 'Incorrect email or password.',
  'failed.to.get.faq.': 'Failed to get FAQ.',
  'mail.token.error': 'This e-mail address is temporarily registered.\nYou can’t register with this email address until {v1}',
  'january': 'january',
  'february': 'february',
  'march': 'march',
  'april': 'april',
  'may': 'may',
  'june': 'june',
  'july': 'july',
  'august': 'august',
  'september': 'september',
  'october': 'october',
  'november': 'november',
  'december': 'december',
  'date1': '{v3} {v1}',
  'sunday': 'sunday',
  'monday': 'monday',
  'tuesday': 'tuesday',
  'wednesday': 'wednesday',
  'thursday': 'thursday',
  'friday': 'friday',
  'saturday': 'saturday',
  'week1': '{v2}',
  'date2': '{v2} {v3}',
  'week2': '{v2}',
  'today': 'Today',
  'robot.icon': robotIcon,
  'click.here.for.how.to.put.out': 'Click here for how to put out {v1}',
  'next': 'next',
  'rough.address': '{v3}, {v2}, {v1}',
  'the.password.reset.email.has.been.sent.successfully.': 'An email has been sent to \'{v1}\' containing instructions for resetting your password.',
  'unable.to.find.user.with.email': 'Unable to find user with email \'{v1}\'',
  'please.log.in.again.': 'Please log in again.',
  'an.error.has.occurred.please.send.an.email.again.': 'An error has occurred.\nPlease send an email again.',
  'news': 'News',
};
export default zh_CN;