import robotIcon from "../assets/images/icon_robot_body_english.svg";

const es_ES = {
  'log.in': 'Iniciar sesión',
  'already.use.gommit?': 'Ya dispone de GOMMIT?',
  '1.select.your.region': '1. Seleccione su área',
  'use.the.other.region': 'Utilice la otra área',
  'dont.you.have.an.account?': '¿No tienes una cuenta?',
  'sign.up': 'Regístrate',
  'email': 'Email',
  'password': 'Contraseña',
  'login': 'Iniciar sesión',
  'forget.password': 'Contraseña olvidada',
  'getting.your.location.data': 'Obtención de los datos de localización ...',
  '2.input.more.information': '2. más información de entrada',
  'retry': 'Procesar de nuevo',
  'input.manually': 'introducir manualmente',
  'prefecture': 'Prefectura',
  'city': 'Ciudad',
  'region': 'Zona',
  'address': 'Habla a',
  'type.of.residence': 'Tipo de residencia',
  'residence.name': 'Nombre residencia',
  'room.number': 'Número de habitación',
  'your.mail': 'Tu correo electrónico',
  'login.password': 'Contraseña de inicio de sesión',
  'required': 'necesario',
  'register': 'Registrarse',
  'upcoming.schedule': 'calendario de eventos',
  'cancel': 'Cancelar',
  'trash.name': 'Nombre de basura',
  'type': 'Tipo',
  'next.collection.date': 'Siguiente Fecha de Cobro',
  'validation.invalid.email': 'Email inválido',
  'validation.required': 'Necesario',
  'please.select': 'Por favor, seleccione',
  '3.check.the.confirmation.email.and.complete.the.registration': '3. Comprobar el correo electrónico de confirmación y completar el registro',
  'check.mailbox': 'comprobación del buzón',
  'start': 'comienzo',
  'ready.to.use.gommit!': 'Listo para usar GOMMIT!',
  'registering': 'Registrando ...',
  'connecting': 'Conectando ...',
  'back.to.login': 'Atrás para iniciar sesión',
  'reset.password': 'Restablecer la contraseña',
  'ok': 'Okay',
  'home': 'Hogar',
  'gommit': 'GOMMIT',
  'ai.helps.you': 'AI le ayuda',
  'reward': 'Recompensa',
  'how.to.dispose': 'Cómo disponer',
  'dictionary': 'Índice',
  'oversized': 'de gran tamaño',
  'burnable.trash': 'Basura quemable',
  'unburnable.trash': 'La basura no combustible',
  'harmful.trash': 'La basura nociva',
  'oversized.trash': 'La basura de gran tamaño',
  'plastic': 'El plastico',
  'books.magazines': 'Libros, Revistas',
  'cardboard': 'Cartulina',
  'milk.carton': 'Cartón de leche',
  'paper.newspaper': 'Papel, Periódico',
  'cans.bottles': 'Latas, botellas',
  'metals': 'Rieles',
  'clothes': 'Ropa',
  'recyclable.trash': 'La basura reciclable',
  'get.again': 'Obtener nuevo',
  'how.to.dispose.of.tab': 'Cómo disponer',
  'items': 'Artículos',
  'notes': 'notas',
  'see.detail': 'Ver detalle',
  'faq': 'Preguntas más frecuentes',
  'information': 'Información',
  'oversized.consultation': 'La basura de gran tamaño',
  'you.cannot.dispose.oversized.trashes.on.general.ways': 'La eliminación de la basura de gran tamaño sigue un proceso diferente',
  'type.your.text': 'Escribe tu texto',
  'enter.the.name.of.the.trash.you.want.to.check': 'Introduzca el nombre de la basura que desea comprobar.',
  'settings': 'ajustes',
  'account': 'Cuenta',
  'notifications': 'Notificaciones',
  'language': 'Idioma',
  'sns.integration': 'Integración SNS',
  'save': 'Salvar',
  'select.language': 'Seleccione el idioma',
  'english': 'Inglés',
  'japanese': 'japonés',
  'reward.programs': 'Los programas de recompensa',
  'total.points': 'Puntos totales',
  'start.reward': 'comienzo',
  'special.reward': 'Recompensa\nespecial',
  'password.confirmation': 'Confirmación de contraseña',
  'please.enter.a.password.with.at.least.6.characters': 'Por favor, introduzca una contraseña con al menos 6 caracteres.',
  'logout': 'Cerrar sesión',
  'based.on.information.of.local.government.website': 'Con base en el sitio web del gobierno local',
  'notice': 'darse cuenta',
  'search': 'Buscar',
  'definition.of.oversized.garbage': 'Definición de "basura de gran tamaño"',
  'how.to.dispose.of.oversized.garbage': 'Cómo disponer',
  'ticket.prices': 'cuota de emisión del billete para la eliminación de basura de gran tamaño\n* Tarifa puede variar en ciertos casos',
  'dispose.step.of.oversized.garbage': '1. Aplicar al centro de recepción\n' +
    '2.Compre un boleto de eliminación de basura voluminosa en un distribuidor designado\n' +
    '3.Basura en días designados',
  'see.on.the.local.government.website': 'Verifique en el sitio HP de su gobierno local',
  'not.found.': 'Extraviado',
  'plain.address': 'Habla a',
  'invisible': 'Invisible',
  'return': 'Regreso',
  'upcoming': 'Próxima.',
  'apartment': 'Apartment',
  'house': 'House',
  'failed.to.get.information.': 'Failed to get information.',
  'communication.failed.': 'Communication failed.{v1}',
  'failed.to.get.your.location.': 'Failed to get your location.',
  'not.compatible.with.your.area.': 'Not compatible with your area.',
  'the.password.was.updated.successfully.': 'The password was updated successfully.',
  'the.address.was.successfully.updated.': 'The address was successfully updated.',
  'failed.to.get.event.': 'Failed to get event.',
  'failed.to.get.list.': 'Failed to get list.',
  'incorrect.email.or.password.': 'Incorrect email or password.',
  'failed.to.get.faq.': 'Failed to get FAQ.',
  'mail.token.error': 'This e-mail address is temporarily registered.\nYou can’t register with this email address until {v1}',
  'january': 'january',
  'february': 'february',
  'march': 'march',
  'april': 'april',
  'may': 'may',
  'june': 'june',
  'july': 'july',
  'august': 'august',
  'september': 'september',
  'october': 'october',
  'november': 'november',
  'december': 'december',
  'date1': '{v3} {v1}',
  'sunday': 'sunday',
  'monday': 'monday',
  'tuesday': 'tuesday',
  'wednesday': 'wednesday',
  'thursday': 'thursday',
  'friday': 'friday',
  'saturday': 'saturday',
  'week1': '{v2}',
  'date2': '{v2} {v3}',
  'week2': '{v2}',
  'today': 'Today',
  'robot.icon': robotIcon,
  'click.here.for.how.to.put.out': 'Click here for how to put out {v1}',
  'next': 'next',
  'rough.address': '{v3}, {v2}, {v1}',
  'the.password.reset.email.has.been.sent.successfully.': 'An email has been sent to \'{v1}\' containing instructions for resetting your password.',
  'unable.to.find.user.with.email': 'Unable to find user with email \'{v1}\'',
  'please.log.in.again.': 'Please log in again.',
  'an.error.has.occurred.please.send.an.email.again.': 'An error has occurred.\nPlease send an email again.',
  'news': 'News',
};
export default es_ES;