import React from "react";
import style from "./Reward.module.scss";
import GlobalHeader from "../../components/organisms/GlobalHeader/GlobalHeader";
import GlobalFooter from "../../components/organisms/GlobalFooter/GlobalFooter";
import { Div, Main, H3, Img, P } from "../../components/atoms/Html/Html";
import { Route } from "react-router-dom";
import ScrollView from "../../components/atoms/ScrollView/ScrollView";
import Heading from "../../components/atoms/Heading/Heading";
import Score from "../../components/molecules/Score/Score.js";
import ContentBoxGroup from "../../components/organisms/ContentBoxGroup/ContentBoxGroup";
import iconRobot from "../../../assets/images/icon_robot_face.svg";
import { getLanguage } from "../../constants/functions";
import StepScore from "../../components/molecules/StepScore/StepScore.js";

const Reward = props => {
  function handleClickBell() {
    props.history.push("/information");
  }

  function handleClickFaq() {
    props.history.push("/faq");
  }

  function handleClickSettings() {
    props.history.push("/settings");
  }
  const leftContent = [{ label: "bell", action: handleClickBell }];
  const rightContent = [
    { label: "faq", action: handleClickFaq },
    { label: "settings", action: handleClickSettings }
  ];
  return (
    <>
      <GlobalHeader
        title={props.intl.formatMessage({ id: "reward.programs" })}
        subtitle={
          props.common.status.authority.name +
          props.common.status.block.name
        }
        leftContent={leftContent}
        rightContent={rightContent}
      />
      <Main className={style.reward__main}>
        <ScrollView contentContainerStyle={style.reward__main__scrollView}>
          <Route exact path="/reward" render={() => <Test1 {...props} />} />
          <Route path="/reward/details" render={() => <Test2 {...props} />} />
        </ScrollView>
      </Main>
      <GlobalFooter
        intl={props.intl}
        pathname={props.history.location.pathname}
      />
    </>
  );
};

class Test1 extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {}

  render() {
    return <P className={style.upcoming}>{this.props.intl.formatMessage({ id: "upcoming" })}</P>;
  }
}

class Test2 extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {}

  render() {
    const contents = [
      {
        type: "base",
        heading:
          "text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.",
        contentBoxStyle: style.contentBoxStyle,
        body: {
          text:
            "text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.text.",
          textStyle: style.textStyle
        }
      }
    ];
    const total = 600;
    const current = 35;
    let itemText;
    switch (getLanguage()) {
      case "en":
        itemText =
          "Reaching " + total + "Points,\nyou can get a special reward!";
        break;
      case "ja":
        itemText = total + "ポイント貯めて\n特典をゲットしよう！";
        break;
      default:
        itemText = total + "ポイント貯めて\n特典をゲットしよう！";
    }
    return (
      <>
        <Div className={style.reward__main__scrollView__details}>
          <Heading
            className={style.reward__main__scrollView__details__heading}
            text={this.props.intl.formatMessage({ id: "total.points" })}
          />
          <Score
            current={current}
            total={total}
            scoreStyle={style.reward__main__scrollView__details__score}
          />
          <StepScore current={current} total={total} intl={this.props.intl} />
          <H3 className={style.reward__main__scrollView__details__item}>
            <Img
              src={iconRobot}
              className={style.reward__main__scrollView__details__item__icon}
            />
            {itemText}
          </H3>
        </Div>
        <ContentBoxGroup contents={contents} />
      </>
    );
  }
}

export default Reward;
