import React from "react";
import { P, Div, Img, A } from "../../atoms/Html/Html";
import loadingBlue from "../../../../assets/images/loading-blue.svg";
import styles from "./Modal.module.scss";

const Modal = props => (
  <Div className={styles.modalBlock}>
    <Div className={styles.modalBlock__contentBlock}>
      {props.component && props.component}
      {props.text && (
        <P className={styles.modalBlock__contentBlock__text}>{props.text}</P>
      )}
      {(() => {
        switch (props.type) {
          case "loading":
            return (
              <Img
                src={loadingBlue}
                className={styles.modalBlock__contentBlock__loading}
              />
            );
          case "choice":
            return (
              <Div className={styles.modalBlock__contentBlock__choiceBlock}>
                {props.choiceList.map(value => (
                  <A
                    className={
                      styles.modalBlock__contentBlock__choiceBlock__button
                    }
                    onClick={value.action}
                  >
                    {value.label}
                  </A>
                ))}
              </Div>
            );
          default:
            return (
              <Img
                src={loadingBlue}
                className={styles.modalBlock__contentBlock__loading}
              />
            );
        }
      })()}
    </Div>
  </Div>
);

export default Modal;
