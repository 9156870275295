import { developmentApiUrl, productionApiUrl } from "../../web/constants/constants";
import {
  getRequestHeader,
  getLanguage,
  isStatusOk
} from "../../web/constants/functions";
import { logOut } from "./auth";

export const CHANGE_HOW_TO_DISPOSE_TAB = "CHANGE_HOW_TO_DISPOSE_TAB";

export function changeHowToDisposeTab(index) {
  return {
    type: CHANGE_HOW_TO_DISPOSE_TAB,
    index
  };
}
