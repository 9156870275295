import React from "react";
import arrow from "../../../../assets/images/arrow-blue.svg";
import styles from "./BackLink.module.scss";
import { Header, A, Img } from "../../atoms/Html/Html";

const BackLink = props => (
  <Header className={styles.backLinkBlocklock}>
    <A onClick={props.handleClick}>
      <Img src={arrow} />
    </A>
  </Header>
);

export default BackLink;
