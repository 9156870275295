import { getRequestHeader, getLanguage, isStatusOk, getFormattedMessage } from "../../web/constants/functions";
import { logOut } from "./auth";

export const REQUEST_FETCH_FAQ_LIST = "REQUEST_FETCH_FAQ_LIST";

function requestFetchFaqList() {
  return {
    type: REQUEST_FETCH_FAQ_LIST
  };
}

export const SUCCESS_FETCH_FAQ_LIST = "SUCCESS_FETCH_FAQ_LIST";

function successFetchFaqList(json) {
  return {
    type: SUCCESS_FETCH_FAQ_LIST,
    list: json
  };
}

export const ERROR_FETCH_FAQ_LIST = "ERROR_FETCH_FAQ_LIST";

function errorFetchFaqList(msg) {
  return {
    type: ERROR_FETCH_FAQ_LIST,
    msg
  };
}

export const ERROR_AUTH_FETCH_FAQ_LIST = "ERROR_AUTH_FETCH_FAQ_LIST";

function errorAuthFetchFaqList() {
  return {
    type: ERROR_AUTH_FETCH_FAQ_LIST
  };
}

export function fetchFaqList() {
  return function(dispatch) {
    dispatch(requestFetchFaqList());
    let status;
    return fetch("/data/faq.json", {
      headers: getRequestHeader()
    })
      .then(function(response) {
        status = response.status;
        return response.json();
      })
      .then(function(json) {
        if (isStatusOk(status)) {
          dispatch(successFetchFaqList(json));
        } else if (status === 401) {
          dispatch(logOut());
          dispatch(errorAuthFetchFaqList());
        } else {
          dispatch(errorFetchFaqList(getFormattedMessage("failed.to.get.faq.")));
        }
      })
      .catch(function() {
        dispatch(errorFetchFaqList(getFormattedMessage('communication.failed.')));
      });
  };
}

export const SET_IS_ERROR_FETCHING_FAQ_LIST = "SET_IS_ERROR_FETCHING_FAQ_LIST";

export function setIsErrorFetchingFaqList(bool) {
  return {
    type: SET_IS_ERROR_FETCHING_FAQ_LIST,
    bool
  };
}

export const SET_FAQ_MSG = "SET_FAQ_MSG";

export function setFaqMsg(msg) {
  return {
    type: SET_FAQ_MSG,
    msg
  };
}

export const SET_INIT_FAQ_FLAG = "SET_INIT_FAQ_FLAG";

export function setInitFaqFlag(bool) {
  return {
    type: SET_INIT_FAQ_FLAG,
    bool
  };
}
