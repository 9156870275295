import React from "react";
import style from "./ScrollView.module.scss";
import { Div } from "../Html/Html";

const ScrollView = props => (
  <Div
    className={style.scrollView + " " + props.contentContainerStyle}
    id={props.id}
    onScroll={props.onScroll}
  >
    {props.children}
  </Div>
);

export default ScrollView;
