import React from "react";
import style from "./LoadingWrapper.module.scss";
import { Div, A, Img, P } from "../../atoms/Html/Html";
import Observer from "react-intersection-observer";
import loadingBlue from "../../../../assets/images/loading-blue.svg";
import 'intersection-observer';

const LoadingWrapper = props => {
  let loadingWrapperComponent;
  if (props.isConnecting) {
    loadingWrapperComponent = <Loading style={props.loadingStyle} />;
  } else if (props.errorText) {
    loadingWrapperComponent = (
      <Error
        errorText={props.errorText}
        function={props.onRetry}
        retryText={props.retryText}
        style={props.errorStyle}
      />
    );
  } else if (props.willLoadNext) {
    loadingWrapperComponent = (
      <LoadNext function={props.onLoadNext} style={props.dummyStyle} />
    );
  } else {
    loadingWrapperComponent = null;
  }

  return loadingWrapperComponent !== null
    ? [props.children, loadingWrapperComponent]
    : [props.children];
};

const LoadNext = props => (
  <Observer onChange={props.function}>
    <Div className={props.style} />
  </Observer>
);

const Loading = props => (
  <Div className={props.style}>
    <Img src={loadingBlue} className={style.loading} />
  </Div>
);

const Error = props => (
  <Div className={style.error + " " + props.style}>
    <P className={style.error__text}>{props.errorText}</P>
    <A onClick={props.function} className={style.retry__link}>
      {props.retryText}
    </A>
  </Div>
);

export default LoadingWrapper;
