import {
  REQUEST_FETCH_OVERSIZED_LIST,
  SUCCESS_FETCH_OVERSIZED_LIST,
  ERROR_FETCH_OVERSIZED_LIST,
  ERROR_AUTH_FETCH_OVERSIZED_LIST,
  SET_IS_ERROR_FETCHING_OVERSIZED_LIST,
  SET_OVERSIZED_MSG,
  SET_INIT_OVERSIZED_FLAG
} from "../actions/oversized";

const Oversized = (
  state = {
    oversizedList: {},
    isFetchingOversizedList: false,
    errors: {
      msg: "",
      isErrorFetchingOversizedList: false
    },
    initOversizedFlag: true
  },
  action
) => {
  switch (action.type) {
    case REQUEST_FETCH_OVERSIZED_LIST:
      return Object.assign({}, state, {
        isFetchingOversizedList: true
      });
    case SUCCESS_FETCH_OVERSIZED_LIST:
      return Object.assign({}, state, {
        oversizedList: action.list,
        isFetchingOversizedList: false
      });
    case ERROR_FETCH_OVERSIZED_LIST:
      return Object.assign({}, state, {
        isFetchingOversizedList: false,
        errors: {
          msg: action.msg,
          isErrorFetchingOversizedList: true
        }
      });
    case ERROR_AUTH_FETCH_OVERSIZED_LIST:
      return Object.assign({}, state, {
        isFetchingOversizedList: false
      });
    case SET_IS_ERROR_FETCHING_OVERSIZED_LIST:
      return Object.assign({}, state, {
        errors: {
          msg: state.errors.msg,
          isErrorFetchingOversizedList: action.bool
        }
      });
    case SET_OVERSIZED_MSG:
      return Object.assign({}, state, {
        errors: {
          msg: action.msg,
          isErrorFetchingOversizedList:
            state.errors.isErrorFetchingOversizedList
        }
      });
    case SET_INIT_OVERSIZED_FLAG:
      return Object.assign({}, state, {
        initOversizedFlag: action.bool
      });
    default:
      return state;
  }
};

export default Oversized;
