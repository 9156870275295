import robotIcon from "../assets/images/icon_robot_body_english.svg";

const th_TH = {
  'log.in': 'เข้าสู่ระบบ',
  'already.use.gommit?': 'แล้วใช้ GOMMIT?',
  '1.select.your.region': '1. เลือกพื้นที่ของคุณ',
  'use.the.other.region': 'ใช้พื้นที่อื่น ๆ',
  'dont.you.have.an.account?': 'อย่าคุณมีบัญชีอยู่แล้ว?',
  'sign.up': 'ลงชื่อ',
  'email': 'อีเมล์',
  'password': 'รหัสผ่าน',
  'login': 'เข้าสู่ระบบ',
  'forget.password': 'ลืมรหัสผ่าน',
  'getting.your.location.data': 'การรับข้อมูลตำแหน่งของคุณ ...',
  '2.input.more.information': '2. ข้อมูลเพิ่มเติมการป้อนข้อมูล',
  'retry': 'ลองใหม่',
  'input.manually': 'ป้อนข้อมูลด้วยตนเอง',
  'prefecture': 'อำเภอ',
  'city': 'เมือง',
  'region': 'พื้นที่',
  'address': 'ที่อยู่',
  'type.of.residence': 'ประเภทของสซิเดนซ์',
  'residence.name': 'เรสซิเดนชื่อ',
  'room.number': 'หมายเลขห้อง',
  'your.mail': 'อีเมลของคุณ',
  'login.password': 'ใส่รหัสผ่าน',
  'required': 'จำเป็นต้องใช้',
  'register': 'สมัครสมาชิก',
  'upcoming.schedule': 'ตารางการแข่งขันที่จะเกิดขึ้น',
  'cancel': 'ยกเลิก',
  'trash.name': 'ชื่อถังขยะ',
  'type': 'ชนิด',
  'next.collection.date': 'คอลเลกชันถัดไปวันที่',
  'validation.invalid.email': 'อีเมลไม่ถูกต้อง',
  'validation.required': 'จำเป็นต้องใช้',
  'please.select': 'โปรดเลือก',
  '3.check.the.confirmation.email.and.complete.the.registration': '3. ตรวจสอบอีเมลยืนยันและเสร็จสิ้นการลงทะเบียน',
  'check.mailbox': 'กล่องจดหมายตรวจสอบ',
  'start': 'เริ่มต้น',
  'ready.to.use.gommit!': 'พร้อมที่จะใช้ GOMMIT!',
  'registering': 'การลงทะเบียน ...',
  'connecting': 'เชื่อมต่อ ...',
  'back.to.login': 'กลับไปเข้าสู่ระบบ',
  'reset.password': 'รีเซ็ตรหัสผ่าน',
  'ok': 'ตกลง',
  'home': 'บ้าน',
  'gommit': 'GOMMIT',
  'ai.helps.you': 'AI ช่วยให้คุณ',
  'reward': 'รางวัล',
  'how.to.dispose': 'วิธีการกำจัด',
  'dictionary': 'ดัชนี',
  'oversized': 'โคร่ง',
  'burnable.trash': 'ถังขยะขยะ',
  'unburnable.trash': 'ถังขยะทำขบวนการ',
  'harmful.trash': 'ถังขยะอันตราย',
  'oversized.trash': 'ขยะขนาดใหญ่',
  'plastic': 'พลาสติก',
  'books.magazines': 'หนังสือนิตยสาร',
  'cardboard': 'กระดาษแข็ง',
  'milk.carton': 'กล่องนม',
  'paper.newspaper': 'กระดาษหนังสือพิมพ์',
  'cans.bottles': 'กระป๋องขวด',
  'metals': 'โลหะ',
  'clothes': 'เสื้อผ้า',
  'recyclable.trash': 'ถังขยะรีไซเคิล',
  'get.again': 'ได้รับอีกครั้ง',
  'how.to.dispose.of.tab': 'วิธีการกำจัด',
  'items': 'รายการ',
  'notes': 'หมายเหตุ',
  'see.detail': 'รายละเอียดโปรดดู',
  'faq': 'คำถามที่พบบ่อย',
  'information': 'ข้อมูล',
  'oversized.consultation': 'ขยะขนาดใหญ่',
  'you.cannot.dispose.oversized.trashes.on.general.ways': 'การกำจัดขยะขนาดใหญ่ต่อไปนี้ขั้นตอนที่แตกต่างกัน',
  'type.your.text': 'พิมพ์ข้อความของคุณ',
  'enter.the.name.of.the.trash.you.want.to.check': 'ป้อนชื่อของถังขยะที่คุณต้องการตรวจสอบ',
  'settings': 'การตั้งค่า',
  'account': 'บัญชีผู้ใช้',
  'notifications': 'การแจ้งเตือน',
  'language': 'ภาษา',
  'sns.integration': 'บูรณาการ SNS',
  'save': 'บันทึก',
  'select.language': 'เลือกภาษา',
  'english': 'อังกฤษ',
  'japanese': 'ญี่ปุ่น',
  'reward.programs': 'โปรแกรมรางวัล',
  'total.points': 'คะแนนรวม',
  'start.reward': 'เริ่มต้น',
  'special.reward': 'พิเศษ\nรางวัล',
  'password.confirmation': 'การยืนยันรหัสผ่าน',
  'please.enter.a.password.with.at.least.6.characters': 'กรุณาใส่รหัสผ่านที่มีอย่างน้อย 6 ตัวอักษร',
  'logout': 'ออกจากระบบ',
  'based.on.information.of.local.government.website': 'อ้างอิงจากเว็บไซต์ของรัฐบาลท้องถิ่น',
  'notice': 'แจ้งให้ทราบ',
  'search': 'ค้นหา',
  'definition.of.oversized.garbage': 'คำนิยามของ "ขนาดใหญ่ขยะ"',
  'how.to.dispose.of.oversized.garbage': 'วิธีการกำจัด',
  'ticket.prices': 'ค่าธรรมเนียมการออกบัตรโดยสารสำหรับการกำจัดขยะขนาดใหญ่\n* ค่าบริการอาจแตกต่างกันในบางกรณี',
  'dispose.step.of.oversized.garbage': '1. สมัครที่ศูนย์รับ\n' +
    '2. ซื้อตั๋วกำจัดขยะขนาดใหญ่ที่ตัวแทนจำหน่ายที่ได้รับมอบหมาย\n' +
    '3. ถังขยะในวันที่กำหนด',
  'see.on.the.local.government.website': 'ตรวจสอบเว็บไซต์ HP ของรัฐบาลท้องถิ่นของคุณ',
  'not.found.': 'ไม่พบ',
  'plain.address': 'ที่อยู่',
  'invisible': 'มองไม่เห็น',
  'return': 'กลับ',
  'upcoming': 'ที่จะเกิดขึ้น',
  'apartment': 'Apartment',
  'house': 'House',
  'failed.to.get.information.': 'Failed to get information.',
  'communication.failed.': 'Communication failed.{v1}',
  'failed.to.get.your.location.': 'Failed to get your location.',
  'not.compatible.with.your.area.': 'Not compatible with your area.',
  'the.password.was.updated.successfully.': 'The password was updated successfully.',
  'the.address.was.successfully.updated.': 'The address was successfully updated.',
  'failed.to.get.event.': 'Failed to get event.',
  'failed.to.get.list.': 'Failed to get list.',
  'incorrect.email.or.password.': 'Incorrect email or password.',
  'failed.to.get.faq.': 'Failed to get FAQ.',
  'mail.token.error': 'This e-mail address is temporarily registered.\nYou can’t register with this email address until {v1}',
  'january': 'january',
  'february': 'february',
  'march': 'march',
  'april': 'april',
  'may': 'may',
  'june': 'june',
  'july': 'july',
  'august': 'august',
  'september': 'september',
  'october': 'october',
  'november': 'november',
  'december': 'december',
  'date1': '{v3} {v1}',
  'sunday': 'sunday',
  'monday': 'monday',
  'tuesday': 'tuesday',
  'wednesday': 'wednesday',
  'thursday': 'thursday',
  'friday': 'friday',
  'saturday': 'saturday',
  'week1': '{v2}',
  'date2': '{v2} {v3}',
  'week2': '{v2}',
  'today': 'Today',
  'robot.icon': robotIcon,
  'click.here.for.how.to.put.out': 'Click here for how to put out {v1}',
  'next': 'next',
  'rough.address': '{v3}, {v2}, {v1}',
  'the.password.reset.email.has.been.sent.successfully.': 'An email has been sent to \'{v1}\' containing instructions for resetting your password.',
  'unable.to.find.user.with.email': 'Unable to find user with email \'{v1}\'',
  'please.log.in.again.': 'Please log in again.',
  'an.error.has.occurred.please.send.an.email.again.': 'An error has occurred.\nPlease send an email again.',
  'news': 'News',
};
export default th_TH;