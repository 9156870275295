import React from "react";
import style from "./StepScore.module.scss";
import { Div, P } from "../../atoms/Html/Html";

const ScoreItem = props => {
  return (
    <Div className={style.t1 + " " + (props.isActive ? style.active : null)}>
      <Div className={style.t3}></Div>
      <P
        className={
          style.t4 + " " + (props.isNumber ? style.number : style.text)
        }
      >
        {props.text}
      </P>
    </Div>
  );
};

const Border = props => {
  return (
    <Div>
      {props.percent && <Div></Div>}
      <Div></Div>
    </Div>
  );
};

const StepScore = props => {
  const quotient = Math.floor(props.total / 4);
  const number = Math.floor(props.current / quotient);
  const percent = ((props.current % quotient) / quotient) * 100;

  return (
    <Div className={style.t2}>
      {[...Array(5)].map((_, index) => {
        if (index === 0) {
          return (
            <>
              <ScoreItem
                isActive={true}
                text={props.intl.formatMessage({ id: "start.reward" })}
              />
              <Border percent={percent} />
            </>
          );
        } else if (index === 1) {
          return (
            <ScoreItem
              isActive={quotient >= props.total}
              text={quotient}
              isNumber={true}
            />
          );
        } else if (index === 2) {
          return (
            <ScoreItem
              isActive={quotient * 2 >= props.total}
              text={quotient * 2}
              isNumber={true}
            />
          );
        } else if (index === 3) {
          return (
            <ScoreItem
              isActive={quotient * 3 >= props.total}
              text={quotient * 3}
              isNumber={true}
            />
          );
        } else {
          return (
            <ScoreItem
              isActive={props.total === props.current}
              text={props.intl.formatMessage({ id: "special.reward" })}
            />
          );
        }
      })}
    </Div>
  );
};

export default StepScore;
