import { developmentApiUrl, productionApiUrl } from "../../web/constants/constants";
import {
  getRequestHeader,
  getLanguage,
  isStatusOk,
  getFormattedMessage
} from "../../web/constants/functions";
import { logOut } from "./auth";

export const REQUEST_FETCH_EVENT_LIST = "REQUEST_FETCH_EVENT_LIST";

function requestFetchEventList(mode) {
  return {
    type: REQUEST_FETCH_EVENT_LIST,
    mode
  };
}

export const SUCCESS_FETCH_EVENT_LIST = "SUCCESS_FETCH_EVENT_LIST";

function successFetchEventList(json, mode, isReset) {
  return {
    type: SUCCESS_FETCH_EVENT_LIST,
    eventList: json.data.list,
    mode,
    isReset
  };
}

export const ERROR_FETCH_EVENT_LIST = "ERROR_FETCH_EVENT_LIST";

function errorFetchEventList(message, mode) {
  return {
    type: ERROR_FETCH_EVENT_LIST,
    msg: message,
    mode
  };
}

export const ERROR_AUTH_FETCH_EVENT_LIST = "ERROR_AUTH_FETCH_EVENT_LIST";

function errorAuthFetchEventList(mode) {
  return {
    type: ERROR_AUTH_FETCH_EVENT_LIST,
    mode
  };
}

export function fetchEventList(
  year,
  month,
  type,
  number,
  blockGroupId,
  isReset
) {
  console.log(blockGroupId);
  return function(dispatch) {
    dispatch(requestFetchEventList(type));

    const params = new URLSearchParams();
    params.set("year", year);
    params.set("month", month);
    params.set("block_group_id", blockGroupId);
    // params.set("block_group_id", 10); // test
    // params.set("number", number);

    let status;
    return fetch(productionApiUrl + "/disposal_days/index?" + params.toString(), {
      headers: getRequestHeader()
    })
      .then(function(response) {
        console.log(response);
        status = response.status;
        return response.json();
      })
      .then(function(json) {
        if (isStatusOk(status)) {
          dispatch(successFetchEventList(json, type, isReset));
        } else if (status === 401) {
          dispatch(logOut());
          dispatch(errorAuthFetchEventList(type));
        } else {
          dispatch(errorFetchEventList(getFormattedMessage('failed.to.get.event.'), type));
        }
      })
      .catch(function() {
        dispatch(errorFetchEventList(getFormattedMessage('communication.failed.'), type));
      });
  };
}

export const CHANGE_WAS_CHECKED = "CHANGE_WAS_CHECKED";

export function changeWasChecked(bool) {
  return {
    type: CHANGE_WAS_CHECKED,
    bool
  };
}

export const CHANGE_CALENDAR_DATE = "CHANGE_CALENDAR_DATE";

export function changeCalendarDate(year, month) {
  return {
    type: CHANGE_CALENDAR_DATE,
    year,
    month
  };
}

export const SET_CALENDAR_MSG = "SET_CALENDAR_MSG";

export function setCalendarMsg(msg) {
  return {
    type: SET_CALENDAR_MSG,
    msg
  };
}

export const SET_IS_ERROR_FETCH_CALENDAR_LIST =
  "SET_IS_ERROR_FETCH_CALENDAR_LIST";

export function setIsErrorFetchCalendarList(bool) {
  return {
    type: SET_IS_ERROR_FETCH_CALENDAR_LIST,
    bool
  };
}

export const CHANGE_SCHEDULE_DATE = "CHANGE_SCHEDULE_DATE";

export function changeScheduleDate(year, month) {
  return {
    type: CHANGE_SCHEDULE_DATE,
    year,
    month
  };
}

export const SET_SCHEDULE_MSG = "SET_SCHEDULE_MSG";

export function setScheduleMsg(msg) {
  return {
    type: SET_SCHEDULE_MSG,
    msg
  };
}

export const SET_IS_ERROR_FETCH_SCHEDULE_LIST =
  "SET_IS_ERROR_FETCH_SCHEDULE_LIST";

export function setIsErrorFetchScheduleList(bool) {
  return {
    type: SET_IS_ERROR_FETCH_SCHEDULE_LIST,
    bool
  };
}

export const SET_NOTICE_FLAG = "SET_NOTICE_FLAG";

export function setNoticeFlag(bool) {
  return {
    type: SET_NOTICE_FLAG,
    bool
  };
}

export const CHANGE_CALENDAR_INDEX = "CHANGE_CALENDAR_INDEX";

export function changeCalendarIndex(index) {
  return {
    type: CHANGE_CALENDAR_INDEX,
    index
  };
}

export const SET_CALENDAR_EVENT_LIST = "SET_CALENDAR_EVENT_LIST";

export function setCalendarEventList(values) {
  return {
    type: SET_CALENDAR_EVENT_LIST,
    values
  };
}

export const SET_SCHEDULE_EVENT_LIST = "SET_SCHEDULE_EVENT_LIST";

export function setScheduleEventList(values) {
  return {
    type: SET_SCHEDULE_EVENT_LIST,
    values
  };
}
