import { developmentApiUrl, productionApiUrl,developmentBuildUrl, productionBuildUrl } from "../../web/constants/constants";
import {
  getRequestHeader,
  isStatusOk,
  getLanguage,
  getState,
  Storage,
  reload,
  getFormattedMessage,
  getUrlQueries
} from "../../web/constants/functions";

export const SET_IS_FETCHING_COMMON_INFO = "SET_IS_FETCHING_COMMON_INFO";

function setIsFetchingCommonInfo(bool) {
  return {
    type: SET_IS_FETCHING_COMMON_INFO,
    bool
  };
}

export const SET_IS_AFTER_FETCH_COMMON_INFO = "SET_IS_AFTER_FETCH_COMMON_INFO";

function setIsAfterFetchCommonInfo(bool) {
  return {
    type: SET_IS_AFTER_FETCH_COMMON_INFO,
    bool
  };
}

export const ERROR_FETCH_COMMON_INFO = "ERROR_FETCH_COMMON_INFO";

function errorFetchCommonInfo(message) {
  return {
    type: ERROR_FETCH_COMMON_INFO,
    message
  };
}

export const SET_IS_ERROR_FETCH_COMMON_INFO = "SET_IS_ERROR_FETCH_COMMON_INFO";

export function setIsErrorFetchCommonInfo(bool) {
  return {
    type: SET_IS_ERROR_FETCH_COMMON_INFO,
    bool
  };
}

export function checkAuth(history) {
  return function(dispatch) {
    dispatch(setIsFetchingCommonInfo(true));
    return fetch(/*developmentApiUrl*/ productionApiUrl + "/users/health", {
      headers: getRequestHeader()
    })
      .then(function(response) {
        if (response.ok) {
          dispatch(fetchDisposalList(history));
        } else {
          dispatch(logOut());
          dispatch(setIsFetchingCommonInfo(false));
        }
      })
      .catch(function(error) {
        const additionalMsg = "(メッセージ : " + String(error) + ", 場所 : checkAuth)"
        dispatch(setIsFetchingCommonInfo(false));
        dispatch(errorFetchCommonInfo(getFormattedMessage('communication.failed.', additionalMsg)));
      });
  };
}

export const SUCCESS_FETCH_DISPOSAL_LIST = "SUCCESS_FETCH_DISPOSAL_LIST";

function successFetchDisposalList(data) {
  return {
    type: SUCCESS_FETCH_DISPOSAL_LIST,
    data
  };
}

export function fetchDisposalList(history) {
  return function(dispatch) {
    let status;
    return fetch(/*developmentApiUrl*/ productionApiUrl + "/disposal_days/list", {
      headers: getRequestHeader()
    })
      .then(function(response) {
        status = response.status;
        return response.json();
      })
      .then(function(json) {
        if (isStatusOk(status)) {
          dispatch(successFetchDisposalList(json.data));
          dispatch(fetchUserStatus(history));
        } else if (status === 401) {
          dispatch(logOut());
          dispatch(setIsFetchingCommonInfo(false));
        } else {
          dispatch(setIsFetchingCommonInfo(false));
          dispatch(errorFetchCommonInfo(getFormattedMessage("failed.to.get.information.")));
        }
      })
      .catch(function(error) {
        const additionalMsg = "(メッセージ : " + String(error) + ", 場所 : fetchDisposalList)"
        dispatch(setIsFetchingCommonInfo(false));
        dispatch(errorFetchCommonInfo(getFormattedMessage('communication.failed.', additionalMsg)));
      });
  };
}

export const SUCCESS_FETCH_USER_STATUS = "SUCCESS_FETCH_USER_STATUS";

function successFetchUserStatus(data) {
  return {
    type: SUCCESS_FETCH_USER_STATUS,
    data
  };
}

export function fetchUserStatus(history) {
  return function(dispatch) {
    let status;
    return fetch(/*developmentApiUrl*/ productionApiUrl + "/users/status", {
      headers: getRequestHeader()
    })
      .then(function(response) {
        status = response.status;
        return response.json();
      })
      .then(function(json) {
        if (isStatusOk(status) && Number.isInteger(json.data.block_group_id)) {
          // responseがokかつblock_group_idがあればdataをstateに入れてchildrenを表示します。
          dispatch(setIsFetchingCommonInfo(false));
          dispatch(successFetchUserStatus(json.data));
          dispatch(setIsAfterFetchCommonInfo(true));
        } else if (isStatusOk(status)) {
          // responseがokの場合、dataをstateに入れて/settings/account/addressを表示します。
          dispatch(setIsFetchingCommonInfo(false));
          dispatch(successFetchUserStatus(json.data));
          history.push("/settings/account/address");
          dispatch(setIsAfterFetchCommonInfo(true));
        } else if (status === 401) {
          // responseが401の場合、logoutします。
          dispatch(setIsFetchingCommonInfo(false));
          dispatch(logOut());
        } else {
          // それ以外の場合、/settings/account/addressを表示します。
          dispatch(setIsFetchingCommonInfo(false));
          history.push("/settings/account/address");
          dispatch(setIsAfterFetchCommonInfo(true));
        }
      })
      .catch(function(error) {
        const additionalMsg = "(メッセージ : " + String(error) + ", 場所 : fetchUserStatus)"
        // modalを出します。
        dispatch(setIsFetchingCommonInfo(false));
        dispatch(errorFetchCommonInfo(getFormattedMessage('communication.failed.', additionalMsg)));
      });
  };
}

export const LOGIN_REQUEST = "LOGIN_REQUEST";

function requestLogin() {
  return {
    type: LOGIN_REQUEST
  };
}

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

function successLogin(token, client, uid) {
  Storage.remove("tmpData");
  Storage.set("isRegistered", true);
  Storage.set("Access-Token", token);
  Storage.set("Client", client);
  Storage.set("Uid", uid);
  return {
    type: LOGIN_SUCCESS,
    token,
    client,
    uid
  };
}

export const LOGIN_FAILURE = "LOGIN_FAILURE";

function failureLogin(value) {
  return {
    type: LOGIN_FAILURE,
    message: value
  };
}

export function login(values) {
  return function(dispatch) {
    dispatch(requestLogin());

    let status;
    let accessToken;
    let client;
    return fetch(
      /*developmentApiUrl*/ productionApiUrl + "/auth/sign_in",
      {
        method: "POST",
        headers: getRequestHeader(),
        body: JSON.stringify({
          email: values.email,
          password: values.password
        })
      }
    )
      .then(function(response) {
        status = response.status;
        accessToken = response.headers.get("access-token");
        client = response.headers.get("client");
        return response.json();
      })
      .then(function(json) {
        if (isStatusOk(status)) {
          dispatch(successLogin(accessToken, client, json.data.uid));
        } else {
          dispatch(failureLogin(getFormattedMessage('incorrect.email.or.password.')));
        }
      })
      .catch(function() {
        dispatch(failureLogin(getFormattedMessage('communication.failed.')));
      });
  };
}

export const SET_IS_REGISTERED = "SET_IS_REGISTERED";

export function setIsRegistered(isRegistered) {
  return {
    type: SET_IS_REGISTERED,
    isRegistered
  };
}

export const SET_TOKEN = "SET_TOKEN";

export function setToken(token, client, uid) {
  return {
    type: SET_TOKEN,
    token,
    client,
    uid
  };
}

export const SET_IS_LOGGEDIN = "SET_IS_LOGGEDIN";

export function setIsLoggedIn(isLoggedIn) {
  return {
    type: SET_IS_LOGGEDIN,
    isLoggedIn
  };
}

export const REQUEST_SEND_MAIL_OF_RESET_PASSWORD =
  "REQUEST_SEND_MAIL_OF_RESET_PASSWORD";

function requestSendMailOfResetPassword() {
  return {
    type: REQUEST_SEND_MAIL_OF_RESET_PASSWORD
  };
}

export const SUCCESS_SEND_MAIL_OF_RESET_PASSWORD =
  "SUCCESS_SEND_MAIL_OF_RESET_PASSWORD";

function successSendMailOfResetPassword(json) {
  return {
    type: SUCCESS_SEND_MAIL_OF_RESET_PASSWORD,
    message: json.message
  };
}

export const ERROR_SEND_MAIL_OF_RESET_PASSWORD =
  "ERROR_SEND_MAIL_OF_RESET_PASSWORD";

function errorSendMailOfResetPassword(value) {
  return {
    type: ERROR_SEND_MAIL_OF_RESET_PASSWORD,
    message: value.message
  };
}

export function sendMailOfResetPassword(values) {
  return function(dispatch) {
    dispatch(requestSendMailOfResetPassword());

    let status;
    return fetch(productionApiUrl + "/auth/password", {
      method: "POST",
      headers: getRequestHeader(),
      body: JSON.stringify({
        email: values.email,
        // redirect_url: "http://localhost:3000/reset_password"
        redirect_url: productionBuildUrl + "/reset_password"
      })
    })
      .then(function(response) {
        status = response.status;
        return response.json();
      })
      .then(function(json) {
        if (isStatusOk(status)) {
          dispatch(successSendMailOfResetPassword({message: getFormattedMessage('the.password.reset.email.has.been.sent.successfully.', values.email)}));
        } else {
          dispatch(errorSendMailOfResetPassword({message: getFormattedMessage('unable.to.find.user.with.email', values.email)}));
        }
      })
      .catch(function() {
        dispatch(errorSendMailOfResetPassword({ message: getFormattedMessage('communication.failed.') }));
      });
  };
}

export const SET_IS_LOGIN_ERROR = "SET_IS_LOGIN_ERROR";

export function setIsLoginError(bool) {
  return {
    type: SET_IS_LOGIN_ERROR,
    bool
  };
}

export const SET_ERROR_MSG_OF_AUTH_STATE = "SET_ERROR_MSG_OF_AUTH_STATE";

export function setErrorMsgOfAuthState(msg) {
  return {
    type: SET_ERROR_MSG_OF_AUTH_STATE,
    msg
  };
}

export const SET_IS_FORGET_PASSWORD_ERROR = "SET_IS_FORGET_PASSWORD_ERROR";

export function setIsForgetPasswordError(bool) {
  return {
    type: SET_IS_FORGET_PASSWORD_ERROR,
    bool
  };
}

export const LOG_OUT = "LOG_OUT";

export function logOut() {
  return function(dispatch) {
    Storage.remove("Access-Token");
    Storage.remove("Client");
    Storage.remove("Uid");
    reload();
    dispatch(setIsAfterFetchCommonInfo(false));
    dispatch({
      type: LOG_OUT
    });
  };
}

export function tryLoginAndRegisterAddress(values, history) {
  return function(dispatch) {
    dispatch(requestLogin());
    let status;
    let accessToken;
    let client;
    return fetch(/*developmentApiUrl*/ productionApiUrl + "/auth/sign_in", {
      method: "POST",
      headers: getRequestHeader(),
      body: JSON.stringify({
        email: values.email,
        password: values.password
      })
    })
      .then(function(response) {
        status = response.status;
        accessToken = response.headers.get("access-token");
        client = response.headers.get("client");
        return response.json();
      })
      .then(function(json) {
        if (isStatusOk(status)) {
          dispatch(
            registerAddress(
              values.email,
              accessToken,
              client,
              json.data.uid,
              history
            )
          );
        } else {
          dispatch(failureLogin(getFormattedMessage('incorrect.email.or.password.')));
        }
      })
      .catch(function() {
        dispatch(failureLogin(getFormattedMessage('communication.failed.')));
      });
  };
}

export const SUCCESS_REGISTER_ADDRESS = "SUCCESS_REGISTER_ADDRESS";

function successRegisterAddress(accessToken, client, uid) {
  Storage.remove("tmpData");
  Storage.set("isRegistered", true);
  Storage.set("Access-Token", accessToken);
  Storage.set("Client", client);
  Storage.set("Uid", uid);
  return {
    type: SUCCESS_REGISTER_ADDRESS,
    accessToken,
    client,
    uid
  };
}

export function registerAddress(email, accessToken, client, uid, history) {
  return function(dispatch) {
    const tmpAddress = JSON.parse(Storage.get("tmpData"))[email].tmpAddress;
    console.log("tmpAddress: " + tmpAddress);
    let addressCreateParams = {};
    tmpAddress.prefecture_id &&
      (addressCreateParams.prefecture_id = tmpAddress.prefecture_id);
    tmpAddress.authority_id &&
      (addressCreateParams.authority_id = tmpAddress.authority_id);
    tmpAddress.block_id && (addressCreateParams.block_id = tmpAddress.block_id);
    tmpAddress.street_name &&
      (addressCreateParams.street_name = tmpAddress.street_name);
    tmpAddress.house_type_id &&
      (addressCreateParams.house_type_id = tmpAddress.house_type_id);
    tmpAddress.residence_name &&
      (addressCreateParams.residence_name = tmpAddress.residence_name);
    tmpAddress.room_number &&
      (addressCreateParams.room_number = tmpAddress.room_number);
    return fetch(/*developmentApiUrl*/ productionApiUrl + "/address/create", {
      method: "POST",
      headers: getRequestHeader(accessToken, client, uid),
      body: JSON.stringify(addressCreateParams)
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(json) {
        history.push({
          pathname: "/register/ready_to_use",
          state: "fromLogin"
        });
        dispatch(setIsLoggedIn(true));
        dispatch(successRegisterAddress(accessToken, client, uid));
      })
      .catch(function() {
        history.push({
          pathname: "/register/ready_to_use",
          state: "fromLogin"
        });
        dispatch(setIsLoggedIn(true));
        dispatch(successRegisterAddress(accessToken, client, uid));
      });
  };
}

export const SET_IS_CATCH_REGISTER_ADDRESS_ERROR =
  "SET_IS_CATCH_REGISTER_ADDRESS_ERROR";

export function setIsCatchRegisterAddressError(bool) {
  return {
    type: SET_IS_CATCH_REGISTER_ADDRESS_ERROR,
    bool
  };
}

export const SET_FLAGS = "SET_FLAGS";

export function setFlags(object) {
  return {
    type: SET_FLAGS,
    object
  };
}

export const SET_VALUES = "SET_VALUES";

export function setValues(object) {
  return {
    type: SET_VALUES,
    object
  };
}

export const SET_ERRORS = "SET_ERRORS";

export function setErrors(object) {
  return {
    type: SET_ERRORS,
    object
  };
}

export const SET_VALIDATIONS = "SET_VALIDATIONS";

function setValidations(target, object) {
  return {
    type: SET_VALIDATIONS,
    target,
    object
  };
}

export function resetPassword(values) {
  return function(dispatch) {
    // バリデーションリセット
    dispatch(setValidations("resetPassword", {
      password: [],
      password_confirmation: []
    }))
    dispatch(setFlags({resettingPasswordFlag: true}));
    const queries = getUrlQueries();
    let status;
    return fetch(productionApiUrl + "/auth/password", {
      method: "PUT",
      headers: getRequestHeader(queries["access-token"], queries.client, queries.uid),
      body: JSON.stringify(values)
    })
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then(function (json) {
        if (isStatusOk(status)) {
          dispatch(setValues({msg: getFormattedMessage('the.password.was.updated.successfully.') + "\n" + getFormattedMessage('please.log.in.again.')}));
          dispatch(setFlags({successResetPasswordFlag: true}));
        } else if (status === 401) {
          dispatch(setErrors({msg: getFormattedMessage('an.error.has.occurred.please.send.an.email.again.'), tokenErrorFlagOfResetPassword: true}));
        } else {
          dispatch(setValidations("resetPassword", json.data.errors))
        }
      })
      .catch(function () {
        dispatch(setErrors({msg: getFormattedMessage('communication.failed.'), communicationFailedFlagOfResetPassword: true}));
      })
      .then(function () {
        dispatch(setFlags({resettingPasswordFlag: false}));
      })
  };
}