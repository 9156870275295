import React from "react";
import styles from "./Register.module.scss";
import { Div } from "../../components/atoms/Html/Html";
import CurrentRegisterNumber from "../../components/molecules/StepNumber/StepNumber";
import CurrentRegisterTitle from "../../components/atoms/Heading/HeadingPrimary";
import MultipurposeButton from "../../components/molecules/MultipurposeButton/MultipurposeButton";
import BackLink from "../../components/molecules/BackLink/BackLink";
import ProgressTracker from "../../components/organisms/ProgressTracker/ProgressTracker";
import { Redirect } from "react-router-dom";

const CheckMail = props => {

  function hundleClickBackLink() {
    props.changeAvailableRegisterPage({ more_information: true });
    props.history.push("/register/more_information");
    props.changeAvailableRegisterPage({ check_mail: false });
  }

  return (
    <Div className={styles.checkMailPage}>
      <BackLink handleClick={hundleClickBackLink} />
      <Div className={styles.checkMailPage__mainBlock}>
        <ProgressTracker
          currentNumber={3}
          totalNumber={3}
          text={props.intl.formatMessage({
            id: "3.check.the.confirmation.email.and.complete.the.registration"
          })}
        />
      </Div>
    </Div>
  );
};

export default CheckMail;
