import React from "react";
import style from "./Home.module.scss";
import GlobalHeader from "../../components/organisms/GlobalHeader/GlobalHeader";
import GlobalFooter from "../../components/organisms/GlobalFooter/GlobalFooter";
import Calendar from "../../components/organisms/Calendar/Calendar";
import Schedule from "../../components/organisms/Schedule/Schedule";
import { Div, Main, P, A } from "../../components/atoms/Html/Html";
import { switchCase } from "@babel/types";
import { howManyMonthsToGet } from "../../constants/constants";
import {
  thisYear,
  thisMonth,
  escapeString
} from "../../constants/functions";
import Modal from "../../components/organisms/Modal/Modal";

class Home extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    // block_group_idが数字ではない場合、/settings/account/addressにlinkします。
    if (!Number.isInteger(this.props.common.status.block_group_id)) {
      this.props.history.push("/settings/account/address");
    } else {
      this.props.setCalendarEventList([]);
      this.props.setScheduleEventList([]);
      this.props.changeCalendarDate(thisYear(), thisMonth());
      this.props.changeScheduleDate(thisYear(), thisMonth());
      this.props.fetchEventList(
        thisYear(),
        thisMonth(),
        "both",
        howManyMonthsToGet,
        this.props.common.status.block_group_id,
        true
      );
      this.props.changeCalendarIndex(0);
    }
  }

  handleClickBell() {
    this.props.history.push("/information");
  }

  handleClickFaq() {
    this.props.history.push("/faq");
  }

  handleClickSettings() {
    this.props.history.push("/settings");
  }

  handleClickNoticeButton() {
    this.props.setNoticeFlag(true);
  }

  handleClickOkButton() {
    this.props.setNoticeFlag(false);
  }

  render() {
    const leftContent = [
      { label: "bell", action: this.handleClickBell.bind(this) }
    ];
    const rightContent = [
      { label: "faq", action: this.handleClickFaq.bind(this) },
      { label: "settings", action: this.handleClickSettings.bind(this) }
    ];
    return (
      <>
        <GlobalHeader
          title={this.props.intl.formatMessage({ id: "gommit" })}
          subtitle={
            this.props.common.status.authority.name +
            this.props.common.status.block.name
          }
          leftContent={leftContent}
          rightContent={rightContent}
        />
        <Main className={style.home__main}>
          <Calendar
            calendar={this.props.home.eventList.calendar}
            date={this.props.home.date.calendar.date}
            intl={this.props.intl}
            fetchEventList={this.props.fetchEventList}
            changeCalendarDate={this.props.changeCalendarDate}
            isFetchingCalendarList={this.props.home.isFetchingCalendarList}
            isFullCalendarList={this.props.home.isFullCalendarList}
            isErrorFetchCalendarList={
              this.props.home.errors.isErrorFetchCalendarList
            }
            calendarMsg={this.props.home.errors.calendarMsg}
            setCalendarMsg={this.props.setCalendarMsg}
            setIsErrorFetchCalendarList={this.props.setIsErrorFetchCalendarList}
            blockGroupId={this.props.common.status.block_group_id}
            calendarIndex={this.props.home.calendarIndex}
            changeCalendarIndex={this.props.changeCalendarIndex}
          />
          <Div className={style.home__main__notice}>
            <P className={style.home__main__notice__text}>
              {this.props.intl.formatMessage({
                id: "based.on.information.of.local.government.website"
              })}
            </P>
            <A
              className={style.home__main__notice__button}
              onClick={this.handleClickNoticeButton.bind(this)}
            >
              {this.props.intl.formatMessage({ id: "notice" })}
            </A>
          </Div>
          <Schedule
            intl={this.props.intl}
            schedule={this.props.home.eventList.schedule}
            changeWasChecked={this.props.changeWasChecked}
            wasChecked={this.props.home.wasChecked}
            fetchEventList={this.props.fetchEventList}
            date={this.props.home.date.schedule.date}
            isFetchingScheduleList={this.props.home.isFetchingScheduleList}
            isFullScheduleList={this.props.home.isFullScheduleList}
            scheduleMsg={this.props.home.errors.scheduleMsg}
            isErrorFetchScheduleList={
              this.props.home.errors.isErrorFetchScheduleList
            }
            changeScheduleDate={this.props.changeScheduleDate}
            setScheduleMsg={this.props.setScheduleMsg}
            setIsErrorFetchScheduleList={this.props.setIsErrorFetchScheduleList}
            blockGroupId={this.props.common.status.block_group_id}
            conversionTable={this.props.common.disposalList.conversion_table}
          />
        </Main>
        <GlobalFooter
          intl={this.props.intl}
          pathname={this.props.history.location.pathname}
        />
        {(() => {
          if (this.props.home.noticeFlag) {
            const noticeText = (
              <Div escapeText={escapeString(this.props.common.disposalList.authority_info.comment)} />
            );
            return (
              <Modal
                type="choice"
                component={
                  <Div className={style.home__noticeModal}>{noticeText}</Div>
                }
                choiceList={[
                  {
                    label: this.props.intl.formatMessage({ id: "ok" }),
                    action: this.handleClickOkButton.bind(this)
                  }
                ]}
              />
            );
          }
        })()}
      </>
    );
  }
}

export default Home;
