import robotIcon from "../assets/images/icon_robot_body_japanese.svg";

const ja_JP = {
  'log.in':'ログイン',
  'already.use.gommit?':'すでにGOMMITを使っていますか？',
  '1.select.your.region': '1. 住所を選択してください',
  'use.the.other.region':'別の住所を使用する',
  'dont.you.have.an.account?':'まだアカウントを持っていませんか？',
  'sign.up':'新規登録',
  'email':'Email',
  'password':'パスワード',
  'login':'ログイン',
  'forget.password':'パスワード再発行',
  'getting.your.location.data':'位置情報を取得中…',
  '2.input.more.information': '2. 追加の情報を入力してください',
  'retry': '再試行',
  'input.manually': '情報を手入力する',
  'prefecture': '都道府県',
  'city': '市区町村',
  'region': '区画',
  'address': '続きの住所',
  'type.of.residence': '物件種別',
  'residence.name': '物件名',
  'room.number': '号室',
  'your.mail': 'Email',
  'login.password': 'パスワード',
  'required': '必須',
  'register': '新規登録',
  'upcoming.schedule':'次のゴミ出し予定',
  'cancel':'キャンセル', 
  'trash.name':'名称',
  'type':'ゴミ種別',
  'next.collection.date':'次回収集日',
  'validation.invalid.email':'メールアドレスが正しくありません。',
  'validation.required':'必須項目です。',
  'please.select':'選択してください',
  '3.check.the.confirmation.email.and.complete.the.registration':'3. 仮登録メールを送信しました。内容をチェックして登録を完成させましょう',
  'check.mailbox':'メールを確認する',
  'start':'始める',
  'ready.to.use.gommit!':'これで準備完了です\nさあ始めましょう！',
  'registering':'登録中…',
  'connecting':'通信中…',
  'back.to.login':'ログインに戻る',
  'reset.password':'パスワード再設定',
  'ok':'OK',
  'home': 'ホーム',
  'gommit': "ごみっとくん",
  'ai.helps.you': 'AI生活コンシェルジュ',
  'reward': '特典',
  'how.to.dispose': 'ごみの出し方',
  'dictionary': '分別辞典',
  'oversized': '粗大ごみ相談',
  'burnable.trash': '燃えるごみ',
  'unburnable.trash': '燃えないごみ',
  'harmful.trash': '有害ゴミ',
  'oversized.trash': '粗大ごみ',
  'plastic': 'プラスチック',
  'books.magazines': '本・雑誌',
  'cardboard': 'ダンボール',
  'milk.carton': '紙パック',
  'paper.newspaper': '古紙・新聞紙',
  'cans.bottles': '缶・ビン',
  'metals': '金属ゴミ',
  'clothes': '古着',
  'recyclable.trash': '資源ゴミ',
  'get.again': 'もう一度取得する',
  'how.to.dispose.of.tab': '出し方',
  'items': '対象物',
  'notes': '備考',
  'see.detail': '詳細を見る',
  'faq': 'よくある質問',
  'information': 'お知らせ',
  'oversized.consultation': '粗大ごみ相談',
  'you.cannot.dispose.oversized.trashes.on.general.ways' : '粗大ごみは通常のゴミ出し方法で捨てることができません',
  'type.your.text': 'テキストを入力してください',
  'enter.the.name.of.the.trash.you.want.to.check': 'こんにちは、ごみっとくんです。\n' +
      'ごみの出し方について案内するよ。\n' +
      '捨てたいごみの名前を教えてね。\n' +
      '例：カイロ、傘、ソファー',
  'settings':'設定',
  'account':'アカウント',
  'notifications':'通知',
  'language':'言語',
  'sns.integration':'SNS連携',
  'save': '保存',
  'select.language':'言語選択',
  'english': '英語',
  'japanese': '日本語',
  'reward.programs': '特典',
  'total.points': '現在のポイント',
  'start.reward':'スタート',
  'special.reward':'スペシャル\n特典',
  'password.confirmation':'パスワード確認',
  'please.enter.a.password.with.at.least.6.characters':'パスワードは6文字以上で入力してください。',
  'logout':'ログアウト',
  'based.on.information.of.local.government.website': '自治体のサイト情報を元に掲載しています',
  'notice':'コロナ対策注意事項',
  'search':'検索',
  'definition.of.oversized.garbage': '粗大ごみの定義',
  'how.to.dispose.of.oversized.garbage':'粗大ごみの捨て方',
  'ticket.prices': '粗大ごみ券価格（収集用）※目安',
  'dispose.step.of.oversized.garbage': '1.受付センターで申し込む\n' +
      '2.有料粗大ゴミ処理券を指定の取扱所で購入\n' +
      '3.指定日にゴミを出す',
  'see.on.the.local.government.website': '自治体のHPで見る',
  'not.found.': '見つかりませんでした。',
  'plain.address': '住所',
  'invisible': '非表示',
  'return': '戻る',
  'upcoming': 'ただいま準備中です。',
  'apartment': '集合住宅',
  'house': '戸建',
  'failed.to.get.information.': '情報の取得に失敗しました。',
  'communication.failed.': '通信に失敗しました。{v1}',
  'failed.to.get.your.location.': '位置情報の取得に失敗しました。',
  'not.compatible.with.your.area.': 'お住いの地域には対応していません。',
  'the.password.was.updated.successfully.': 'パスワードの更新に成功しました。',
  'the.address.was.successfully.updated.': '住所の更新に成功しました。',
  'failed.to.get.event.': 'イベントの取得に失敗しました。',
  'failed.to.get.list.': 'リストの取得に失敗しました。',
  'incorrect.email.or.password.': 'Emailまたはパスワードが間違っています。',
  'failed.to.get.faq.': 'よくある質問の取得に失敗しました。',
  'mail.token.error': 'このメールアドレスは仮登録中です。\n{v1} までこのメールアドレスではご登録できません。',
  'january': '1月',
  'february': '2月',
  'march': '3月',
  'april': '4月',
  'may': '5月',
  'june': '6月',
  'july': '7月',
  'august': '8月',
  'september': '9月',
  'october': '10月',
  'november': '11月',
  'december': '12月',
  'date1': '{v1}年{v2}月',
  'sunday': '日曜日',
  'monday': '月曜日',
  'tuesday': '火曜日',
  'wednesday': '水曜日',
  'thursday': '木曜日',
  'friday': '金曜日',
  'saturday': '土曜日',
  'week1': '{v1}',
  'date2': '{v1}月{v3}日',
  'week2': '({v1})',
  'today': '今日',
  'robot.icon': robotIcon,
  'click.here.for.how.to.put.out': '{v1}の出し方はこちら',
  'next': '次回',
  'rough.address': '{v1}{v2}{v3}',
  'the.password.reset.email.has.been.sent.successfully.': '\'{v1}\' にパスワード再設定の案内が送信されました。',
  'unable.to.find.user.with.email': 'メールアドレス \'{v1}\' のユーザーが見つかりません。',
  'please.log.in.again.': '再度ログインをお願いします。',
  'an.error.has.occurred.please.send.an.email.again.': 'エラーが発生しました。\n再度メール送信からお願いします。',
  'news': 'ごみニュース',
};
export default ja_JP;