import React from "react";

export const Div = props => (
  <div
    className={props.className}
    id={props.id}
    style={props.style}
    onClick={props.onClick}
    data-type={props.dataType}
    onScroll={props.onScroll}
    dangerouslySetInnerHTML={
      props.escapeText ? { __html: props.escapeText } : null
    }
  >
    {props.children}
  </div>
);

export const P = props => <p className={props.className}>{props.children}</p>;

export const A = props => (
  <a
    className={props.className}
    onClick={props.onClick}
    data-value={props.dataValue}
    href={props.href}
    target={props.target}
    data-id={props.dataId}
  >
    {props.children}
  </a>
);

export const Header = props => (
  <header className={props.className}>{props.children}</header>
);

export const Img = props => <img className={props.className} src={props.src} />;

export const H1 = props => (
  <h1 className={props.className}>{props.children}</h1>
);

export const H2 = props => (
  <h2 className={props.className}>{props.children}</h2>
);

export const H3 = props => (
  <h3 className={props.className}>{props.children}</h3>
);

export const Label = props => (
  <label className={props.className} for={props.for}>
    {props.children}
  </label>
);

export const Option = props => (
  <option className={props.className} value={props.value} key={props.key}>
    {props.children}
  </option>
);

export const Span = props => (
  <span className={props.className}>{props.children}</span>
);

export const Li = props => (
  <li
    className={props.className}
    key={props.key}
    style={props.style}
    name={props.name}
  >
    {props.children}
  </li>
);

export const Ul = props => (
  <ul className={props.className} id={props.id}>
    {props.children}
  </ul>
);

export const Button = props => (
  <button
    data-value={props.dataValue}
    type={props.type}
    className={props.className}
    onClick={props.onClick}
    disabled={props.disabled}
  >
    {props.children}
  </button>
);

export const Footer = props => (
  <footer className={props.className}>{props.children}</footer>
);

export const Main = props => (
  <main className={props.className}>{props.children}</main>
);

export const Table = props => (
  <table className={props.className}>{props.children}</table>
);

export const Tbody = props => (
  <tbody className={props.className}>{props.children}</tbody>
);

export const Tr = props => (
  <tr className={props.className}>{props.children}</tr>
);

export const Th = props => (
  <th className={props.className}>{props.children}</th>
);

export const Td = props => (
  <td className={props.className}>{props.children}</td>
);

export const Select = props => (
  <select
    className={props.className}
    name={props.name}
    id={props.id}
    defaultValue={props.defaultValue}
    onChange={props.onChange}
  >
    {props.children}
  </select>
);
