import { store } from "../../index";
import burnables from "../../assets/images/icon_fire.svg";
import nonBurnables from "../../assets/images/icon_unburnable.svg";
import hazardousWaste from "../../assets/images/icon_harmful.svg";
import oversizedGarbage from "../../assets/images/icon_oversized_garbage.svg";
import plastic from "../../assets/images/icon_plastic.svg";
import books from "../../assets/images/icon_books_magazines.svg";
import cardboard from "../../assets/images/icon_cardboard.svg";
import milkCartons from "../../assets/images/icon_milk.svg";
import newspapers from "../../assets/images/icon_newspapers_other_papers.svg";
import cans from "../../assets/images/icon_cans.svg";
import metalItems from "../../assets/images/icon_metal_items.svg";
import oldClothes from "../../assets/images/icon_old_clothes.svg";
import recyclables from "../../assets/images/icon_recyclables.svg";
import plasticBottles from "../../assets/images/icon_plastic_bottles.svg";
import bottles from "../../assets/images/icon_bottles.svg";
import treeBranches from "../../assets/images/icon_tree_branches_grass_and_leaves.svg";
import usedCookingOil from "../../assets/images/icon_used_cooking_oil.svg";
import glassProducts from "../../assets/images/icon_glass_products.svg";
import ceramics from "../../assets/images/icon_ceramics.svg";
import harmfulGarbage from "../../assets/images/icon_harmful_garbage.svg";
import plasticProducts from "../../assets/images/icon_plastic_products.svg";
import smallAppliances from "../../assets/images/icon_small_appliances.svg";
import uncollectables from "../../assets/images/icon_uncollectables.svg";
import otherItems from "../../assets/images/icon_other_items.svg";
import homeAppliances from "../../assets/images/icon_home_appliances_electronics_product.svg";
import { IntlProvider } from "react-intl";
import { languageObject } from "../components/App/App";

// ステータスコードの値を判別する
export const isStatusOk = statusCode => {
  if (200 <= statusCode && 299 >= statusCode) {
    return true;
  } else {
    return false;
  }
};

//objectかどうか判別します。
export const isObject = obj => {
  return Object.prototype.toString.call(obj) === "[object Object]";
};

// apiのheaderを取得します。
export const getRequestHeader = (
  accessToken = store.getState().auth.accessToken,
  client = store.getState().auth.client,
  uid = store.getState().user.uid
) => {
  let headers = {};
  headers["Content-Type"] = "application/json; charset=utf-8";
  accessToken && (headers["Access-Token"] = accessToken);
  client && (headers["Client"] = client);
  uid && (headers["Uid"] = uid);
  const lang = store.getState().user.language;
  switch (lang) {
    case "en":
      headers["Accept-Language"] = "en-US, ja";
      break;
    case "ja":
      headers["Accept-Language"] = "ja, en-US";
      break;
    case "ko":
    case "zh":
      headers["Accept-Language"] = lang + ", ja, en-US";
      break;
    case "vi":
    case "es":
    case "fr":
    case "pt":
    case "th":
    case "tl":
      headers["Accept-Language"] = lang + ", en-US, ja";
      break;
    default:
      headers["Accept-Language"] = "ja, en-US";
  }
  return headers;
};

// languageを取得します。
export const getLanguage = () => {
  return store.getState().user.language;
};

// 言語別のフォント設定 / 切り替え
export const changeFontSetting = lang => {
  const root = document.documentElement;
  let property = {};

  switch (lang) {
    case "en":
    case "vi":
    case "es":
    case "fr":
    case "pt":
    case "th":
    case "tl":
      property = {
        font_family_display:
          '"SF Pro Display", -apple-system, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, sans-serif',
        font_family_text:
          '"SF Pro Text", -apple-system, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        font_size_heading_l: 3.0 + "rem",
        font_size_heading_m: 2.1 + "rem",
        font_size_heading_s: 1.7 + "rem",
        font_size_nav: 1.0 + "rem",
        font_size_text: 1.5 + "rem",
        font_size_label: 1.5 + "rem",
        font_size_label_s: 1.2 + "rem",
        font_size_button: 1.8 + "rem",
        font_size_weeks: 1.4 + "rem",
        font_size_date: 1.3 + "rem",
        font_size_question: 1.4 + "rem",
        font_size_search_button: 1.7 + "rem",
        font_size_dictionary_index: 2.3 + "rem",
        font_weight_heading: 600,
        font_weight_list: 500,
        font_weight_text: 500,
        font_weight_regular: 400,
        font_weight_item: 500,
        line_height_l: 1.6,
        line_height_m: 1.4,
        line_height_s: 1.2
      };
      break;
    case "ja":
    case "zh":
    case "ko":
      property = {
        font_family_display:
          '"Hiragino Kaku Gothic Pro", "SF Pro Display", -apple-system, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, sans-serif',
        font_family_text:
          '"Hiragino Kaku Gothic Pro", "SF Pro Text", -apple-system, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        font_size_heading_l: 2.5 + "rem",
        font_size_heading_m: 1.9 + "rem",
        font_size_heading_s: 1.5 + "rem",
        font_size_nav: 0.9 + "rem",
        font_size_text: 1.4 + "rem",
        font_size_label: 1.3 + "rem",
        font_size_label_s: 1.1 + "rem",
        font_size_button: 1.6 + "rem",
        font_size_weeks: 1.2 + "rem",
        font_size_date: 1.2 + "rem",
        font_size_question: 1.3 + "rem",
        font_size_search_button: 1.4 + "rem",
        font_size_dictionary_index: 1.5 + "rem",
        font_weight_heading: 600,
        font_weight_list: 600,
        font_weight_text: 400,
        font_weight_regular: 300,
        font_weight_item: 600,
        line_height_l: 1.8,
        line_height_m: 1.6,
        line_height_s: 1.4
      };
  }

  switch (lang) {
    case "en":
    case "vi":
    case "es":
    case "fr":
    case "pt":
    case "th":
    case "tl":
    case "zh":
    case "ko":
      property.search_list_margin_bottom = 5 + "px";
      property.chat_link_display = "none";
      break;
    case "ja":
      property.search_list_margin_bottom = 10 + "px";
      property.chat_link_display = "inherit";
  }
  root.style.setProperty("--font_family_display", property.font_family_display);
  root.style.setProperty("--font_family_text", property.font_family_text);
  root.style.setProperty("--font_size_heading_l", property.font_size_heading_l);
  root.style.setProperty("--font_size_heading_m", property.font_size_heading_m);
  root.style.setProperty("--font_size_heading_s", property.font_size_heading_s);
  root.style.setProperty("--font_size_nav", property.font_size_nav);
  root.style.setProperty("--font_size_text", property.font_size_text);
  root.style.setProperty("--font_size_label", property.font_size_label);
  root.style.setProperty("--font_size_label_s", property.font_size_label_s);
  root.style.setProperty("--font_size_button", property.font_size_button);
  root.style.setProperty("--font_size_date", property.font_size_date);
  root.style.setProperty("--font_size_weeks", property.font_size_weeks);
  root.style.setProperty("--font_size_question", property.font_size_question);
  root.style.setProperty(
    "--font_size_search_button",
    property.font_size_search_button
  );
  root.style.setProperty(
    "--font_size_dictionary_index",
    property.font_size_dictionary_index
  );
  root.style.setProperty("--font_weight_heading", property.font_weight_heading);
  root.style.setProperty("--font_weight_list", property.font_weight_list);
  root.style.setProperty("--font_weight_text", property.font_weight_text);
  root.style.setProperty("--font_weight_regular", property.font_weight_regular);
  root.style.setProperty("--font_weight_item", property.font_weight_item);
  root.style.setProperty("--line_height_l", property.line_height_l);
  root.style.setProperty("--line_height_m", property.line_height_m);
  root.style.setProperty("--line_height_s", property.line_height_s);
  root.style.setProperty("--search_list_margin_bottom", property.search_list_margin_bottom);
  root.style.setProperty("--chat_link_display", property.chat_link_display);
};

// 本日の日付をyyyy-mm-dd形式で取得
export const today = () => {
  const today_obj = new Date();
  return (
    String(today_obj.getFullYear()) +
    "/" +
    ("0" + (today_obj.getMonth() + 1)).slice(-2) +
    "/" +
    ("0" + today_obj.getDate()).slice(-2)
  );
};

// 年を取得
export const thisYear = (date = today()) => {
  const today_obj = new Date(date);
  return today_obj.getFullYear();
};

// 月を取得
export const thisMonth = (date = today()) => {
  const today_obj = new Date(date);
  return today_obj.getMonth() + 1;
};

// 曜日(数値)を取得
export const getDay = (year, month, day) => {
  const date = new Date(year + "/" + month + "/" + day);
  return date.getDay();
};

//Dateオブジェクトをyyyy-mm-dd形式に変換
export const dateToString = date => {
  return (
    String(date.getFullYear()) +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2)
  );
};

// yearとmonthが重複しない配列を返します。
export function returnsAUniqueArray(stateList, actionList) {
  const filteredStateList = stateList.filter(function(elementOfState) {
    const filteredActionList = actionList.filter(function(elementOfAction) {
      return (
        elementOfState.year === elementOfAction.year &&
        elementOfState.month === elementOfAction.month
      );
    });
    if (filteredActionList.length === 0) {
      return elementOfState;
    }
  });
  return filteredStateList.concat(actionList);
}

// 月を計算します。
export function calcMonth(year, month, number) {
  month += number;
  if (month > 12) {
    year += Math.floor(month / 12);
    month = month % 12;
  } else if (month < 1) {
    month = month * -1 + 12;
    year -= Math.floor(month / 12);
    month = 12 - (month % 12);
  }
  const dateList = {
    month,
    year
  };
  return dateList;
}

//stateを返します。
export const getState = () => {
  return store.getState();
};

// クエリがあればobjectで返します。
export const getUrlQueries = () => {
  const queryStr = decodeURIComponent(window.location.search.slice(1));
  let queries = {};
  if (!queryStr) {
    return queries;
  }
  queryStr.split("&").forEach(function(queryStr) {
    const queryArr = queryStr.split("=");
    queries[queryArr[0]] = queryArr[1];
  });
  return queries;
};

// storage処理をします。
export class Storage {
  static get(key) {
    return localStorage.getItem(key);
  }
  static set(key, values) {
    localStorage.setItem(key, values);
  }
  static remove(key) {
    localStorage.removeItem(key);
  }
}

// ゴミの種類のアイコンを返します。
export const getTrashTypeIcon = type => {
  switch (type) {
    case "burnables":
      return burnables;
    case "non-burnables":
      return nonBurnables;
    case "hazardous waste":
      return hazardousWaste;
    case "oversized garbage":
      return oversizedGarbage;
    case "plastic":
      return plastic;
    case "books & magazines":
      return books;
    case "cardboard":
      return cardboard;
    case "milk cartons":
      return milkCartons;
    case "newspapers & other papars":
      return newspapers;
    case "cans":
      return cans;
    case "plastic bottles":
      return plasticBottles;
    case "metal items":
      return metalItems;
    case "old clothes":
      return oldClothes;
    case "recyclables":
      return recyclables;
    case "recyclable-1":
      return recyclables;
    case "recyclable-2":
      return recyclables;
    case "recyclable-a":
      return recyclables;
    case "recyclable-b":
      return recyclables;
    case "recyclable items with value":
      return recyclables;
    case "bottles":
      return bottles;
    case "tree branches, grass and leaves":
      return treeBranches;
    case "used cooking oil":
      return usedCookingOil;
    case "glass products":
      return glassProducts;
    case "ceramics":
      return ceramics;
    case "harmful garbage":
      return harmfulGarbage;
    case "plastic products":
      return plasticProducts;
    case "small appliances":
      return smallAppliances;
    case "uncollectables":
      return uncollectables;
    case "other items collected by other organizations":
      return otherItems;
    case "home appliances, electronics product":
      return homeAppliances;
    default:
      return "";
  }
};

export const arrangeString = string => {
  let filtered = string;
  filtered = hiraganaToKatakana(filtered);
  filtered = toHalfSize(hiraganaToKatakana(filtered));
  return convertHalfToFull(filtered);
};

//ひらがなをカタカナに変換する
function hiraganaToKatakana(string) {
  return string.replace(/[\u3041-\u3096]/g, function(str) {
    const chr = str.charCodeAt(0) + 0x60;
    return String.fromCharCode(chr);
  });
}

//全角英数字記号を半角にする
function toHalfSize(string) {
  const half = string.replace(/[！-～]/g, function(str) {
    return String.fromCharCode(str.charCodeAt(0) - 0xfee0);
  });
  return half
    .replace(/”/g, '"')
    .replace(/‘/g, "`")
    .replace(/’/g, "'")
    .replace(/　/g, " ")
    .replace(/〜/g, "~")
    .replace(/、/g, "､")
    .replace(/。/g, "｡")
    .replace(/「/g, "｢")
    .replace(/」/g, "｣")
    .replace(/｟/g, "⦅")
    .replace(/｠/g, "⦆")
    .replace(/￠/g, "¢")
    .replace(/￡/g, "£")
    .replace(/￢/g, "¬")
    .replace(/￣/g, "¯")
    .replace(/￤/g, "¦")
    .replace(/￥/g, "¥")
    .replace(/￦/g, "₩")
    .replace(/│/g, "￨");
}

//半角カタカナを全角カタカナに変換する
function convertHalfToFull(string) {
  const halfStr = [
    "ｧ",
    "ｨ",
    "ｩ",
    "ｪ",
    "ｫ",
    "ｬ",
    "ｭ",
    "ｮ",
    "ｯ",
    "ｰ",
    "ｳﾞ",
    "ｶﾞ",
    "ｷﾞ",
    "ｸﾞ",
    "ｹﾞ",
    "ｺﾞ",
    "ｻﾞ",
    "ｼﾞ",
    "ｽﾞ",
    "ｾﾞ",
    "ｿﾞ",
    "ﾀﾞ",
    "ﾁﾞ",
    "ﾂﾞ",
    "ﾃﾞ",
    "ﾄﾞ",
    "ﾊﾞ",
    "ﾋﾞ",
    "ﾌﾞ",
    "ﾍﾞ",
    "ﾎﾞ",
    "ﾊﾟ",
    "ﾋﾟ",
    "ﾌﾟ",
    "ﾍﾟ",
    "ﾎﾟ",
    "ｱ",
    "ｲ",
    "ｳ",
    "ｴ",
    "ｵ",
    "ｶ",
    "ｷ",
    "ｸ",
    "ｹ",
    "ｺ",
    "ｻ",
    "ｼ",
    "ｽ",
    "ｾ",
    "ｿ",
    "ﾀ",
    "ﾁ",
    "ﾂ",
    "ﾃ",
    "ﾄ",
    "ﾅ",
    "ﾆ",
    "ﾇ",
    "ﾈ",
    "ﾉ",
    "ﾊ",
    "ﾋ",
    "ﾌ",
    "ﾍ",
    "ﾎ",
    "ﾏ",
    "ﾐ",
    "ﾑ",
    "ﾒ",
    "ﾓ",
    "ﾔ",
    "ﾕ",
    "ﾖ",
    "ﾗ",
    "ﾘ",
    "ﾙ",
    "ﾚ",
    "ﾛ",
    "ﾜ",
    "ｦ",
    "ﾝ"
  ];
  const fullStr = [
    "ァ",
    "ィ",
    "ゥ",
    "ェ",
    "ォ",
    "ャ",
    "ュ",
    "ョ",
    "ッ",
    "ー",
    "ヴ",
    "ガ",
    "ギ",
    "グ",
    "ゲ",
    "ゴ",
    "ザ",
    "ジ",
    "ズ",
    "ゼ",
    "ゾ",
    "ダ",
    "ヂ",
    "ヅ",
    "デ",
    "ド",
    "バ",
    "ビ",
    "ブ",
    "ベ",
    "ボ",
    "パ",
    "ピ",
    "プ",
    "ペ",
    "ポ",
    "ア",
    "イ",
    "ウ",
    "エ",
    "オ",
    "カ",
    "キ",
    "ク",
    "ケ",
    "コ",
    "サ",
    "シ",
    "ス",
    "セ",
    "ソ",
    "タ",
    "チ",
    "ツ",
    "テ",
    "ト",
    "ナ",
    "ニ",
    "ヌ",
    "ネ",
    "ノ",
    "ハ",
    "ヒ",
    "フ",
    "ヘ",
    "ホ",
    "マ",
    "ミ",
    "ム",
    "メ",
    "モ",
    "ヤ",
    "ユ",
    "ヨ",
    "ラ",
    "リ",
    "ル",
    "レ",
    "ロ",
    "ワ",
    "ヲ",
    "ン"
  ];
  let tmpStr = string;
  for (var i = 0; i < halfStr.length; i++) {
    tmpStr = tmpStr.replace(new RegExp(halfStr[i], "g"), fullStr[i]);
  }
  return tmpStr;
}

// ¥nを\nに置き換えます。
export const escapeString = string => {
  return string.replace(/¥n/g, "\n");
};

// ページをリロードします。
export const reload = () => {
  window.location.reload();
};

// intlを取得します。
export const getIntl = () => {
  const lang = getLanguage();
  const intlProvider = new IntlProvider(
    { locale: lang, messages: languageObject[lang] },
    {}
  );
  return  intlProvider.getChildContext().intl;
};

// 翻訳されたメッセージを取得します。
export const getFormattedMessage = (id, v1, v2, v3, v4) => {
  const intl = getIntl();
  return intl.formatMessage({id}, {v1, v2, v3, v4});
};

// Dateを整形します。
export const arrangeDate = (date, format) => {
  format = format.replace(/yyyy/g, date.getFullYear());
  format = format.replace(/M/g, (date.getMonth() + 1));
  format = format.replace(/d/g, (date.getDate()));
  return format;
};