import React from "react";
import ItemButton from "../../molecules/ItemButton/ItemButton";
import { Img } from "../../atoms/Html/Html";
import style from "./SimpleButtonList.module.scss";
import { Link } from "react-router-dom";
import { getTrashTypeIcon, getFormattedMessage } from "../../../constants/functions";

const SimpleButtonList = props => {
  function handleClick(e) {
    props.history.push({
      pathname: "/how_to_dispose/details",
      state: { trashType: e.currentTarget.dataset.type }
    });
  }
  return (
    <>
      {props.howToDisposeList.map(function(value) {
        return (
          <ItemButton
            type={value.type}
            rightContentType="arrow"
            function={handleClick}
            text={props.conversionTable[value.type]}
            src={getTrashTypeIcon(value.type)}
          />
        );
      })}
      <Link className={style.itemBottonBlock__link} to="/ai_helps_you">
        <Img
          src={getFormattedMessage("robot.icon")}
          className={style.itemBottonBlock__link__robotIcon}
        />
      </Link>
    </>
  );
};

export default SimpleButtonList;
