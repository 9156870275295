import React from "react";
import style from "./Settings.module.scss";
import GlobalHeader from "../../components/organisms/GlobalHeader/GlobalHeader";
import GlobalFooter from "../../components/organisms/GlobalFooter/GlobalFooter";
import { Div, Main } from "../../components/atoms/Html/Html";
import ScrollView from "../../components/atoms/ScrollView/ScrollView";
import ActionBoxGroup from "../../components/organisms/ActionBoxGroup/ActionBoxGroup";
import {
  houseTypeOptions
} from "../../constants/constants";
import * as Yup from "yup";
import Modal from "../../components/organisms/Modal/Modal";
import {
  reload
} from "../../constants/functions";

const Account = props => {
  return (
    <>
      {(() => {
        if (props.history.location.pathname === "/settings/account") {
          return (
            <AccountTop
              common={props.common}
              history={props.history}
              intl={props.intl}
            />
          );
        } else if (
          props.history.location.pathname === "/settings/account/address"
        ) {
          return (
            <Address
              common={props.common}
              history={props.history}
              intl={props.intl}
              fetchPrefectures={props.fetchPrefectures}
              fetchAuthorities={props.fetchAuthorities}
              fetchBlocks={props.fetchBlocks}
              {...props.account.address}
              modifyFormValues={props.modifyFormValues}
              setAddressOptions={props.setAddressOptions}
              updateAddress={props.updateAddress}
              resetValidationMessages={props.resetValidationMessages}
              setAddressMsg={props.setAddressMsg}
              setIsEmptyBlocksError={props.setIsEmptyBlocksError}
              setIsSeccessUpdateAddress={props.setIsSeccessUpdateAddress}
              setIsFetchPrefecturesError={props.setIsFetchPrefecturesError}
              setIsFetchAuthoritiesError={props.setIsFetchAuthoritiesError}
              setIsFetchBlocksError={props.setIsFetchBlocksError}
              setIsErrorFetchUpdateAddress={props.setIsErrorFetchUpdateAddress}
            />
          );
        } else if (
          props.history.location.pathname === "/settings/account/email"
        ) {
          return <Email history={props.history} intl={props.intl} />;
        } else if (
          props.history.location.pathname === "/settings/account/password"
        ) {
          return (
            <Password
              history={props.history}
              intl={props.intl}
              updatePassword={props.updatePassword}
              {...props.account.password}
              setPasswordMsg={props.setPasswordMsg}
              setIsSuccessUpdatePassword={props.setIsSuccessUpdatePassword}
              setIsErrorFetchUpdatePassword={
                props.setIsErrorFetchUpdatePassword
              }
              resetPasswordValidationMessages={
                props.resetPasswordValidationMessages
              }
            />
          );
        }
      })()}
    </>
  );
};

class AccountTop extends React.Component {
  constructor(props) {
    super(props);
  }

  handleClickArrow() {
    this.props.history.push("/settings");
  }

  render() {
    const leftContent = [
      { label: "arrow", action: this.handleClickArrow.bind(this) }
    ];
    const list = [
      {
        type: "link",
        body: {
          label: this.props.intl.formatMessage({ id: "plain.address" }),
          content: this.props.common.status.block.name,
          to: "/settings/account/address"
        }
      },
      // {
      //   type: "link",
      //   body: {
      //     label: this.props.intl.formatMessage({ id: "email" }),
      //     content: this.props.common.status.email,
      //     to: "/settings/account/email"
      //   }
      // },
      {
        type: "link",
        body: {
          label: this.props.intl.formatMessage({ id: "password" }),
          invisible: true,
          intl: this.props.intl,
          to: "/settings/account/password"
        }
      }
    ];
    return (
      <>
        <GlobalHeader
          title={this.props.intl.formatMessage({ id: "account" })}
          leftContent={leftContent}
          intl={this.props.intl}
        />
        <Main className={style.main}>
          <ScrollView contentContainerStyle={style.main__contentContainer}>
            <ActionBoxGroup actions={list} />
          </ScrollView>
        </Main>
        <GlobalFooter
          intl={this.props.intl}
          pathname={this.props.history.location.pathname}
        />
      </>
    );
  }
}

class Address extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    const tmpObject = {
      prefecture_id: "",
      authority_id: "",
      block_id: "",
      street_name: this.props.common.status.street_name,
      house_type_id:
        this.props.common.status.house_type === null
          ? null
          : this.props.common.status.house_type.id,
      residence_name: this.props.common.status.residence_name,
      room_number: this.props.common.status.room_number,
      prefecture_value: this.props.common.status.prefecture.id,
      authority_value: this.props.common.status.authority.id,
      block_value: this.props.common.status.block.id
    };
    this.props.modifyFormValues(tmpObject);
    this.props.fetchPrefectures();
    if (Number.isInteger(this.props.common.status.prefecture.id)) {
      this.props.fetchAuthorities(this.props.common.status.prefecture.id);
    }
    if (Number.isInteger(this.props.common.status.authority.id)) {
      this.props.fetchBlocks(
        this.props.common.status.authority.id,
        this.props.common.status.authority.district_id
      );
    }
  }

  handleClickCancel() {
    this.props.history.push("/settings/account");
    this.props.resetValidationMessages();
  }

  handleChangeForm(name, value) {
    if (name === "block_id") {
      const tmpObject = {};
      if (value !== "") {
        const filtered = this.props.addressOptions.blocks[value];
        tmpObject.block_value = String(filtered.id);
      } else {
        tmpObject.block_value = String(value);
      }
      tmpObject.block_id = String(value);
      this.props.modifyFormValues(tmpObject);
    } else if (name === "authority_id") {
      const tmpObject = {};
      if (value !== "") {
        const filtered = this.props.addressOptions.authorities[value];
        this.props.fetchBlocks(filtered.id, filtered.district_id);
        tmpObject.authority_value = String(filtered.id);
        tmpObject.district_id = Number.isInteger(filtered.district_id)
          ? String(filtered.district_id)
          : null;
      } else {
        tmpObject.authority_value = String(value);
        tmpObject.district_id = null;
      }
      tmpObject.authority_id = String(value);
      tmpObject.block_id = "";
      this.props.modifyFormValues(tmpObject);
      this.props.setAddressOptions({ blocks: [] });
    } else if (name === "prefecture_id") {
      const tmpObject = {};
      if (value !== "") {
        const filtered = this.props.addressOptions.prefectures[value];
        this.props.fetchAuthorities(filtered.id);
        tmpObject.prefecture_value = String(filtered.id);
      } else {
        tmpObject.prefecture_value = String(value);
      }
      tmpObject.prefecture_id = String(value);
      tmpObject.authority_id = "";
      tmpObject.block_id = "";
      this.props.modifyFormValues(tmpObject);
      this.props.setAddressOptions({ authorities: [] });
      this.props.setAddressOptions({ blocks: [] });
    } else {
      this.props.modifyFormValues({ [name]: value });
    }
  }

  submitMyForm = null;

  handleSubmitMyForm = e => {
    if (this.submitMyForm) {
      this.submitMyForm(e);
    }
  };
  bindSubmitForm = submitForm => {
    this.submitMyForm = submitForm;
  };

  submitData(values) {
    this.props.resetValidationMessages();
    this.props.updateAddress(values, this.props.intl);
  }

  handleClickOkOfEmptyBlocksError() {
    this.props.setAddressMsg("");
    this.props.setIsEmptyBlocksError(false);
  }

  handleClickOkOfSeccessUpdateAddress() {
    this.props.history.push("/settings/account");
    this.props.setAddressMsg("");
    this.props.setIsSeccessUpdateAddress(false);
    reload()
  }

  handleClickOkOfFetchAddress() {
    this.props.history.push("/settings/account");
    this.props.setAddressMsg("");
    if (this.props.flags.isFetchPrefecturesError) {
      this.props.setIsFetchPrefecturesError(false);
    }
    if (this.props.flags.isFetchAuthoritiesError) {
      this.props.setIsFetchAuthoritiesError(false);
    }
    if (this.props.flags.isFetchBlocksError) {
      this.props.setIsFetchBlocksError(false);
    }
  }

  handleClickRetryOfFetchAddress() {
    this.props.setAddressMsg("");
    if (this.props.flags.isFetchPrefecturesError) {
      this.props.setIsFetchPrefecturesError(false);
      this.props.fetchPrefectures();
    }
    if (this.props.flags.isFetchAuthoritiesError) {
      this.props.setIsFetchAuthoritiesError(false);
      this.props.fetchAuthorities(this.props.formValues.prefecture_value);
    }
    if (this.props.flags.isFetchBlocksError) {
      this.props.setIsFetchBlocksError(false);
      this.props.fetchBlocks(
        this.props.formValues.authority_value,
        this.props.formValues.district_id
      );
    }
  }

  handleClickOkOfErrorFetchUpdateAddress() {
    this.props.history.push("/settings/account");
    this.props.setAddressMsg("");
    this.props.setIsErrorFetchUpdateAddress(false);
  }

  handleClickRetryOfErrorFetchUpdateAddress() {
    this.handleSubmitMyForm();
    this.props.setAddressMsg("");
    this.props.setIsErrorFetchUpdateAddress(false);
  }

  render() {
    const leftContent = [
      { label: "cancel", action: this.handleClickCancel.bind(this) }
    ];
    const rightContent = [
      { label: "save", action: this.handleSubmitMyForm.bind(this) }
    ];

    const { intl } = this.props;

    function createOptions(array) {
      let tmpList;
      if (!array.length) {
        tmpList = [];
      } else {
        tmpList = array.map(function (element, index) {
          return {
            value: index,
            label: element.name
          };
        });
        tmpList.unshift({
          value: "",
          label: intl.formatMessage({ id: "please.select" })
        });
      }
      return tmpList;
    }

    const list = [
      {
        type: "select",
        body: {
          label: intl.formatMessage({ id: "prefecture" }),
          name: "prefecture_id",
          options: this.props.fetching.isFetchingPrefectures
            ? [{ value: "", label: intl.formatMessage({ id: "connecting" }) }]
            : createOptions(this.props.addressOptions.prefectures),
          isConnecting: this.props.fetching.isFetchingPrefectures
        }
      },
      {
        type: "select",
        body: {
          label: intl.formatMessage({ id: "city" }),
          name: "authority_id",
          options: this.props.fetching.isFetchingAuthorities
            ? [{ value: "", label: intl.formatMessage({ id: "connecting" }) }]
            : createOptions(this.props.addressOptions.authorities),
          isConnecting: this.props.fetching.isFetchingAuthorities,
          disabled: !this.props.addressOptions.authorities.length ? true : false
        }
      },
      {
        type: "select",
        body: {
          label: intl.formatMessage({ id: "region" }),
          name: "block_id",
          options: this.props.fetching.isFetchingBlocks
            ? [{ value: "", label: intl.formatMessage({ id: "connecting" }) }]
            : createOptions(this.props.addressOptions.blocks),
          isConnecting: this.props.fetching.isFetchingBlocks,
          disabled: !this.props.addressOptions.blocks.length ? true : false
        }
      },
      {
        type: "input",
        body: {
          label: intl.formatMessage({ id: "address" }),
          name: "street_name",
          type: "text"
        }
      },
      {
        type: "select",
        body: {
          label: intl.formatMessage({ id: "type.of.residence" }),
          name: "house_type_id",
          options: houseTypeOptions.map(element => ({ value: element.value, label: intl.formatMessage({ id: element.id }) }))
        }
      },
      {
        type: "input",
        body: {
          label: intl.formatMessage({ id: "residence.name" }),
          name: "residence_name",
          type: "text"
        }
      },
      {
        type: "input",
        body: {
          label: intl.formatMessage({ id: "room.number" }),
          name: "room_number",
          type: "text"
        }
      }
    ];

    const schema = Yup.object().shape({
      prefecture_id: Yup.string().required(
        intl.formatMessage({ id: "validation.required" })
      ),
      authority_id: Yup.string().required(
        intl.formatMessage({ id: "validation.required" })
      ),
      block_id: Yup.string().required(
        intl.formatMessage({ id: "validation.required" })
      )
    });

    let modalType, modalText, modalChoiceList;
    if (this.props.flags.isEmptyBlocksError) {
      modalType = "choice";
      modalText = this.props.msg;
      modalChoiceList = [
        {
          label: this.props.intl.formatMessage({ id: "ok" }),
          action: this.handleClickOkOfEmptyBlocksError.bind(this)
        }
      ];
    } else if (this.props.flags.isSeccessUpdateAddress) {
      modalType = "choice";
      modalText = this.props.msg;
      modalChoiceList = [
        {
          label: this.props.intl.formatMessage({ id: "ok" }),
          action: this.handleClickOkOfSeccessUpdateAddress.bind(this)
        }
      ];
    } else if (this.props.fetching.isFetchingUpdateAddress) {
      modalType = "loading";
      modalText = intl.formatMessage({ id: "connecting" });
      modalChoiceList = null;
    } else if (
      this.props.flags.isFetchPrefecturesError ||
      this.props.flags.isFetchAuthoritiesError ||
      this.props.flags.isFetchBlocksError
    ) {
      modalType = "choice";
      modalText = this.props.msg;
      modalChoiceList = [
        {
          label: this.props.intl.formatMessage({ id: "return" }),
          action: this.handleClickOkOfFetchAddress.bind(this)
        },
        {
          label: this.props.intl.formatMessage({ id: "retry" }),
          action: this.handleClickRetryOfFetchAddress.bind(this)
        }
      ];
    } else if (this.props.flags.isErrorFetchUpdateAddress) {
      modalType = "choice";
      modalText = this.props.msg;
      modalChoiceList = [
        {
          label: this.props.intl.formatMessage({ id: "ok" }),
          action: this.handleClickOkOfErrorFetchUpdateAddress.bind(this)
        },
        {
          label: this.props.intl.formatMessage({ id: "retry" }),
          action: this.handleClickRetryOfErrorFetchUpdateAddress.bind(this)
        }
      ];
    }

    return (
      <>
        <GlobalHeader
          title={this.props.intl.formatMessage({ id: "plain.address" })}
          leftContent={leftContent}
          intl={this.props.intl}
          rightContent={rightContent}
        />
        <Main className={style.main}>
          <ScrollView contentContainerStyle={style.main__contentContainer}>
            <ActionBoxGroup
              actions={list}
              bindSubmitForm={this.bindSubmitForm}
              submitData={this.submitData.bind(this)}
              form={this.props.formValues}
              handleChangeForm={this.handleChangeForm.bind(this)}
              schema={schema}
              stateErrors={this.props.validationMessages}
            />
          </ScrollView>
        </Main>
        <GlobalFooter
          intl={this.props.intl}
          pathname={this.props.history.location.pathname}
        />
        {modalType && (
          <Modal
            type={modalType}
            text={modalText}
            choiceList={modalChoiceList}
          />
        )}
      </>
    );
  }
}

class Email extends React.Component {
  constructor(props) {
    super(props);
  }

  handleClickCancel() {
    this.props.history.push("/settings/account");
  }

  handleClickSave() { }

  render() {
    const leftContent = [
      { label: "cancel", action: this.handleClickCancel.bind(this) }
    ];
    const rightContent = [
      { label: "save", action: this.handleClickSave.bind(this) }
    ];
    return (
      <>
        <GlobalHeader
          title={this.props.intl.formatMessage({ id: "email" })}
          leftContent={leftContent}
          intl={this.props.intl}
          rightContent={rightContent}
        />
        <GlobalFooter
          intl={this.props.intl}
          pathname={this.props.history.location.pathname}
        />
      </>
    );
  }
}

class Password extends React.Component {
  constructor(props) {
    super(props);
  }

  handleClickCancel() {
    this.props.history.push("/settings/account");
    this.props.resetPasswordValidationMessages();
  }

  submitMyForm = null;

  handleSubmitMyForm = e => {
    if (this.submitMyForm) {
      this.submitMyForm(e);
    }
  };
  bindSubmitForm = submitForm => {
    this.submitMyForm = submitForm;
  };

  submitData(values) {
    this.props.resetPasswordValidationMessages();
    this.props.updatePassword(values);
  }

  handleClickOkOfSuccessUpdatePassword() {
    this.props.history.push("/settings/account");
    this.props.setPasswordMsg("");
    this.props.setIsSuccessUpdatePassword(false);
  }

  handleClickOkOfErrorFetchUpdatePassword() {
    this.props.history.push("/settings/account");
    this.props.setPasswordMsg("");
    this.props.setIsErrorFetchUpdatePassword(false);
  }

  handleClickRetryOfErrorFetchUpdatePassword() {
    this.handleSubmitMyForm();
    this.props.setPasswordMsg("");
    this.props.setIsErrorFetchUpdatePassword(false);
  }

  render() {
    const leftContent = [
      { label: "cancel", action: this.handleClickCancel.bind(this) }
    ];
    const rightContent = [
      { label: "save", action: this.handleSubmitMyForm.bind(this) }
    ];
    const list = [
      {
        type: "input",
        body: {
          label: this.props.intl.formatMessage({ id: "login.password" }),
          name: "password",
          type: "password"
        }
      },
      {
        type: "input",
        body: {
          label: this.props.intl.formatMessage({ id: "password.confirmation" }),
          name: "password_confirmation",
          type: "password"
        }
      }
    ];
    const schema = Yup.object().shape({
      password: Yup.string()
        .min(
          6,
          this.props.intl.formatMessage({
            id: "please.enter.a.password.with.at.least.6.characters"
          })
        )
        .required(this.props.intl.formatMessage({ id: "validation.required" })),
      password_confirmation: Yup.string().required(
        this.props.intl.formatMessage({ id: "validation.required" })
      )
    });
    let modalType, modalText, modalChoiceList;
    if (this.props.flags.isSuccessUpdatePassword) {
      modalType = "choice";
      modalText = this.props.msg;
      modalChoiceList = [
        {
          label: this.props.intl.formatMessage({ id: "ok" }),
          action: this.handleClickOkOfSuccessUpdatePassword.bind(this)
        }
      ];
    } else if (this.props.flags.isErrorFetchUpdatePassword) {
      modalType = "choice";
      modalText = this.props.msg;
      modalChoiceList = [
        {
          label: this.props.intl.formatMessage({ id: "ok" }),
          action: this.handleClickOkOfErrorFetchUpdatePassword.bind(this)
        },
        {
          label: this.props.intl.formatMessage({ id: "retry" }),
          action: this.handleClickRetryOfErrorFetchUpdatePassword.bind(this)
        }
      ];
    } else if (this.props.fetching.isFetchingUpdatePassword) {
      modalType = "loading";
      modalText = this.props.intl.formatMessage({ id: "connecting" });
      modalChoiceList = null;
    }

    return (
      <>
        <GlobalHeader
          title={this.props.intl.formatMessage({ id: "password" })}
          leftContent={leftContent}
          intl={this.props.intl}
          rightContent={rightContent}
        />
        <Main className={style.main}>
          <ScrollView contentContainerStyle={style.main__contentContainer}>
            <ActionBoxGroup
              actions={list}
              bindSubmitForm={this.bindSubmitForm}
              submitData={this.submitData.bind(this)}
              form={{ password: "", password_confirmation: "" }}
              schema={schema}
              stateErrors={this.props.validationMessages}
            />
          </ScrollView>
        </Main>
        <GlobalFooter
          intl={this.props.intl}
          pathname={this.props.history.location.pathname}
        />
        {modalType && (
          <Modal
            type={modalType}
            text={modalText}
            choiceList={modalChoiceList}
          />
        )}
      </>
    );
  }
}

export default Account;
