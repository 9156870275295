import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  fetchOversizedList,
  setIsErrorFetchingOversizedList,
  setOversizedMsg,
  setInitOversizedFlag
} from "../actions/oversized";

import Oversized from "../../web/pages/Oversized/Oversized";

const mapStateToProps = state => {
  return {
    oversized: state.oversized,
    common: state.common
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOversizedList: () => {
      dispatch(fetchOversizedList());
    },
    setIsErrorFetchingOversizedList: bool => {
      dispatch(setIsErrorFetchingOversizedList(bool));
    },
    setOversizedMsg: msg => {
      dispatch(setOversizedMsg(msg));
    },
    setInitOversizedFlag: bool => {
      dispatch(setInitOversizedFlag(bool));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Oversized)
);
