import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { changeHowToDisposeTab } from "../actions/howToDispose";

import HowToDispose from "../../web/pages/HowToDispose/HowToDispose";

const mapStateToProps = state => {
  return { howToDispose: state.howToDispose, common: state.common };
};

const mapDispatchToProps = dispatch => {
  return {
    changeHowToDisposeTab: index => {
      dispatch(changeHowToDisposeTab(index));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HowToDispose)
);
