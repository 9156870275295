import React from "react";
import style from "./GlobalHeader.module.scss";
import question from "../../../../assets/images/icon_question.svg";
import whiteQuestionIcon from "../../../../assets/images/icon_question_white.svg";
import bell from "../../../../assets/images/icon_bell.svg";
import whiteBellIcon from "../../../../assets/images/icon_bell_white.svg";
import arrow from "../../../../assets/images/icon_arrow_white_left.svg";
import setting from "../../../../assets/images/icon_setting.svg";
import whiteSettingIcon from "../../../../assets/images/icon_setting_white.svg";
import { Img, Div, H1, P, Header, A } from "../../atoms/Html/Html";

function GlobalHeader(props) {
  return (
    <Header className={style.mainContentHeader}>
      {props.title && (
        <H1 className={style.mainContentHeader__center__h1}>{props.title}</H1>
      )}
      {props.subtitle && (
        <P className={style.mainContentHeader__center__p}>{props.subtitle}</P>
      )}
      {props.leftContent && (
        <Content
          list={props.leftContent}
          wapperClass={style.mainContentHeader__left}
          linkClass={style.mainContentHeader__left__link}
          intl={props.intl}
        />
      )}
      {props.rightContent && (
        <Content
          list={props.rightContent}
          wapperClass={style.mainContentHeader__right}
          linkClass={style.mainContentHeader__right__Link}
          intl={props.intl}
        />
      )}
    </Header>
  );
}

const Content = props => (
  <Div className={props.wapperClass}>
    {props.list.map(function(value) {
      let src;
      let text;
      switch (value.label) {
        case "bell":
          src = value.isActive ? whiteBellIcon : bell;
          break;
        case "arrow":
          src = arrow;
          break;
        case "faq":
          src = value.isActive ? whiteQuestionIcon : question;
          break;
        case "settings":
          src = value.isActive ? whiteSettingIcon : setting;
          break;
        case "cancel":
          text = props.intl ? props.intl.formatMessage({ id: "cancel" }) : null;
          break;
        case "save":
          text = props.intl ? props.intl.formatMessage({ id: "save" }) : null;
          break;
        default:
          src = null;
      }
      return (
        <A className={props.linkClass} onClick={value.action}>
          {text && text}
          {src && <Img src={src} />}
        </A>
      );
    })}
  </Div>
);

export default GlobalHeader;
