import { developmentApiUrl } from "../../web/constants/constants";
import { getRequestHeader, getLanguage, getFormattedMessage, isStatusOk } from "../../web/constants/functions";
import { logOut } from "./auth";

export const REQUEST_FETCH_INFORMATION_LIST =
  "REQUEST_FETCH_INFORMATION_LIST";

function requestFetchInformationList() {
  return {
    type: REQUEST_FETCH_INFORMATION_LIST
  };
}

export const SUCCESS_FETCH_INFORMATION_LIST =
  "SUCCESS_FETCH_INFORMATION_LIST";

function successFetchInformationList(json) {
  return {
    type: SUCCESS_FETCH_INFORMATION_LIST,
    list: json
  };
}

export const ERROR_FETCH_INFORMATION_LIST = "ERROR_FETCH_INFORMATION_LIST";

function errorFetchInformationList(msg) {
  return {
    type: ERROR_FETCH_INFORMATION_LIST,
    msg
  };
}

export const ERROR_AUTH_FETCH_INFORMATION_LIST =
  "ERROR_AUTH_FETCH_INFORMATION_LIST";

function errorAuthFetchInformationList() {
  return {
    type: ERROR_AUTH_FETCH_INFORMATION_LIST
  };
}

export function fetchInformationList() {
  return function(dispatch) {
    dispatch(requestFetchInformationList());
    let status;
    return fetch("/data/information.json", {
      headers: getRequestHeader()
    })
      .then(function(response) {
        status = response.status;
        return response.json();
      })
      .then(function(json) {
        if (isStatusOk(status)) {
          dispatch(successFetchInformationList(json));
        } else if (status === 401) {
          dispatch(logOut());
          dispatch(errorAuthFetchInformationList());
        } else {
          dispatch(errorFetchInformationList(getFormattedMessage('failed.to.get.information.')));
        }
      })
      .catch(function() {
        dispatch(errorFetchInformationList(getFormattedMessage('communication.failed.')));
      });
  };
}

export const SET_IS_ERROR_FETCHING_INFORMATION_LIST =
  "SET_IS_ERROR_FETCHING_INFORMATION_LIST";

export function setIsErrorFetchingInformationList(bool) {
  return {
    type: SET_IS_ERROR_FETCHING_INFORMATION_LIST,
    bool
  };
}

export const SET_INFORMATION_MSG = "SET_INFORMATION_MSG";

export function setInformationMsg(msg) {
  return {
    type: SET_INFORMATION_MSG,
    msg
  };
}

export const SET_INIT_INFORMATION_FLAG = "SET_INIT_INFORMATION_FLAG";

export function setInitInformationFlag(bool) {
  return {
    type: SET_INIT_INFORMATION_FLAG,
    bool
  };
}
