import React from "react";
import style from "./HowToDispose.module.scss";
import GlobalHeader from "../../components/organisms/GlobalHeader/GlobalHeader";
import GlobalFooter from "../../components/organisms/GlobalFooter/GlobalFooter";
import SimpleButtonList from "../../components/organisms/SimpleButtonList/SimpleButtonList";
import { Div, Main, P, Img, Span } from "../../components/atoms/Html/Html";
import { Switch, Route, Redirect } from "react-router-dom";
import GeneralTab from "../../components/organisms/GeneralTab/GeneralTab";
import JapaneseHowToDisposeImage from "../../../assets/images/how_to_dispose_ja.png";
import englishHowToDisposeImage from "../../../assets/images/how_to_dispose_en.png";
import {
  weekEnglishList,
  monthEnglishList
} from "../../constants/constants";
import {getDay, thisYear, thisMonth, escapeString, getFormattedMessage} from "../../constants/functions";
import ContentBox from "../../components/molecules/ContentBox/ContentBox";
import robot from "../../../assets/images/icon_robot_face.svg";
import LoadingWrapper from "../../components/organisms/LoadingWrapper/LoadingWrapper";
import { Link } from "react-router-dom";
import ScrollView from "../../components/atoms/ScrollView/ScrollView";

const HowToDispose = props => {
  return (
    <Switch>
      <Route
        exact
        path="/how_to_dispose"
        render={() => <HowToDisposeList {...props} />}
      />
      <Route
        exact
        path="/how_to_dispose/details"
        render={() => <HowToDisposeDetails {...props} />}
      />
    </Switch>
  );
};

class HowToDisposeList extends React.Component {
  constructor(props) {
    super(props);
  }

  handleClickBell() {
    this.props.history.push("/information");
  }

  handleClickFaq() {
    this.props.history.push("/faq");
  }

  handleClickSettings() {
    this.props.history.push("/settings");
  }

  render() {
    const leftContent = [
      { label: "bell", action: this.handleClickBell.bind(this) }
    ];
    const rightContent = [
      { label: "faq", action: this.handleClickFaq.bind(this) },
      { label: "settings", action: this.handleClickSettings.bind(this) }
    ];
    return (
      <>
        <GlobalHeader
          title={this.props.intl.formatMessage({ id: "how.to.dispose" })}
          subtitle={
            this.props.common.status.authority.name +
            this.props.common.status.block.name
          }
          leftContent={leftContent}
          rightContent={rightContent}
        />
        <Main className={style.howToDisposeList__main}>
          <ScrollView contentContainerStyle={style.list__scrollView}>
            <SimpleButtonList
              howToDisposeList={this.props.common.disposalList.details}
              intl={this.props.intl}
              history={this.props.history}
              conversionTable={this.props.common.disposalList.conversion_table}
            />
          </ScrollView>
        </Main>
        <GlobalFooter
          intl={this.props.intl}
          pathname={this.props.history.location.pathname}
        />
      </>
    );
  }
}

class HowToDisposeDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from:
        props.history.location.state && props.history.location.state.from
          ? props.history.location.state.from
          : null
    };
  }

  handleClickArrow() {
    if (this.state.from) {
      this.props.history.push({
        pathname: this.state.from,
        state: { from: "/how_to_dispose/details" }
      });
    } else {
      this.props.history.goBack();
    }
  }

  render() {
    if (!this.props.history.location.state) {
      return <Redirect to={"/how_to_dispose"} />;
    }
    const leftContent = [
      { label: "arrow", action: this.handleClickArrow.bind(this) }
    ];

    const trashType = this.props.history.location.state.trashType;

    const filteredList = this.props.common.disposalList.details.filter(function(
      element
    ) {
      return element.type === trashType;
    });

    const nextDate = filteredList[0].next && filteredList[0].next !== "なし"
      ? new Date(filteredList[0].next.replace(/-/g, "/"))
      : null;
    let nextDateString = "";
    if (nextDate !== null) {
      const month = nextDate.getMonth();
      const date = nextDate.getDate();
      const day = nextDate.getDay();
      const week = getFormattedMessage(weekEnglishList[day]);
      const monthStr = getFormattedMessage(monthEnglishList[month]);
      nextDateString = (
        <>
          {getFormattedMessage('next')}
          {" "}
          <Span className={style.descriptionBlock__dateBlock__next__large}>
            {getFormattedMessage("week2", week.slice(0, 1), week.charAt(0).toUpperCase() + week.slice(1, 3))}
          </Span>
          {" "}
          {getFormattedMessage("date2", month + 1, monthStr.charAt(0).toUpperCase() + monthStr.slice(1, 3), date)}
        </>
      )
    }

    const DescriptionComponent = props => {
      return (
        <ScrollView contentContainerStyle={style.descriptionBlock}>
          <>
            {props.rule && props.next ? (
              <Div className={style.descriptionBlock__dateBlock}>
                <P className={style.descriptionBlock__dateBlock__rule}>
                  {props.rule}
                </P>
                <P className={style.descriptionBlock__dateBlock__next}>
                  {props.next}
                </P>
              </Div>
            ) : (
              ""
            )}
            {props.imageUrls.map(value => (
              <Div className={style.descriptionBlock__imageBlock}>
                <Img
                  src={value}
                  className={style.descriptionBlock__imageBlock__image}
                />
              </Div>
            ))}
          </>
        </ScrollView>
      );
    };

    const ItemsComponent = props => (
      <ScrollView contentContainerStyle={style.itemBlockBlock}>
        <>
          <ContentBox
            exLinkUrl={props.link}
            exLinkText={this.props.intl.formatMessage({
              id: "see.on.the.local.government.website"
            })}
          >
            <Div
                className={style.itemBlock__content}
                escapeText={escapeString(props.description)}
            />
          </ContentBox>
          {/*<Div className={style.itemBlock__talkBlock}>
            <Img src={robot} className={style.itemBlock__talkBlock__icon} />
            <Div className={style.itemBlock__talkBlock__desc}>…</Div>
          </Div>*/}
        </>
      </ScrollView>
    );
    const list = [
      {
        label: this.props.intl.formatMessage({ id: "how.to.dispose.of.tab" }),
        component: (
          <DescriptionComponent
            /*
            imageUrls={filteredList[0].image_urls.map(function(value) {
              return value === "en.png"
                ? englishHowToDisposeImage
                : JapaneseHowToDisposeImage;
            })}
            // development
            */
            imageUrls={filteredList[0].image_urls} // production
            rule={filteredList[0].rules !== "なし" ? filteredList[0].rules : null}
            next={nextDateString}
          />
        )
      },
      {
        label: this.props.intl.formatMessage({ id: "notes" }),
        component: (
          <ItemsComponent
            link={
              this.props.common.disposalList.authority_info.disposal_info_url
            }
            description={filteredList[0].comment}
          />
        )
      }
    ];

    return (
      <>
        <GlobalHeader
          title={this.props.common.disposalList.conversion_table[trashType]}
          subtitle={
            this.props.common.status.authority.name +
            this.props.common.status.block.name
          }
          leftContent={leftContent}
        />
        <Main className={style.howToDisposeDetails__main}>
          <GeneralTab
            tabList={list}
            changeTab={this.props.changeHowToDisposeTab}
            index={this.props.howToDispose.tabIndex}
          />
          <Link
            className={style.howToDisposeDetails__main__link}
            to="/ai_helps_you"
          >
            <Img
              src={getFormattedMessage("robot.icon")}
              className={style.howToDisposeDetails__main__link__robotIcon}
            />
          </Link>
        </Main>
        <GlobalFooter
          intl={this.props.intl}
          pathname={this.props.history.location.pathname}
        />
      </>
    );
  }
}

export default HowToDispose;
