import React from "react";
import { withFormik, Form } from "formik";
import FormParts from "../../molecules/FormParts/FormParts";
import MultipurposeButton from "../../molecules/MultipurposeButton/MultipurposeButton";
import styles from "./ResetPasswordForm.module.scss";
import { Div } from "../../atoms/Html/Html";
import * as Yup from "yup";
import { getFormattedMessage } from "../../../constants/functions";

const ResetPasswordForm = props => {
  const { touched, errors, handleChange, validations } = props;
  return (
    <Form>
      <Div className={styles.resetPasswordFormBlock__formItem}>
        <FormParts
          label={getFormattedMessage("password")}
          name="password"
          component="input"
          type="password"
          errors={errors.password && touched.password ? [errors.password] : validations.password}
          handleChange={handleChange}
        />
      </Div>
      <Div
        className={
          styles.resetPasswordFormBlock__formItem +
          " " +
          styles.resetPasswordFormBlock__formItem_last
        }
      >
        <FormParts
          label={getFormattedMessage("password.confirmation")}
          name="password_confirmation"
          component="input"
          type="password"
          errors={errors.password_confirmation && touched.password_confirmation ? [errors.password_confirmation] : validations.password_confirmation}
          handleChange={handleChange}
        />
      </Div>
      <MultipurposeButton
        type="submit"
        text={getFormattedMessage("reset.password")}
      />
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: () => {
    return {
      password: "",
      password_confirmation: ""
    }
  },
  validationSchema() {
    return Yup.object().shape({
      password: Yup.string().min(6, getFormattedMessage("please.enter.a.password.with.at.least.6.characters")).required(
        getFormattedMessage("validation.required")
      ),
      password_confirmation: Yup.string().required(
        getFormattedMessage("validation.required")
      )
    });
  },
  handleSubmit: (values, { props }) => {
    const submitValue = {
      password: values.password,
      password_confirmation: values.password_confirmation
    };
    props.submitData(submitValue);
  }
})(ResetPasswordForm);
