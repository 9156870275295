import React from "react";
import styles from "./Register.module.scss";
import { Div } from "../../components/atoms/Html/Html";
import SelectLanguage from "../../components/register/SelectLanguage/SelectLanguage";

const LanguageSetting = props => {
  function handleClick(e) {
    e.preventDefault();
    props.setLanguage(e.currentTarget.getAttribute("data-value"));
    props.history.push("/register/select_region");
  }

  return (
    <Div className={styles.selectLanguagePage}>
      <SelectLanguage
        handleClick={handleClick}
      />
    </Div>
  );
};

export default LanguageSetting;
