import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import home from "./home";
import auth from "./auth";
import user from "./user";
import howToDispose from "./howToDispose";
import faq from "./faq";
import information from "./information";
import settings from "./settings";
import oversized from "./oversized";
import aiHelpsYou from "./aiHelpsYou";
import reward from "./reward";
import common from "./common";
import dictionary from "./dictionary";

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    home,
    auth,
    user,
    howToDispose,
    faq,
    information,
    settings,
    oversized,
    aiHelpsYou,
    reward,
    common,
    dictionary
  });

export default rootReducer;
