import robotIcon from "../assets/images/icon_robot_body_english.svg";

const ko_KR = {
  'log.in':'로그인',
  'already.use.gommit?':'이미 GOMMIT를 사용하십니까?',
  '1.select.your.region': '1. 지역을 선택하십시오',
  'use.the.other.region':'다른 영역을 사용하십시오',
  'dont.you.have.an.account?':'계정이 없습니까?',
  'sign.up':'가입하기',
  'email':'이메일',
  'password':'암호',
  'login':'로그인',
  'forget.password':'비밀번호 분실',
  'getting.your.location.data':'위치 데이터를 가져 오는 중…',
  '2.input.more.information': '2. 더 많은 정보를 입력하십시오',
  'retry': '다시 해 보다',
  'input.manually': '수동 입력',
  'prefecture': '현',
  'city': '시티',
  'region': '지역',
  'address': '주소',
  'type.of.residence': '거주 유형',
  'residence.name': '거주지 이름',
  'room.number': '방 번호',
  'your.mail': '귀하의 이메일',
  'login.password': '로그인 비밀번호',
  'required': '필수',
  'register': '레지스터',
  'upcoming.schedule':'예정된 일정',
  'cancel':'취소',
  'trash.name':'쓰레기 이름',
  'type':'유형',
  'next.collection.date':'다음 컬렉션 날짜',
  'validation.invalid.email':'잘못된 이메일',
  'validation.required':'필수',
  'please.select':'선택 해주세요',
  '3.check.the.confirmation.email.and.complete.the.registration':'3. 확인 이메일을 확인하고 등록을 완료하십시오',
  'check.mailbox':'사서함 확인',
  'start':'스타트',
  'ready.to.use.gommit!':'GOMMIT 사용 준비!',
  'registering':'등록하는 중…',
  'connecting':'연결 중…',
  'back.to.login':'로그인으로 돌아 가기',
  'reset.password':'암호를 재설정',
  'ok':'확인',
  'home': '家',
  'gommit': 'GOMMIT',
  'ai.helps.you': 'AI가 당신을 도와줍니다',
  'reward': '보상',
  'how.to.dispose': '처분하는 방법',
  'dictionary': '인덱스',
  'oversized': '특대',
  'burnable.trash': '가연 쓰레기',
  'unburnable.trash': '타지 않는 쓰레기',
  'harmful.trash': '유해한 쓰레기',
  'oversized.trash': '대형 쓰레기',
  'plastic': '플라스틱',
  'books.magazines': '책, 잡지',
  'cardboard': '판지',
  'milk.carton': '우유 카톤',
  'paper.newspaper': '종이, 신문',
  'cans.bottles': '캔, 병',
  'metals': '궤조',
  'clothes': '천',
  'recyclable.trash': '재활용 가능',
  'get.again': '다시 받기',
  'how.to.dispose.of.tab': '처분하는 방법',
  'items': '아이템',
  'notes': '노트',
  'see.detail': '상세보기',
  'faq': '자주하는 질문',
  'information': '정보',
  'oversized.consultation': '대형 쓰레기',
  'you.cannot.dispose.oversized.trashes.on.general.ways' : '대형 쓰레기 처리는 다른 과정을 따른다',
  'type.your.text': '당신의 텍스트를 입력',
  'enter.the.name.of.the.trash.you.want.to.check': '확인하려는 휴지통 이름을 입력하십시오.',
  'settings':'설정',
  'account':'계정',
  'notifications':'알림',
  'language':'언어',
  'sns.integration':'SNS 통합',
  'save': '저장',
  'select.language':'언어를 선택하십시오',
  'english': '영어',
  'japanese': '일본어',
  'reward.programs': '보상 프로그램',
  'total.points': '총 포인트',
  'start.reward':'스타트',
  'special.reward':'특별한\n보상',
  'password.confirmation':'비밀번호 확인',
  'please.enter.a.password.with.at.least.6.characters':'6 자 이상의 비밀번호를 입력하십시오.',
  'logout': '로그 아웃',
  'based.on.information.of.local.government.website': '지방 정부 웹 사이트를 기반으로',
  'notice':'주의',
  'search':'검색',
  'definition.of.oversized.garbage': '"대형 쓰레기"의 정의',
  'how.to.dispose.of.oversized.garbage':'처분하는 방법',
  'ticket.prices': '대형 쓰레기 처리 티켓 발행 수수료\n*경우에 따라 요금이 다를 수 있습니다',
  'dispose.step.of.oversized.garbage': '1. 리셉션 센터에서 신청하십시오\n' +
      '2. 지정된 대리점에서 부피가 큰 쓰레기 처리 티켓을 구입하십시오.\n' +
      '3. 지정일에 쓰레기',
  'see.on.the.local.government.website': '해당 지역 정부의 HP 사이트를 확인하십시오.',
  'not.found.': '찾을 수 없습니다.',
  'plain.address': '주소',
  'invisible': '보이지 않는',
  'return': '반환',
  'upcoming': '다가오는.',
  'apartment': 'Apartment',
  'house': 'House',
  'failed.to.get.information.': 'Failed to get information.',
  'communication.failed.': 'Communication failed.{v1}',
  'failed.to.get.your.location.': 'Failed to get your location.',
  'not.compatible.with.your.area.': 'Not compatible with your area.',
  'the.password.was.updated.successfully.': 'The password was updated successfully.',
  'the.address.was.successfully.updated.': 'The address was successfully updated.',
  'failed.to.get.event.': 'Failed to get event.',
  'failed.to.get.list.': 'Failed to get list.',
  'incorrect.email.or.password.': 'Incorrect email or password.',
  'failed.to.get.faq.': 'Failed to get FAQ.',
  'mail.token.error': 'This e-mail address is temporarily registered.\nYou can’t register with this email address until {v1}',
  'january': 'january',
  'february': 'february',
  'march': 'march',
  'april': 'april',
  'may': 'may',
  'june': 'june',
  'july': 'july',
  'august': 'august',
  'september': 'september',
  'october': 'october',
  'november': 'november',
  'december': 'december',
  'date1': '{v3} {v1}',
  'sunday': 'sunday',
  'monday': 'monday',
  'tuesday': 'tuesday',
  'wednesday': 'wednesday',
  'thursday': 'thursday',
  'friday': 'friday',
  'saturday': 'saturday',
  'week1': '{v2}',
  'date2': '{v2} {v3}',
  'week2': '{v2}',
  'today': 'Today',
  'robot.icon': robotIcon,
  'click.here.for.how.to.put.out': 'Click here for how to put out {v1}',
  'next': 'next',
  'rough.address': '{v3}, {v2}, {v1}',
  'the.password.reset.email.has.been.sent.successfully.': 'An email has been sent to \'{v1}\' containing instructions for resetting your password.',
  'unable.to.find.user.with.email': 'Unable to find user with email \'{v1}\'',
  'please.log.in.again.': 'Please log in again.',
  'an.error.has.occurred.please.send.an.email.again.': 'An error has occurred.\nPlease send an email again.',
  'news': 'News',
};
export default ko_KR;