import React from "react";
import styles from "./Character.module.scss";

const Character = props => {
  return (
    <div className={styles.characterBlock}>
      <img src={props.src} className={styles.characterBlock__character} />
    </div>
  );
};

export default Character;
