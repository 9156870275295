import React from "react";
import style from "./Settings.module.scss";
import GlobalHeader from "../../components/organisms/GlobalHeader/GlobalHeader";
import GlobalFooter from "../../components/organisms/GlobalFooter/GlobalFooter";
import { Div, Main } from "../../components/atoms/Html/Html";
import ScrollView from "../../components/atoms/ScrollView/ScrollView";
import ActionBoxGroup from "../../components/organisms/ActionBoxGroup/ActionBoxGroup";
import Language from "./Language";
import Account from "./Account";

const Settings = props => {
  return (
    <>
      {(() => {
        if (props.history.location.pathname === "/settings") {
          return <SettingsTop {...props} />;
        } else if (props.history.location.pathname === "/settings/language") {
          return (
            <Language
              {...props.settings.language}
              changeSettingsLang={props.changeSettingsLang}
              setLanguage={props.setLanguage}
              history={props.history}
              intl={props.intl}
            />
          );
        } else if (
          props.history.location.pathname === "/settings/account" ||
          props.history.location.pathname === "/settings/account/address" ||
          props.history.location.pathname === "/settings/account/email" ||
          props.history.location.pathname === "/settings/account/password"
        ) {
          return (
            <Account
              common={props.common}
              history={props.history}
              intl={props.intl}
              fetchPrefectures={props.fetchPrefectures}
              fetchAuthorities={props.fetchAuthorities}
              fetchBlocks={props.fetchBlocks}
              account={props.settings.account}
              modifyFormValues={props.modifyFormValues}
              setAddressOptions={props.setAddressOptions}
              updateAddress={props.updateAddress}
              resetValidationMessages={props.resetValidationMessages}
              setAddressMsg={props.setAddressMsg}
              setIsEmptyBlocksError={props.setIsEmptyBlocksError}
              setIsSeccessUpdateAddress={props.setIsSeccessUpdateAddress}
              setIsFetchPrefecturesError={props.setIsFetchPrefecturesError}
              setIsFetchAuthoritiesError={props.setIsFetchAuthoritiesError}
              setIsFetchBlocksError={props.setIsFetchBlocksError}
              setIsErrorFetchUpdateAddress={props.setIsErrorFetchUpdateAddress}
              updatePassword={props.updatePassword}
              setPasswordMsg={props.setPasswordMsg}
              setIsSuccessUpdatePassword={props.setIsSuccessUpdatePassword}
              setIsErrorFetchUpdatePassword={
                props.setIsErrorFetchUpdatePassword
              }
              resetPasswordValidationMessages={
                props.resetPasswordValidationMessages
              }
            />
          );
        }
      })()}
    </>
  );
};

class SettingsTop extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {}

  handleClickBell() {
    this.props.history.push("/information");
  }

  handleClickFaq() {
    this.props.history.push("/faq");
  }

  render() {
    const leftContent = [
      { label: "bell", action: this.handleClickBell.bind(this) }
    ];
    const rightContent = [
      { label: "faq", action: this.handleClickFaq.bind(this) },
      { label: "settings", isActive: true }
    ];
    const settingsList = [
      {
        type: "link",
        body: {
          label: this.props.intl.formatMessage({ id: "account" }),
          to: "/settings/account"
        }
      },
      // {
      //   type: "link",
      //   body: {
      //     label: this.props.intl.formatMessage({ id: "notifications" }),
      //     to: "/settings/notifications"
      //   }
      // },
      {
        type: "link",
        body: {
          label: this.props.intl.formatMessage({ id: "language" }),
          to: "/settings/language"
        }
      },
      {
        type: "link",
        body: {
          label: this.props.intl.formatMessage({ id: "logout" }),
          to: "/logout"
        }
      }
      /*
      { 
        type: "link",
        body: {
          label: this.props.intl.formatMessage({ id: "sns.integration" }),
          to: "/settings/sns_integration"
        }
      }
      */
    ];
    return (
      <>
        <GlobalHeader
          title={this.props.intl.formatMessage({ id: "settings" })}
          leftContent={leftContent}
          rightContent={rightContent}
        />
        <Main className={style.main}>
          <ScrollView contentContainerStyle={style.main__contentContainer}>
            <ActionBoxGroup actions={settingsList} />
          </ScrollView>
        </Main>
        <GlobalFooter
          intl={this.props.intl}
          pathname={this.props.history.location.pathname}
        />
      </>
    );
  }
}

export default Settings;
