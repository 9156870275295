import {
  REQUEST_FETCH_EVENT_LIST,
  SUCCESS_FETCH_EVENT_LIST,
  ERROR_FETCH_EVENT_LIST,
  ERROR_AUTH_FETCH_EVENT_LIST,
  CHANGE_WAS_CHECKED,
  CHANGE_CALENDAR_DATE,
  SET_CALENDAR_MSG,
  SET_IS_ERROR_FETCH_CALENDAR_LIST,
  CHANGE_SCHEDULE_DATE,
  SET_SCHEDULE_MSG,
  SET_IS_ERROR_FETCH_SCHEDULE_LIST,
  SET_NOTICE_FLAG,
  CHANGE_CALENDAR_INDEX,
  SET_CALENDAR_EVENT_LIST,
  SET_SCHEDULE_EVENT_LIST
} from "../actions/home";

import {
  thisMonth,
  thisYear,
  returnsAUniqueArray
} from "../../web/constants/functions";

const home = (
  state = {
    date: {
      calendar: {
        date: {
          month: thisMonth(),
          year: thisYear()
        }
      },
      schedule: {
        date: {
          month: thisMonth(),
          year: thisYear()
        }
      }
    },
    eventList: {
      calendar: {
        list: []
      },
      schedule: {
        list: []
      }
    },
    wasChecked: false,
    isFetchingCalendarList: false,
    isFetchingScheduleList: false,
    isFullCalendarList: false,
    isFullScheduleList: false,
    errors: {
      calendarMsg: "",
      scheduleMsg: "",
      isErrorFetchCalendarList: false,
      isErrorFetchScheduleList: false
    },
    noticeFlag: true,
    calendarIndex: 0,
    message: ""
  },
  action
) => {
  switch (action.type) {
    case REQUEST_FETCH_EVENT_LIST:
      let isFetchingCalendarListOfRequest;
      let isFetchingScheduleListOfRequest;
      switch (action.mode) {
        case "both":
          isFetchingCalendarListOfRequest = true;
          isFetchingScheduleListOfRequest = true;
          break;
        case "calendar":
          isFetchingCalendarListOfRequest = true;
          isFetchingScheduleListOfRequest = state.isFetchingScheduleList;
          break;
        case "schedule":
          isFetchingCalendarListOfRequest = state.isFetchingCalendarList;
          isFetchingScheduleListOfRequest = true;
          break;
      }
      return Object.assign({}, state, {
        isFetchingCalendarList: isFetchingCalendarListOfRequest,
        isFetchingScheduleList: isFetchingScheduleListOfRequest
      });
    case SUCCESS_FETCH_EVENT_LIST:
      let calendarListOfSuccess;
      let scheduleListOfSuccess;
      let isFetchingCalendarListOfSuccess;
      let isFetchingScheduleListOfSuccess;
      let isErrorFetchCalendarListOfSuccess;
      let isErrorFetchScheduleListOfSuccess;
      let tmpCalendarListOfSuccess;
      let tmpScheduleListOfSuccess;
      let isFullCalendarListOfSuccess;
      let isFullScheduleListOfSuccess;
      console.log(action.eventList);
      switch (action.mode) {
        case "both":
          tmpCalendarListOfSuccess = action.isReset
            ? action.eventList
            : returnsAUniqueArray(
                state.eventList.calendar.list,
                action.eventList
              );
          tmpScheduleListOfSuccess = action.isReset
            ? action.eventList
            : returnsAUniqueArray(
                state.eventList.schedule.list,
                action.eventList
              );
          calendarListOfSuccess = tmpCalendarListOfSuccess;
          scheduleListOfSuccess = tmpScheduleListOfSuccess;
          isFetchingCalendarListOfSuccess = false;
          isFetchingScheduleListOfSuccess = false;
          isErrorFetchCalendarListOfSuccess = false;
          isErrorFetchScheduleListOfSuccess = false;
          isFullCalendarListOfSuccess = false;
          isFullScheduleListOfSuccess = false;
          break;
        case "calendar":
          tmpCalendarListOfSuccess = action.isReset
            ? action.eventList
            : returnsAUniqueArray(
                state.eventList.calendar.list,
                action.eventList
              );
          calendarListOfSuccess = tmpCalendarListOfSuccess;
          scheduleListOfSuccess = state.eventList.schedule.list;
          isFetchingCalendarListOfSuccess = false;
          isFetchingScheduleListOfSuccess = state.isFetchingScheduleList;
          isErrorFetchCalendarListOfSuccess = false;
          isErrorFetchScheduleListOfSuccess =
            state.errors.isErrorFetchScheduleList;
          isFullCalendarListOfSuccess = false;
          isFullScheduleListOfSuccess = state.isFullScheduleList;
          break;
        case "schedule":
          tmpScheduleListOfSuccess = action.isReset
            ? action.eventList
            : returnsAUniqueArray(
                state.eventList.schedule.list,
                action.eventList
              );
          calendarListOfSuccess = state.eventList.calendar.list;
          scheduleListOfSuccess = tmpScheduleListOfSuccess;
          isFetchingCalendarListOfSuccess = state.isFetchingCalendarList;
          isFetchingScheduleListOfSuccess = false;
          isErrorFetchCalendarListOfSuccess =
            state.errors.isErrorFetchCalendarList;
          isErrorFetchScheduleListOfSuccess = false;
          isFullCalendarListOfSuccess = state.isFullCalendarList;
          isFullScheduleListOfSuccess = false;
          break;
      }
      return Object.assign({}, state, {
        eventList: {
          calendar: {
            list: calendarListOfSuccess
          },
          schedule: {
            list: scheduleListOfSuccess
          }
        },
        isFetchingCalendarList: isFetchingCalendarListOfSuccess,
        isFetchingScheduleList: isFetchingScheduleListOfSuccess,
        isFullCalendarList: isFullCalendarListOfSuccess,
        isFullScheduleList: isFullScheduleListOfSuccess,
        errors: {
          calendarMsg: state.errors.calendarMsg,
          scheduleMsg: state.errors.scheduleMsg,
          isErrorFetchCalendarList: isErrorFetchCalendarListOfSuccess,
          isErrorFetchScheduleList: isErrorFetchScheduleListOfSuccess
        }
      });
    case ERROR_FETCH_EVENT_LIST:
      let isFetchingCalendarListOfError;
      let isFetchingScheduleListOfError;
      let calendarMsgOfError;
      let scheduleMsgOfError;
      let isErrorFetchCalendarListOfError;
      let isErrorFetchScheduleListOfError;
      switch (action.mode) {
        case "both":
          isFetchingCalendarListOfError = false;
          isFetchingScheduleListOfError = false;
          calendarMsgOfError = action.msg;
          scheduleMsgOfError = action.msg;
          isErrorFetchCalendarListOfError = true;
          isErrorFetchScheduleListOfError = true;
          break;
        case "calendar":
          isFetchingCalendarListOfError = false;
          isFetchingScheduleListOfError = state.isFetchingScheduleList;
          calendarMsgOfError = action.msg;
          scheduleMsgOfError = state.errors.scheduleMsg;
          isErrorFetchCalendarListOfError = true;
          isErrorFetchScheduleListOfError =
            state.errors.isErrorFetchScheduleList;
          break;
        case "schedule":
          isFetchingCalendarListOfError = state.isFetchingCalendarList;
          isFetchingScheduleListOfError = false;
          calendarMsgOfError = state.errors.calendarMsg;
          scheduleMsgOfError = action.msg;
          isErrorFetchCalendarListOfError =
            state.errors.isErrorFetchCalendarList;
          isErrorFetchScheduleListOfError = true;
          break;
      }
      return Object.assign({}, state, {
        isFetchingCalendarList: isFetchingCalendarListOfError,
        isFetchingScheduleList: isFetchingScheduleListOfError,
        errors: {
          calendarMsg: calendarMsgOfError,
          scheduleMsg: scheduleMsgOfError,
          isErrorFetchCalendarList: isErrorFetchCalendarListOfError,
          isErrorFetchScheduleList: isErrorFetchScheduleListOfError
        }
      });
    case ERROR_AUTH_FETCH_EVENT_LIST:
      let isFetchingCalendarListOfAuthError;
      let isFetchingScheduleListOfAuthError;
      switch (action.mode) {
        case "both":
          isFetchingCalendarListOfAuthError = false;
          isFetchingScheduleListOfAuthError = false;
          break;
        case "calendar":
          isFetchingCalendarListOfAuthError = false;
          isFetchingScheduleListOfAuthError = state.isFetchingScheduleList;
          break;
        case "schedule":
          isFetchingCalendarListOfAuthError = state.isFetchingCalendarList;
          isFetchingScheduleListOfAuthError = false;
          break;
      }
      return Object.assign({}, state, {
        isFetchingCalendarList: isFetchingCalendarListOfAuthError,
        isFetchingScheduleList: isFetchingScheduleListOfAuthError
      });
    case CHANGE_WAS_CHECKED:
      return Object.assign({}, state, {
        wasChecked: action.bool
      });
    case CHANGE_CALENDAR_DATE:
      return Object.assign({}, state, {
        date: {
          calendar: {
            date: {
              month: action.month,
              year: action.year
            }
          },
          schedule: state.date.schedule
        }
      });
    case SET_CALENDAR_MSG:
      return Object.assign({}, state, {
        errors: {
          calendarMsg: action.msg,
          scheduleMsg: state.errors.scheduleMsg,
          isErrorFetchCalendarList: state.errors.isErrorFetchCalendarList,
          isErrorFetchScheduleList: state.errors.isErrorFetchScheduleList
        }
      });
    case SET_IS_ERROR_FETCH_CALENDAR_LIST:
      return Object.assign({}, state, {
        errors: {
          calendarMsg: state.errors.calendarMsg,
          scheduleMsg: state.errors.scheduleMsg,
          isErrorFetchCalendarList: action.bool,
          isErrorFetchScheduleList: state.errors.isErrorFetchScheduleList
        }
      });
    case CHANGE_SCHEDULE_DATE:
      return Object.assign({}, state, {
        date: {
          calendar: state.date.calendar,
          schedule: {
            date: {
              month: action.month,
              year: action.year
            }
          }
        }
      });
    case SET_SCHEDULE_MSG:
      return Object.assign({}, state, {
        errors: {
          calendarMsg: state.errors.calendarMsg,
          scheduleMsg: action.msg,
          isErrorFetchCalendarList: state.errors.isErrorFetchCalendarList,
          isErrorFetchScheduleList: state.errors.isErrorFetchScheduleList
        }
      });
    case SET_IS_ERROR_FETCH_SCHEDULE_LIST:
      return Object.assign({}, state, {
        errors: {
          calendarMsg: state.errors.calendarMsg,
          scheduleMsg: state.errors.scheduleMsg,
          isErrorFetchCalendarList: state.errors.isErrorFetchCalendarList,
          isErrorFetchScheduleList: action.bool
        }
      });
    case SET_NOTICE_FLAG:
      return Object.assign({}, state, {
        noticeFlag: action.bool
      });
    case CHANGE_CALENDAR_INDEX:
      return Object.assign({}, state, {
        calendarIndex: action.index
      });
    case SET_CALENDAR_EVENT_LIST:
      return Object.assign({}, state, {
        eventList: Object.assign({}, state.eventList, {
          calendar: Object.assign({}, state.eventList.calendar, {
            list: action.values
          })
        })
      });
    case SET_SCHEDULE_EVENT_LIST:
      return Object.assign({}, state, {
        eventList: Object.assign({}, state.eventList, {
          schedule: Object.assign({}, state.eventList.schedule, {
            list: action.values
          })
        })
      });
    default:
      return state;
  }
};

export default home;
