import React from "react";
import styles from "./StepNumber.module.scss";
import { Div } from "../../atoms/Html/Html";

const StepNumber = props => {
  let countList = [];
  for (let i = 0; i < props.totalNumber; i++) {
    countList.push(i + 1);
  }
  return (
    <Div className={styles.currentRegisterNumberBlock}>
      {countList.map(data => {
        if (data <= props.currentNumber) {
          return data === props.totalNumber ? (
            <Div className={styles.currentRegisterNumberBlock__onNumber}>
              {data}
            </Div>
          ) : (
            <Div
              className={styles.currentRegisterNumberBlock__numberHorizontalBar}
            >
              <Div className={styles.currentRegisterNumberBlock__onNumber}>
                {data}
              </Div>
              <Div
                className={styles.currentRegisterNumberBlock__onHorizontalBar}
              />
            </Div>
          );
        } else {
          return data === props.totalNumber ? (
            <Div className={styles.currentRegisterNumberBlock__offNumber}>
              {data}
            </Div>
          ) : (
            <Div
              className={styles.currentRegisterNumberBlock__numberHorizontalBar}
            >
              <Div className={styles.currentRegisterNumberBlock__offNumber}>
                {data}
              </Div>
              <Div
                className={styles.currentRegisterNumberBlock__offHorizontalBar}
              />
            </Div>
          );
        }
      })}
    </Div>
  );
};

export default StepNumber;
