import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect
} from "react-router-dom";
import { IntlProvider, addLocaleData } from "react-intl";

// Load translation file
import en from "react-intl/locale-data/en";
import ja from "react-intl/locale-data/ja";
import ko from "react-intl/locale-data/ko";
import vi from "react-intl/locale-data/vi";
import zh from "react-intl/locale-data/zh";
import es from "react-intl/locale-data/es";
import fr from "react-intl/locale-data/fr";
import pt from "react-intl/locale-data/pt";
import th from "react-intl/locale-data/th";
import tl from "react-intl/locale-data/tl";
import en_US from "../../../locales/en_US";
import ja_JP from "../../../locales/ja_JP";
import ko_KR from "../../../locales/ko_KR";
import vi_VN from "../../../locales/vi_VN";
import zh_CN from "../../../locales/zh_CN";
import es_ES from "../../../locales/es_ES";
import fr_FR from "../../../locales/fr_FR";
import pt_PT from "../../../locales/pt_PT";
import th_TH from "../../../locales/th_TH";
import tl_PH from "../../../locales/tl_PH";
import { changeFontSetting, getIntl } from "../../constants/functions";

import { ConnectedRouter } from "connected-react-router";
import Home from "../../../redux/containers/HomeContainer";
import Splash from "../molecules/Splash/Splash";
import Auth from "../../../redux/containers/AuthContainer";
import Login from "../../../redux/containers/LoginContainer";
import IsRegistered from "./IsRegistered";
import Register from "../../../redux/containers/RegisterContainer.js";
import Dictionary from "../../../redux/containers/DictionaryContainer";
import ForgetPassword from "../../../redux/containers/ForgetPasswordContainer";
import HowToDispose from "../../../redux/containers/HowToDisposeContainer";
import Faq from "../../../redux/containers/FaqContainer";
import Information from "../../../redux/containers/InformationContainer";
import Settings from "../../../redux/containers/SettingsContainer";
import Oversized from "../../../redux/containers/OversizedContainer";
import AiHelpsYou from "../../../redux/containers/AiHelpsYouContainer";
import Reward from "../../../redux/containers/RewardContainer";
import News from "../../../redux/containers/NewsContainer";
import ResetPassword from "../../../redux/containers/ResetPasswordContainer";

addLocaleData(en);
addLocaleData(ja);
addLocaleData(ko);
addLocaleData(vi);
addLocaleData(zh);
addLocaleData(es);
addLocaleData(fr);
addLocaleData(pt);
addLocaleData(th);
addLocaleData(tl);

export const languageObject = {
  en: en_US,
  ja: ja_JP,
  ko: ko_KR,
  vi: vi_VN,
  zh: zh_CN,
  es: es_ES,
  fr: fr_FR,
  pt: pt_PT,
  th: th_TH,
  tl: tl_PH,
};

// toDo: Authで切り替え
export const App = props => {
  const intl = getIntl();
  // 言語毎にフォント設定の切り替え
  changeFontSetting(props.lang);
  return (
    <IntlProvider locale={props.lang} messages={languageObject[props.lang]}>
      <ConnectedRouter history={props.history}>
        <Switch>
          <Route path="/register" render={() => <Register intl={intl} />} />
          <Route exact path="/login" render={() => <Login intl={intl} />} />
          <Route
            exact
            path="/forget_password"
            render={() => <ForgetPassword intl={intl} />}
          />
          <Route exact path="/reset_password" component={ResetPassword} />
          <Route
            exact
            path="/logout"
            render={() => {
              props.history.push("/login");
              props.logOut();
            }}
          />
          <IsRegistered {...props}>
            <Switch>
              <Auth intl={intl}>
                <Switch>
                  <Route exact path="/" render={() => <Home intl={intl} />} />
                  <Route
                    path="/dictionary"
                    render={() => <Dictionary intl={intl} />}
                  />
                  <Route path="/splash" component={Splash} />
                  <Route
                    path="/how_to_dispose"
                    render={() => <HowToDispose intl={intl} />}
                  />
                  <Route
                    exact
                    path="/oversized"
                    render={() => <Oversized intl={intl} />}
                  />
                  <Route
                    exact
                    path="/ai_helps_you"
                    render={() => <AiHelpsYou intl={intl} />}
                  />
                  <Route
                    exact
                    path="/information"
                    render={() => <Information intl={intl} />}
                  />
                  <Route exact path="/faq" render={() => <Faq intl={intl} />} />
                  <Route
                    path="/settings"
                    render={() => <Settings intl={intl} />}
                  />
                  <Route path="/reward" render={() => <Reward intl={intl} />} />
                  <Route path="/news" component={News} />
                </Switch>
              </Auth>
            </Switch>
          </IsRegistered>
        </Switch>
      </ConnectedRouter>
    </IntlProvider>
  );
};
