import {
  REQUEST_FETCH_AI_ANSWER,
  SUCCESS_FETCH_AI_ANSWER,
  ERROR_FETCH_AI_ANSWER,
  ERROR_AUTH_FETCH_AI_ANSWER,
  SET_RESULTS,
  SET_USER_MESSAGE
} from "../actions/aiHelpsYou";

const aiHelpsYou = (
  state = {
    loading: false,
    results: [],
    userMessage: ""
  },
  action
) => {
  switch (action.type) {
    case REQUEST_FETCH_AI_ANSWER:
      return Object.assign({}, state, {
        loading: true
      });
    case SUCCESS_FETCH_AI_ANSWER:
      return Object.assign({}, state, {
        loading: false,
        results: action.data
      });
    case ERROR_FETCH_AI_ANSWER:
      return Object.assign({}, state, {
        loading: false,
        results: [
          {
            type: "plain",
            message: action.msg,
            text: "",
            note: "",
            userAction: true
          }
        ]
      });
    case ERROR_AUTH_FETCH_AI_ANSWER:
      return Object.assign({}, state, {
        loading: false
      });
    case SET_RESULTS:
      return Object.assign({}, state, {
        results: [action.values]
      });
    case SET_USER_MESSAGE:
      return Object.assign({}, state, {
        userMessage: action.value
      });
    default:
      return state;
  }
};

export default aiHelpsYou;
