import React, { Component } from "react";
import { Redirect } from "react-router-dom";

class IsRegistered extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return this.props.auth.isRegistered ? (
      this.props.children
    ) : (
      <Redirect to={"/register/language_setting"} />
    );

    //return this.props.children;
  }
}

export default IsRegistered;
