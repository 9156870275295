import {
  SET_LANGUAGE,
  SUCCESS_GET_REGION,
  ERROR_GET_REGION,
  MODIFY_STATE_OF_LOCATION,
  CHNAGE_IS_OTHER_REGION_MODE,
  SET_IS_FETCH_REGION_ERROR,
  SET_ERROR_MSG_OF_USER_STATE,
  REQUEST_REGISTER_USER_INFO,
  SUCCESS_REGISTER_USER_INFO,
  ERROR_REGISTER_USER_INFO,
  SET_IS_SUCCESS_USER_REGISTRATION,
  CATCH_ERROR_REGISTER_USER_INFO,
  SET_IS_REGISTER_ERROR,
  RESET_VALIDATION_MESSAGE_OF_REGISTER_USER_INFO,
  MODIFY_REGISTER_USER_FORM,
  CHANGE_AVAILABLE_REGISTER_PAGE,
  RESET_USER_STATE,
  REQUEST_FETCH_PREFECTURES,
  SUCCESS_FETCH_PREFECTURES,
  ERROR_FETCH_PREFECTURES,
  SET_IS_FETCH_PREFECTURES_ERROR,
  REQUEST_FETCH_AUTHORITIES,
  SUCCESS_FETCH_AUTHORITIES,
  ERROR_FETCH_AUTHORITIES,
  SET_IS_FETCH_AUTHORITIES_ERROR,
  REQUEST_FETCH_BLOCKS,
  SUCCESS_FETCH_BLOCKS,
  ERROR_EMPTY_BLOCKS,
  ERROR_FETCH_BLOCKS,
  SET_IS_EMPTY_BLOCKS_ERROR,
  SET_IS_FETCH_BLOCKS_ERROR,
  SET_PREFECTURES,
  SET_AUTHORITIES,
  SET_BLOCKS,
  SET_THE_VALUE_OF_FORM_AUTHORITY_ID,
  SET_THE_VALUE_OF_FORM_BLOCK_ID,
  RESET_INIT_FETCH_PREFECTURES,
  ERROR_MAIL_TOKEN_TIME,
  SET_IS_MAIL_TOKEN_TIME_ERROR,
  SET_IS_GETTING_REGION
} from "../actions/user";

import {
  SUCCESS_REGISTER_ADDRESS,
  SET_TOKEN,
  LOGIN_SUCCESS
} from "../actions/auth";

const user = (
  state = {
    language: "",
    isGettingRegion: false,
    regions: {
      authority: {
        id: "",
        name: ""
      },
      prefecture: {
        id: "",
        name: ""
      },
      blocks: []
    },
    errors: {
      msg: "",
      isFetchRegionError: false,
      validationOfRegisterUserInfo: {
        prefecture_id: [],
        authority_id: [],
        block_id: [],
        street_name: [],
        house_type_id: [],
        residence_name: [],
        room_number: [],
        email: [],
        password: [],
        password_confirmation: []
      },
      isRegisterError: false,
      isFetchPrefecturesError: false,
      isFetchAuthoritiesError: false,
      isFetchBlocksError: false,
      isEmptyBlocksError: false,
      isMailTokenTimeError: false
    },
    form: {
      prefecture_id: "",
      authority_id: "",
      block_id: "",
      street_name: "",
      house_type_id: "",
      residence_name: "",
      room_number: "",
      email: "",
      password: "",
      password_confirmation: "",
      prefecture_value: "",
      authority_value: "",
      block_value: ""
    },
    isOtherRegionMode: false,
    isRegisteringUser: false,
    isSuccessUserRegistration: false,
    availableRegisterPage: {
      select_region: true,
      more_information: false,
      check_mail: false
    },
    addressOptions: {
      isFetchingPrefectures: false,
      prefectures: [],
      initFetchPrefectures: false,
      isFetchingAuthorities: false,
      authorities: [],
      isFetchingBlocks: false,
      blocks: []
    },
    uid: ""
  },
  action
) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return Object.assign({}, state, {
        language: action.language
      });
    case SET_IS_GETTING_REGION:
      return Object.assign({}, state, {
        isGettingRegion: action.bool
      });
    case SUCCESS_GET_REGION:
      return Object.assign({}, state, {
        regions: {
          prefecture: action.prefecture,
          authority: action.authority,
          blocks: action.blocks
        }
      });
    case ERROR_GET_REGION:
      return Object.assign({}, state, {
        errors: {
          msg: action.errorMsg,
          isFetchRegionError: true,
          validationOfRegisterUserInfo:
            state.errors.validationOfRegisterUserInfo,
          isRegisterError: state.errors.isRegisterError,
          isFetchPrefecturesError: state.errors.isFetchPrefecturesError,
          isFetchAuthoritiesError: state.errors.isFetchAuthoritiesError,
          isFetchBlocksError: state.errors.isFetchBlocksError,
          isEmptyBlocksError: state.errors.isEmptyBlocksError,
          isMailTokenTimeError: state.errors.isMailTokenTimeError
        }
      });
    case MODIFY_STATE_OF_LOCATION:
      const tmpFormOfChangeRegion = Object.assign({}, state.form, {
        prefecture_value: state.regions.prefecture.id,
        authority_value: state.regions.authority.id,
        block_value: action.values.id
      });
      return Object.assign({}, state, {
        form: tmpFormOfChangeRegion
      });
    case CHNAGE_IS_OTHER_REGION_MODE:
      return Object.assign({}, state, {
        isOtherRegionMode: action.bool
      });
    case SET_IS_FETCH_REGION_ERROR:
      return Object.assign({}, state, {
        errors: {
          msg: state.errors.msg,
          isFetchRegionError: action.bool,
          validationOfRegisterUserInfo:
            state.errors.validationOfRegisterUserInfo,
          isRegisterError: state.errors.isRegisterError,
          isFetchPrefecturesError: state.errors.isFetchPrefecturesError,
          isFetchAuthoritiesError: state.errors.isFetchAuthoritiesError,
          isFetchBlocksError: state.errors.isFetchBlocksError,
          isEmptyBlocksError: state.errors.isEmptyBlocksError,
          isMailTokenTimeError: state.errors.isMailTokenTimeError
        }
      });
    case SET_ERROR_MSG_OF_USER_STATE:
      return Object.assign({}, state, {
        errors: {
          msg: action.string,
          isFetchRegionError: state.errors.isFetchRegionError,
          validationOfRegisterUserInfo:
            state.errors.validationOfRegisterUserInfo,
          isRegisterError: state.errors.isRegisterError,
          isFetchPrefecturesError: state.errors.isFetchPrefecturesError,
          isFetchAuthoritiesError: state.errors.isFetchAuthoritiesError,
          isFetchBlocksError: state.errors.isFetchBlocksError,
          isEmptyBlocksError: state.errors.isEmptyBlocksError,
          isMailTokenTimeError: state.errors.isMailTokenTimeError
        }
      });
    case REQUEST_REGISTER_USER_INFO:
      return Object.assign({}, state, {
        isRegisteringUser: true
      });
    case SUCCESS_REGISTER_USER_INFO:
      return Object.assign({}, state, {
        isRegisteringUser: false,
        isSuccessUserRegistration: true
      });
    case ERROR_REGISTER_USER_INFO:
      return Object.assign({}, state, {
        isRegisteringUser: false,
        errors: {
          msg: state.errors.msg,
          isFetchRegionError: state.errors.isFetchRegionError,
          //validationOfRegisterUserInfo: action.errors,
          validationOfRegisterUserInfo: Object.assign(
            {},
            state.errors.validationOfRegisterUserInfo,
            action.errors
          ),
          isRegisterError: state.errors.isRegisterError,
          isFetchPrefecturesError: state.errors.isFetchPrefecturesError,
          isFetchAuthoritiesError: state.errors.isFetchAuthoritiesError,
          isFetchBlocksError: state.errors.isFetchBlocksError,
          isEmptyBlocksError: state.errors.isEmptyBlocksError,
          isMailTokenTimeError: state.errors.isMailTokenTimeError
        }
      });
    case SET_IS_SUCCESS_USER_REGISTRATION:
      return Object.assign({}, state, {
        isSuccessUserRegistration: action.bool
      });
    case CATCH_ERROR_REGISTER_USER_INFO:
      return Object.assign({}, state, {
        isRegisteringUser: false,
        errors: {
          msg: action.errorMsg,
          isFetchRegionError: state.errors.isFetchRegionError,
          validationOfRegisterUserInfo:
            state.errors.validationOfRegisterUserInfo,
          isRegisterError: true,
          isFetchPrefecturesError: state.errors.isFetchPrefecturesError,
          isFetchAuthoritiesError: state.errors.isFetchAuthoritiesError,
          isFetchBlocksError: state.errors.isFetchBlocksError,
          isEmptyBlocksError: state.errors.isEmptyBlocksError,
          isMailTokenTimeError: state.errors.isMailTokenTimeError
        }
      });
    case SET_IS_REGISTER_ERROR:
      return Object.assign({}, state, {
        errors: {
          msg: state.errors.msg,
          isFetchRegionError: state.errors.isFetchRegionError,
          validationOfRegisterUserInfo:
            state.errors.validationOfRegisterUserInfo,
          isRegisterError: action.bool,
          isFetchPrefecturesError: state.errors.isFetchPrefecturesError,
          isFetchAuthoritiesError: state.errors.isFetchAuthoritiesError,
          isFetchBlocksError: state.errors.isFetchBlocksError,
          isEmptyBlocksError: state.errors.isEmptyBlocksError,
          isMailTokenTimeError: state.errors.isMailTokenTimeError
        }
      });
    case RESET_VALIDATION_MESSAGE_OF_REGISTER_USER_INFO:
      return Object.assign({}, state, {
        errors: {
          msg: state.errors.msg,
          isFetchRegionError: state.errors.isFetchRegionError,
          validationOfRegisterUserInfo: {
            prefecture_id: [],
            authority_id: [],
            block_id: [],
            street_name: [],
            house_type_id: [],
            residence_name: [],
            room_number: [],
            email: [],
            password: [],
            password_confirmation: []
          },
          isRegisterError: state.errors.isRegisterError,
          isFetchPrefecturesError: state.errors.isFetchPrefecturesError,
          isFetchAuthoritiesError: state.errors.isFetchAuthoritiesError,
          isFetchBlocksError: state.errors.isFetchBlocksError,
          isEmptyBlocksError: state.errors.isEmptyBlocksError,
          isMailTokenTimeError: state.errors.isMailTokenTimeError
        }
      });
    case MODIFY_REGISTER_USER_FORM:
      return Object.assign({}, state, {
        form: Object.assign({}, state.form, action.values)
      });
    case CHANGE_AVAILABLE_REGISTER_PAGE:
      const tmpAvailableRegisterPage = Object.assign(
        {},
        state.availableRegisterPage,
        action.values
      );
      return Object.assign({}, state, {
        availableRegisterPage: tmpAvailableRegisterPage
      });
    case RESET_USER_STATE:
      return Object.assign({}, state, {
        isGettingRegion: false,
        regions: {
          authority: {
            id: "",
            name: ""
          },
          prefecture: {
            id: "",
            name: ""
          },
          blocks: []
        },
        errors: {
          msg: "",
          isFetchRegionError: false,
          validationOfRegisterUserInfo: {
            prefecture_id: [],
            authority_id: [],
            block_id: [],
            street_name: [],
            house_type_id: [],
            residence_name: [],
            room_number: [],
            email: [],
            password: [],
            password_confirmation: []
          },
          isRegisterError: false,
          isFetchPrefecturesError: false,
          isFetchAuthoritiesError: false,
          isFetchBlocksError: false,
          isEmptyBlocksError: false,
          isMailTokenTimeError: false
        },
        form: {
          prefecture_id: "",
          authority_id: "",
          block_id: "",
          street_name: "",
          house_type_id: "",
          residence_name: "",
          room_number: "",
          email: "",
          password: "",
          password_confirmation: "",
          prefecture_value: "",
          authority_value: "",
          block_value: ""
        },
        isOtherRegionMode: false,
        isRegisteringUser: false,
        isSuccessUserRegistration: false,
        availableRegisterPage: {
          select_region: true,
          more_information: false,
          check_mail: false
        },
        addressOptions: {
          isFetchingPrefectures: false,
          prefectures: [],
          initFetchPrefectures: false,
          isFetchingAuthorities: false,
          authorities: [],
          isFetchingBlocks: false,
          blocks: []
        },
        uid: ""
      });
    case REQUEST_FETCH_PREFECTURES:
      return Object.assign({}, state, {
        addressOptions: {
          isFetchingPrefectures: true,
          prefectures: state.addressOptions.prefectures,
          initFetchPrefectures: state.addressOptions.initFetchPrefectures,
          isFetchingAuthorities: state.addressOptions.isFetchingAuthorities,
          authorities: state.addressOptions.authorities,
          isFetchingBlocks: state.addressOptions.isFetchingBlocks,
          blocks: state.addressOptions.blocks
        }
      });
    case SUCCESS_FETCH_PREFECTURES:
      return Object.assign({}, state, {
        addressOptions: {
          isFetchingPrefectures: false,
          prefectures: action.prefectures,
          initFetchPrefectures: true,
          isFetchingAuthorities: state.addressOptions.isFetchingAuthorities,
          authorities: state.addressOptions.authorities,
          isFetchingBlocks: state.addressOptions.isFetchingBlocks,
          blocks: state.addressOptions.blocks
        }
      });
    case ERROR_FETCH_PREFECTURES:
      return Object.assign({}, state, {
        errors: {
          msg: action.errorMsg,
          isFetchRegionError: state.errors.isFetchRegionError,
          validationOfRegisterUserInfo:
            state.errors.validationOfRegisterUserInfo,
          isRegisterError: state.errors.isRegisterError,
          isFetchPrefecturesError: true,
          isFetchAuthoritiesError: state.errors.isFetchAuthoritiesError,
          isFetchBlocksError: state.errors.isFetchBlocksError,
          isEmptyBlocksError: state.errors.isEmptyBlocksError,
          isMailTokenTimeError: state.errors.isMailTokenTimeError
        },
        addressOptions: {
          isFetchingPrefectures: false,
          prefectures: state.addressOptions.prefectures,
          initFetchPrefectures: state.addressOptions.initFetchPrefectures,
          isFetchingAuthorities: state.addressOptions.isFetchingAuthorities,
          authorities: state.addressOptions.authorities,
          isFetchingBlocks: state.addressOptions.isFetchingBlocks,
          blocks: state.addressOptions.blocks
        }
      });
    case SET_IS_FETCH_PREFECTURES_ERROR:
      return Object.assign({}, state, {
        errors: {
          msg: state.errors.msg,
          isFetchRegionError: state.errors.isFetchRegionError,
          validationOfRegisterUserInfo:
            state.errors.validationOfRegisterUserInfo,
          isRegisterError: state.errors.isRegisterError,
          isFetchPrefecturesError: action.bool,
          isFetchAuthoritiesError: state.errors.isFetchAuthoritiesError,
          isFetchBlocksError: state.errors.isFetchBlocksError,
          isEmptyBlocksError: state.errors.isEmptyBlocksError,
          isMailTokenTimeError: state.errors.isMailTokenTimeError
        }
      });
    case REQUEST_FETCH_AUTHORITIES:
      return Object.assign({}, state, {
        addressOptions: {
          isFetchingPrefectures: state.addressOptions.isFetchingPrefectures,
          prefectures: state.addressOptions.prefectures,
          initFetchPrefectures: state.addressOptions.initFetchPrefectures,
          isFetchingAuthorities: true,
          authorities: state.addressOptions.authorities,
          isFetchingBlocks: state.addressOptions.isFetchingBlocks,
          blocks: state.addressOptions.blocks
        }
      });
    case SUCCESS_FETCH_AUTHORITIES:
      return Object.assign({}, state, {
        addressOptions: {
          isFetchingPrefectures: state.addressOptions.isFetchingPrefectures,
          prefectures: state.addressOptions.prefectures,
          initFetchPrefectures: state.addressOptions.initFetchPrefectures,
          isFetchingAuthorities: false,
          authorities: action.authorities,
          isFetchingBlocks: state.addressOptions.isFetchingBlocks,
          blocks: state.addressOptions.blocks
        }
      });
    case ERROR_FETCH_AUTHORITIES:
      return Object.assign({}, state, {
        errors: {
          msg: action.errorMsg,
          isFetchRegionError: state.errors.isFetchRegionError,
          validationOfRegisterUserInfo:
            state.errors.validationOfRegisterUserInfo,
          isRegisterError: state.errors.isRegisterError,
          isFetchPrefecturesError: state.errors.isFetchPrefecturesError,
          isFetchAuthoritiesError: true,
          isFetchBlocksError: state.errors.isFetchBlocksError,
          isEmptyBlocksError: state.errors.isEmptyBlocksError,
          isMailTokenTimeError: state.errors.isMailTokenTimeError
        },
        addressOptions: {
          isFetchingPrefectures: state.addressOptions.isFetchingPrefectures,
          prefectures: state.addressOptions.prefectures,
          initFetchPrefectures: state.addressOptions.initFetchPrefectures,
          isFetchingAuthorities: false,
          authorities: state.addressOptions.authorities,
          isFetchingBlocks: state.addressOptions.isFetchingBlocks,
          blocks: state.addressOptions.blocks
        }
      });
    case SET_IS_FETCH_AUTHORITIES_ERROR:
      return Object.assign({}, state, {
        errors: {
          msg: state.errors.msg,
          isFetchRegionError: state.errors.isFetchRegionError,
          validationOfRegisterUserInfo:
            state.errors.validationOfRegisterUserInfo,
          isRegisterError: state.errors.isRegisterError,
          isFetchPrefecturesError: state.errors.isFetchPrefecturesError,
          isFetchAuthoritiesError: action.bool,
          isFetchBlocksError: state.errors.isFetchBlocksError,
          isEmptyBlocksError: state.errors.isEmptyBlocksError,
          isMailTokenTimeError: state.errors.isMailTokenTimeError
        }
      });
    case REQUEST_FETCH_BLOCKS:
      return Object.assign({}, state, {
        addressOptions: {
          isFetchingPrefectures: state.addressOptions.isFetchingPrefectures,
          prefectures: state.addressOptions.prefectures,
          initFetchPrefectures: state.addressOptions.initFetchPrefectures,
          isFetchingAuthorities: state.addressOptions.isFetchingAuthorities,
          authorities: state.addressOptions.authorities,
          isFetchingBlocks: true,
          blocks: state.addressOptions.blocks
        }
      });
    case SUCCESS_FETCH_BLOCKS:
      return Object.assign({}, state, {
        addressOptions: {
          isFetchingPrefectures: state.addressOptions.isFetchingPrefectures,
          prefectures: state.addressOptions.prefectures,
          initFetchPrefectures: state.addressOptions.initFetchPrefectures,
          isFetchingAuthorities: state.addressOptions.isFetchingAuthorities,
          authorities: state.addressOptions.authorities,
          isFetchingBlocks: false,
          blocks: action.blocks
        }
      });
    case ERROR_EMPTY_BLOCKS:
      return Object.assign({}, state, {
        errors: {
          msg: action.errorMsg,
          isFetchRegionError: state.errors.isFetchRegionError,
          validationOfRegisterUserInfo:
            state.errors.validationOfRegisterUserInfo,
          isRegisterError: state.errors.isRegisterError,
          isFetchPrefecturesError: state.errors.isFetchPrefecturesError,
          isFetchAuthoritiesError: state.errors.isFetchAuthoritiesError,
          isFetchBlocksError: state.errors.isFetchBlocksError,
          isEmptyBlocksError: true,
          isMailTokenTimeError: state.errors.isMailTokenTimeError
        },
        addressOptions: {
          isFetchingPrefectures: state.addressOptions.isFetchingPrefectures,
          prefectures: state.addressOptions.prefectures,
          initFetchPrefectures: state.addressOptions.initFetchPrefectures,
          isFetchingAuthorities: state.addressOptions.isFetchingAuthorities,
          authorities: state.addressOptions.authorities,
          isFetchingBlocks: false,
          blocks: state.addressOptions.blocks
        }
      });
    case ERROR_FETCH_BLOCKS:
      return Object.assign({}, state, {
        errors: {
          msg: action.errorMsg,
          isFetchRegionError: state.errors.isFetchRegionError,
          validationOfRegisterUserInfo:
            state.errors.validationOfRegisterUserInfo,
          isRegisterError: state.errors.isRegisterError,
          isFetchPrefecturesError: state.errors.isFetchPrefecturesError,
          isFetchAuthoritiesError: state.errors.isFetchAuthoritiesError,
          isFetchBlocksError: true,
          isEmptyBlocksError: state.errors.isEmptyBlocksError,
          isMailTokenTimeError: state.errors.isMailTokenTimeError
        },
        addressOptions: {
          isFetchingPrefectures: state.addressOptions.isFetchingPrefectures,
          prefectures: state.addressOptions.prefectures,
          initFetchPrefectures: state.addressOptions.initFetchPrefectures,
          isFetchingAuthorities: state.addressOptions.isFetchingAuthorities,
          authorities: state.addressOptions.authorities,
          isFetchingBlocks: false,
          blocks: state.addressOptions.blocks
        }
      });
    case SET_IS_EMPTY_BLOCKS_ERROR:
      return Object.assign({}, state, {
        errors: {
          msg: state.errors.msg,
          isFetchRegionError: state.errors.isFetchRegionError,
          validationOfRegisterUserInfo:
            state.errors.validationOfRegisterUserInfo,
          isRegisterError: state.errors.isRegisterError,
          isFetchPrefecturesError: state.errors.isFetchPrefecturesError,
          isFetchAuthoritiesError: state.errors.isFetchAuthoritiesError,
          isFetchBlocksError: state.errors.isFetchBlocksError,
          isEmptyBlocksError: action.bool,
          isMailTokenTimeError: state.errors.isMailTokenTimeError
        }
      });
    case SET_IS_FETCH_BLOCKS_ERROR:
      return Object.assign({}, state, {
        errors: {
          msg: state.errors.msg,
          isFetchRegionError: state.errors.isFetchRegionError,
          validationOfRegisterUserInfo:
            state.errors.validationOfRegisterUserInfo,
          isRegisterError: state.errors.isRegisterError,
          isFetchPrefecturesError: state.errors.isFetchPrefecturesError,
          isFetchAuthoritiesError: state.errors.isFetchAuthoritiesError,
          isFetchBlocksError: action.bool,
          isEmptyBlocksError: state.errors.isEmptyBlocksError,
          isMailTokenTimeError: state.errors.isMailTokenTimeError
        }
      });
    case SET_PREFECTURES:
      return Object.assign({}, state, {
        addressOptions: {
          isFetchingPrefectures: state.addressOptions.isFetchingPrefectures,
          prefectures: action.values,
          initFetchPrefectures: state.addressOptions.initFetchPrefectures,
          isFetchingAuthorities: state.addressOptions.isFetchingAuthorities,
          authorities: state.addressOptions.authorities,
          isFetchingBlocks: state.addressOptions.isFetchingBlocks,
          blocks: state.addressOptions.blocks
        }
      });
    case SET_AUTHORITIES:
      return Object.assign({}, state, {
        addressOptions: {
          isFetchingPrefectures: state.addressOptions.isFetchingPrefectures,
          prefectures: state.addressOptions.prefectures,
          initFetchPrefectures: state.addressOptions.initFetchPrefectures,
          isFetchingAuthorities: state.addressOptions.isFetchingAuthorities,
          authorities: action.values,
          isFetchingBlocks: state.addressOptions.isFetchingBlocks,
          blocks: state.addressOptions.blocks
        }
      });
    case SET_BLOCKS:
      return Object.assign({}, state, {
        addressOptions: {
          isFetchingPrefectures: state.addressOptions.isFetchingPrefectures,
          prefectures: state.addressOptions.prefectures,
          initFetchPrefectures: state.addressOptions.initFetchPrefectures,
          isFetchingAuthorities: state.addressOptions.isFetchingAuthorities,
          authorities: state.addressOptions.authorities,
          isFetchingBlocks: state.addressOptions.isFetchingBlocks,
          blocks: action.values
        }
      });
    case SET_THE_VALUE_OF_FORM_AUTHORITY_ID:
      return Object.assign({}, state, {
        form: Object.assign({}, state.form, {
          authority_id: action.value
        })
      });
    case SET_THE_VALUE_OF_FORM_BLOCK_ID:
      return Object.assign({}, state, {
        form: Object.assign({}, state.form, {
          block_id: action.value
        })
      });
    case RESET_INIT_FETCH_PREFECTURES:
      return Object.assign({}, state, {
        addressOptions: {
          isFetchingPrefectures: state.addressOptions.isFetchingPrefectures,
          prefectures: state.addressOptions.prefectures,
          initFetchPrefectures: false,
          isFetchingAuthorities: state.addressOptions.isFetchingAuthorities,
          authorities: state.addressOptions.authorities,
          isFetchingBlocks: state.addressOptions.isFetchingBlocks,
          blocks: state.addressOptions.blocks
        }
      });
    case ERROR_MAIL_TOKEN_TIME:
      return Object.assign({}, state, {
        errors: {
          msg: action.errorMsg,
          isFetchRegionError: state.errors.isFetchRegionError,
          validationOfRegisterUserInfo:
            state.errors.validationOfRegisterUserInfo,
          isRegisterError: state.errors.isRegisterError,
          isFetchPrefecturesError: state.errors.isFetchPrefecturesError,
          isFetchAuthoritiesError: state.errors.isFetchAuthoritiesError,
          isFetchBlocksError: state.errors.isFetchBlocksError,
          isEmptyBlocksError: state.errors.isEmptyBlocksError,
          isMailTokenTimeError: true
        }
      });
    case SET_IS_MAIL_TOKEN_TIME_ERROR:
      return Object.assign({}, state, {
        errors: {
          msg: state.errors.msg,
          isFetchRegionError: state.errors.isFetchRegionError,
          validationOfRegisterUserInfo:
            state.errors.validationOfRegisterUserInfo,
          isRegisterError: state.errors.isRegisterError,
          isFetchPrefecturesError: state.errors.isFetchPrefecturesError,
          isFetchAuthoritiesError: state.errors.isFetchAuthoritiesError,
          isFetchBlocksError: state.errors.isFetchBlocksError,
          isEmptyBlocksError: state.errors.isEmptyBlocksError,
          isMailTokenTimeError: action.bool
        }
      });
    case SUCCESS_REGISTER_ADDRESS:
      return Object.assign({}, state, {
        uid: action.uid
      });
    case SET_TOKEN:
      return Object.assign({}, state, {
        uid: action.uid
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        uid: action.uid
      });
    default:
      return state;
  }
};

export default user;
