import {
  CHANGE_SETTINGS_LANG,
  REQUEST_FETCH_PREFECTURES,
  SUCCESS_FETCH_PREFECTURES,
  ERROR_FETCH_PREFECTURES,
  MODIFY_FORM_VALUES,
  REQUEST_FETCH_AUTHORITIES,
  SUCCESS_FETCH_AUTHORITIES,
  ERROR_FETCH_AUTHORITIES,
  REQUEST_FETCH_BLOCKS,
  SUCCESS_FETCH_BLOCKS,
  ERROR_EMPTY_BLOCKS,
  ERROR_FETCH_BLOCKS,
  SET_ADDRESS_OPTIONS,
  REQUEST_UPDATE_ADDRESS,
  SUCCESS_UPDATE_ADDRESS,
  ERROR_UPDATE_ADDRESS,
  ERROR_AUTH_UPDATE_ADDRESS,
  ERROR_FETCH_UPDATE_ADDRESS,
  RESET_VALIDATION_MESSAGES,
  SET_ADDRESS_MSG,
  SET_IS_EMPTY_BLOCKS_ERROR,
  SET_IS_SUCCESS_UPDATE_ADDRESS,
  SET_IS_FETCH_PREFECTURES_ERROR,
  SET_IS_FETCH_AUTHORITIES_ERROR,
  SET_IS_FETCH_BLOCKS_ERROR,
  SET_IS_ERROR_FETCH_UPDATE_ADDRESS,
  REQUEST_UPDATE_PASSWORD,
  SUCCESS_UPDATE_PASSWORD,
  ERROR_UPDATE_PASSWORD,
  ERROR_AUTH_UPDATE_PASSWORD,
  ERROR_FETCH_UPDATE_PASSWORD,
  SET_PASSWORD_MSG,
  SET_IS_SUCCESS_UPDATE_PASSWORD,
  SET_IS_ERROR_FETCH_UPDATE_PASSWORD,
  RESET_PASSWORD_VALIDATION_MESSAGES
} from "../actions/settings";

const Settings = (
  state = {
    language: {
      lang: ""
    },
    account: {
      address: {
        addressOptions: {
          prefectures: [],
          authorities: [],
          blocks: []
        },
        fetching: {
          isFetchingPrefectures: false,
          isFetchingAuthorities: false,
          isFetchingBlocks: false,
          isFetchingUpdateAddress: false
        },
        flags: {
          isFetchPrefecturesError: false,
          isFetchAuthoritiesError: false,
          isEmptyBlocksError: false,
          isFetchBlocksError: false,
          isSeccessUpdateAddress: false,
          isErrorFetchUpdateAddress: false
        },
        msg: "",
        formValues: {
          prefecture_id: "",
          authority_id: "",
          block_id: "",
          street_name: "",
          house_type_id: "",
          residence_name: "",
          room_number: "",
          prefecture_value: "",
          authority_value: "",
          block_value: "",
          district_id: ""
        },
        validationMessages: {
          prefecture_id: [],
          authority_id: [],
          block_id: [],
          street_name: [],
          house_type_id: [],
          residence_name: [],
          room_number: []
        }
      },
      password: {
        fetching: {
          isFetchingUpdatePassword: false
        },
        flags: {
          isSuccessUpdatePassword: false,
          isErrorFetchUpdatePassword: false
        },
        msg: "",
        validationMessages: {
          password: [],
          password_confirmation: []
        }
      }
    }
  },
  action
) => {
  switch (action.type) {
    case CHANGE_SETTINGS_LANG:
      return Object.assign({}, state, {
        language: Object.assign({}, state.language, {
          lang: action.lang
        })
      });
    case REQUEST_FETCH_PREFECTURES:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            fetching: Object.assign({}, state.account.address.fetching, {
              isFetchingPrefectures: true
            })
          })
        })
      });
    case SUCCESS_FETCH_PREFECTURES:
      const prefectureIndex = action.prefectures.findIndex(function(element) {
        return element.id == state.account.address.formValues.prefecture_value;
      });
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            fetching: Object.assign({}, state.account.address.fetching, {
              isFetchingPrefectures: false
            }),
            addressOptions: Object.assign(
              {},
              state.account.address.addressOptions,
              {
                prefectures: action.prefectures
              }
            ),
            formValues: Object.assign({}, state.account.address.formValues, {
              prefecture_id: prefectureIndex === -1 ? "" : prefectureIndex
            })
          })
        })
      });
    case ERROR_FETCH_PREFECTURES:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            fetching: Object.assign({}, state.account.address.fetching, {
              isFetchingPrefectures: false
            }),
            flags: Object.assign({}, state.account.address.flags, {
              isFetchPrefecturesError: true
            }),
            msg: action.errorMsg
          })
        })
      });
    case MODIFY_FORM_VALUES:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            formValues: Object.assign(
              {},
              state.account.address.formValues,
              action.values
            )
          })
        })
      });
    case REQUEST_FETCH_AUTHORITIES:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            fetching: Object.assign({}, state.account.address.fetching, {
              isFetchingAuthorities: true
            })
          })
        })
      });
    case SUCCESS_FETCH_AUTHORITIES:
      const authorityIndex = action.authorities.findIndex(function(element) {
        return element.id == state.account.address.formValues.authority_value;
      });
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            fetching: Object.assign({}, state.account.address.fetching, {
              isFetchingAuthorities: false
            }),
            addressOptions: Object.assign(
              {},
              state.account.address.addressOptions,
              {
                authorities: action.authorities
              }
            ),
            formValues: Object.assign({}, state.account.address.formValues, {
              authority_id: authorityIndex === -1 ? "" : authorityIndex
            })
          })
        })
      });
    case ERROR_FETCH_AUTHORITIES:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            fetching: Object.assign({}, state.account.address.fetching, {
              isFetchingAuthorities: false
            }),
            flags: Object.assign({}, state.account.address.flags, {
              isFetchAuthoritiesError: true
            }),
            msg: action.errorMsg
          })
        })
      });
    case REQUEST_FETCH_BLOCKS:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            fetching: Object.assign({}, state.account.address.fetching, {
              isFetchingBlocks: true
            })
          })
        })
      });
    case SUCCESS_FETCH_BLOCKS:
      const blockIndex = action.blocks.findIndex(function(element) {
        return element.id == state.account.address.formValues.block_value;
      });
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            fetching: Object.assign({}, state.account.address.fetching, {
              isFetchingBlocks: false
            }),
            addressOptions: Object.assign(
              {},
              state.account.address.addressOptions,
              {
                blocks: action.blocks
              }
            ),
            formValues: Object.assign({}, state.account.address.formValues, {
              block_id: blockIndex === -1 ? "" : blockIndex
            })
          })
        })
      });
    case ERROR_EMPTY_BLOCKS:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            fetching: Object.assign({}, state.account.address.fetching, {
              isFetchingBlocks: false
            }),
            flags: Object.assign({}, state.account.address.flags, {
              isEmptyBlocksError: true
            }),
            msg: action.errorMsg
          })
        })
      });
    case ERROR_FETCH_BLOCKS:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            fetching: Object.assign({}, state.account.address.fetching, {
              isFetchingBlocks: false
            }),
            flags: Object.assign({}, state.account.address.flags, {
              isFetchBlocksError: true
            }),
            msg: action.errorMsg
          })
        })
      });
    case SET_ADDRESS_OPTIONS:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            addressOptions: Object.assign(
              {},
              state.account.address.addressOptions,
              action.values
            )
          })
        })
      });
    case REQUEST_UPDATE_ADDRESS:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            fetching: Object.assign({}, state.account.address.fetching, {
              isFetchingUpdateAddress: true
            })
          })
        })
      });
    case SUCCESS_UPDATE_ADDRESS:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            fetching: Object.assign({}, state.account.address.fetching, {
              isFetchingUpdateAddress: false
            }),
            flags: Object.assign({}, state.account.address.flags, {
              isSeccessUpdateAddress: true
            }),
            msg: action.msg
          })
        })
      });
    case ERROR_UPDATE_ADDRESS:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            fetching: Object.assign({}, state.account.address.fetching, {
              isFetchingUpdateAddress: false
            }),
            validationMessages: Object.assign(
              {},
              state.account.address.validationMessages,
              action.errors
            )
          })
        })
      });
    case ERROR_AUTH_UPDATE_ADDRESS:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            fetching: Object.assign({}, state.account.address.fetching, {
              isFetchingUpdateAddress: false
            })
          })
        })
      });
    case ERROR_FETCH_UPDATE_ADDRESS:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            fetching: Object.assign({}, state.account.address.fetching, {
              isFetchingUpdateAddress: false
            }),
            flags: Object.assign({}, state.account.address.flags, {
              isErrorFetchUpdateAddress: true
            }),
            msg: action.errorMsg
          })
        })
      });
    case RESET_VALIDATION_MESSAGES:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            validationMessages: Object.assign(
              {},
              state.account.address.validationMessages,
              {
                prefecture_id: [],
                authority_id: [],
                block_id: [],
                street_name: [],
                house_type_id: [],
                residence_name: [],
                room_number: []
              }
            )
          })
        })
      });
    case SET_ADDRESS_MSG:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            msg: action.msg
          })
        })
      });
    case SET_IS_EMPTY_BLOCKS_ERROR:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            flags: Object.assign({}, state.account.address.flags, {
              isEmptyBlocksError: action.bool
            })
          })
        })
      });
    case SET_IS_SUCCESS_UPDATE_ADDRESS:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            flags: Object.assign({}, state.account.address.flags, {
              isSeccessUpdateAddress: action.bool
            })
          })
        })
      });
    case SET_IS_FETCH_PREFECTURES_ERROR:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            flags: Object.assign({}, state.account.address.flags, {
              isFetchPrefecturesError: action.bool
            })
          })
        })
      });
    case SET_IS_FETCH_AUTHORITIES_ERROR:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            flags: Object.assign({}, state.account.address.flags, {
              isFetchAuthoritiesError: action.bool
            })
          })
        })
      });
    case SET_IS_FETCH_BLOCKS_ERROR:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            flags: Object.assign({}, state.account.address.flags, {
              isFetchBlocksError: action.bool
            })
          })
        })
      });
    case SET_IS_ERROR_FETCH_UPDATE_ADDRESS:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          address: Object.assign({}, state.account.address, {
            flags: Object.assign({}, state.account.address.flags, {
              isErrorFetchUpdateAddress: action.bool
            })
          })
        })
      });
    case REQUEST_UPDATE_PASSWORD:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          password: Object.assign({}, state.account.password, {
            fetching: Object.assign({}, state.account.password.fetching, {
              isFetchingUpdatePassword: true
            })
          })
        })
      });
    case SUCCESS_UPDATE_PASSWORD:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          password: Object.assign({}, state.account.password, {
            fetching: Object.assign({}, state.account.password.fetching, {
              isFetchingUpdatePassword: false
            }),
            flags: Object.assign({}, state.account.password.flags, {
              isSuccessUpdatePassword: true
            }),
            msg: action.msg
          })
        })
      });
    case ERROR_UPDATE_PASSWORD:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          password: Object.assign({}, state.account.password, {
            fetching: Object.assign({}, state.account.password.fetching, {
              isFetchingUpdatePassword: false
            }),
            validationMessages: Object.assign(
              {},
              state.account.password.validationMessages,
              action.errors
            )
          })
        })
      });
    case ERROR_AUTH_UPDATE_PASSWORD:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          password: Object.assign({}, state.account.password, {
            fetching: Object.assign({}, state.account.password.fetching, {
              isFetchingUpdatePassword: false
            })
          })
        })
      });
    case ERROR_FETCH_UPDATE_PASSWORD:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          password: Object.assign({}, state.account.password, {
            fetching: Object.assign({}, state.account.password.fetching, {
              isFetchingUpdatePassword: false
            }),
            flags: Object.assign({}, state.account.password.flags, {
              isErrorFetchUpdatePassword: true
            }),
            msg: action.msg
          })
        })
      });
    case SET_PASSWORD_MSG:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          password: Object.assign({}, state.account.password, {
            msg: action.msg
          })
        })
      });
    case SET_IS_SUCCESS_UPDATE_PASSWORD:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          password: Object.assign({}, state.account.password, {
            flags: Object.assign({}, state.account.password.flags, {
              isSuccessUpdatePassword: action.bool
            })
          })
        })
      });
    case SET_IS_ERROR_FETCH_UPDATE_PASSWORD:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          password: Object.assign({}, state.account.password, {
            flags: Object.assign({}, state.account.password.flags, {
              isErrorFetchUpdatePassword: action.bool
            })
          })
        })
      });
    case RESET_PASSWORD_VALIDATION_MESSAGES:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          password: Object.assign({}, state.account.password, {
            validationMessages: Object.assign(
              {},
              state.account.password.validationMessages,
              {
                password: [],
                password_confirmation: []
              }
            )
          })
        })
      });
    default:
      return state;
  }
};

export default Settings;
