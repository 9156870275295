import robotIcon from "../assets/images/icon_robot_body_english.svg";

const pt_PT = {
  'log.in': 'Conecte-se',
  'already.use.gommit?': 'Já usam GOMMIT?',
  '1.select.your.region': '1. Selecione sua área',
  'use.the.other.region': 'Use a outra área',
  'dont.you.have.an.account?': 'Você não tem uma conta?',
  'sign.up': 'inscrever-se',
  'email': 'O email',
  'password': 'Senha',
  'login': 'Conecte-se',
  'forget.password': 'Esqueceu a senha',
  'getting.your.location.data': 'Obtendo seus dados de localização ...',
  '2.input.more.information': '2. informações de entrada mais',
  'retry': 'Repetir',
  'input.manually': 'manualmente entrada',
  'prefecture': 'Prefeitura',
  'city': 'Cidade',
  'region': 'Área',
  'address': 'Endereço',
  'type.of.residence': 'Tipo de residência',
  'residence.name': 'Residence Nome',
  'room.number': 'Número do quarto',
  'your.mail': 'Seu email',
  'login.password': 'Senha de login',
  'required': 'requeridos',
  'register': 'Registro',
  'upcoming.schedule': 'agenda futura',
  'cancel': 'Cancelar',
  'trash.name': 'Nome lixo',
  'type': 'Tipo',
  'next.collection.date': 'Próxima coleção da data',
  'validation.invalid.email': 'E-mail inválido',
  'validation.required': 'Requeridos',
  'please.select': 'Por favor selecione',
  '3.check.the.confirmation.email.and.complete.the.registration': '3. Verifique o e-mail de confirmação e complete o registo',
  'check.mailbox': 'verificação de caixa postal',
  'start': 'Começar',
  'ready.to.use.gommit!': 'Pronto para usar GOMMIT!',
  'registering': 'Registrando ...',
  'connecting': 'Conectando ...',
  'back.to.login': 'Volte ao login',
  'reset.password': 'Password Reset',
  'ok': 'Está bem',
  'home': 'Casa',
  'gommit': 'GOMMIT',
  'ai.helps.you': 'AI ajuda você',
  'reward': 'Recompensa',
  'how.to.dispose': 'Como eliminar',
  'dictionary': 'Índice',
  'oversized': 'grande demais',
  'burnable.trash': 'Lixo incinerável',
  'unburnable.trash': 'Trash Unburnable',
  'harmful.trash': 'Lixo Nocivo',
  'oversized.trash': 'lixo de grande porte',
  'plastic': 'Plástico',
  'books.magazines': 'Livros, Revistas',
  'cardboard': 'Cartão',
  'milk.carton': 'Caixa de leite',
  'paper.newspaper': 'Livro, Jornal',
  'cans.bottles': 'Latas, garrafas',
  'metals': 'metais',
  'clothes': 'Roupas',
  'recyclable.trash': 'Lixo reciclável',
  'get.again': 'obter novamente',
  'how.to.dispose.of.tab': 'Como eliminar',
  'items': 'Itens',
  'notes': 'Notas',
  'see.detail': 'Veja detalhes',
  'faq': 'Perguntas frequentes',
  'information': 'Em formação',
  'oversized.consultation': 'lixo de grande porte',
  'you.cannot.dispose.oversized.trashes.on.general.ways': 'Descarte de lixo de grande porte segue um processo diferente',
  'type.your.text': 'Digite o texto',
  'enter.the.name.of.the.trash.you.want.to.check': 'Digite o nome do lixo que você deseja verificar.',
  'settings': 'Configurações',
  'account': 'Conta',
  'notifications': 'notificações',
  'language': 'Língua',
  'sns.integration': 'Integração SNS',
  'save': 'Salve ',
  'select.language': 'Selecione o idioma',
  'english': 'Inglês',
  'japanese': 'japonês',
  'reward.programs': 'Programas de recompensa',
  'total.points': 'Total de pontos',
  'start.reward': 'Começar',
  'special.reward': 'Especial\nReward',
  'password.confirmation': 'ConfirmaÇão Da Senha',
  'please.enter.a.password.with.at.least.6.characters': 'Por favor, digite uma senha com pelo menos 6 caracteres.',
  'logout': 'Sair',
  'based.on.information.of.local.government.website': 'Com base no website do governo local',
  'notice': 'Aviso prévio',
  'search': 'Procurar',
  'definition.of.oversized.garbage': 'Definição de "Lixo de grandes dimensões"',
  'how.to.dispose.of.oversized.garbage': 'Como eliminar',
  'ticket.prices': 'taxa de emissão de bilhete para o descarte de lixo de grande porte\n* Taxa pode variar em certos casos',
  'dispose.step.of.oversized.garbage': '1. Aplique no centro de recepção\n' +
    '2.Purchase um bilhete de coleta de lixo volumoso em um revendedor designado\n' +
    '3.Trash em dias designados',
  'see.on.the.local.government.website': 'Verifique no seu governo local site da HP',
  'not.found.': 'Não encontrado.',
  'plain.address': 'Endereço',
  'invisible': 'Invisível',
  'return': 'Retorna',
  'upcoming': 'Próximos.',
  'apartment': 'Apartment',
  'house': 'House',
  'failed.to.get.information.': 'Failed to get information.',
  'communication.failed.': 'Communication failed.{v1}',
  'failed.to.get.your.location.': 'Failed to get your location.',
  'not.compatible.with.your.area.': 'Not compatible with your area.',
  'the.password.was.updated.successfully.': 'The password was updated successfully.',
  'the.address.was.successfully.updated.': 'The address was successfully updated.',
  'failed.to.get.event.': 'Failed to get event.',
  'failed.to.get.list.': 'Failed to get list.',
  'incorrect.email.or.password.': 'Incorrect email or password.',
  'failed.to.get.faq.': 'Failed to get FAQ.',
  'mail.token.error': 'This e-mail address is temporarily registered.\nYou can’t register with this email address until {v1}',
  'january': 'january',
  'february': 'february',
  'march': 'march',
  'april': 'april',
  'may': 'may',
  'june': 'june',
  'july': 'july',
  'august': 'august',
  'september': 'september',
  'october': 'october',
  'november': 'november',
  'december': 'december',
  'date1': '{v3} {v1}',
  'sunday': 'sunday',
  'monday': 'monday',
  'tuesday': 'tuesday',
  'wednesday': 'wednesday',
  'thursday': 'thursday',
  'friday': 'friday',
  'saturday': 'saturday',
  'week1': '{v2}',
  'date2': '{v2} {v3}',
  'week2': '{v2}',
  'today': 'Today',
  'robot.icon': robotIcon,
  'click.here.for.how.to.put.out': 'Click here for how to put out {v1}',
  'next': 'next',
  'rough.address': '{v3}, {v2}, {v1}',
  'the.password.reset.email.has.been.sent.successfully.': 'An email has been sent to \'{v1}\' containing instructions for resetting your password.',
  'unable.to.find.user.with.email': 'Unable to find user with email \'{v1}\'',
  'please.log.in.again.': 'Please log in again.',
  'an.error.has.occurred.please.send.an.email.again.': 'An error has occurred.\nPlease send an email again.',
  'news': 'News',
};
export default pt_PT;