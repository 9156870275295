import React from "react";
import style from "./ContentBoxGroup.module.scss";
import { Div, Span, P } from "../../atoms/Html/Html";
import ContentBox from "../../molecules/ContentBox/ContentBox";
import Item from "../../atoms/Item/Item";
import ItemButton from "../../molecules/ItemButton/ItemButton";
import Question from "../../molecules/Question/Question";
import Answer from "../../molecules/Answer/Answer";
import Heading from "../../atoms/Heading/Heading";
import { getDay, getFormattedMessage } from "../../../constants/functions";

import {
  monthEnglishList,
  weekEnglishList
} from "../../../constants/constants";

/*
 ** 引数
 * contents : array (object)
 *
 ** contentsの構造
 * type : str
 * heading : str
 * headingIcon : img_path
 * exLinkUrl : str
 * exLinkText : str
 * body : object
 * component : component
 * componentArgs : object
 *
 ** bodyの構造
 * text : str
 * src : img_path
 * question : str
 * answer : str
 * textStyle : str
 * date: date
 *
 */

const ContentBoxGroup = props => {
  const contentBoxes = props.contents.map((content, index) => {
    // componentを優先して表示
    let body = content.component
      ? content.component(content.componentArgs)
      : null;
    if (!body) {
      switch (content.type) {
        case "item":
          body = <Item text={content.body.text} />;
          break;
        case "trashCategory":
          body = (
            <ItemButton
              rightContentType={content.body.onClick ? "arrow" : null}
              text={content.body.text}
              src={content.body.src}
              function={content.body.onClick}
            />
          );
          break;
        case "date":
          const month = content.body.date.getMonth();
          const date = content.body.date.getDate();
          const day = content.body.date.getDay();
          const week = getFormattedMessage(weekEnglishList[day]);
          const monthStr = getFormattedMessage(monthEnglishList[month]);
          const dateBlock = (
            <>
              <Span className={style.contentBoxGroup__contentBox__date__large}>
                {getFormattedMessage("week2", week.slice(0, 1), week.charAt(0).toUpperCase() + week.slice(1, 3))}
              </Span>
              {getFormattedMessage("date2", month + 1, monthStr.charAt(0).toUpperCase() + monthStr.slice(1, 3), date)}
            </>
          );
          body = (
            <Div className={style.contentBoxGroup__contentBox__date}>
              {dateBlock}
            </Div>
          );
          break;
        case "faq":
          body = (
            <>
              <Question text={content.body.question} />
              <Answer text={content.body.answer} />
            </>
          );
          break;
        case "base":
          body = (
            <Div
              className={
                style.contentBoxGroup__contentBox__baseBlock +
                " " +
                content.body.blockStyle
              }
            >
              {content.body.title && (
                <Heading
                  text={content.body.title}
                  className={
                    style.contentBoxGroup__contentBox__baseBlock__title
                  }
                />
              )}
              {content.body.date && (
                <P
                  className={style.contentBoxGroup__contentBox__baseBlock__date}
                >
                  {content.body.date}
                </P>
              )}
              {content.body.text && (
                <P
                  className={
                    style.contentBoxGroup__contentBox__baseBlock__text +
                    " " +
                    content.body.textStyle
                  }
                >
                  {content.body.text}
                </P>
              )}
            </Div>
          );
          break;
        default:
          body = null;
      }
    }
    return (
      <ContentBox
        key={index}
        heading={content.heading}
        headingIcon={content.headingIcon}
        exLinkUrl={content.exLinkUrl}
        exLinkText={content.exLinkText}
        className={content.contentBoxStyle}
      >
        {body}
      </ContentBox>
    );
  });

  return <Div className={style.contentBoxGroup}>{contentBoxes}</Div>;
};

export default ContentBoxGroup;
